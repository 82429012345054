import { Button, ButtonProps } from '@mui/material';

export const KIconButton = (properties: ButtonProps) => (
  <Button
    variant="outlined"
    sx={{
      minWidth: '2rem',
      mr: 1,
      pr: 2.3,
    }}
    {...properties}
  />
);
