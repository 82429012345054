import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useRecoilValue } from 'recoil';

import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { KaeplaTargets } from '../../../services/types/Application/KaeplaTargets';
import { scopePathSimplified } from '../../helpers/scopePathSimplified';

interface Options {
  targets: KaeplaTargets;
  open: boolean;
  handleClose: () => void;
  deleteTargets: () => void;
}

export const TargetsDeleteDialog = ({ targets, open, handleClose, deleteTargets }: Options) => {
  const project = useRecoilValue(projectState);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle data-testid="dialog-title">Delete targets</DialogTitle>
      <DialogContent sx={{ py: 0 }}>
        <Typography variant="caption">
          {[project.name, ...scopePathSimplified(targets.scopePath)].join(' ➔ ')}
        </Typography>
      </DialogContent>
      <DialogContent>
        <DialogContentText data-testid="delete-info">
          This will delete everything in your project and can't be undone. Along with the project
          associated data, project assignments, simulations, targets, commitments will be deleted.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="button-cancel-delete"
          onClick={(event) => {
            event.stopPropagation();
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          data-testid="button-delete"
          onClick={(event) => {
            event.stopPropagation();
            void deleteTargets();
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
