import StarIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

interface Options {
  setStep: (step: number) => void;
  loading: boolean;
}
export const Step0Setup = ({ setStep, loading }: Options) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading ? <LinearProgress /> : <Box sx={{ height: 4 }} />}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Set up a perspective
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <List>
          <ListItem disablePadding>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <StarIcon />
            </ListItemIcon>
            <ListItemText primary="Choose Aggregation Dimensions" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <StarIcon />
            </ListItemIcon>
            <ListItemText primary="Choose Time Dimensions" />
          </ListItem>
          <ListItem disablePadding>
            <ListItemIcon sx={{ minWidth: 30 }}>
              <StarIcon />
            </ListItemIcon>
            <ListItemText primary="Configure Insights" />
          </ListItem>
        </List>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Button
          variant="contained"
          size="large"
          disabled={loading}
          onClick={() => {
            setStep(1);
          }}
        >
          Set up
        </Button>
      </Grid>
    </Grid>
  );
};
