import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

interface Options {
  currency: string | undefined;
  setCurrency: Dispatch<SetStateAction<string | undefined>>;
}

export const CurrencySelect = ({ currency, setCurrency }: Options) => {
  const handleChange = (event: SelectChangeEvent) => {
    setCurrency(event.target.value);
  };

  return (
    <Select size="small" value={currency} label="Currency" onChange={handleChange}>
      <MenuItem value="EUR">EUR</MenuItem>
      <MenuItem value="USD">USD</MenuItem>
      <MenuItem value="CNY">CNY</MenuItem>
    </Select>
  );
};
