import { Grid, TextField, Button, Typography, Alert } from '@mui/material';
import localforage from 'localforage';
import Image from 'mui-image';
import { useState } from 'react';

import { useAuth } from '../../../../AuthProvider';
import { KaeplaBranding } from '../../../../services/types/Application/KaeplaBranding';

interface Options {
  branding: KaeplaBranding;
}

export const BrandedRequestEmail = ({ branding }: Options) => {
  const [email, setEmail] = useState<string>('');
  const { signInWithLink } = useAuth();

  const loginWithInviteLink = async () => {
    await localforage.setItem('emailForSignIn', email);
    void signInWithLink();
  };

  return (
    <Grid
      container
      spacing={3}
      // direction="column"
      // justifyContent="center"
      alignItems="center"
    >
      {branding?.appLogoUrl && (
        <Grid item xs={12} container justifyContent="center">
          <Image width={200} alt={branding?.appName} src={branding?.appLogoUrl} />
        </Grid>
      )}
      {branding?.appName && !branding.appLogoUrl && (
        <Grid item xs={12}>
          <Typography sx={{ color: '#000' }} variant="h2">
            {branding.appName}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Alert variant="outlined" severity="info">
          Please enter the email addres for which you received the invite.
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Email"
          size="small"
          fullWidth
          sx={{ minWidth: 250 }}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          autoFocus
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={() => {
            void loginWithInviteLink();
          }}
        >
          Login
        </Button>
      </Grid>
    </Grid>
  );
};
