import styled from '@emotion/styled';
import SimulationIcon from '@mui/icons-material/AutoGraphOutlined';
import AssignmentsIcon from '@mui/icons-material/ChecklistOutlined';
import LogsIcon from '@mui/icons-material/CodeOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TargetsIcon from '@mui/icons-material/FilterCenterFocusOutlined';
import ResellerIcon from '@mui/icons-material/KeyOutlined';
import ScopesIcon from '@mui/icons-material/LayersOutlined';
import UsersIcon from '@mui/icons-material/People';
import UserIcon from '@mui/icons-material/PersonOutlined';
import PerspectivesIcon from '@mui/icons-material/QueryStatsOutlined';
import CustomerIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import EventsIcon from '@mui/icons-material/ViewTimelineOutlined';
import { Divider } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { customerAssignmentState } from '../../services/recoil/nonpersistent/customerAssignmentState';
import { resellerAssignmentsState } from '../../services/recoil/nonpersistent/resellerAssignmentState';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState';
import { pageState } from '../../services/recoil/persistent/pageState';
import {
  assignmentsColor,
  eventsColor,
  perspectivesColor,
  projectsColor,
  scopesColor,
  simulationsColor,
  targetsColor,
  matrixDataColorLight,
} from '../Screens/defaults';

interface Options {
  hasScope?: boolean;
}

const StyledListItemButton = styled(({ ...other }) => (
  <ListItemButton data-custom="custom" {...other} />
))`
  &.Mui-selected {
    border-right: 5px solid ${matrixDataColorLight};
  }
`;

export const Menu = ({ hasScope }: Options) => {
  const navigate = useNavigate();
  const page = useRecoilValue(pageState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const resellerAssignments = useRecoilValue(resellerAssignmentsState);
  const customerAssignments = useRecoilValue(customerAssignmentState);

  return (
    <>
      <StyledListItemButton
        selected={page === '/Projects'}
        data-testid="menu-Dashboard"
        onClick={() => navigate('/')}
      >
        <ListItemIcon>
          <DashboardIcon sx={{ color: projectsColor }} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </StyledListItemButton>
      {hasScope && (
        <>
          <StyledListItemButton
            selected={page === '/Assignments'}
            data-testid="menu-Assignments"
            onClick={() => navigate('/Assignments')}
          >
            <ListItemIcon>
              <AssignmentsIcon sx={{ color: assignmentsColor }} />
            </ListItemIcon>
            <ListItemText primary="Assignments" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={page === '/Perspectives'}
            data-testid="menu-Perspectives"
            onClick={() => navigate('/Perspectives')}
          >
            <ListItemIcon>
              <PerspectivesIcon sx={{ color: perspectivesColor }} />
            </ListItemIcon>
            <ListItemText primary="Perspectives" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={page.startsWith('/Simulation')}
            data-testid="menu-Simulations"
            onClick={() => navigate('/Simulations')}
          >
            <ListItemIcon>
              <SimulationIcon sx={{ color: simulationsColor }} />
            </ListItemIcon>
            <ListItemText primary="Simulations" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={page === '/Scopes'}
            data-testid="menu-Scopes"
            onClick={() => navigate('/Scopes')}
          >
            <ListItemIcon>
              <ScopesIcon sx={{ color: scopesColor }} />
            </ListItemIcon>
            <ListItemText primary="Scopes" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={page.startsWith('/Targets')}
            data-testid="menu-Targets"
            onClick={() => navigate('/Targets')}
          >
            <ListItemIcon>
              <TargetsIcon sx={{ color: targetsColor }} />
            </ListItemIcon>
            <ListItemText primary="Targets" />
          </StyledListItemButton>
          <StyledListItemButton
            selected={page === '/Events'}
            data-testid="menu-Events"
            onClick={() => navigate('/Events')}
          >
            <ListItemIcon>
              <EventsIcon sx={{ color: eventsColor }} />
            </ListItemIcon>
            <ListItemText primary="Events" />
          </StyledListItemButton>
        </>
      )}
      <Divider />
      <StyledListItemButton
        selected={page === '/Users'}
        data-testid="menu-Users"
        onClick={() => navigate('/Users')}
      >
        <ListItemIcon>
          <UsersIcon />
        </ListItemIcon>
        <ListItemText primary="Users" />
      </StyledListItemButton>
      <StyledListItemButton
        selected={page === '/User'}
        data-testid="menu-User"
        onClick={() => navigate('/User')}
      >
        <ListItemIcon>
          <UserIcon />
        </ListItemIcon>
        <ListItemText primary="User" />
      </StyledListItemButton>
      {(customerAssignments.length > 0 || resellerAssignments.length > 0) && (
        <StyledListItemButton
          selected={page === '/Customers'}
          data-testid="menu-Customers"
          onClick={() => navigate('/Customers')}
        >
          <ListItemIcon>
            <CustomerIcon />
          </ListItemIcon>
          <ListItemText primary="Customers" />
        </StyledListItemButton>
      )}
      {(kaeplaAssignment || resellerAssignments.length > 0) && (
        <StyledListItemButton
          selected={page === '/Resellers'}
          data-testid="menu-Resellers"
          onClick={() => navigate('/Resellers')}
        >
          <ListItemIcon>
            <ResellerIcon />
          </ListItemIcon>
          <ListItemText primary="Resellers" />
        </StyledListItemButton>
      )}
      {kaeplaAssignment?.devTeamMember && <Divider />}
      {kaeplaAssignment?.devTeamMember && (
        <StyledListItemButton
          selected={page === '/Logs'}
          data-testid="menu-Logs"
          onClick={() => navigate('/Logs')}
        >
          <ListItemIcon>
            <LogsIcon />
          </ListItemIcon>
          <ListItemText primary="Logs" />
        </StyledListItemButton>
      )}
    </>
  );
};
