import { JsonTree } from '@react-awesome-query-builder/mui';
import { getApp } from 'firebase/app';
import { collection, DocumentData, getFirestore, doc, setDoc } from 'firebase/firestore';
import { DateTime } from 'luxon';

import { KaeplaFilter } from '../types/Application/KaeplaFilter';
import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  project: KaeplaProject;
  createdBy: string;
  scopePath: string[];
  filterTree: JsonTree;
  isCopy?: boolean;
  filterName?: string;
}

export const saveFilterAsPreset = async ({
  project,
  scopePath,
  createdBy,
  filterTree,
  isCopy,
  filterName,
}: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/filters`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const documentReference = doc(firestoreCollectionReference);
  const date = DateTime.now();
  const humanReadable = date.toLocaleString(DateTime.DATETIME_MED);
  const filterPreset: Partial<KaeplaFilter> = {
    id: documentReference.id,
    name: `${filterName || 'New filter preset'} ${isCopy ? '' : humanReadable}${
      isCopy ? '(copy)' : ''
    }`,
    createdBy,
    scopePath,
    scopePathStringified: JSON.stringify(scopePath),
    description: `${filterName || 'New filter preset'}${
      isCopy ? ' copy' : ''
    } saved on ${humanReadable}`,
    filterTree,
    filterTreeStringified: JSON.stringify(filterTree),
  };
  await setDoc(documentReference, filterPreset as DocumentData);
  return filterPreset as KaeplaFilter;
};
