import { atom } from 'recoil';

import { localForageEffect } from './localForageEffect';
import { MatrixTimeSlice } from '../../types/MatrixTimeSlice';

const defaultTimeSliceState: MatrixTimeSlice = 'MONTH' as MatrixTimeSlice;

export const timeSliceState = atom<MatrixTimeSlice>({
  key: 'timeSlice',
  default: defaultTimeSliceState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
