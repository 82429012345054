import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger';

interface KOptions {
  params: Record<string, unknown>;
}
export const runKaeplaSimulation = ({ params }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const runSimulation = httpsCallable(functions, 'runKaeplaSimulation');

  requestLog.log(`runSimulation.post().json(${JSON.stringify(params)})`);
  void runSimulation(params);
};
