import { Stepper, Step, StepLabel, Box } from '@mui/material';

import { StepLabels } from './StepLabels';

interface Options {
  activeStep: number;
}

export const Steps = ({ activeStep }: Options) => {
  if (activeStep < 1) return null;
  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {StepLabels.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
