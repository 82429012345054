import { DocumentData } from 'firebase/firestore';

import { requestLog } from '../../Frontend/helpers/logger';
import { watcherServiceKaepla } from '../../firebaseInit';

interface Options {
  fireStorePath: string;
  callback: (document: DocumentData | undefined) => void;
}

export const addFirestoreDocumentListener = ({ fireStorePath, callback }: Options) => {
  const firestoreService = watcherServiceKaepla.get();
  return firestoreService.subscribeToDocument({
    path: fireStorePath,
    onSnapshot: (snapshot, key) => {
      requestLog.log(`firestore documentListener subscribe`, fireStorePath);
      const document = snapshot.data();
      if (document) {
        document.id = snapshot.id;
      }
      void callback(document);
    },
    onError: (error) => {
      requestLog.log(`firestore documentListener error`, error);
      return false;
    },
  });
};
