import iwanthue from 'iwanthue';

import { getStackedBarChartBorders } from './getStackedBarChartBorders';
import { KaeplaSimulation } from '../../../../../../../services/types/Application/KaeplaSimulation';
import { MatrixMultiTimeseriesEntry } from '../../../../../../../services/types/MatrixMultiTimeseriesEntry';
import { matrixDataColor, simulationDataColor } from '../../../../../defaults';
import { currentDataColorShift, simulationDataColorShift } from '../../helpers/chartColors';
import { KaeplaChartLabel } from '../../helpers/createChartLabels';

interface TOptions {
  timeSeries: MatrixMultiTimeseriesEntry[];
  datasets: string[];
  chartLabels: KaeplaChartLabel[];
  simulation?: Partial<KaeplaSimulation>;
  timeSeriesSimulated?: MatrixMultiTimeseriesEntry[];
}

export interface KaeplaChartDatasets {
  labels: string[];
  datasets: KaeplaChartDataset[];
}

export interface KaeplaChartDataset {
  label: string;
  data: number[];
  backgroundColor: string;
  borderWidth: Record<string, number> | number;
  borderStyle?: string;
  borderColor: string;
  stack: string;
  hidden?: boolean;
}

export const getChartDataForStackedBarChart = ({
  timeSeries,
  datasets,
  chartLabels,
  simulation,
  timeSeriesSimulated,
}: TOptions) => {
  const palette = iwanthue(20);
  const ticks = timeSeries.map((t) => t.date);

  const chartDatasets: KaeplaChartDataset[] = [];

  datasets.forEach((dataset, index) => {
    chartDatasets.push({
      label: dataset,
      data: ticks.map((tick) => {
        return timeSeries.find((t) => t.date === tick)?.values[dataset] || 0;
      }),
      backgroundColor: currentDataColorShift(
        chartLabels?.find((c) => c.datasetName === dataset)?.color || palette[index],
      ),
      borderWidth: getStackedBarChartBorders(datasets, index, !!simulation),
      borderColor: currentDataColorShift(matrixDataColor),
      stack: 'Original Data',
    });
  });

  if (simulation && timeSeriesSimulated) {
    datasets.forEach((dataset, index) => {
      chartDatasets.push({
        label: dataset,
        data: ticks.map((tick) => {
          return timeSeriesSimulated.find((t) => t.date === tick)?.values[dataset] || 0;
        }),
        backgroundColor: simulationDataColorShift(
          chartLabels?.find((c) => c.datasetName === dataset)?.color || palette[index],
        ),
        borderWidth: {
          top: index === datasets.length - 1 ? 1 : 0,
          bottom: 0,
          left: 1,
          right: 1,
        },
        borderStyle: 'dashed',
        borderColor: simulationDataColorShift(simulationDataColor),
        stack: 'Simulation',
      });
    });
  }

  return {
    labels: ticks,
    datasets: chartDatasets,
  };
};
