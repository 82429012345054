import { atom } from 'recoil';

import { localForageEffect } from './localForageEffect';
import { KaeplaAssignment } from '../../types/Application/KaeplaAssignment';

const defaultKaeplaAssignmentState: KaeplaAssignment | undefined = undefined;

export const kaeplaAssignmentState = atom<KaeplaAssignment | undefined>({
  key: 'kaeplaAssignments',
  default: defaultKaeplaAssignmentState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
