import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger';
import { KaeplaApiParameters } from '../types/Application/KaeplaApiParameters';
import { MatrixApiResponse } from '../types/MatrixApiResponse';

type DataReturn = (data: MatrixApiResponse) => void;
interface KOptions {
  callBack?: DataReturn;
  errorCallBack?: (error: Error) => void;
  setLoading?: (loading: boolean) => void;
  params: KaeplaApiParameters;
  uid?: string;
}

export const getFromKaepla = ({ callBack, errorCallBack, setLoading, params, uid }: KOptions) => {
  if (typeof setLoading === 'function') {
    setLoading(true);
  }

  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const getKaeplaMatrix = httpsCallable(functions, 'getKaeplaMatrix');

  requestLog.log(`kaeplaMatrix.post().json(${JSON.stringify({ ...params, uid })})`);
  void getKaeplaMatrix(params)
    .then((result) => {
      // console.log("loading", params.q, loading);
      if (typeof setLoading === 'function') {
        setLoading(false);
      }
      if (callBack) {
        callBack(result.data as MatrixApiResponse);
      }
    })
    .catch((error: Error) => {
      if (errorCallBack) {
        errorCallBack(error);
      }
    });
};
