import { getApp } from 'firebase/app';
import { doc, getFirestore, setDoc, Timestamp } from 'firebase/firestore';

import { KaeplaPerspectiveFavorite } from '../types/Application/KaeplaPerspectiveFavorite';
import { KaeplaProject } from '../types/Application/KaeplaProject';
import { KaeplaSimulation } from '../types/Application/KaeplaSimulation';

interface Options {
  uid: string;
  perspective: Partial<KaeplaSimulation>;
  scopePath: string[];
  isFavorite: boolean;
  project: KaeplaProject;
}

export const togglePerspectiveFavorite = async ({
  uid,
  perspective,
  isFavorite,
  scopePath,
  project,
}: Options) => {
  if (!uid || !perspective.id || !project.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `perspectiveFavorites/${uid}-${perspective.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);

  const update: KaeplaPerspectiveFavorite = {
    id: `${uid}-${perspective.id}`,
    uid,
    projectId: project.id,
    perspectiveId: perspective.id,
    scopePath,
    scopePathStringified: JSON.stringify(scopePath),
    isFavorite,
    updatedAt: Timestamp.now(),
  };

  return setDoc(firestoreDocumentReference, update, {
    merge: true,
  });
};
