import { JsonRuleGroup, JsonTree, RuleProperties } from '@react-awesome-query-builder/mui';

export const getRuleCountFromFilterTree = (filterTree: JsonTree) => {
  const ruleGroups = filterTree.children1 as unknown as JsonRuleGroup[];
  if (!ruleGroups) return 0;
  const groupChildren = ruleGroups.map((g) => g.children1);
  const groupChildrenFlat = groupChildren.flat().filter((rule) => {
    if (!rule?.properties) return false;
    const properties = rule.properties as RuleProperties;
    let isIncompleteRule = false;
    if (properties.field && properties.field === null) {
      isIncompleteRule = true;
    }
    if (properties.value && properties.value !== null) {
      isIncompleteRule = true;
    }
    if (properties.value[0] && properties.value[0] === undefined) {
      isIncompleteRule = true;
    }
    return isIncompleteRule;
  });
  return groupChildrenFlat.length;
};
