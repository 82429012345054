import { ThemeOptions } from '@mui/material';

import { mainColor, secondaryColor } from './colors';

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: mainColor,
    },
    secondary: {
      main: secondaryColor,
    },
  },
  typography: {
    body2: {
      fontWeight: 300,
      lineHeight: 1.3,
      fontSize: '0.9rem',
    },
  },
};
