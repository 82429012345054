/* eslint-disable import/no-unassigned-import */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { useTheme } from '@mui/material/styles';
import Uppy from '@uppy/core';
import ImageEditor from '@uppy/image-editor';
import { Dashboard as UppyDashboard } from '@uppy/react';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import Webcam from '@uppy/webcam';
import { useEffect, useMemo } from 'react';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import { avatarSize } from '../../Screens/defaults';

function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

interface Options {
  show: boolean;
  callback: (base64: string) => void;
}

// const thumbSize = 250;
function imageToDataUri(img: HTMLImageElement, size: number, quality: number) {
  // create an off-screen canvas
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const ratio = Math.min(size / img.width, size / img.height);
  const w = img.width * ratio;
  const h = img.height * ratio;
  const canvasSize = w > h ? w : h;
  canvas.width = canvasSize;
  canvas.height = canvasSize;
  const sx = w < h ? w / 2 : 0;
  const sy = h < w ? h / 2 : 0;

  // draw source image into the off-screen canvas:
  if (context) {
    context.imageSmoothingEnabled = true;
    context.drawImage(img, sx, sy, w, h);
    // console.log("--->", sx, sy, w, h);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL('image/jpeg', quality); // quality = [0.0, 1.0]
  }
  return 'n/a';
}

export const ImageUpload = ({ show, callback }: Options) => {
  const theme = useTheme();
  // Avatar Image uploading
  const uppy = useMemo(() => {
    return new Uppy({
      restrictions: {
        maxFileSize: null,
        minFileSize: null,
        maxTotalFileSize: null,
        maxNumberOfFiles: 1,
        minNumberOfFiles: null,
        allowedFileTypes: null,
      },
    })
      .use(Webcam)
      .use(ImageEditor, {
        // id: "MyImageEditor",
        quality: 0.8,
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          croppedCanvasOptions: {},
        },
        actions: {
          revert: true,
          rotate: true,
          granularRotate: false,
          flip: false,
          zoomIn: true,
          zoomOut: true,
          cropSquare: true,
          cropWidescreen: false,
          cropWidescreenVertical: false,
        },
      })
      .use(ThumbnailGenerator)
      .on('thumbnail:generated', (file, preview) => {
        const convert = async () => {
          const base64 = (await blobToBase64(file.data)) as string;
          const img = document.createElement('img');
          img.src = base64;
          img.addEventListener('load', () => {
            const resizedImage = imageToDataUri(img, avatarSize, 0.6);
            callback(resizedImage);
          });
        };
        void convert();
      });
  }, [callback]);

  useEffect(() => {
    return () => uppy.close();
  }, [uppy]);

  return (
    <UppyDashboard
      plugins={['ImageEditor', 'Webcam']}
      metaFields={[{ id: 'name', name: 'Name', placeholder: 'File name' }]}
      proudlyDisplayPoweredByUppy={false}
      hideUploadButton
      disableStatusBar
      disableInformer
      theme={theme.palette.mode}
      uppy={uppy}
      height={350}
      width="100%"
    />
  );
};
