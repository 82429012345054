import SimulationIcon from '@mui/icons-material/AutoGraphOutlined';
import { Box, Grid, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { KaeplaSimulation } from '../../../../services/types/Application/KaeplaSimulation';
import { KaeplaUser } from '../../../../services/types/Application/KaeplaUser';
import { UserAvatar } from '../../../features/UserAvatar';
import { simulationsColor } from '../../defaults';

export interface Options {
  simulation?: KaeplaSimulation;
  owner?: KaeplaUser;
  own: boolean;
}

export const SimulationEditorHeader = ({ own, simulation, owner }: Options) => {
  if (!simulation || !owner || own) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Stack direction="row" spacing={2}>
        {owner && <UserAvatar user={owner} />}
        <Grid2 container xs={10}>
          <Grid2 xs={12}>
            <Stack direction="row" spacing={1}>
              <SimulationIcon sx={{ color: simulationsColor }} fontSize="small" />
              <Box>{simulation.name}</Box>
            </Stack>
          </Grid2>
          <Grid2 xs={12}>{simulation.description}</Grid2>
        </Grid2>
      </Stack>
    </Grid>
  );
};
