import { JsonGroup, JsonTree } from '@react-awesome-query-builder/mui';
import { atom } from 'recoil';

import { localForageEffect } from './localForageEffect';

const queryValue: JsonGroup = { id: 'init', type: 'group' };

export const defaultFilterState: JsonTree = queryValue;

export const filterTreeState = atom<JsonTree>({
  key: 'filterTree',
  default: defaultFilterState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
