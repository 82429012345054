import { createContext, useCallback, useContext, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useAuth } from './AuthProvider';
import { createOrUpdateUserPreferences } from './services/firestore/createOrUpdateUserPreferences';
import { getUserPreferences } from './services/firestore/getUserPreferences';
import { getUserPreferencesId } from './services/firestore/getUserPreferencesId';
import { projectState } from './services/recoil/nonpersistent/projectState';
import { userPreferencesState } from './services/recoil/nonpersistent/userPreferencesState';
import { currentScopePathState } from './services/recoil/persistent/currentScopePathState';
import { KaeplaUserPreferences } from './services/types/Application/KaeplaUserPreferences';

export interface UserPreferencesContextType {
  userPreferences: KaeplaUserPreferences[];
  getPreferences: () => KaeplaUserPreferences | undefined;
  setPreferences: (preferences: KaeplaUserPreferences) => void;
}

interface UserPreferencesProviderProperties {
  children?: React.ReactNode;
}

export interface UserPreferencesContextModel {
  userPreferences: KaeplaUserPreferences[];
  getPreferences: () => KaeplaUserPreferences | undefined;
  setPreferences: (preferences: KaeplaUserPreferences) => void;
}

export const UserPreferencesContext = createContext<UserPreferencesContextType>(
  {} as UserPreferencesContextType,
);

export function useUserPreferences(): UserPreferencesContextModel {
  return useContext(UserPreferencesContext);
}

export const UserPreferencesProvider = ({
  children,
}: UserPreferencesProviderProperties): JSX.Element => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [userPreferences, setUserPreferences] = useRecoilState(userPreferencesState);

  const getPreferences = useCallback(() => {
    if (!project?.id) return;
    const preferences = userPreferences.find(
      (p) =>
        p.projectId === project.id && p.scopePathStringified === JSON.stringify(currentScopePath),
    );
    return preferences;
  }, [currentScopePath, project?.id, userPreferences]);

  const setPreferences = useCallback(
    (incomingPreferences: KaeplaUserPreferences) => {
      if (!kaeplaUser?.uid) return;
      if (!project?.id) return;
      const existingPreferences = getPreferences();
      const preferences = { ...existingPreferences, ...incomingPreferences };
      if (!existingPreferences?.id) {
        preferences.id = getUserPreferencesId();
      }
      preferences.uid = kaeplaUser?.uid;
      preferences.projectId = project.id;
      preferences.scopePath = currentScopePath;
      preferences.scopePathStringified = JSON.stringify(currentScopePath);

      void createOrUpdateUserPreferences({
        preferences,
      });

      const _userPreferences = [...userPreferences].map((p) => {
        if (
          p.projectId === project.id &&
          p.scopePathStringified === JSON.stringify(currentScopePath)
        ) {
          return { ...p, ...preferences };
        }
        return p;
      });

      setUserPreferences(_userPreferences);
    },
    [
      currentScopePath,
      getPreferences,
      kaeplaUser?.uid,
      project?.id,
      setUserPreferences,
      userPreferences,
    ],
  );

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    const load = async () => {
      const initialUserPreferences = await getUserPreferences({ uid: kaeplaUser.uid });
      setUserPreferences(initialUserPreferences);
    };
    void load();
  }, [kaeplaUser?.uid, setUserPreferences]);

  const values: UserPreferencesContextModel = {
    userPreferences,
    getPreferences,
    setPreferences,
  };

  return (
    <UserPreferencesContext.Provider value={values}>{children}</UserPreferencesContext.Provider>
  );
};
