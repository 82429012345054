import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useRecoilValue } from 'recoil';
import { matrixLoadingState } from 'src/services/recoil/nonpersistent/matrixLoadingState';

import { matrixFilteredState } from '../../../../../../services/recoil/nonpersistent/matrixFilteredState';
import { KaeplaProject } from '../../../../../../services/types/Application/KaeplaProject';
import { BQJobInfo } from '../../../../../features/BQJobInfo';

interface Options {
  project: KaeplaProject;
}

export const ProjectDimensionsCount = ({ project }: Options) => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const matrixLoading = useRecoilValue(matrixLoadingState);

  return (
    <Box>
      <Typography sx={{ fontSize: '100%' }}>Dimensions</Typography>
      <Typography sx={{ fontSize: '90%' }} color="text.secondary">
        {matrixFiltered?.dimensions?.dimensions && !matrixLoading
          ? matrixFiltered.dimensions?.dimensions?.length
          : 'n/a'}{' '}
        {matrixFiltered?.dimensions?.job && <BQJobInfo job={matrixFiltered.dimensions?.job} />}
      </Typography>
    </Box>
  );
};
