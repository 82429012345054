import { getApp } from 'firebase/app';
import {
  collection,
  getDocs,
  getFirestore,
  query,
  Timestamp,
  where,
  writeBatch,
} from 'firebase/firestore';

interface Options {
  uid: string;
}

export const updateProjectAssignmentsUdatedAtForUser = async ({ uid }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projectAssignments`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  let firestoreQuery = query(firestoreCollection);
  firestoreQuery = query(firestoreQuery, where('uid', '==', uid));
  const snapshot = await getDocs(firestoreQuery);

  const batch = writeBatch(firestoreDatabase);
  snapshot.forEach((document) => {
    batch.update(document.ref, { updatedAt: Timestamp.now() });
  });
  void batch.commit();
};
