import { getProject } from './getProject';
import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  projectIds: string[];
}

export const getProjectsFromProjectIds = async ({ projectIds }: Options) => {
  const data: KaeplaProject[] = [];
  const uniqueProjectIds = projectIds.filter((v, index, a) => a.indexOf(v) === index);
  if (uniqueProjectIds.length === 0) return data;
  const projects = await Promise.all(uniqueProjectIds.map(async (id) => getProject({ id })));
  return projects
    .filter((c) => c !== undefined)
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));
};
