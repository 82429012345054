import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../AuthProvider';
import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener';
import { getProjectsFromProjectIds } from '../../services/firestore/getProjectFromProjectIds';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState';
import { knownProjectsState } from '../../services/recoil/nonpersistent/knownProjectsState';
import { projectsOverviewState } from '../../services/recoil/nonpersistent/projectsOverviewState';
import { projectAssignmentsState } from '../../services/recoil/nonpersistent/userAssignmentState';
import { userRootPathsState } from '../../services/recoil/nonpersistent/userRootPathsState';
import { brandingState } from '../../services/recoil/persistent/brandingState';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState';
import { KaeplaProjectAssignment } from '../../services/types/Application/KaeplaProjectAssignment';
import { getUserRootPaths } from '../helpers/getUserRootPaths';
import { logger } from '../helpers/logger';

export const ProjectAssignmentsListenerUsers = () => {
  const { kaeplaUser } = useAuth();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const setKnownProjects = useSetRecoilState(knownProjectsState);
  const setProjectsOverview = useSetRecoilState(projectsOverviewState);
  const setProjectAssignments = useSetRecoilState(projectAssignmentsState);
  const setUserRootPaths = useSetRecoilState(userRootPathsState);
  const branding = useRecoilValue(brandingState);
  const [app, setApp] = useRecoilState(applicationState);

  const loadProjectsForAssignments = useCallback(
    async (projectAssignments: KaeplaProjectAssignment[]) => {
      setApp((previousApp) => ({ ...previousApp, projectsLoaded: false }));
      setProjectAssignments(projectAssignments);
      const userRootPaths = getUserRootPaths(projectAssignments);
      setUserRootPaths(userRootPaths);

      const projectIds = projectAssignments.map((projectAssignment) => projectAssignment.projectId);
      const projectsOrdered = await getProjectsFromProjectIds({ projectIds }); // TODO: can we cache those?

      logger.log('ProjectAssignmentsListenerUsers setKnownProjects', projectsOrdered);
      setKnownProjects(projectsOrdered);
      setProjectsOverview(projectsOrdered);

      logger.log('ProjectAssignmentsListenerUsers setApp projectsLoaded => true');
      setApp((previousApp) => ({ ...previousApp, projectsLoaded: true }));
    },
    [setApp, setKnownProjects, setProjectAssignments, setProjectsOverview, setUserRootPaths],
  );

  useEffect(() => {
    if (kaeplaAssignment || !kaeplaUser?.uid) return; // user is an admin or not authenticated yet
    if (!branding) return; // we always need a branding
    if (app.connecting) return; // we are not connected yet

    logger.log('\nuseEffect ProjectAssignmentsListenerUsers');

    const fireStorePath = `projectAssignments`;
    const queryWhere: WhereClause[] = [];
    // filter by uid for non kaepla admins
    queryWhere.push({
      fieldPath: 'uid',
      opStr: '==',
      value: kaeplaUser.uid,
    });

    logger.log('branding?.realmType', branding.realmType);
    if (branding.realmType === 'customer') {
      logger.log('query by customerId ==', branding.appRealmId);
      // TODO: how can we use KaeplaDomainType.customer instead of 'customer'?
      // customer domain lock through branding
      queryWhere.push({
        fieldPath: 'customerId',
        opStr: '==',
        value: branding.appRealmId,
      });
    } else if (branding.realmType === 'reseller') {
      logger.log('query by resellerId ==', branding.appRealmId);
      // customer domain lock through branding
      queryWhere.push({
        fieldPath: 'resellerId',
        opStr: '==',
        value: branding.appRealmId,
      });
    }

    queryWhere.push({
      fieldPath: 'complete',
      opStr: '==',
      value: true,
    });

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (projectAssignments) => {
        logger.log('ProjectAssignmentsListenerUsers fires:', projectAssignments);
        void loadProjectsForAssignments(projectAssignments as KaeplaProjectAssignment[]);
      },
      context: 'ProjectAssignmentsListenerUsers',
    });
    return () => {
      logger.log('ProjectAssignmentsListenerUsers is unmounted!');
      setApp((previousApp) => ({ ...previousApp, projectsLoaded: false }));
      unsubscribe();
    };
  }, [
    app.connecting,
    branding,
    kaeplaUser?.uid,
    kaeplaAssignment,
    loadProjectsForAssignments,
    setApp,
  ]);

  return null;
};
