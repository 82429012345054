import AddIcon from '@mui/icons-material/AddOutlined';
import SqlOffIcon from '@mui/icons-material/CodeOffOutlined';
import SqlIcon from '@mui/icons-material/CodeOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DuplicateIcon from '@mui/icons-material/FileCopyOutlined';
import FilterIcon from '@mui/icons-material/FilterAlt';
import FilterOffIcon from '@mui/icons-material/FilterAltOffOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import ChooseOffIcon from '@mui/icons-material/SearchOffOutlined';
import ChooseIcon from '@mui/icons-material/SearchOutlined';
import HideIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  Button,
  ButtonGroup,
  Collapse,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from '@mui/material';
import { Utils as QbUtils, Config, ImmutableTree } from '@react-awesome-query-builder/mui';
import { diff } from 'deep-object-diff';
import { DateTime } from 'luxon';
import { clone, isEmpty } from 'rambda';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { FilterPresetSelector } from './FilterPresetSelector';
import { useAuth } from '../../../../AuthProvider';
import { deleteFilter } from '../../../../services/firestore/deleteFilter';
import { getFilter } from '../../../../services/firestore/getFilter';
import { saveFilterAsPreset } from '../../../../services/firestore/saveFilterAsPreset';
import { updateFilter } from '../../../../services/firestore/updateFilter';
import { applicationState } from '../../../../services/recoil/nonpersistent/applicationState';
import { knownProjectFiltersState } from '../../../../services/recoil/nonpersistent/knownProjectFiltersState';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState';
import { filterSettingsState } from '../../../../services/recoil/persistent/filterSettingState';
import { filterTreeState } from '../../../../services/recoil/persistent/filterTreeState';
import { KaeplaFilter } from '../../../../services/types/Application/KaeplaFilter';
import { ExpandMore } from '../../ExpandMore';
import { InlineEdit } from '../../InlineEdit';
import { KIconButton } from '../../KIconButton';

interface Options {
  tree: ImmutableTree;
  config: Config;
  choosePreset: boolean;
  setChoosePreset: Dispatch<SetStateAction<boolean>>;
}

export const FilterToolbar = ({ tree, config, choosePreset, setChoosePreset }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [filterSettings, setFilterSettings] = useRecoilState(filterSettingsState);
  const [knownProjectFilters, setKnownProjectFilters] = useRecoilState(knownProjectFiltersState);
  const resetFilterTree = useResetRecoilState(filterTreeState);
  const resetFilterSettings = useResetRecoilState(filterSettingsState);
  const [application, setApplication] = useRecoilState(applicationState);
  const [filterPreset, setFilterPreset] = useState<KaeplaFilter>();
  const [canSaveFilter, setCanSaveFilter] = useState(false);

  const handleExpandClick = () => {
    setFilterSettings({ ...filterSettings, isExpanded: !filterSettings.isExpanded });
  };

  const handleToggleSqlClick = () => {
    setFilterSettings({ ...filterSettings, showSql: !filterSettings.showSql });
  };

  const handleToggleActiveClick = () => {
    setFilterSettings({ ...filterSettings, isActive: !filterSettings.isActive });
  };

  const saveFilterAsync = async () => {
    if (!kaeplaUser) return;
    if (!filterPreset) return;
    const jsonTree = QbUtils.getTree(tree);
    const updatedFilterPreset = {
      ...filterPreset,
      filterTree: jsonTree,
      filterTreeStringified: JSON.stringify(jsonTree),
    } as KaeplaFilter;
    const _filterPreset = await updateFilter({
      project,
      filter: updatedFilterPreset,
    });
    setFilterPreset(_filterPreset);
    setFilterSettings({ ...filterSettings, filterPresetId: _filterPreset.id });
    const newKnownProjectFilters = [
      ...knownProjectFilters.filter((k) => k.id !== _filterPreset.id),
      _filterPreset,
    ];
    setKnownProjectFilters(newKnownProjectFilters);
    const _canSaveFilter = isFilterSaveable(_filterPreset, newKnownProjectFilters);
    setCanSaveFilter(_canSaveFilter);
  };

  const saveNewFilterAsync = async () => {
    if (!kaeplaUser) return;
    if (!filterPreset?.name) {
      const jsonTree = QbUtils.getTree(tree);
      const _filterPreset = await saveFilterAsPreset({
        createdBy: kaeplaUser.uid,
        filterTree: jsonTree,
        scopePath: currentScopePath,
        project,
        filterName: `New filter ${DateTime.now().toLocaleString(DateTime.DATETIME_MED)}`,
        isCopy: false,
      });
      setFilterPreset(_filterPreset);
      setFilterSettings({ ...filterSettings, filterPresetId: _filterPreset.id });
      setKnownProjectFilters([...knownProjectFilters, _filterPreset]);
      return;
    }
  };

  const saveFilter = () => {
    if (!filterPreset) {
      void saveNewFilterAsync();
      return;
    }
    void saveFilterAsync();
  };

  const duplicateFilterAsync = async () => {
    if (!kaeplaUser) return;
    const jsonTree = QbUtils.getTree(tree);
    const jsonTreeNewId = { ...jsonTree, id: uuidv4() };
    const _filterPreset = await saveFilterAsPreset({
      createdBy: kaeplaUser.uid,
      filterTree: jsonTreeNewId,
      scopePath: currentScopePath,
      project,
      filterName: filterPreset?.name,
      isCopy: true,
    });
    setFilterPreset(_filterPreset);
    setFilterSettings({ ...filterSettings, filterPresetId: _filterPreset.id });
    setKnownProjectFilters([
      ...knownProjectFilters.filter((k) => k.id !== _filterPreset.id),
      _filterPreset,
    ]);
  };

  const duplicateFilter = () => {
    void duplicateFilterAsync();
  };

  const isFilterSaveable = useCallback(
    (_filterPreset: KaeplaFilter | undefined, _knownProjectFilters: KaeplaFilter[]) => {
      if (!filterSettings.isExpanded) {
        return false; // only if expanded
      }
      if (_filterPreset?.name && _filterPreset.createdBy !== kaeplaUser?.uid) {
        return false; // we don't own this
      }
      // did the tree change?
      let jsonTree = {};
      let knownTree = {};
      if (_filterPreset) {
        jsonTree = _filterPreset.filterTree;
        knownTree = _knownProjectFilters?.find((k) => k.id === _filterPreset.id)?.filterTree || {};
      }
      // console.log(
      //   'deepDiff -> changed',
      //   !isEmpty(diff(jsonTree, knownTree)),
      //   diff(jsonTree, knownTree),
      // );
      // console.log(jsonTree, knownTree);
      if (_filterPreset && isEmpty(diff(jsonTree, knownTree))) {
        return false; // tree changed
      }
      return true;
    },
    [filterSettings.isExpanded, kaeplaUser?.uid],
  );

  // check if we can save the filter when the tree or the preset changes
  useEffect(() => {
    if (!kaeplaUser) return;
    const existingPreset = { ...filterPreset } || {};
    const jsonTree = QbUtils.getTree(tree);
    const checkFilterPreset = { ...existingPreset, filterTree: jsonTree } as KaeplaFilter;
    const _canSaveFilter = isFilterSaveable(checkFilterPreset, knownProjectFilters);
    // console.log('check if we can save the filter when the filterTree changes->', _canSaveFilter);
    // const knownTree = knownProjectFilters.find((k) => k.id === filterPreset.id)?.filterTree || {};
    // console.log(jsonTree, knownTree);
    setCanSaveFilter(_canSaveFilter);
  }, [tree, filterPreset, kaeplaUser, isFilterSaveable, knownProjectFilters]);

  useEffect(() => {
    if (!kaeplaUser) return;

    const loadFilterPreset = async () => {
      const filterFromServer = await getFilter({
        projectId: project.id,
        filterId: filterSettings?.filterPresetId || 'none',
      });

      if (filterFromServer) {
        setFilterPreset(filterFromServer);
        const _canSaveFilter = isFilterSaveable(filterFromServer, knownProjectFilters);
        setCanSaveFilter(_canSaveFilter);
      } else {
        // eslint-disable-next-line unicorn/no-useless-undefined
        setFilterPreset(undefined);
      }
    };
    void loadFilterPreset();
  }, [
    filterSettings?.filterPresetId,
    isFilterSaveable,
    kaeplaUser,
    knownProjectFilters,
    project.id,
  ]);

  return (
    <>
      <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
        <Typography sx={{ mt: 1, fontSize: '110%' }}>
          {filterSettings.isActive ? (
            <FilterIcon color="info" />
          ) : (
            <FilterOffIcon color="disabled" />
          )}
        </Typography>
        {filterPreset && (
          <InlineEdit
            fullWidth
            value={filterPreset.name}
            callback={(newValue) => {
              if (filterPreset.createdBy !== kaeplaUser?.uid) return;
              const newFilterPreset = { ...filterPreset };
              newFilterPreset.name = newValue;
              void updateFilter({ project, filter: newFilterPreset });
              setFilterPreset(newFilterPreset);
              setFilterSettings({ ...filterSettings, updatedAt: DateTime.now() });
            }}
            readOnly={filterPreset.createdBy !== kaeplaUser?.uid}
          />
        )}
        {!filterPreset && <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />}

        <ToggleButtonGroup size="small" color="primary" sx={{ mr: 1 }}>
          <ToggleButton
            sx={{ py: 0.5 }}
            value="toggle filter"
            selected={filterSettings.isActive}
            onClick={handleToggleActiveClick}
          >
            {filterSettings.isActive ? (
              <FilterIcon fontSize="small" />
            ) : (
              <FilterOffIcon fontSize="small" />
            )}
          </ToggleButton>
          <ToggleButton
            sx={{ py: 0.5 }}
            value="toggle SQL"
            selected={filterSettings.showSql}
            onClick={handleToggleSqlClick}
          >
            {filterSettings.showSql ? (
              <SqlIcon fontSize="small" />
            ) : (
              <SqlOffIcon fontSize="small" />
            )}
          </ToggleButton>
          <ToggleButton
            sx={{ py: 0.5 }}
            value="choose filter preset"
            selected={filterSettings.isExpanded && choosePreset}
            aria-label="choose preset"
            onClick={() => {
              setChoosePreset(!choosePreset);
            }}
            disabled={!filterSettings.isExpanded}
          >
            {choosePreset ? <ChooseOffIcon fontSize="small" /> : <ChooseIcon fontSize="small" />}
          </ToggleButton>
        </ToggleButtonGroup>
        <ButtonGroup sx={{ mr: 2 }} disableElevation variant="outlined" size="small">
          <Button
            endIcon={<SaveIcon sx={{ m: 0.25, ml: -1 }} />}
            onClick={saveFilter}
            disabled={!canSaveFilter}
          />
          <Button
            endIcon={<AddIcon sx={{ ml: -1 }} />}
            onClick={() => {
              // we "add" a new filter by resetting filters
              resetFilterTree();
              resetFilterSettings();
            }}
            disabled={!filterSettings.filterPresetId}
          />
          <Button
            endIcon={<DuplicateIcon sx={{ ml: -1 }} />}
            onClick={duplicateFilter}
            disabled={!filterSettings.filterPresetId}
          />
        </ButtonGroup>
        <KIconButton
          endIcon={<DeleteIcon sx={{ ml: -1 }} />}
          onClick={() => {
            if (!filterPreset || filterPreset?.createdBy !== kaeplaUser?.uid) return;
            void deleteFilter({ project, filter: filterPreset });
            resetFilterTree();
            resetFilterSettings();
          }}
          disabled={!filterSettings.filterPresetId || filterPreset?.createdBy !== kaeplaUser?.uid}
        />
        <KIconButton
          endIcon={<HideIcon sx={{ ml: -1 }} />}
          onClick={() => {
            const newApplication = clone(application);
            newApplication.showFilter = false;
            setApplication(newApplication);
          }}
        />
        <ExpandMore
          expand={filterSettings.isExpanded}
          onClick={handleExpandClick}
          aria-expanded={filterSettings.isExpanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </Toolbar>
      <Collapse in={choosePreset && filterSettings.isExpanded} timeout="auto">
        <FilterPresetSelector />
      </Collapse>
      {!choosePreset && filterPreset && filterSettings.isExpanded && (
        <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
          <InlineEdit
            fullWidth
            value={filterPreset.description || 'no description'}
            callback={(newValue) => {
              if (filterPreset.createdBy !== kaeplaUser?.uid) return;
              const newFilterPreset = { ...filterPreset };
              newFilterPreset.description = newValue;
              void updateFilter({ project, filter: newFilterPreset });
              setFilterPreset(newFilterPreset);
            }}
            readOnly={filterPreset.createdBy !== kaeplaUser?.uid}
          />
        </Toolbar>
      )}
    </>
  );
};
