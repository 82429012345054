import SimulationIcon from '@mui/icons-material/AutoGraphOutlined';
import TargetsIcon from '@mui/icons-material/FilterCenterFocusOutlined';
import { Badge, Box, Button, Grid, Input, Stack, Toolbar, Typography } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import Countdown from 'react-countdown';
import { useRecoilValue } from 'recoil';
import { InlineEdit } from 'src/Frontend/features/InlineEdit';
import { KaeplaTargets } from 'src/services/types/Application/KaeplaTargets';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';

import { countDownRenderer } from './_helpers/countDownRenderer';
import { runKaeplaSimulation } from '../../../../services/api/runKaeplaSimulation';
import { updateSimulationWithScope } from '../../../../services/firestore/updateSimulationWithScope';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState';
import { KaeplaSimulation } from '../../../../services/types/Application/KaeplaSimulation';
import { KaeplaSimulationRulesetWithParameters } from '../../../../services/types/Application/KaeplaSimulationRulesetWithParameters';
import { BQJobInfo } from '../../../features/BQJobInfo';
import { simulationsColor } from '../../defaults';

interface Options {
  id?: string;
  own: boolean;
  simulation?: KaeplaSimulation;
  simulationRulesets?: KaeplaSimulationRulesetWithParameters[];
  simulatedAt: string;
  targetsForSimulation: KaeplaTargets[];
  setReset: Dispatch<SetStateAction<boolean>>;
  setCreateTargets: Dispatch<SetStateAction<boolean>>;
  createTargets: boolean;
  setRuleset: Dispatch<SetStateAction<KaeplaSimulationRulesetWithParameters | undefined>>;
  preview: boolean;
  setPreview: Dispatch<SetStateAction<boolean>>;
  addingParameter: boolean;
  setAddingParameter: Dispatch<SetStateAction<boolean>>;
}

export const SimulationEditorHeaderOwner = ({
  id,
  own,
  simulation,
  simulationRulesets,
  simulatedAt,
  targetsForSimulation,
  setReset,
  setCreateTargets,
  createTargets,
  setRuleset,
  preview,
  setPreview,
  addingParameter,
  setAddingParameter,
}: Options) => {
  const currentScopePath = useRecoilValue(currentScopePathState);
  const project = useRecoilValue(projectState);

  const changeSimulationName = useDebouncedCallback((newName: string) => {
    if (!simulation) return;
    const simulationUpdate = {
      id: simulation.id,
      name: newName,
    };

    void updateSimulationWithScope({
      project,
      simulation: simulationUpdate,
      scopePath: currentScopePath,
    });
  }, 500);

  const changeSimulationDescription = useDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!project || !simulation) return;
      const simulationUpdate = {
        id: simulation.id,
        description: event.target.value,
      };
      void updateSimulationWithScope({
        project,
        simulation: simulationUpdate,
        scopePath: currentScopePath,
      });
    },
    500,
  );

  if (!simulation || !id || !own) {
    return null;
  }

  return (
    <>
      <Grid item xs={12}>
        <Toolbar disableGutters>
          <Stack direction="row" spacing={1}>
            <SimulationIcon sx={{ color: simulationsColor, mt: 1 }} />
            <Box>
              <Box component="span" sx={{ fontSize: '120%', fontWeight: '500', mr: 3 }}>
                <InlineEdit
                  fullWidth
                  value={simulation.name}
                  changeCallback={(newValue) => {
                    changeSimulationName(newValue);
                  }}
                  callback={(newValue) => {
                    changeSimulationName(newValue);
                  }}
                />
              </Box>
              <Typography variant="caption">
                <Input
                  sx={{ fontSize: '110%' }}
                  disableUnderline
                  fullWidth
                  defaultValue={simulation.description}
                  onChange={changeSimulationDescription}
                />
              </Typography>
            </Box>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Badge overlap="circular" badgeContent={targetsForSimulation.length} color="primary">
            <Button
              variant="outlined"
              size="small"
              sx={{ mr: 1 }}
              disabled={!!simulation.isBeingSimulated}
              onClick={() => setCreateTargets(!createTargets)}
            >
              <TargetsIcon />
            </Button>
          </Badge>
          <Button
            variant="outlined"
            size="small"
            sx={{ mr: 1 }}
            disabled={!preview || !!simulation.isBeingSimulated}
            onClick={() => setReset(true)}
          >
            reset
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => {
              if (!project || !simulation) return null;
              runKaeplaSimulation({
                params: {
                  projectId: project.id,
                  simulationId: id,
                  rulesets: simulationRulesets,
                },
              });
              setPreview(false);
            }}
            disabled={!preview || !!simulation.isBeingSimulated}
          >
            simulate
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const _ruleset: KaeplaSimulationRulesetWithParameters = {
                simulationId: simulation.id,
                id: uuidv4(),
                position: simulationRulesets?.length || 0,
                parameters: [],
              };
              setRuleset(_ruleset);
              setAddingParameter(true);
            }}
            disabled={addingParameter || !!simulation.isBeingSimulated}
          >
            +
          </Button>
        </Toolbar>
      </Grid>
      <Grid item xs={12}>
        <Box
          component="span"
          color="text.secondary"
          sx={{
            fontFamily: 'monospace',
            fontSize: 12,
            mr: 1,
          }}
        >
          {simulatedAt === 'n/a' ? `not yet simulated` : `simulated on ${simulatedAt}`}
        </Box>
        {simulation?.lastSimulationJob && !simulation.isBeingSimulated && (
          <BQJobInfo job={simulation.lastSimulationJob} />
        )}
        {simulation.isBeingSimulated && (
          <Countdown
            date={Date.now() + ((simulation.lastSimulationJob?.ms || 30_000) + 20_000)}
            renderer={countDownRenderer}
          />
        )}
      </Grid>
    </>
  );
};
