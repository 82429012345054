import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../AuthProvider';
import { getUsersForUser } from '../../services/api/getUsersForUser';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState';
import { customerAssignmentState } from '../../services/recoil/nonpersistent/customerAssignmentState';
import { resellerAssignmentsState } from '../../services/recoil/nonpersistent/resellerAssignmentState';
import { projectAssignmentsState } from '../../services/recoil/nonpersistent/userAssignmentState';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState';
import { knownUsersState } from '../../services/recoil/persistent/knownUsersState';
import { logger } from '../helpers/logger';

export const UserListenerUsers = () => {
  const { kaeplaUser } = useAuth();
  const [app, setApp] = useRecoilState(applicationState);
  const setKnownUsers = useSetRecoilState(knownUsersState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const resellerAssignments = useRecoilValue(resellerAssignmentsState);
  const customerAssignments = useRecoilValue(customerAssignmentState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (kaeplaAssignment) return; // admins have their own listener
    if (!app.projectsLoaded || !app.customersLoaded) return;
    if (!app.initialized) return;
    const getUsers = () => {
      const date = DateTime.now();
      const humanReadable = date.toLocaleString(DateTime.DATETIME_MED);
      setApp((previousApp) => ({ ...previousApp, usersLastFetched: humanReadable }));
      getUsersForUser({
        params: { uid: kaeplaUser.uid },
        callBack: (users) => {
          setKnownUsers(users);
        },
      });
    };
    logger.log('getUsersForUser', kaeplaUser.uid);
    getUsers();
  }, [
    app.customersLoaded,
    app.initialized,
    app.projectsLoaded,
    customerAssignments,
    kaeplaAssignment,
    kaeplaUser?.uid,
    projectAssignments,
    resellerAssignments,
    setApp,
    setKnownUsers,
  ]);

  return null;
};
