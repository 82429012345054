import { Link, Typography } from '@mui/material';

import { KaeplaReseller } from '../../../../services/types/Application/KaeplaReseller';

interface Options {
  reseller: KaeplaReseller;
  printAll?: boolean;
}
export const ResellerDomain = ({ reseller, printAll }: Options) => {
  if (!reseller.domains || reseller.domains.length === 0) {
    return <Typography variant="caption">no domain configured</Typography>;
  }

  if (printAll) {
    return (
      <>
        {reseller.domains.map((domain) => {
          const accessUrl = `https://${domain}`;

          return (
            <Link
              key={accessUrl}
              color="inherit"
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
              href={accessUrl}
            >
              <Typography component="div" variant="caption">
                {accessUrl}
              </Typography>
            </Link>
          );
        })}
      </>
    );
  }

  const resellerUrl = `https://${reseller.domains[0]}`;

  return (
    <Link
      color="inherit"
      underline="hover"
      target="_blank"
      rel="noopener noreferrer"
      href={resellerUrl}
    >
      <Typography variant="caption">{resellerUrl}</Typography>
    </Link>
  );
};
