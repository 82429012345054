import { atom } from 'recoil';

import { localForageEffect } from './localForageEffect';

export const pageState = atom({
  key: 'page',
  default: '/',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
