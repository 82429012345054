import Grid from '@mui/material/Grid';
import { useRecoilValue } from 'recoil';

import { DataPreview } from './features/DataPreview/DataPreview';
import { DataTimeline } from './features/DataTimeline/DataTimeline';
import { ProjectSummary } from './features/ProjectSummary/ProjectSummary';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState';
import { Layout } from '../../Layout/Layout';
import { Filters } from '../../features/Filters/Filters';

export const DefaultPerspective = () => {
  const application = useRecoilValue(applicationState);

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={4}>
          <ProjectSummary />
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <DataTimeline />
        </Grid>
        <Grid sx={{ display: application.showFilter ? 'block' : 'none' }} item xs={12}>
          <Filters />
        </Grid>
        <Grid item xs={12}>
          <DataPreview />
        </Grid>
      </Grid>
    </Layout>
  );
};
