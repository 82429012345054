import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAuth } from 'src/AuthProvider';
import { addFirestoreCollectionListener } from 'src/services/firestore/addFirestoreCollectionListener';
import { projectState } from 'src/services/recoil/nonpersistent/projectState';
import { simulationFavoritesState } from 'src/services/recoil/nonpersistent/simulationFavoritesState';
import { currentScopePathState } from 'src/services/recoil/persistent/currentScopePathState';
import { KaeplaSimulationFavorite } from 'src/services/types/Application/KaeplaSimulationFavorite';

interface Options {
  showAllSimulationsForProject?: boolean;
}

export const SimulationFavoritesListener = ({ showAllSimulationsForProject }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const setSimulationFavorites = useSetRecoilState(simulationFavoritesState);

  // this is the simulationFavoritesListener
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    const fireStorePath = `simulationFavorites`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'uid',
        opStr: '==',
        value: kaeplaUser.uid,
      },
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
    ];
    if (!showAllSimulationsForProject) {
      queryWhere.push({
        fieldPath: 'scopePathStringified',
        opStr: '==',
        value: JSON.stringify(currentScopePath),
      });
    }

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (data) => {
        const favorites = data as KaeplaSimulationFavorite[];
        setSimulationFavorites(favorites);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [
    setSimulationFavorites,
    kaeplaUser?.uid,
    project?.id,
    currentScopePath,
    showAllSimulationsForProject,
  ]);
  return null;
};
