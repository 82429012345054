import { Alert, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { simulationsState } from 'src/services/recoil/nonpersistent/simulationsState';
import { currentScopePathState } from 'src/services/recoil/persistent/currentScopePathState';

import { SimulationCard } from './SimulationCard';
import { useAuth } from '../../../../AuthProvider';
import { KaeplaSimulation } from '../../../../services/types/Application/KaeplaSimulation';

interface Options {
  showAllSimulationsForProject: boolean;
}

export const SimulationOverview = ({ showAllSimulationsForProject }: Options) => {
  const { kaeplaUser } = useAuth();
  const simulations = useRecoilValue(simulationsState);
  const [ownSimulations, setOwnSimulations] = useState<KaeplaSimulation[]>([]);
  const [otherSimulations, setOtherSimulations] = useState<KaeplaSimulation[]>([]);
  const currentScopePath = useRecoilValue(currentScopePathState);

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    const simulationsFiltered = [...simulations].filter((s) => {
      if (showAllSimulationsForProject) {
        return true;
      } else {
        if (s.scopePathStringified === JSON.stringify(currentScopePath)) {
          return true;
        }
      }
      return false;
    });
    const own = simulationsFiltered.filter((s) => s.createdBy === kaeplaUser.uid);
    setOwnSimulations(own);
    const other = simulationsFiltered.filter((s) => s.createdBy !== kaeplaUser.uid);
    setOtherSimulations(other);
  }, [currentScopePath, kaeplaUser?.uid, showAllSimulationsForProject, simulations]);

  return (
    <Grid container spacing={1}>
      {ownSimulations.length > 0 ? (
        <Grid xs={12} item>
          <Typography variant="h6">Own Simulations</Typography>
        </Grid>
      ) : (
        <Grid xs={12} item>
          <Alert variant="outlined" severity="info">
            Your have no simulations for this project. Create one!
          </Alert>
        </Grid>
      )}
      {ownSimulations?.map((_simulation) => {
        return (
          <Grid xs={12} sm={6} md={4} lg={3} key={_simulation.id} item>
            <SimulationCard simulation={_simulation} own />
          </Grid>
        );
      })}
      {otherSimulations.length > 0 && (
        <Grid xs={12} item>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="h6">Other Simulations</Typography>
        </Grid>
      )}
      {otherSimulations?.map((_simulation) => {
        return (
          <Grid xs={12} sm={6} md={4} lg={3} key={_simulation.id} item>
            <SimulationCard simulation={_simulation} />
          </Grid>
        );
      })}
    </Grid>
  );
};
