import AddIcon from '@mui/icons-material/Add';
import { Fab, FormControlLabel, Switch, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { TargetsOverview } from './TargetsOverview';
import { useAuth } from '../../../AuthProvider';
import { createEvent } from '../../../services/firestore/createEvent';
import { createNewTargets } from '../../../services/firestore/createNewTargets';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { KaeplaDataOperation } from '../../../services/types/Application/KaeplaDataOperation';
import { KaeplaEventEffect } from '../../../services/types/Application/KaeplaEventEffect';
import { KaeplaEventType } from '../../../services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from '../../../services/types/Application/KaeplaFunctionGroup';
import { Layout } from '../../Layout/Layout';

export const Targets = () => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [showAllTargetsForProject, setShowAllTargetsForProject] = useState(false);

  const handleShowAllTargetsForProject = () => {
    setShowAllTargetsForProject(!showAllTargetsForProject);
  };

  if (!project) {
    navigate('/');
    return null;
  }

  const create = async () => {
    if (!kaeplaUser) return;
    const _targets = await createNewTargets({
      project,
      scopePath: currentScopePath,
      createdBy: kaeplaUser.uid,
      targetFigures: [],
    });

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.TARGETS_CREATE_TARGETS,
      effect: KaeplaEventEffect.PROJECT_TARGETS_CHANGE,
      functionGroup: KaeplaFunctionGroup.TARGETS,
      operation: KaeplaDataOperation.CREATE,
      project,
      scopePath: currentScopePath,
    });

    if (_targets) {
      navigate(`/Targets/${project.id}/${_targets.id}`);
    }
  };

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <>
        <Toolbar disableGutters variant="dense">
          <Typography variant="h5">{project.name} Targets</Typography>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <FormControlLabel
            control={
              <Switch
                checked={showAllTargetsForProject}
                size="small"
                onChange={handleShowAllTargetsForProject}
              />
            }
            label="all"
          />
          <Fab
            color="primary"
            aria-label="add targets"
            data-testid="targets-add"
            sx={{ position: 'fixed', bottom: 20, right: 20 }}
            onClick={() => {
              void create();
            }}
          >
            <AddIcon />
          </Fab>
        </Toolbar>
        <TargetsOverview showAllTargetsForProject={showAllTargetsForProject} />
      </>
    </Layout>
  );
};
