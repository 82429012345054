import { Button, Toolbar } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { filterSettingsState } from '../../../../services/recoil/persistent/filterSettingState';

interface Options {
  applyFilter: () => void;
}

export const FilterActions = ({ applyFilter }: Options) => {
  const filterSettings = useRecoilValue(filterSettingsState);
  return (
    <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
      <Button
        sx={{ mr: 1 }}
        variant="contained"
        onClick={() => {
          applyFilter();
        }}
        disabled={filterSettings.isApplied || filterSettings.ruleCount === 0}
      >
        Apply
      </Button>
    </Toolbar>
  );
};
