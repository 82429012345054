import { Box, Typography } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

import { KaeplaPerspectiveBlock } from '../../../../../../../services/types/Application/KaeplaPerspectiveBlock';
import { isEligibleForAggregationDimension } from '../../helpers/isEligibleForAggregationDimension';
import { isEligibleForValueDimension } from '../../helpers/isEligibleForValueDimension';
import { AggregationSelect } from '../AggregationSelect';
import { DimensionSelect } from '../DimensionSelect';

interface Options {
  block: KaeplaPerspectiveBlock;
  setAggregation: Dispatch<SetStateAction<string>>;
  setValueDimension: Dispatch<SetStateAction<string>>;
  setAggregationDimension: Dispatch<SetStateAction<string>>;
}

export const DonutChartEditor = ({
  block,
  setAggregation,
  setValueDimension,
  setAggregationDimension,
}: Options) => {
  return (
    <Box>
      <AggregationSelect setAggregation={setAggregation} aggregation={block.aggregation} />
      <Typography variant="subtitle2">OF</Typography>
      <DimensionSelect
        setDimension={setValueDimension}
        dimension={block.valueDimension}
        isEligibleForDimension={isEligibleForValueDimension}
      />
      <Typography variant="subtitle2">PER</Typography>
      <DimensionSelect
        setDimension={setAggregationDimension}
        dimension={block.aggregationDimension}
        isEligibleForDimension={isEligibleForAggregationDimension}
      />
      <Box sx={{ m: 2 }} />
    </Box>
  );
};
