import GridSpanLargeIcon from '@mui/icons-material/DensityLargeOutlined';
import GridSpanMediumIcon from '@mui/icons-material/DensityMediumOutlined';
import GridSpanSmallIcon from '@mui/icons-material/DensitySmallOutlined';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MouseEvent } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { updatePerspectiveBlock } from '../../../../../../services/firestore/updatePerspectiveBlock';
import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState';
import { KaeplaPerspectiveBlock } from '../../../../../../services/types/Application/KaeplaPerspectiveBlock';
import { getGridSpanString } from '../helpers/getGridSpanString';

interface Options {
  block: KaeplaPerspectiveBlock;
  blockNumber: number;
}

export const ChartToolbarGridSpan = ({ block, blockNumber }: Options) => {
  const project = useRecoilValue(projectState);
  const [perspective, setPerspective] = useRecoilState(perspectiveState);
  const currentGridSpan = getGridSpanString(block.gridSpan);

  const updateGridSpan = async (newGridspan: 'small' | 'medium' | 'large') => {
    const newBlock: KaeplaPerspectiveBlock = { ...block, gridSpan: newGridspan };
    const newPerspective = await updatePerspectiveBlock({
      project,
      perspective,
      block: newBlock,
      blockNumber,
    });
    setPerspective(newPerspective);
  };

  const toggleGridSpan = (
    _event: MouseEvent<HTMLElement>,
    newGridspan: 'small' | 'medium' | 'large',
  ) => {
    void updateGridSpan(newGridspan);
  };

  return (
    <ToggleButtonGroup exclusive size="small" onChange={toggleGridSpan}>
      <ToggleButton
        sx={{ p: 0.5 }}
        value="small"
        aria-label="small"
        selected={currentGridSpan === 'small'}
      >
        <GridSpanSmallIcon
          fontSize="small"
          sx={{ transform: 'rotate(90deg) scaleX(0.5)', height: 0.5 }}
        />
      </ToggleButton>
      <ToggleButton
        sx={{ p: 0.5 }}
        value="medium"
        aria-label="medium"
        selected={currentGridSpan === 'medium'}
      >
        <GridSpanMediumIcon fontSize="small" sx={{ transform: 'rotate(90deg) scaleX(0.5)' }} />
      </ToggleButton>
      <ToggleButton
        sx={{ p: 0.5 }}
        value="large"
        aria-label="large"
        selected={currentGridSpan === 'large'}
      >
        <GridSpanLargeIcon fontSize="small" sx={{ transform: 'rotate(90deg) scaleX(0.5)' }} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
