import { atom } from 'recoil';

import { KaeplaCustomerAssignment } from '../../types/Application/KaeplaCustomerAssignment';

const defaultCustomerAssignmentState: KaeplaCustomerAssignment[] = [];

export const customerAssignmentState = atom<KaeplaCustomerAssignment[]>({
  key: 'customerAssignments',
  default: defaultCustomerAssignmentState,
});
