import { Avatar, Box, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Image from 'mui-image';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { lastCustomerIDState } from 'src/services/recoil/persistent/lastCustomerState';

import { knownProjectsState } from '../../services/recoil/nonpersistent/knownProjectsState';
import { projectsOverviewState } from '../../services/recoil/nonpersistent/projectsOverviewState';
import { selectedCustomerState } from '../../services/recoil/nonpersistent/selectedCustomer';
import { lastProjectIdState } from '../../services/recoil/persistent/lastProjectState';
import { KaeplaCustomer } from '../../services/types/Application/KaeplaCustomer';
import { avatarAppearDuration } from '../Screens/defaults';

interface Options {
  customer: KaeplaCustomer;
}

export const CustomerAvatar = ({ customer }: Options) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const knownProjects = useRecoilValue(knownProjectsState);
  const setLastCustomer = useSetRecoilState(lastCustomerIDState);
  const setProjectsOverview = useSetRecoilState(projectsOverviewState);
  const setSelectedCustomer = useSetRecoilState(selectedCustomerState);
  const setLastProject = useSetRecoilState(lastProjectIdState);
  let content: JSX.Element = <Box>{customer?.name.slice(0, 2)}</Box>;
  if (customer?.iconBase64) {
    content = (
      <Image
        width="70%"
        height="70%"
        alt={customer?.name}
        src={customer?.iconBase64}
        duration={avatarAppearDuration}
      />
    );
  }
  return (
    <Tooltip title={customer.id}>
      <Avatar
        data-testid="customer-avatar"
        sx={{
          cursor: 'pointer',
          bgcolor: 'transparent',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor:
            theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[800],
        }}
        variant="square"
        onClick={() => {
          setSelectedCustomer(customer);
          setLastCustomer(customer.id);
          setProjectsOverview(knownProjects.filter((p) => p.customerId === customer.id));
          const projects = knownProjects.filter((p) => p.customerId === customer.id);
          if (projects.length > 0) {
            const project = projects[0];
            setLastProject(project.id);
          } else {
            setLastProject(undefined as unknown as string);
          }
          navigate(`/`);
        }}
      >
        {content}
      </Avatar>
    </Tooltip>
  );
};
