import { clone, path } from 'rambda';

import { getCleanPath } from './getCleanPath';
import { KaeplaModel } from '../../../services/types/Application/KaeplaModel';

export const addScopeToModel = (model: KaeplaModel, scopePath: string[], dimension: string) => {
  const defaultModel: KaeplaModel = {
    delegation: {},
  };
  const newModel = clone(model || defaultModel);
  const newModelScope = newModel.delegation || {};
  const cleanPath = getCleanPath(scopePath);
  const dotNotation = cleanPath.join('.');
  const parentNode = path<Record<string, unknown>>(dotNotation, newModelScope);
  if (parentNode) {
    parentNode[dimension] = {} as Record<string, unknown>;
  } else {
    newModelScope[dimension] = {} as Record<string, unknown>;
  }
  newModel.delegation = newModelScope;
  return newModel;
};
