import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  AvatarGroup,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import pluralize from 'pluralize';
import { useState, MouseEvent, useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { watcherKeysState } from 'src/services/recoil/nonpersistent/watcherKeysState';

import { CardMenu } from './CardMenu';
import { CustomerDeleteDialog } from './CustomerDeleteDialog';
import { CustomerDomains } from './CustomerDomains';
import { CustomerRenameDialog } from './CustomerRenameDialog';
import { useAuth } from '../../../../AuthProvider';
import { resendInvite } from '../../../../services/api/resendInviteEmail';
import { addFirestoreCollectionListener } from '../../../../services/firestore/addFirestoreCollectionListener';
import { createCustomerAssignmentForEmail } from '../../../../services/firestore/createCustomerAssignmentForEmail';
import { createEvent } from '../../../../services/firestore/createEvent';
import { getUsers } from '../../../../services/firestore/getUsers';
import { removeCustomerAssignment } from '../../../../services/firestore/removeCustomerAssignment';
import { customerAssignmentState } from '../../../../services/recoil/nonpersistent/customerAssignmentState';
import { knownResellersState } from '../../../../services/recoil/nonpersistent/knownResellersState';
import { selectedCustomerState } from '../../../../services/recoil/nonpersistent/selectedCustomer';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState';
import { KaeplaCustomer } from '../../../../services/types/Application/KaeplaCustomer';
import { KaeplaCustomerAssignment } from '../../../../services/types/Application/KaeplaCustomerAssignment';
import { KaeplaDataOperation } from '../../../../services/types/Application/KaeplaDataOperation';
import { KaeplaEventEffect } from '../../../../services/types/Application/KaeplaEventEffect';
import { KaeplaEventType } from '../../../../services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from '../../../../services/types/Application/KaeplaFunctionGroup';
import { KaeplaUser } from '../../../../services/types/Application/KaeplaUser';
import { AddTeamMember } from '../../../features/AddTeamMember';
import { AddTeamMemberToggle } from '../../../features/AddTeamMemberToggle';
import { CustomerAvatar } from '../../../features/CustomerAvatar';
import { ResellerAvatar } from '../../../features/ResellerAvatar';
import { UserAvatar } from '../../../features/UserAvatar';
import { convertTimestamp, HumanReadableTimestampType } from '../../../helpers/convertTimestamp';
import { logger } from '../../../helpers/logger';
import { avatarGroupSlot } from '../../defaults';

interface Options {
  customer: KaeplaCustomer;
}

export const CustomerCard = ({ customer }: Options) => {
  const { kaeplaUser } = useAuth();
  const selectedCustomer = useRecoilValue(selectedCustomerState);
  const knownResellers = useRecoilValue(knownResellersState);
  const [customerAssignments, setCustomerAssignments] = useRecoilState(customerAssignmentState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const setWatcherKeys = useSetRecoilState(watcherKeysState);
  const [customerTeam, setCustomerTeam] = useState<KaeplaUser[]>([]);
  const reseller = knownResellers.find((r) => r.id === customer.resellerId);
  const [hover, setHover] = useState(false);
  const [openAddTeamMember, setOpenAddTeamMember] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRename, setOpenRename] = useState(false);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const handleClickOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleClickOpenRename = () => {
    setOpenRename(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseRename = () => {
    setOpenRename(false);
  };

  useEffect(() => {
    logger.log('Init CustomerCard Assignments Listener fires', customer.id);
    const fireStorePath = `customerAssignments`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'customerId',
        opStr: '==',
        value: customer.id,
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (_customerAssignments) => {
        const assignments = _customerAssignments as KaeplaCustomerAssignment[];
        logger.log('CustomerCard Assignments Listener fires', customer.id);
        const load = async () => {
          const userIds = assignments.map((c) => c.uid);
          const uniqueUserIds = userIds.filter((v, index, a) => a.indexOf(v) === index);
          const assignedUsers = await getUsers({ userIds: uniqueUserIds });
          setCustomerTeam(assignedUsers);
          setCustomerAssignments(assignments);
        };

        void load();
      },
      watcherKeyCallback: (key) => {
        setWatcherKeys((_watcherKeys) => {
          const keyId = `customerTeam-${customer.id}`;
          const newWatcherKeys = { ..._watcherKeys };
          newWatcherKeys[keyId] = key;
          return newWatcherKeys;
        });
      },
    });
    return () => {
      unsubscribe();
    };
  }, [customer.id, setCustomerAssignments, setWatcherKeys]);

  const openAddTeamMemberDialog = () => {
    setOpenAddTeamMember(true);
  };

  const closeAddTeamMemberDialog = (email: string) => {
    setOpenAddTeamMember(false);
    if (!kaeplaUser?.uid || !reseller || email.length === 0) return;
    const sendInvite = async () => {
      let emailToInvite = email;
      if (!emailToInvite.includes('@')) {
        emailToInvite = `${email}@kaepla.atrigam.com`;
      }

      await createCustomerAssignmentForEmail({
        customerId: customer.id,
        resellerId: reseller.id,
        email: emailToInvite,
        assignedBy: kaeplaUser.uid,
      });

      void createEvent({
        uid: kaeplaUser?.uid,
        eventType: KaeplaEventType.PROJECT_TEAM_INVITE_USER_TO_TEAM,
        effect: KaeplaEventEffect.PROJECT_TEAM_CHANGE,
        functionGroup: KaeplaFunctionGroup.CUSTOMER,
        operation: KaeplaDataOperation.CREATE,
        customer,
        eventInfo: {
          affectedUserEmail: email,
        },
      });
    };
    void sendInvite();
  };

  const wasAssignedByMe = (user: KaeplaUser) => {
    const customerAssignment = customerAssignments.find(
      (r) => r.customerId === customer.id && r.uid === user.uid,
    );
    if (!customerAssignment) return false;
    return customerAssignment.assignedBy === kaeplaUser?.uid;
  };

  const lastInviteSent = (user: KaeplaUser) => {
    const customerAssignment = customerAssignments.find(
      (r) => r.customerId === customer.id && r.uid === user.uid,
    );
    if (!customerAssignment) return;
    const time = convertTimestamp(
      customerAssignment.lastInviteSentAt || customerAssignment.assignedAt,
      HumanReadableTimestampType.timeago,
    );
    return time;
  };

  const removeTeamMemberFromCustomer = (user: KaeplaUser) => {
    const customerAssignment = customerAssignments.find(
      (r) => r.customerId === customer.id && r.uid === user.uid,
    );
    if (!customerAssignment) return;
    void removeCustomerAssignment({ assignmentId: customerAssignment.id });
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.CUSTOMER_TEAM_REMOVE_USER_FROM_TEAM,
      functionGroup: KaeplaFunctionGroup.CUSTOMER,
      operation: KaeplaDataOperation.DELETE,
      customer,
      eventInfo: {
        affectedUserUid: customerAssignment.uid,
      },
    });
  };

  const resendEmailInvite = (user: KaeplaUser) => {
    const customerAssignment = customerAssignments.find(
      (r) => r.customerId === customer.id && r.uid === user.uid,
    );
    if (!customerAssignment) return;
    void resendInvite({ params: { customerAssignmentId: customerAssignment.id } });
  };

  return (
    <Grid xs={12} sm={6} md={4} lg={3} key={customer.id} item>
      <Card
        data-testid="customer-card"
        data-customerid={customer.id}
        elevation={selectedCustomer?.id === customer.id || hover ? 8 : 2}
        onFocus={() => {
          setHover(true);
        }}
        onBlur={() => {
          setHover(false);
        }}
      >
        <CardHeader
          avatar={<CustomerAvatar customer={customer} />}
          title={
            <Tooltip title={customer.id}>
              <Box data-testid="customer-name">{customer.name}</Box>
            </Tooltip>
          }
          subheader={<Box data-testid="customer-appname">{customer.appName}</Box>}
          action={
            <IconButton
              data-testid="customer-menu-toggle"
              edge="end"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                handleMenuClick(event);
              }}
            >
              <MoreVertIcon data-testid="customer-menu-icon" />
            </IconButton>
          }
        />
        <CardContent sx={{ pt: 0, pb: 0 }}>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              {reseller && (
                <IconButton size="small" aria-label="settings" edge="start">
                  <ResellerAvatar
                    title={reseller.name}
                    icon={reseller.iconBase64}
                    small
                    id={reseller.id}
                  />
                </IconButton>
              )}
              <Chip
                label={`${pluralize('project', customer.projectCount, true)}`}
                size="small"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">Team</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="space-between"
                // divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
              >
                <AvatarGroup max={4} spacing="small" slotProps={avatarGroupSlot}>
                  {customerTeam.map((customerUser) => (
                    <UserAvatar
                      key={customerUser.uid}
                      user={customerUser}
                      self={customer.createdBy === customerUser.uid}
                      lastInviteSent={lastInviteSent(customerUser)}
                      removeCallback={
                        wasAssignedByMe(customerUser) || kaeplaAssignment
                          ? removeTeamMemberFromCustomer
                          : undefined
                      }
                      resendInviteCallback={resendEmailInvite}
                    />
                  ))}
                </AvatarGroup>
                <AddTeamMemberToggle callback={openAddTeamMemberDialog} />
                <AddTeamMember open={openAddTeamMember} onClose={closeAddTeamMemberDialog} />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption">Access Domains</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <CustomerDomains customer={customer} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <CustomerDeleteDialog
        openDelete={openDelete}
        handleCloseDelete={handleCloseDelete}
        customer={customer}
        reseller={reseller}
      />

      <CustomerRenameDialog
        openRename={openRename}
        handleCloseRename={handleCloseRename}
        customer={customer}
        reseller={reseller}
      />

      <CardMenu
        anchorEl={anchorElement}
        open={!!anchorElement}
        canRename={customer.createdBy === kaeplaUser?.uid}
        canDelete={customer.createdBy === kaeplaUser?.uid}
        onClose={handleMenuClose}
        handleClickOpenDelete={handleClickOpenDelete}
        handleClickOpenRename={handleClickOpenRename}
      />
    </Grid>
  );
};
