import { atom } from 'recoil';

import { MatrixSummary } from '../../types/MatrixSummary';

const defaultMatrixFilteredState: MatrixSummary | undefined = {};

export const matrixFilteredState = atom<MatrixSummary | undefined>({
  key: 'matrixFiltered',
  default: defaultMatrixFilteredState,
});
