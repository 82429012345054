import { getApp } from 'firebase/app';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  project: KaeplaProject;
}

export const deleteProject = async ({ project }: Options) => {
  if (!project.createdBy) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  return deleteDoc(firestoreDocumentReference);
};
