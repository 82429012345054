import { useCallback, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { getBranding } from '../../services/api/getBranding';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState';
import { brandingState } from '../../services/recoil/persistent/brandingState';
import { documentTitle } from '../helpers/documentTitle';
import { logger } from '../helpers/logger';

export const Branding = () => {
  const setApp = useSetRecoilState(applicationState);
  const setBranding = useSetRecoilState(brandingState);

  const load = useCallback(async () => {
    logger.log('\nuseEffect Branding');
    const branding = await getBranding({ params: { hostname: window.location.hostname } });
    if (!branding) {
      setApp((app) => ({ ...app, connecting: true }));
      return;
    }
    document.title = documentTitle(branding);
    logger.log('Branding got branding:', branding.appProvider, branding.appRealm);
    setBranding(branding);

    logger.log('Branding setApp connecting => false');
    setApp((app) => ({ ...app, connecting: false }));
  }, [setApp, setBranding]);

  useEffect(() => {
    void load();
    return () => {
      setApp((app) => ({ ...app, connecting: true }));
    };
  }, [load, setApp]);

  return null;
};
