import { getApp } from 'firebase/app';
import { DocumentData, getFirestore, doc, updateDoc } from 'firebase/firestore';

import { KaeplaPerspective } from '../types/Application/KaeplaPerspective';
import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  project: KaeplaProject;
  perspective?: Partial<KaeplaPerspective>;
  blockNumber: number;
}

export const removePerspectiveBlock = async ({ project, perspective, blockNumber }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  if (!perspective?.id) return perspective as KaeplaPerspective;
  const fireStorePath = `projects/${project.id}/perspectives/${perspective.id}`;
  const documentReference = doc(firestoreDatabase, fireStorePath);
  const newPerspective = { ...perspective };
  if (!newPerspective?.blocks) return perspective;
  const newBlocks = [...newPerspective.blocks];
  newBlocks.splice(blockNumber, 1);
  newPerspective.blocks = newBlocks;
  await updateDoc(documentReference, newPerspective as DocumentData);
  return newPerspective as KaeplaPerspective;
};
