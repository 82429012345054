import { getApp } from 'firebase/app';
import { doc, DocumentData, getFirestore, updateDoc } from 'firebase/firestore';
import { SetterOrUpdater } from 'recoil';

import { KaeplaPerspective } from '../types/Application/KaeplaPerspective';
import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  project: KaeplaProject;
  perspective: Partial<KaeplaPerspective>;
  setProject: SetterOrUpdater<KaeplaProject>;
}

export const updateDefaultPerspective = async ({ project, perspective, setProject }: Options) => {
  if (!perspective.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  if (perspective.id === 'default') {
    const fireStorePath = `projects/${project.id}`;
    const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
    const newDefaultPerspective = {
      ...project.defaultPerspective,
      ...perspective,
    };
    const newProject = { defaultPerspective: newDefaultPerspective };
    setProject({ ...newProject, ...project } as KaeplaProject);
    return updateDoc(firestoreDocumentReference, newProject as DocumentData);
  }
  const fireStorePerspectivePath = `projects/${project.id}/perspectives/${perspective.id}`;
  const firestorePerspectiveDocumentReference = doc(firestoreDatabase, fireStorePerspectivePath);
  return updateDoc(firestorePerspectiveDocumentReference, perspective as DocumentData);
};
