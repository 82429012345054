import { getApp } from 'firebase/app';
import { doc, getFirestore, setDoc, Timestamp } from 'firebase/firestore';

import { KaeplaSimulation } from '../types/Application/KaeplaSimulation';
import { KaeplaSimulationFavorite } from '../types/Application/KaeplaSimulationFavorite';

interface Options {
  uid: string;
  simulation: Partial<KaeplaSimulation>;
  scopePath: string[];
  isFavorite: boolean;
}

export const toggleSimulationFavorite = async ({
  uid,
  simulation,
  isFavorite,
  scopePath,
}: Options) => {
  if (!uid || !simulation.id || !simulation.projectId) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `simulationFavorites/${uid}-${simulation.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);

  const update: KaeplaSimulationFavorite = {
    uid,
    projectId: simulation.projectId,
    simulationId: simulation.id,
    scopePath,
    scopePathStringified: JSON.stringify(scopePath),
    isFavorite,
    updatedAt: Timestamp.now(),
  };

  return setDoc(firestoreDocumentReference, update, {
    merge: true,
  });
};
