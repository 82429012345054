import { atom } from 'recoil';

import { MatrixTimeseries } from '../../types/MatrixTimeseries';
import { MatrixTimeseriesEntry } from '../../types/MatrixTimeseriesEntry';

// TODO: have a default time series so the animation is smoother initially?
const defaultEntries: MatrixTimeseriesEntry[] = [
  { date: '2020-01-01', value: 8 },
  { date: '2021-01-01', value: 20 },
  { date: '2022-01-01', value: 25 },
  { date: '2023-01-01', value: 35 },
  { date: '2024-01-01', value: 10 },
  { date: '2025-01-01', value: 30 },
  { date: '2026-01-01', value: 20 },
  { date: '2027-01-01', value: 15 },
  { date: '2028-01-01', value: 45 },
];

const defaultTimeSeriesState: MatrixTimeseries = {
  timeseries: defaultEntries,
  job: { ms: 0, bytes: 0, cached: true },
};

export const timeSeriesState = atom<MatrixTimeseries>({
  key: 'timeSeries',
  default: defaultTimeSeriesState,
});
