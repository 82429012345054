import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AuthProvider, useAuth } from './AuthProvider';
import { ColorModeContext } from './Frontend/Layout/features/ColorModeToggle';
import { Branding } from './Frontend/ListenerComponents/Branding';
import { CustomerAssignmentsListener } from './Frontend/ListenerComponents/CustomerAssignmentsListener';
import { KaeplaAssignmentsListener } from './Frontend/ListenerComponents/KaeplaAssignmentsListener';
import { LastProjectAndCustomer } from './Frontend/ListenerComponents/LastProjectAndCustomer';
import { ProjectAssignmentsListener } from './Frontend/ListenerComponents/ProjectAssignmentsListener';
import { ResellerAssignmentsListener } from './Frontend/ListenerComponents/ResellerAssignmentsListener';
import { SimulationFavoritesListener } from './Frontend/ListenerComponents/SimulationFavoritesListener';
import { SimulationsListener } from './Frontend/ListenerComponents/SimulationsListener';
import { TargetsListener } from './Frontend/ListenerComponents/TargetsListener';
import { UserListenerKaeplaAdmin } from './Frontend/ListenerComponents/UserListenerKaeplaAdmin';
import { UserListenerUsers } from './Frontend/ListenerComponents/UserListenerUsers';
import { Assignments } from './Frontend/Screens/Assignments/Assignments';
import { Customers } from './Frontend/Screens/Customers/Customers';
import { Disclaimers } from './Frontend/Screens/Disclaimers/Disclaimers';
import { Events } from './Frontend/Screens/Events/Events';
import { Login } from './Frontend/Screens/Login/Login';
import { Logout } from './Frontend/Screens/Login/Logout';
import { Logs } from './Frontend/Screens/Logs';
import { Perspective } from './Frontend/Screens/Perspectives/Perspective';
import { Perspectives } from './Frontend/Screens/Perspectives/Perspectives';
import { Projects } from './Frontend/Screens/Projects/Projects';
import { Resellers } from './Frontend/Screens/Resellers/Resellers';
import { Scopes } from './Frontend/Screens/Scopes/Scopes';
import { SimulationEditor } from './Frontend/Screens/Simulations/SimulationEditor/SimulationEditor';
import { Simulations } from './Frontend/Screens/Simulations/Simulations';
import { Targets } from './Frontend/Screens/Targets/Targets';
import { TargetsEditor } from './Frontend/Screens/Targets/TargetsEditor';
import { User } from './Frontend/Screens/User/User';
import { Users } from './Frontend/Screens/Users/Users';
import { themeOptions } from './Frontend/Theme/KaeplaTheme';
import { logger } from './Frontend/helpers/logger';
import { KaeplaErrorProvider } from './KaeplaErrorProvider';
import { UserPreferencesProvider } from './UserPreferencesProvider';

// eslint-disable-next-line import/no-unassigned-import
import './logging';

const Routing = () => {
  const { authState } = useAuth();

  if (authState === null) return null;

  logger.log('App renders');

  return (
    <Routes>
      <Route
        path="/"
        element={authState ? <Navigate to="/Projects" /> : <Navigate to="/Login" />}
      />
      <Route path="/Projects" element={authState ? <Projects /> : <Navigate to="/Login" />} />
      <Route
        path="/Project/:projectId"
        element={authState ? <Perspective /> : <Navigate to="/Login" />}
      />
      <Route path="/Assignments" element={authState ? <Assignments /> : <Navigate to="/Login" />} />
      <Route
        path="/Perspectives"
        element={authState ? <Perspectives /> : <Navigate to="/Login" />}
      />
      <Route path="/Perspective" element={authState ? <Perspective /> : <Navigate to="/Login" />} />
      <Route path="/Simulations" element={authState ? <Simulations /> : <Navigate to="/Login" />} />
      <Route
        path="/Simulation/:projectId/:id"
        element={authState ? <SimulationEditor /> : <Navigate to="/Login" />}
      />
      <Route path="/Targets" element={authState ? <Targets /> : <Navigate to="/Login" />} />
      <Route
        path="/Targets/:projectId/:id"
        element={authState ? <TargetsEditor /> : <Navigate to="/Login" />}
      />
      <Route path="/Scopes" element={authState ? <Scopes /> : <Navigate to="/Login" />} />
      <Route path="/Events" element={authState ? <Events /> : <Navigate to="/Login" />} />
      <Route path="/Disclaimers" element={authState ? <Disclaimers /> : <Navigate to="/Login" />} />
      <Route path="/Login" element={authState ? <Navigate to="/Projects" /> : <Login />} />
      <Route path="/Logout" element={<Logout />} />
      <Route path="/Users" element={authState ? <Users /> : <Navigate to="/Login" />} />
      <Route path="/User" element={authState ? <User /> : <Navigate to="/Login" />} />
      <Route path="/Customers" element={authState ? <Customers /> : <Navigate to="/Login" />} />
      <Route
        path="/Customers/:resellerId"
        element={authState ? <Customers /> : <Navigate to="/Login" />}
      />
      <Route path="/Resellers" element={authState ? <Resellers /> : <Navigate to="/Login" />} />
      <Route path="/Logs" element={authState ? <Logs /> : <Navigate to="/Login" />} />
    </Routes>
  );
};

/*
useEffect fires twice in strict mode, putting double load on BQ
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AppWithStrictMode = () => {
  return (
    <StrictMode>
      <RecoilRoot>
        <AuthProvider>
          <KaeplaAssignmentsListener />
          <ResellerAssignmentsListener />
          <CustomerAssignmentsListener />
          <UpdateKnownUsers />
        <Router>
          <Routing />
        </Router>
        </AuthProvider>
      </RecoilRoot>
    </StrictMode>
  );
};
*/

const AppWithoutStrictMode = () => {
  return (
    <RecoilRoot>
      <AuthProvider>
        <UserPreferencesProvider>
          <KaeplaErrorProvider>
            <Branding />
            <LastProjectAndCustomer />
            <KaeplaAssignmentsListener />
            <ResellerAssignmentsListener />
            <CustomerAssignmentsListener />
            <ProjectAssignmentsListener />
            <SimulationsListener showAllSimulationsForProject={true} />
            <SimulationFavoritesListener showAllSimulationsForProject={true} />
            <TargetsListener showAllTargetsForProject={true} />
            <UserListenerKaeplaAdmin />
            <UserListenerUsers />
            <Router>
              <Routing />
            </Router>
          </KaeplaErrorProvider>
        </UserPreferencesProvider>
      </AuthProvider>
    </RecoilRoot>
  );
};

export const App = () => {
  const [mode, setMode] = useState<'light' | 'dark'>('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((previousMode) => (previousMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            mode,
          },
        },
        themeOptions,
      ),
    [mode],
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <AppWithoutStrictMode />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
