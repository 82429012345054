import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger';
import { KaeplaBranding } from '../types/Application/KaeplaBranding';
import { KaeplaBrandingRequest } from '../types/Application/KaeplaBrandingRequest';

interface KOptions {
  params: KaeplaBrandingRequest;
}

export const getBranding = async ({ params }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const getKaeplaBranding = httpsCallable(functions, 'getKaeplaBranding');

  requestLog.log(`kaeplaBranding.post().json(${JSON.stringify(params)})`);
  const result = await getKaeplaBranding(params).catch((error) => {
    requestLog.log(`kaeplaBranding.post() ERROR`, error);
  });
  if (result) {
    return result.data as KaeplaBranding;
  }
  return false;
};
