import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

import { KaeplaSimulation } from '../types/Application/KaeplaSimulation';

interface Options {
  projectId: string;
  simulationId: string;
}
export const getSimulation = async ({ projectId, simulationId }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${projectId}/simulations/${simulationId}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  const snapshot = await getDoc(firestoreDocumentReference);
  return snapshot.data() as KaeplaSimulation;
};
