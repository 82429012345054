import { Box } from '@mui/material';
import React from 'react';

interface Options {
  minutes: number;
  seconds: number;
  completed: boolean;
}

export const countDownRenderer = ({ minutes, seconds, completed }: Options) => {
  if (completed) {
    // Render a completed state
    return null;
  } else {
    // Render a countdown
    return (
      <Box
        component="span"
        sx={{
          fontFamily: 'monospace',
          fontSize: 14,
          mr: 1,
          color: 'blue',
        }}
      >
        ready in approx. {minutes}:{seconds}
      </Box>
    );
  }
};
