import { KaeplaProject } from 'src/services/types/Application/KaeplaProject';
import { KaeplaProjectAssignment } from 'src/services/types/Application/KaeplaProjectAssignment';

interface Options {
  project: KaeplaProject;
  projectAssignments: KaeplaProjectAssignment[];
  scopePath: string[];
}

export const checkPathIsInScope = ({ project, projectAssignments, scopePath }: Options) => {
  if (!scopePath) return false;
  const scopePathStringified = JSON.stringify(scopePath);
  let shortestScopePathLength = 10_000;
  projectAssignments
    .filter((assignment) => assignment.projectId === project.id)
    .forEach((assignment) => {
      if (assignment.scopePath.length < shortestScopePathLength) {
        shortestScopePathLength = assignment.scopePath.length;
      }
    });

  if (shortestScopePathLength > scopePath.length) {
    return false;
  }

  if (shortestScopePathLength === scopePath.length) {
    const parentAssignmentExists = projectAssignments.some(
      (assignment) => assignment.scopePathStringified === scopePathStringified,
    );
    if (!parentAssignmentExists) {
      return false;
    }
  }
  return true;
};
