import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

interface Options {
  decimalPositions: number | undefined;
  setDecimalPositions: Dispatch<SetStateAction<number | undefined>>;
}

export const CurrencyDecimalPlaces = ({ decimalPositions, setDecimalPositions }: Options) => {
  const handleChange = (event: SelectChangeEvent) => {
    setDecimalPositions(Number.parseInt(event.target.value, 10));
  };

  return (
    <Select
      size="small"
      value={`${decimalPositions || 2}`}
      label="Currency"
      onChange={handleChange}
    >
      <MenuItem value={1}>1</MenuItem>
      <MenuItem value={2}>2</MenuItem>
      <MenuItem value={3}>3</MenuItem>
    </Select>
  );
};
