import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, Grid, IconButton, TextField, Typography } from '@mui/material';
import numbro from 'numbro';
import { Dispatch, SetStateAction } from 'react';

import { RoundingSuggestions } from './RoundingSuggestions';
import { TimeSliceValueAndLabel } from './TimeSliceValueAndLabel';
import { KaeplaDimension } from '../../../../../services/types/Application/KaeplaDimension';
import { calculatePercentage } from '../_helpers/calculatePercentage';

interface AOptions {
  absoluteSimulationValue: number;
  setAbsoluteSimulationValue: Dispatch<SetStateAction<number>>;
  saveAbsoluteSimulationValue: () => void;
  handlePopoverClose: () => void;
  open: boolean;
  year: number;
  valueDimension: KaeplaDimension;
  parameterValue: number;
  simulationValue: number;
}

export const AbsoluteSimulationValueDialog = ({
  open,
  absoluteSimulationValue,
  setAbsoluteSimulationValue,
  saveAbsoluteSimulationValue,
  handlePopoverClose,
  year,
  valueDimension,
  parameterValue,
  simulationValue,
}: AOptions) => {
  return (
    <Dialog open={open} onClose={handlePopoverClose}>
      <IconButton
        size="small"
        data-testid="button-cancel-set-absolute"
        sx={{ position: 'absolute', top: 0, right: 0 }}
        onClick={handlePopoverClose}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={1} sx={{ p: 2 }}>
        <Grid item xs={12}>
          <Typography variant="body1">{year}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TimeSliceValueAndLabel
            valueDimension={valueDimension}
            value={parameterValue}
            align="inherit"
            full
          />
        </Grid>
        <Grid item xs={12}>
          <TimeSliceValueAndLabel
            valueDimension={valueDimension}
            value={simulationValue}
            simulation
            align="inherit"
            full
          />
        </Grid>
        <Grid item xs={12}>
          <RoundingSuggestions
            valueDimension={valueDimension}
            value={simulationValue}
            setAbsoluteSimulationValue={setAbsoluteSimulationValue}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="standard"
            label="set absolut simulation value"
            inputProps={{ 'data-testid': 'simulation-value-input' }}
            value={absoluteSimulationValue}
            onChange={(event) => {
              const newValue = Number.parseFloat(event.target.value);
              setAbsoluteSimulationValue(newValue);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          {parameterValue && (
            <Typography variant="body2" sx={{ pt: 3 }}>
              {numbro(calculatePercentage(parameterValue, absoluteSimulationValue)).format({
                output: 'percent',
                mantissa: 3,
              })}{' '}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            size="small"
            sx={{ mt: 1 }}
            disabled={simulationValue === absoluteSimulationValue}
            onClick={() => {
              saveAbsoluteSimulationValue();
            }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            size="small"
            sx={{ mt: 1, ml: 1 }}
            onClick={(event) => {
              if (!simulationValue) return;
              setAbsoluteSimulationValue(simulationValue);
              saveAbsoluteSimulationValue();
              handlePopoverClose();
            }}
          >
            reset
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
