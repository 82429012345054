import { atom } from 'recoil';

import { localForageEffect } from './localForageEffect';

const defaultLastProjectIdState: string | undefined = undefined;

export const lastProjectIdState = atom<string>({
  key: 'lastProjectId',
  default: defaultLastProjectIdState,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
