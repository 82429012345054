/* eslint-disable no-console */
// methods like c*nsole.log are typically used in development
// -> this is why our linter warns us about not leaving c*nsole.method calls in our
//
// however: sometimes we desire to actually log something and leave in our code.
// this convenience export works around two issues.
// 1) having to disable lint warnings for each of those occurences
// 2) clearing up the codebase so we can easily search for "c*nsole.log"
//    and remove those dev logs

// TL;DR:
// logger should only be used for messages that SHOULD be logged in production too
// dev logging should still use c*nsole.method

// introduce enable/disable logging to reduce noise in tests
let isEnabled = true;

export const toggleAppLoadingLog = (toggle: boolean) => (isEnabled = toggle);

export const logger: Console = {
  ...console,
  group: (...data) => {
    if (isEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return console.group(...data);
    }
  },
  groupEnd: () => {
    if (isEnabled) {
      return console.groupEnd();
    }
  },
  error: (...data) => {
    if (isEnabled) {
      return console.error(...data);
    }
  },
  info: (...data) => {
    if (isEnabled) {
      return console.info(...data);
    }
  },
  debug: (...data) => {
    if (isEnabled) {
      return console.debug(...data);
    }
  },
  log: (...data) => {
    if (isEnabled) {
      return console.log(...data);
    }
  },
};

let isLayoutLogEnabled = false;

export const toggleLayoutLog = (toggle: boolean) => (isLayoutLogEnabled = toggle);

export const layoutLog: Console = {
  ...logger,
  log: (...data) => {
    if (isLayoutLogEnabled) {
      return console.log(...data);
    }
  },
};

let isRequestLogEnabled = true;

export const toggleRequestLog = (toggle: boolean) => (isRequestLogEnabled = toggle);

export const requestLog: Console = {
  ...logger,
  log: (...data) => {
    if (isRequestLogEnabled) {
      return console.log(...data);
    }
  },
};

let isParseErrorLogEnabled = true;

export const toggleParseErrorLog = (toggle: boolean) => (isParseErrorLogEnabled = toggle);

export const parseErrorLog: Console = {
  ...logger,
  log: (...data) => {
    if (isParseErrorLogEnabled) {
      return console.log(...data);
    }
  },
};

let isToDoLogEnabled = true;

export const toggleToDoLog = (toggle: boolean) => (isToDoLogEnabled = toggle);

export const toDoLog: Console = {
  ...logger,
  log: (...data) => {
    if (isToDoLogEnabled) {
      return console.log(...data);
    }
  },
};

let isMfaLogEnabled = true;

export const toggleMfaLog = (toggle: boolean) => (isMfaLogEnabled = toggle);

export const mfaLog: Console = {
  ...logger,
  log: (...data) => {
    if (isMfaLogEnabled) {
      return console.log(...data);
    }
  },
};
