import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import Timeline from '@mui/lab/Timeline';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import { Box, LinearProgress, Paper, Typography } from '@mui/material';
import { DocumentData, QueryDocumentSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { EventGroups } from './EventGroups';
import { eventListenerDefaultAmount, eventsFetchMore } from './defaults';
import { useAuth } from '../../../AuthProvider';
import { addFirestoreCollectionListener } from '../../../services/firestore/addFirestoreCollectionListener';
import { getEvents } from '../../../services/firestore/getEvents';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { KaeplaEvent } from '../../../services/types/Application/KaeplaEvent';
import { Layout } from '../../Layout/Layout';

export const Events = () => {
  const navigate = useNavigate();
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [events, setEvents] = useState<KaeplaEvent[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData>>();

  const fetchEvents = async () => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    const nextEvents = await getEvents({
      project,
      scopePathStringified: JSON.stringify(currentScopePath),
      lastVisible,
      setLastVisible,
      more: eventsFetchMore,
    });

    if (nextEvents.length === 0) {
      setHasMore(false);
    }

    setEvents((previousEvents) => [...previousEvents, ...nextEvents]);
  };

  useEffect(() => {
    if (!project?.id) return;
    if (!kaeplaUser?.uid || !currentScopePath) return;

    const fireStorePath = `events`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
      {
        fieldPath: 'scopePathStringified',
        opStr: '==',
        value: JSON.stringify(currentScopePath),
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      orderBy: {
        fieldPath: 'dateTime',
        direction: 'desc',
      },
      limit: eventListenerDefaultAmount,
      callback: (data) => {
        const _events = data as KaeplaEvent[];
        const load = async () => {
          setLoading(true);
          await new Promise((resolve) => setTimeout(resolve, 500));
          setEvents((previousEvents) => [
            ..._events.filter((event) => !previousEvents.map((p) => p.id).includes(event.id)),
            ...previousEvents,
          ]);
          setLoading(false);
        };
        void load();
      },
      snapshotCallback: (snapshot) => {
        const _lastVisible = snapshot.docs.at(-1);
        setLastVisible(_lastVisible);
      },
    });
    return () => {
      unsubscribe();
    };
  }, [currentScopePath, kaeplaUser?.uid, project?.id]);

  if (!project?.id) {
    navigate('/');
    return null;
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Box
        id="events-scrollable"
        component={Paper}
        sx={{
          p: 2,
          height: window.innerHeight - 200,
          overflow: 'auto',
        }}
      >
        {loading && <LinearProgress />}
        <InfiniteScroll
          dataLength={events.length} // This is important field to render the next data
          next={fetchEvents}
          hasMore={hasMore}
          loader={
            <Box>
              <Typography variant="body2">
                Loading...{' '}
                <Box
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                  component="span"
                  onClick={() => {
                    void fetchEvents();
                  }}
                >
                  click to load
                </Box>
              </Typography>
            </Box>
          }
          scrollableTarget="events-scrollable"
        >
          <Timeline
            sx={{
              p: 0,
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.01,
              },
            }}
          >
            {events.length > 0 && (
              <EventGroups events={events} fetchEvents={fetchEvents} hasMore={hasMore} />
            )}
          </Timeline>
        </InfiniteScroll>
      </Box>
    </Layout>
  );
};
