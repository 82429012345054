import { Box, Skeleton } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Zoom from 'chartjs-plugin-zoom';
import { MutableRefObject, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import { KaeplaChartDatasets } from './getChartDataForStackedBarChart';
import { stackedBarChartConfig } from './stackedBarChart.config';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, Zoom);

interface SBOptions {
  chartReference: MutableRefObject<undefined>;
  chartData?: KaeplaChartDatasets;
  loading: boolean;
}
export const StackedBarChart = ({ chartReference, chartData, loading }: SBOptions) => {
  useEffect(() => {
    if (loading && chartReference && chartReference.current) {
      const chart = chartReference.current as ChartJS;
      chart.resetZoom();
    }
  }, [chartReference, loading]);
  return (
    <Box height={240}>
      {loading && (
        <Skeleton
          sx={{ margin: 'auto', opacity: 0.5 }}
          variant="rectangular"
          animation="wave"
          width="100%"
          height={240}
        />
      )}
      {chartData && !loading && (
        <Bar
          ref={chartReference}
          options={stackedBarChartConfig as Record<string, unknown>}
          data={{ ...chartData, datasets: chartData.datasets.filter((d) => !d.hidden) }}
        />
      )}
    </Box>
  );
};
