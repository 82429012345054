import { atom } from 'recoil';

import { KaeplaCustomer } from '../../types/Application/KaeplaCustomer';

const defaultSelectedCustomerState: KaeplaCustomer | undefined = undefined;

export const selectedCustomerState = atom<KaeplaCustomer | undefined>({
  key: 'selectedCustomer',
  default: defaultSelectedCustomerState,
});
