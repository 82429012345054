import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useAuth } from 'src/AuthProvider';
import { DelayedLinearProgress } from 'src/Frontend/features/DelayedLinearProgress';
import { useUserPreferences } from 'src/UserPreferencesProvider';
import { getFromKaepla } from 'src/services/api/getFromKaepla';
import { getKaeplaTargets } from 'src/services/api/getKaeplaTargets';
import { createEvent } from 'src/services/firestore/createEvent';
import { applicationState } from 'src/services/recoil/nonpersistent/applicationState';
import { matrixFilteredState } from 'src/services/recoil/nonpersistent/matrixFilteredState';
import { matrixLoadingState } from 'src/services/recoil/nonpersistent/matrixLoadingState';
import { perspectiveTargetFiguresState } from 'src/services/recoil/nonpersistent/perspectiveTargetFigures';
import { perspectivesState } from 'src/services/recoil/nonpersistent/perspectivesState';
import { projectTargetsState } from 'src/services/recoil/nonpersistent/projectTargetsState';
import { simulationState } from 'src/services/recoil/nonpersistent/simulationState';
import { simulationsState } from 'src/services/recoil/nonpersistent/simulationsState';
import { currentScopePathState } from 'src/services/recoil/persistent/currentScopePathState';
import { timeSliceState } from 'src/services/recoil/persistent/timeSliceState';
import { KaeplaApiParameters } from 'src/services/types/Application/KaeplaApiParameters';
import { KaeplaDataOperation } from 'src/services/types/Application/KaeplaDataOperation';
import { KaeplaEventType } from 'src/services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from 'src/services/types/Application/KaeplaFunctionGroup';
import { KaeplaQueryType } from 'src/services/types/Application/KaeplaQueryType';
import { KaeplaSimulation } from 'src/services/types/Application/KaeplaSimulation';
import { MatrixSummary } from 'src/services/types/MatrixSummary';
import { MatrixTimeSlice } from 'src/services/types/MatrixTimeSlice';

import { CustomPerspective } from './CustomPerspective';
import { DefaultPerspective } from './DefaultPerspective';
import { CreateDummyMatrix } from './Wizard/CreateDummyMatrix';
import { PerspectiveWizard } from './Wizard/PerspectiveWizard';
import { perspectiveState } from '../../../services/recoil/nonpersistent/perspectiveState';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { Layout } from '../../Layout/Layout';

export const Perspective = () => {
  const { kaeplaUser } = useAuth();
  const { getPreferences } = useUserPreferences();
  const navigate = useNavigate();
  // get
  const app = useRecoilValue(applicationState);
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const perspectives = useRecoilValue(perspectivesState);
  const simulations = useRecoilValue(simulationsState);
  const projectTargets = useRecoilValue(projectTargetsState);
  // set
  const setTimeSlice = useSetRecoilState(timeSliceState);
  const setSimulation = useSetRecoilState(simulationState);
  const setMatrixFiltered = useSetRecoilState(matrixFilteredState);
  const setMatrixLoading = useSetRecoilState(matrixLoadingState);
  const setPerspectiveTargetFigures = useSetRecoilState(perspectiveTargetFiguresState);
  // get & set
  const [perspective, setPerspective] = useRecoilState(perspectiveState);
  // reset
  const resetPerspective = useResetRecoilState(perspectiveState);
  const resetSimulation = useResetRecoilState(simulationState);
  // local
  const [perspectiveLoading, setPerspectiveLoading] = useState(true);

  // this effect runs if perspective is changed from outside
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    if (!app.initialized) return;
    if (!app.simulationListenerActive) return;
    if (project?.matrixUnavailable) {
      setPerspectiveLoading(false);
      return;
    }

    const parameters: KaeplaApiParameters = {
      q: 'summary' as KaeplaQueryType,
      p: currentScopePath,
      projectId: project.id,
      s: 'Perspective',
    };
    getFromKaepla({
      callBack: (apiResponse) => {
        // console.log("->", apiResponse);
        const response = apiResponse.response as MatrixSummary;
        setMatrixFiltered((_matrixFiltered) => ({ ..._matrixFiltered, ...response }));
      },
      setLoading: setMatrixLoading,
      params: parameters,
      uid: kaeplaUser?.uid,
    });

    // default
    let perspectiveFromState = project.defaultPerspective;
    // from preferences
    const preferences = getPreferences();
    if (preferences?.lastPerspectiveId) {
      perspectiveFromState = perspectives?.find((p) => p.id === preferences.lastPerspectiveId);
    }
    if (perspectiveFromState) {
      setPerspective(perspectiveFromState);
    } else {
      resetPerspective();
    }

    // get target figures
    if (perspectiveFromState?.valueDimension && projectTargets) {
      const targetsForPerspective = projectTargets
        .filter((targets) => targets.dimension === perspectiveFromState?.valueDimension)
        .filter((targets) => targets.scopePathStringified === JSON.stringify(currentScopePath));
      // how do we deal with this if there are more than one?
      // will there be a dropdown to select from them like with simulations?
      // will we prevent having more than one set of target figures per scope and valueDimension?
      if (targetsForPerspective.length > 0) {
        const targets = targetsForPerspective[0];

        void getKaeplaTargets({
          callBack: (apiResponse) => {
            // const result = apiResponse;
            const _targetFigures = apiResponse.rows;
            if (_targetFigures) {
              const targetFiguresSorted = _targetFigures.sort((a, b) =>
                a.validAsOf.localeCompare(b.validAsOf),
              );
              setPerspectiveTargetFigures(targetFiguresSorted);
            }
          },
          params: {
            projectId: project.id,
            targetsId: targets.id,
          },
        });
      } else {
        setPerspectiveTargetFigures([]);
      }
    }

    if (preferences?.lastScopeTimeSlice) {
      setTimeSlice(preferences.lastScopeTimeSlice as MatrixTimeSlice);
    }
    let simulationFromState: KaeplaSimulation | undefined;
    if (preferences?.lastSimulationId) {
      simulationFromState = simulations?.find((s) => s.id === preferences.lastSimulationId);
    }
    if (simulationFromState) {
      setSimulation(simulationFromState);
    } else {
      resetSimulation();
    }

    setPerspectiveLoading(false);

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PERSPECTIVES_VIEW_PERSPECTIVE,
      functionGroup: KaeplaFunctionGroup.PERSPECTIVES,
      operation: KaeplaDataOperation.READ,
      scopePath: currentScopePath,
      project,
      perspectiveId: perspective?.id || perspectiveFromState?.id,
    });
    return () => {
      setPerspectiveLoading(true);
    };
  }, [
    app.simulationListenerActive,
    currentScopePath,
    getPreferences,
    kaeplaUser?.uid,
    perspective?.id,
    perspectives,
    project,
    setMatrixLoading,
    setMatrixFiltered,
    setPerspective,
    setPerspectiveLoading,
    setSimulation,
    simulations,
    app.initialized,
    setTimeSlice,
    resetPerspective,
    resetSimulation,
    projectTargets,
    setPerspectiveTargetFigures,
  ]);

  if (!project?.id) {
    navigate('/');
    return null;
  }

  if (perspectiveLoading) {
    return (
      <Layout hasScopeNavigation showCustomerSelector>
        <DelayedLinearProgress loading={perspectiveLoading} delay={1000} />
      </Layout>
    );
  }

  if (project?.matrixUnavailable) {
    return (
      <Layout>
        <CreateDummyMatrix />
      </Layout>
    );
  }

  if (!project?.initializedAt) {
    return (
      <Layout hasScopeNavigation={!project?.matrixUnavailable}>
        <PerspectiveWizard />
      </Layout>
    );
  }

  if (perspective?.id && perspective.id !== 'default') {
    return <CustomPerspective perspective={perspective} />;
  }

  return <DefaultPerspective />;
};
