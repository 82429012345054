import { Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { FilterPresetCard } from './FilterPresetCard';
import { useAuth } from '../../../../AuthProvider';
import { knownProjectFiltersState } from '../../../../services/recoil/nonpersistent/knownProjectFiltersState';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState';
import { filterSettingsState } from '../../../../services/recoil/persistent/filterSettingState';
import { filterTreeState } from '../../../../services/recoil/persistent/filterTreeState';
import { KaeplaFilter } from '../../../../services/types/Application/KaeplaFilter';

export const FilterPresetSelector = () => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const filterTree = useRecoilValue(filterTreeState);
  const knownProjectFilters = useRecoilValue(knownProjectFiltersState);
  const [filterPresets, setFilterPresets] = useState<KaeplaFilter[]>([]);
  const [selectedFilterPreset, setSelectedFilterPreset] = useState<KaeplaFilter>();

  useEffect(() => {
    if (!kaeplaUser) return;
    const loadFilterPresets = () => {
      if (filterSettings.filterPresetId) {
        const filterFromState = knownProjectFilters?.find(
          (_perspective) => _perspective.id === filterSettings.filterPresetId,
        );
        if (filterFromState) {
          setSelectedFilterPreset(filterFromState);
        } else {
          // eslint-disable-next-line unicorn/no-useless-undefined
          setSelectedFilterPreset(undefined);
        }
      }
      setFilterPresets(knownProjectFilters || []);
    };
    void loadFilterPresets();
  }, [
    kaeplaUser?.uid,
    project.id,
    currentScopePath,
    filterTree,
    kaeplaUser,
    filterSettings.filterPresetId,
    knownProjectFilters,
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography noWrap sx={{ fontSize: '120%' }}>
          Filter Presets
        </Typography>
      </Grid>
      {filterPresets.map((filter) => (
        <Grid key={filter.id} item xs={3}>
          <FilterPresetCard filter={filter} selected={filter.id === selectedFilterPreset?.id} />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};
