import numbro from 'numbro';

import { mapCurrency } from './mapCurrency';

export const formatValueWithCurrency = (
  value: string | number,
  currency?: string,
  decimalPositions?: number,
) => {
  let thisValue = `${value || 0}`;
  let mantissa = 2;
  if (decimalPositions) {
    mantissa = decimalPositions;
  }
  if (currency && currency === 'NULL') {
    thisValue = numbro(thisValue).format({
      average: true,
      mantissa,
    });
  } else if (currency) {
    const formatted = numbro(thisValue).format({
      average: true,
      mantissa,
    });
    thisValue = `${mapCurrency(currency)}${formatted}`;
  } else {
    thisValue = numbro(thisValue).format({
      average: true,
      mantissa,
    });
  }

  return `${thisValue}`;
};
