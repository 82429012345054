import { atom } from 'recoil';

import { KaeplaTargets } from '../../types/Application/KaeplaTargets';

const defaultTargetsState: Partial<KaeplaTargets> | undefined = undefined;

export const targetsState = atom<Partial<KaeplaTargets>>({
  key: 'Targets',
  default: defaultTargetsState,
});
