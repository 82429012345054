import { getApp } from 'firebase/app';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

import { KaeplaProject } from '../types/Application/KaeplaProject';
import { KaeplaTargets } from '../types/Application/KaeplaTargets';

interface Options {
  project: KaeplaProject;
  targets: Partial<KaeplaTargets>;
}

export const deleteTargets = async ({ project, targets }: Options) => {
  if (!targets.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/targets/${targets.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  return deleteDoc(firestoreDocumentReference);
};
