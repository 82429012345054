import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { useAuth } from '../../AuthProvider';
import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener';
import { getProjectsFromProjectIds } from '../../services/firestore/getProjectFromProjectIds';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState';
import { knownProjectsState } from '../../services/recoil/nonpersistent/knownProjectsState';
import { projectAssignmentsState } from '../../services/recoil/nonpersistent/userAssignmentState';
import { userRootPathsState } from '../../services/recoil/nonpersistent/userRootPathsState';
import { KaeplaProject } from '../../services/types/Application/KaeplaProject';
import { KaeplaProjectAssignment } from '../../services/types/Application/KaeplaProjectAssignment';
import { getUserRootPaths } from '../helpers/getUserRootPaths';
import { logger } from '../helpers/logger';

export const ProjectAssignmentsListenerKaeplaAdmins = () => {
  const { kaeplaUser } = useAuth();
  const setApp = useSetRecoilState(applicationState);
  const setKnownProjects = useSetRecoilState(knownProjectsState);
  const setProjectAssignments = useSetRecoilState(projectAssignmentsState);
  const setUserRootPaths = useSetRecoilState(userRootPathsState);

  // projectsListener -> for admins
  useEffect(() => {
    if (!kaeplaUser?.uid) return; // user is an admin or not authenticated yet
    // no additional checks here because:
    // we know that app.connecting isn't true any more,
    // as we set kaeplaAssignment after connecting
    // and this component only loads if we have a kaeplaAssignment

    logger.log('\nuseEffect ProjectAssignmentsListenerKaeplaAdmins');

    const fireStorePathProjects = `projects`;
    const unsubscribeProjectsListener = addFirestoreCollectionListener({
      fireStorePath: fireStorePathProjects,
      callback: (_projects) => {
        const projectIds = (_projects as KaeplaProject[]).map((p) => p.id);
        logger.log('ProjectListenerKaeplaAdmins fires:', projectIds);
        const loadProjects = async () => {
          const projectsOrdered = await getProjectsFromProjectIds({ projectIds }); // TODO: can we cache those?

          setUserRootPaths((existingRootPaths) => {
            const extendedRootPaths = { ...existingRootPaths };
            projectsOrdered.forEach((project) => {
              if (!existingRootPaths[project.id]) {
                extendedRootPaths[project.id] = '[]';
              }
            });
            return extendedRootPaths;
          });
          logger.log('ProjectListenerKaeplaAdmins setKnownProjects', projectsOrdered);
          setKnownProjects(projectsOrdered);
          setApp((previousApp) => ({ ...previousApp, projectsLoaded: true }));
        };
        void loadProjects();
      },
    });

    const fireStorePathProjectAssignments = `projectAssignments`;
    const queryWhere: WhereClause[] = [];
    // filter by uid for non kaepla admins
    queryWhere.push(
      {
        fieldPath: 'uid',
        opStr: '==',
        value: kaeplaUser.uid,
      },
      {
        fieldPath: 'complete',
        opStr: '==',
        value: true,
      },
    );

    const unsubscribeProjectAssignmentListener = addFirestoreCollectionListener({
      fireStorePath: fireStorePathProjectAssignments,
      queryWhere,
      callback: (projectAssignments) => {
        logger.log('ProjectAssignmentsListenerKaeplaAdmins fires:', projectAssignments);
        setProjectAssignments(projectAssignments as KaeplaProjectAssignment[]);
        const userRootPaths = getUserRootPaths(projectAssignments as KaeplaProjectAssignment[]);
        setUserRootPaths(userRootPaths);
      },
    });

    return () => {
      logger.log('ProjectListenerKaeplaAdmins is unmounted!');
      unsubscribeProjectsListener();
      logger.log('ProjectAssignmentsListenerKaeplaAdmins is unmounted!');
      unsubscribeProjectAssignmentListener();
    };
  }, [kaeplaUser?.uid, setApp, setKnownProjects, setProjectAssignments, setUserRootPaths]);

  return null;
};
