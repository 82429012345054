import AddIcon from '@mui/icons-material/AddCircleOutline';
import SimulationIcon from '@mui/icons-material/AutoGraph';
import FilterIcon from '@mui/icons-material/FilterAlt';
import FilterOffIcon from '@mui/icons-material/FilterAltOffOutlined';
import HideIcon from '@mui/icons-material/VisibilityOffOutlined';
import ShowIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Grid, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { clone } from 'rambda';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useUserPreferences } from 'src/UserPreferencesProvider';
import { toggleSimulationFavorite } from 'src/services/firestore/toggleSimulationFavorite';
import { currentScopePathState } from 'src/services/recoil/persistent/currentScopePathState';

import { ProjectDimensionsCount } from './ProjectDimensionsCount';
import { ProjectRecordsCount } from './ProjectRecordsCount';
import { ProjectTeam } from './ProjectTeam';
import { SelectFilter } from './SelectFilter';
import { SelectPerspective } from './SelectPerspective';
import { SelectSimulation } from './SelectSimulation';
import { useAuth } from '../../../../../../AuthProvider';
import { watcherServiceKaepla } from '../../../../../../firebaseInit';
import { createNewPerspective } from '../../../../../../services/firestore/createNewPerspective';
import { createNewSimulation } from '../../../../../../services/firestore/createNewSimulation';
import { applicationState } from '../../../../../../services/recoil/nonpersistent/applicationState';
import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState';
import { scopePathsState } from '../../../../../../services/recoil/nonpersistent/scopePathsState';
import { simulationState } from '../../../../../../services/recoil/nonpersistent/simulationState';
import { filterSettingsState } from '../../../../../../services/recoil/persistent/filterSettingState';
import { logger } from '../../../../../helpers/logger';

interface Options {
  title: string;
  disableSimulationSelect?: boolean;
  disableFilters?: boolean;
}

export const ProjectInfo = ({ title, disableSimulationSelect, disableFilters }: Options) => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  const { setPreferences } = useUserPreferences();
  const project = useRecoilValue(projectState);
  const [perspective, setPerspective] = useRecoilState(perspectiveState);
  const simulation = useRecoilValue(simulationState);
  const scopePath = useRecoilValue(scopePathsState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [filterSettings, setFilterSettings] = useRecoilState(filterSettingsState);
  const [application, setApplication] = useRecoilState(applicationState);

  const createSimulation = async () => {
    if (!kaeplaUser) return;
    const _simulation = await createNewSimulation({
      project,
      scopePath: scopePath[project.id] || [],
      createdBy: kaeplaUser.uid,
    });

    void toggleSimulationFavorite({
      uid: kaeplaUser.uid,
      simulation: _simulation,
      scopePath: currentScopePath,
      isFavorite: true,
    });
    setPreferences({ lastSimulationId: perspective.id });
    navigate(`/Simulation/${project.id}/${_simulation.id}`);
  };

  const createPerspective = async () => {
    if (!kaeplaUser) return;
    const _perspective = await createNewPerspective({
      project,
      createdBy: kaeplaUser.uid,
      scopePath: scopePath[project.id] || [],
      perspective: perspective || project.defaultPerspective,
    });
    setPerspective(_perspective);
    setPreferences({ lastPerspectiveId: perspective.id });
  };

  const goToSimulation = async () => {
    if (!simulation?.id) return;
    // unmount the simulation listener
    const watcherKeys = watcherServiceKaepla.get().getSubscriptionKeys();
    let simulationWatcherKey;
    watcherKeys.forEach((watcher) => {
      const [watcherType] = watcher.split(':');
      if (watcherType === `projects/${project.id}/simulations/${simulation.id || 'x'}`) {
        simulationWatcherKey = watcher;
      }
    });
    if (simulationWatcherKey) {
      watcherServiceKaepla.get().unsubscribe(simulationWatcherKey);
    }
    logger.log('watcher keys', watcherKeys, simulationWatcherKey);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    navigate(`/Simulation/${project.id}/${simulation.id}`);
  };

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: 20, color: grey[900] }} color="text.secondary" gutterBottom>
          {title || 'Matrix'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ProjectTeam project={project} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
      <Grid item xs={6}>
        <ProjectRecordsCount project={project} />
      </Grid>
      <Grid item xs={6}>
        <ProjectDimensionsCount project={project} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
      <Grid item xs={8}>
        <SelectSimulation disabled={disableSimulationSelect} />
      </Grid>
      <Grid item xs={2} container sx={{ justifyContent: { xs: 'flex-end' } }}>
        {simulation && (
          <Box sx={{ alignSelf: { xs: 'end' } }}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                void goToSimulation();
              }}
            >
              <SimulationIcon />
            </IconButton>
          </Box>
        )}
        {!simulation && (
          <Box sx={{ alignSelf: { xs: 'end' } }}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => {
                navigate(`/Simulations`);
              }}
            >
              <SimulationIcon />
            </IconButton>
          </Box>
        )}
      </Grid>
      <Grid item xs={2} container sx={{ justifyContent: { xs: 'flex-end' } }}>
        <Box sx={{ alignSelf: { xs: 'end' } }}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              void createSimulation();
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
      <Grid item xs={8}>
        <SelectPerspective />
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={2} container sx={{ justifyContent: { xs: 'flex-end' } }}>
        <Box sx={{ alignSelf: { xs: 'end' } }}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              void createPerspective();
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
      <Grid item xs={8}>
        <SelectFilter disabled={disableFilters} />
      </Grid>
      <Grid item xs={2} container sx={{ justifyContent: { xs: 'flex-end' } }}>
        <Box sx={{ alignSelf: { xs: 'end' } }}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              setFilterSettings({ ...filterSettings, isActive: !filterSettings.isActive });
            }}
            disabled={!filterSettings.filterPresetId || disableFilters}
          >
            {filterSettings.isActive ? <FilterIcon /> : <FilterOffIcon />}
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={2} container sx={{ justifyContent: { xs: 'flex-end' } }}>
        <Box sx={{ alignSelf: { xs: 'end' } }}>
          <IconButton
            size="small"
            color="primary"
            onClick={() => {
              const newApplication = clone(application);
              newApplication.showFilter = !newApplication.showFilter;
              setApplication(newApplication);
            }}
            disabled={disableFilters}
          >
            {application.showFilter ? <HideIcon /> : <ShowIcon />}
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ m: 1 }} />
      </Grid>
    </Grid>
  );
};
