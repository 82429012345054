import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger';

interface KOptions {
  q: string;
  projectId: string;
  p?: string[];
  dimension?: string;
  searchKey?: string;
  limit?: number;
  offset?: number;
  uid?: string;
}

export const searchDimensionValues = ({
  q,
  projectId,
  p,
  dimension,
  searchKey,
  uid,
  limit,
  offset,
}: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const getKaeplaMatrix = httpsCallable(functions, 'getKaeplaMatrix');

  requestLog.log(
    `kaeplaMatrix.post().json(${JSON.stringify({
      q,
      projectId,
      p,
      dimension,
      searchKey,
      uid,
      limit,
      offset,
    })})`,
  );
  return getKaeplaMatrix({ q, projectId, p, dimension, searchKey, limit, offset });
};
