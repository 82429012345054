import { Timestamp } from 'firebase/firestore';
import { createContext, useCallback, useContext, useState } from 'react';

export interface KaeplaError {
  message: string;
  occurredAt?: Timestamp;
}

export interface KaeplaErrorContextType {
  errors: KaeplaError[];
  lastError?: KaeplaError;
  setError: (error: KaeplaError) => void;
  snackOpen: boolean;
  handleSnackClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
}

interface KaeplaErrorProviderProperties {
  children?: React.ReactNode;
}

export interface KaeplaErrorContextModel {
  errors: KaeplaError[];
  lastError?: KaeplaError;
  setError: (error: KaeplaError) => void;
  snackOpen: boolean;
  handleSnackClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
}

export const KaeplaErrorContext = createContext<KaeplaErrorContextType>(
  {} as KaeplaErrorContextType,
);

export function useError(): KaeplaErrorContextModel {
  return useContext(KaeplaErrorContext);
}

export const KaeplaErrorProvider = ({ children }: KaeplaErrorProviderProperties): JSX.Element => {
  const [errors, setErrors] = useState<KaeplaError[]>([
    {
      message: 'ERROR 1',
      occurredAt: Timestamp.now(),
    },
    {
      message: 'ERROR 2',
      occurredAt: Timestamp.now(),
    },
  ]);
  const [lastError, setLastError] = useState<KaeplaError | undefined>();

  const [snackOpen, setSnackOpen] = useState(false);

  const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const setError = useCallback((error: KaeplaError) => {
    const newError: KaeplaError = {
      message: error.message,
      occurredAt: Timestamp.now(),
    };
    setLastError(newError);
    setSnackOpen(true);
    setErrors((previousErrors) => [...previousErrors, newError]);
  }, []);

  const values: KaeplaErrorContextModel = {
    errors,
    lastError,
    setError,
    snackOpen,
    handleSnackClose,
  };

  return <KaeplaErrorContext.Provider value={values}>{children}</KaeplaErrorContext.Provider>;
};
