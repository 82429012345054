import { atom } from 'recoil';
import { KaeplaTargetsFigure } from 'src/services/types/Application/KaeplaTargetsFigure';

import { localForageEffect } from '../persistent/localForageEffect';

export const perspectiveTargetFiguresState = atom<KaeplaTargetsFigure[]>({
  key: 'perspectiveTargetFigures',
  default: [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
