import { getApp } from 'firebase/app';
import { collection, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';

import { KaeplaSimulation } from '../types/Application/KaeplaSimulation';
import { KaeplaTargets } from '../types/Application/KaeplaTargets';

interface Options {
  simulation: KaeplaSimulation;
}

export const getTargetsForSimulation = async ({ simulation }: Options) => {
  const data: KaeplaTargets[] = [];
  if (!simulation) return data;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${simulation.projectId}/targets`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const firestoreQuery = query(
    firestoreCollection,
    where('simulationId', '==', simulation.id),
    where('scopePathStringified', '==', simulation.scopePathStringified),
    limit(50),
  );
  const snapshot = await getDocs(firestoreQuery);
  snapshot.forEach((document_) => {
    const targets = document_.data();
    targets.id = document_.id;
    data.push(targets as KaeplaTargets);
  });
  return data;
};
