import { atom } from 'recoil';

import { KaeplaResellerAssignment } from '../../types/Application/KaeplaResellerAssignment';

const defaultResellerAssignmentsState: KaeplaResellerAssignment[] = [];

export const resellerAssignmentsState = atom<KaeplaResellerAssignment[]>({
  key: 'resellerAssignments',
  default: defaultResellerAssignmentsState,
});
