import { JsonTree } from '@react-awesome-query-builder/mui';
import { KaeplaSimulation } from 'src/services/types/Application/KaeplaSimulation';
import { MatrixTimeSlice } from 'src/services/types/MatrixTimeSlice';

import { KaeplaApiParameters } from '../../../../../../services/types/Application/KaeplaApiParameters';
import { KaeplaPerspectiveBlock } from '../../../../../../services/types/Application/KaeplaPerspectiveBlock';
import { KaeplaProject } from '../../../../../../services/types/Application/KaeplaProject';
import { KaeplaQueryType } from '../../../../../../services/types/Application/KaeplaQueryType';

interface Options {
  project: KaeplaProject;
  queryType: KaeplaQueryType;
  currentScopePath: string[];
  filterTree: JsonTree;
  filterActive: boolean;
  block: KaeplaPerspectiveBlock;
  timeSlice?: MatrixTimeSlice;
  simulation?: Partial<KaeplaSimulation>;
}

export const chartSimulationParameters = ({
  project,
  queryType,
  currentScopePath,
  filterTree,
  filterActive,
  block,
  timeSlice,
  simulation,
}: Options) => {
  const parameters: KaeplaApiParameters = {
    s: 'simulationChartParameters',
    q: queryType,
    p: currentScopePath,
    projectId: project.id,
    simulationId: simulation?.id,
    info: JSON.stringify(block),
  };

  if (timeSlice) {
    parameters.timeSlice = timeSlice;
  }

  if (filterActive) {
    parameters.filterTree = filterTree;
  }

  return parameters;
};
