import { KaeplaSimulationParameterNumeric } from 'src/services/types/Application/KaeplaSimulationParameterNumeric';
import { KaeplaSimulationParameterText } from 'src/services/types/Application/KaeplaSimulationParameterText';
import { KaeplaSimulationTextValue } from 'src/services/types/Application/KaeplaSimulationTextValue';

import { KaeplaSimulationRulesetWithParameters } from '../../../../../services/types/Application/KaeplaSimulationRulesetWithParameters';

interface Options {
  ruleset: KaeplaSimulationRulesetWithParameters;
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  parameter: string;
}

export const addTextParameterToSimulationRulesets = ({
  ruleset,
  parameter,
  simulationRulesets,
}: Options) => {
  const newRuleset = { ...ruleset };

  let rulesetParameters: (KaeplaSimulationParameterText | KaeplaSimulationParameterNumeric)[] = [];
  if (newRuleset.parameters) {
    rulesetParameters = [...newRuleset.parameters];
  }

  const defaultValues: KaeplaSimulationTextValue[] = [
    {
      key: 'NULL-NULL',
      originalTextValue: 'NULL',
      simulatedTextValue: 'NULL',
    },
  ];

  const newParameter: KaeplaSimulationParameterText = {
    dimension: parameter,
    parameterType: 'text',
    rulesetId: ruleset.id,
    values: defaultValues,
  };

  rulesetParameters.push(newParameter);

  newRuleset.parameters = rulesetParameters;

  const newRulesets = simulationRulesets.map((r) => {
    if (r.id === ruleset.id) {
      return newRuleset;
    }
    return r;
  });

  if (!newRulesets?.find((r) => r.id === ruleset.id)) {
    newRulesets?.push(newRuleset);
  }

  return newRulesets;
};
