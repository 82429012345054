import { Divider, Grid, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { DimensionCard } from './components/DimensionCard';
import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState';
import { KaeplaDimensionDetails } from '../../../../services/types/Application/KaeplaDimensionDetails';
import { KaeplaPerspective } from '../../../../services/types/Application/KaeplaPerspective';

const isChoosableAsTimeDimension = (detail: KaeplaDimensionDetails) => {
  if (detail.dataType !== 'DATE') return false;
  const nulls = detail.dimensionDetails.find((row) => row.value === null);
  let nullValues = 0;
  if (nulls) {
    nullValues = nulls.numberOfRecords;
  }
  if (nullValues > 0) return false;

  return true;
};

const choosableDimensions = (details?: KaeplaDimensionDetails[]) => {
  if (!details) return [];
  return details.filter((detail) => isChoosableAsTimeDimension(detail));
};

const notChoosableDimensions = (details?: KaeplaDimensionDetails[]) => {
  if (!details) return [];
  return details
    .filter((detail) => detail.dataType === 'DATE')
    .filter((detail) => !isChoosableAsTimeDimension(detail));
};

interface Options {
  defaultPerspective: Partial<KaeplaPerspective>;
  setDefaultPerspective: (step: Partial<KaeplaPerspective>) => void;
}

export const StepTimeDimension = ({ setDefaultPerspective, defaultPerspective }: Options) => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Choose a time dimension</Typography>
      </Grid>
      {choosableDimensions(matrixFiltered?.details)?.map((detail) => (
        <Grid key={detail.columnName} item xs={12} sm={6} md={4} lg={3}>
          <DimensionCard
            detail={detail}
            ideal={true}
            totalCount={matrixFiltered?.totalCount?.totalCount || 0}
            setDefaultPerspective={setDefaultPerspective}
            defaultPerspective={defaultPerspective}
            dimensionType="timeDimension"
            desiredDataType="DATE"
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          These Dimensions are not suited for aggregation over all records
        </Typography>
      </Grid>
      {notChoosableDimensions(matrixFiltered?.details)?.map((detail) => (
        <Grid key={detail.columnName} item xs={12} sm={6} md={4} lg={3}>
          <DimensionCard
            detail={detail}
            ideal={false}
            totalCount={matrixFiltered?.totalCount?.totalCount || 0}
            dimensionType="timeDimension"
            desiredDataType="DATE"
          />
        </Grid>
      ))}
    </Grid>
  );
};
