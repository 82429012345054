import { Box, Card, CircularProgress, Toolbar, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DelayedLinearProgress } from 'src/Frontend/features/DelayedLinearProgress';
import { useDebouncedCallback } from 'use-debounce';

import { DataTimelineChart } from './DataTimelineChart';
import { TimeSliceSwitcher } from './TimeSliceSwitcher';
import { updateDefaultPerspective } from '../../../../../services/firestore/updateProjectDefaultPerspective';
import { perspectiveState } from '../../../../../services/recoil/nonpersistent/perspectiveState';
import { projectState } from '../../../../../services/recoil/nonpersistent/projectState';
import { simulationState } from '../../../../../services/recoil/nonpersistent/simulationState';
import { timeSeriesState } from '../../../../../services/recoil/nonpersistent/timeSeriesState';
import { KaeplaPerspective } from '../../../../../services/types/Application/KaeplaPerspective';
import { BQJobInfo } from '../../../../features/BQJobInfo';
import { CardContentMinPadding } from '../../../../features/CardContentMinPadding';
import { InlineEdit } from '../../../../features/InlineEdit';
import {
  matrixDataColor,
  matrixDataColorLight,
  simulationDataColor,
  simulationDataColorLight,
  targetsDataColor,
  targetsDataColorLight,
} from '../../../defaults';
import { chartTitle } from '../../helpers/aggregationOverTime';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface Options {
  disableSliceSwitcher?: boolean;
  simulationPreview?: boolean;
  simulationRunning?: boolean;
}

export const DataTimeline = ({
  disableSliceSwitcher,
  simulationPreview,
  simulationRunning,
}: Options) => {
  const [project, setProject] = useRecoilState(projectState);
  const perspective = useRecoilValue(perspectiveState);
  const simulation = useRecoilValue(simulationState);
  const timeSeries = useRecoilValue(timeSeriesState);
  const [loading, setLoading] = useState(false);
  const [hasSimulation, setHasSimulation] = useState(false);
  const [hasTargets, setHasTargets] = useState(false);

  const changeAggregationOverTimeName = useDebouncedCallback((value: string) => {
    const perspectiveUpdate: Partial<KaeplaPerspective> = {
      ...perspective,
      aggregationOverTimeName: value,
    };
    void updateDefaultPerspective({
      project,
      perspective: perspectiveUpdate,
      setProject,
    });
  }, 500);

  return (
    <Card sx={{ minHeight: 320, height: '100%' }}>
      {loading ? (
        <DelayedLinearProgress loading={loading} delay={1000} />
      ) : (
        <Box sx={{ height: 4 }} />
      )}
      <CardContentMinPadding sx={{ pt: 1.1 }}>
        <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
          <InlineEdit
            value={perspective?.aggregationOverTimeName || 'n/a'}
            callback={changeAggregationOverTimeName}
          />
          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
            {timeSeries?.job && <BQJobInfo job={timeSeries.job} />}
            {simulationRunning && (
              <Box
                component="span"
                sx={{
                  ml: 2,
                  fontSize: '70%',
                  fontWeight: '300',
                  fontFamily: 'monospace',
                }}
              >
                <CircularProgress size={10} /> Simulation is running
              </Box>
            )}
          </Typography>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <Box component="div" color="inherit" sx={{ whiteSpace: 'nowrap' }}>
            {!disableSliceSwitcher && <TimeSliceSwitcher setLoading={setLoading} />}
          </Box>
        </Toolbar>
        <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
          <Typography sx={{ fontSize: 18 }} color="text.secondary" gutterBottom>
            <Box component="span" sx={{ fontSize: '80%', fontWeight: '100', mr: 1 }}>
              {perspective && chartTitle(perspective)}
            </Box>
          </Typography>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        </Toolbar>
        <DataTimelineChart
          setHasSimulation={setHasSimulation}
          setHasTargets={setHasTargets}
          simulationPreview={simulationPreview}
        />
        <Box fontSize={12} pt={1}>
          {(hasSimulation || hasTargets) && (
            <Box component="span">
              <Box
                component="span"
                display="inline-block"
                border={1}
                borderRadius={6}
                width={12}
                height={12}
                bgcolor={matrixDataColorLight}
                borderColor={matrixDataColor}
                mr={1}
              />
              Original Data
            </Box>
          )}
          {hasSimulation && (
            <Box component="span">
              <Box
                component="span"
                display="inline-block"
                border={1}
                borderRadius={6}
                width={12}
                height={12}
                bgcolor={simulationDataColorLight}
                borderColor={simulationDataColor}
                ml={2}
                mr={1}
              />
              Simulation ({simulation?.name})
            </Box>
          )}
          {hasTargets && (
            <Box component="span">
              <Box
                component="span"
                display="inline-block"
                border={1}
                borderRadius={6}
                width={12}
                height={12}
                bgcolor={targetsDataColorLight}
                borderColor={targetsDataColor}
                ml={2}
                mr={1}
              />
              Targets ({perspective.valueDimension})
            </Box>
          )}
        </Box>
      </CardContentMinPadding>
    </Card>
  );
};
