import { Fields } from '@react-awesome-query-builder/mui';

import { getFieldSetting } from './getFieldSetting';
import { KaeplaProject } from '../../../../services/types/Application/KaeplaProject';
import { MatrixSummary } from '../../../../services/types/MatrixSummary';

interface Options {
  matrixFiltered: MatrixSummary;
  project: KaeplaProject;
  currentScopePath: string[];
  uid: string;
  alias?: string;
}
export const getFieldSettings = ({
  alias,
  matrixFiltered,
  project,
  currentScopePath,
  uid,
}: Options) => {
  const fields: Fields = {};

  if (!matrixFiltered?.dimensions) return fields;
  // Autocomplete with simulatd Async fetch: https://github.com/ukrbublik/react-awesome-query-builder/blob/master/packages/sandbox/src/demo/config.tsx#L254
  matrixFiltered.dimensions.dimensions.forEach((dimension) => {
    // console.log('dimension', dimension);
    let fieldName = dimension.columnName;
    if (alias) {
      fieldName = `${alias}.${dimension.columnName}`;
    }
    fields[fieldName] = getFieldSetting({ project, currentScopePath, uid, dimension });
  });
  return fields;
};
