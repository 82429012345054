import { Link, Typography } from '@mui/material';

import { KaeplaCustomer } from '../../../../services/types/Application/KaeplaCustomer';

interface Options {
  customer: KaeplaCustomer;
  printAll?: boolean;
}

export const CustomerDomain = ({ customer, printAll }: Options) => {
  const allDomains = [
    ...(customer?.customerDomains || []),
    ...(customer?.resellerDomains || []),
  ].filter((v, index, a) => a.indexOf(v) === index);

  if (printAll) {
    return (
      <>
        {allDomains.map((domain) => {
          const accessUrl = `https://${domain}`;

          return (
            <Link
              key={accessUrl}
              color="inherit"
              underline="hover"
              target="_blank"
              rel="noopener noreferrer"
              href={accessUrl}
            >
              <Typography component="div" variant="caption">
                {accessUrl}
              </Typography>
            </Link>
          );
        })}
      </>
    );
  }

  const hasCustomerDomain = customer.customerDomains && customer.customerDomains.length > 0;
  const hasResellerDomain = customer.resellerDomains && customer.resellerDomains.length > 0;

  if (hasCustomerDomain && customer.customerDomains?.[0]) {
    const customerUrl = `https://${customer.customerDomains[0]}`;

    return (
      <Link
        color="inherit"
        underline="hover"
        target="_blank"
        rel="noopener noreferrer"
        href={customerUrl}
      >
        <Typography variant="caption">{customerUrl}</Typography>
      </Link>
    );
  }

  if (hasResellerDomain && customer.resellerDomains?.[0]) {
    const resellerUrl = `https://${customer.resellerDomains[0]}`;

    return (
      <Link
        color="inherit"
        underline="hover"
        target="_blank"
        rel="noopener noreferrer"
        href={resellerUrl}
      >
        <Typography variant="caption">{resellerUrl}</Typography>
      </Link>
    );
  }

  return <Typography variant="caption">no domain configured</Typography>;
};
