import { Grid, Typography, Divider, Alert, AlertTitle, Box } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';

import { CurrencyDimensionCard } from './components/CurrencyDimensionCard';
import { DimensionCard } from './components/DimensionCard';
import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState';
import { KaeplaDimension } from '../../../../services/types/Application/KaeplaDimension';
import { KaeplaDimensionDetails } from '../../../../services/types/Application/KaeplaDimensionDetails';

const isChoosableAsCurrencyDimension = (detail: KaeplaDimension) => {
  if (['FLOAT64', 'DECIMAL', 'NUMERIC', 'INT', 'INT64'].includes(detail.dataType)) return true;
  return false;
};

const choosableDimensions = (details?: KaeplaDimension[]) => {
  if (!details) return [];
  return details.filter((detail) => isChoosableAsCurrencyDimension(detail));
};

const notChoosableDimensions = (details?: KaeplaDimensionDetails[]) => {
  if (!details) return [];
  return details.filter((detail) => !isChoosableAsCurrencyDimension(detail));
};

export const StepCurrency = () => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);

  const [currencyDefault, setCurrencyDefault] = useState<string>('EUR');
  const [decimalPositionsDefault, setDecimalPositionsDefault] = useState(2);

  if (!matrixFiltered?.details || matrixFiltered?.details.length === 0) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <Alert severity="error">
            <AlertTitle>ERROR</AlertTitle>
            Matrix dimensions could not be read
          </Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Currency can be set up for these dimensions</Typography>
        <Box>
          Current default: {currencyDefault}, {decimalPositionsDefault} decimal positions
        </Box>
        <Box>
          Note: The currency settings affect only display. Actual numbers are not rounded when
          stored and calculated.
        </Box>
      </Grid>
      {choosableDimensions(matrixFiltered?.dimensions?.dimensions)?.map((dimension) => (
        <Grid key={dimension.columnName} item xs={12} sm={6} md={4} lg={3}>
          <CurrencyDimensionCard
            dimension={dimension}
            ideal={true}
            desiredDataType="FLOAT64"
            currencyDefault={currencyDefault}
            setCurrencyDefault={setCurrencyDefault}
            decimalPositionsDefault={decimalPositionsDefault}
            setDecimalPositionsDefault={setDecimalPositionsDefault}
          />
        </Grid>
      ))}
      {!choosableDimensions(matrixFiltered?.details) ||
        (choosableDimensions(matrixFiltered?.details).length === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="center">
              <Alert severity="warning">
                <AlertTitle>WARNING</AlertTitle>
                No ideal dimensions for aggregation over all records found
              </Alert>
            </Grid>
          </Grid>
        ))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">These Dimensions can't have a currency set up</Typography>
      </Grid>
      {notChoosableDimensions(matrixFiltered?.details)?.map((detail) => (
        <Grid key={detail.columnName} item xs={12} sm={6} md={4} lg={3}>
          <DimensionCard
            detail={detail}
            ideal={false}
            totalCount={matrixFiltered.totalCount?.totalCount || 0}
            dimensionType="valueDimension"
            desiredDataType="FLOAT64"
          />
        </Grid>
      ))}
    </Grid>
  );
};
