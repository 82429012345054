import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import ShowMinimalIcon from '@mui/icons-material/ArrowLeft';
import { Avatar, AvatarGroup, Box, Button, Skeleton, Stack } from '@mui/material';
import { clone } from 'rambda';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { watcherKeysState } from 'src/services/recoil/nonpersistent/watcherKeysState';

import { useAuth } from '../../../../../../AuthProvider';
import { watcherServiceKaepla } from '../../../../../../firebaseInit';
import { resendInvite } from '../../../../../../services/api/resendInviteEmail';
import { addFirestoreCollectionListener } from '../../../../../../services/firestore/addFirestoreCollectionListener';
import { createEvent } from '../../../../../../services/firestore/createEvent';
import { createProjectAssignmentForEmail } from '../../../../../../services/firestore/createProjectAssignmentForEmail';
import { getUser } from '../../../../../../services/firestore/getUser';
import { removeProjectAssignment } from '../../../../../../services/firestore/removeProjectAssignment';
import { currentScopePathState } from '../../../../../../services/recoil/persistent/currentScopePathState';
import { KaeplaDataOperation } from '../../../../../../services/types/Application/KaeplaDataOperation';
import { KaeplaEventEffect } from '../../../../../../services/types/Application/KaeplaEventEffect';
import { KaeplaEventType } from '../../../../../../services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from '../../../../../../services/types/Application/KaeplaFunctionGroup';
import { KaeplaProject } from '../../../../../../services/types/Application/KaeplaProject';
import { KaeplaProjectAssignment } from '../../../../../../services/types/Application/KaeplaProjectAssignment';
import { KaeplaUser } from '../../../../../../services/types/Application/KaeplaUser';
import { AddTeamMember } from '../../../../../features/AddTeamMember';
import { AddTeamMemberToggle } from '../../../../../features/AddTeamMemberToggle';
import { UserAvatar } from '../../../../../features/UserAvatar';
import { logger } from '../../../../../helpers/logger';
import { avatarGroupSlot, maxAvatarsDefault, scrollStyle } from '../../../../defaults';

interface KaeplaUserWithAssignment extends KaeplaUser {
  assignmentId: string;
}
interface Options {
  project: KaeplaProject;
  disableInvites?: boolean;
  defaultPath?: string[];
}

export const ProjectTeam = ({ project, disableInvites, defaultPath }: Options) => {
  const { kaeplaUser } = useAuth();
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [watcherKeys, setWatcherKeys] = useRecoilState(watcherKeysState);
  const [team, setTeam] = useState<KaeplaUserWithAssignment[]>([]);
  const [self, setSelf] = useState<KaeplaUserWithAssignment>();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [maxAvatars, setMaxAvatars] = useState<number>(maxAvatarsDefault);

  const openAddTeamMemberDialog = () => {
    setOpen(true);
  };

  const closeAddTeamMemberDialog = (email: string) => {
    setOpen(false);
    if (!kaeplaUser?.uid || email.length === 0) return;
    const sendInvite = async () => {
      let emailToInvite = email;
      if (!emailToInvite.includes('@')) {
        emailToInvite = `${email}@kaepla.atrigam.com`;
      }
      await createProjectAssignmentForEmail({
        project,
        email: emailToInvite,
        scopePath: currentScopePath,
        assignedBy: kaeplaUser.uid,
      });

      void createEvent({
        uid: kaeplaUser?.uid,
        eventType: KaeplaEventType.PROJECT_TEAM_INVITE_USER_TO_TEAM,
        effect: KaeplaEventEffect.PROJECT_TEAM_CHANGE,
        functionGroup: KaeplaFunctionGroup.PROJECTS,
        operation: KaeplaDataOperation.CREATE,
        project,
        scopePath: currentScopePath,
        eventInfo: {
          affectedUserEmail: email,
        },
      });
    };
    void sendInvite();
  };

  const leaveProjectTeam = async (userWithAssignment: KaeplaUserWithAssignment) => {
    // unmount the project listener
    const keyId = `projectTeam-${project.id}`;
    const allWatcherKeys = watcherServiceKaepla.get().getSubscriptionKeys();
    const projectWatcherKey = allWatcherKeys.find((w) => w === watcherKeys[keyId]);

    if (projectWatcherKey) {
      watcherServiceKaepla.get().unsubscribe(projectWatcherKey);
    }
    logger.log('watcher key', keyId, projectWatcherKey);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PROJECT_TEAM_LEAVE_TEAM,
      effect: KaeplaEventEffect.PROJECT_TEAM_CHANGE,
      functionGroup: KaeplaFunctionGroup.PROJECTS,
      operation: KaeplaDataOperation.DELETE,
      project,
      scopePath: currentScopePath,
      eventInfo: {
        affectedUserUid: userWithAssignment.uid,
      },
    });
    removeProjectAssignment({ id: userWithAssignment.assignmentId });
  };

  const removeAssignmentFromUser = (user: KaeplaUser) => {
    let userWithAssignment = team.find((u) => u.uid === user.uid);
    if (!userWithAssignment && self?.uid === user.uid) {
      userWithAssignment = self; // we leave the team
      void leaveProjectTeam(userWithAssignment);
      return;
    }

    if (userWithAssignment) {
      // we remove another user from the team
      void createEvent({
        uid: kaeplaUser?.uid,
        eventType: KaeplaEventType.PROJECT_TEAM_REMOVE_USER_FROM_TEAM,
        effect: KaeplaEventEffect.PROJECT_TEAM_CHANGE,
        functionGroup: KaeplaFunctionGroup.PROJECTS,
        operation: KaeplaDataOperation.DELETE,
        project,
        scopePath: currentScopePath,
        eventInfo: {
          affectedUserUid: userWithAssignment.uid,
        },
      });
      removeProjectAssignment({ id: userWithAssignment.assignmentId });
    }
  };

  const resendEmailInvite = (user: KaeplaUser) => {
    const userWithAssignment = team.find((t) => t.uid === user.uid);
    if (!userWithAssignment) return;
    void resendInvite({ params: { projectAssignmentId: userWithAssignment.assignmentId } });
  };

  // this is the assignmentListener
  useEffect(() => {
    if (!kaeplaUser || !currentScopePath) return;
    setSelf(kaeplaUser as KaeplaUserWithAssignment);
    const currentPath = JSON.stringify(currentScopePath);
    const fireStorePath = `projectAssignments`;
    const queryWhere: WhereClause[] = [
      {
        fieldPath: 'projectId',
        opStr: '==',
        value: project.id,
      },
      {
        fieldPath: 'scopePathStringified',
        opStr: '==',
        value: JSON.stringify(defaultPath) ?? currentPath,
      },
    ];

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (data) => {
        const _projectAssignments = data as KaeplaProjectAssignment[];
        const load = async () => {
          setLoading(true);
          const assignments = _projectAssignments.filter(
            (assignment) => assignment.projectId === project.id,
          );

          const _team: KaeplaUserWithAssignment[] = [];
          await Promise.all(
            assignments.map(async (assignment) => {
              const user = (await getUser({ uid: assignment.uid })) as KaeplaUserWithAssignment;
              user.assignmentId = assignment.id;
              _team.push(user);
            }),
          );

          const _self = _team.find((teamMember) => teamMember.uid === kaeplaUser.uid);
          setSelf(_self);
          // await new Promise((resolve) => setTimeout(resolve, 2000));
          const others = clone(_team).filter((teamMember) => teamMember.uid !== kaeplaUser.uid);
          setTeam(others);
          setLoading(false);
        };
        void load();
      },
      watcherKeyCallback: (key) => {
        setWatcherKeys((_watcherKeys) => {
          const newWatcherKeys = { ..._watcherKeys };
          const keyId = `projectTeam-${project.id}`;
          newWatcherKeys[keyId] = key;
          return newWatcherKeys;
        });
      },
      context: 'ProjectTeam',
    });
    return () => {
      logger.log('ProjectTeam Listener is unmounted!');
      unsubscribe();
    };
  }, [kaeplaUser?.uid, currentScopePath, kaeplaUser, project.id, defaultPath, setWatcherKeys]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      data-testid="project-team"
      // divider={<Divider orientation="vertical" flexItem />}
      spacing={2}
    >
      <Box sx={showAll && team.length > 5 ? scrollStyle : { display: 'inherit' }}>
        <Box sx={{ pl: 0.5 }}>
          <AvatarGroup
            max={maxAvatars}
            spacing="small"
            slotProps={avatarGroupSlot}
            onClick={() => {
              setShowAll(true);
              setMaxAvatars(team.length + 1);
            }}
          >
            {self && (
              <UserAvatar
                user={self}
                self={true}
                star={project.createdBy === self.uid}
                removeCallback={removeAssignmentFromUser}
              />
            )}
            {loading && (
              <Avatar sx={{ width: 45, height: 45 }}>
                <Skeleton animation="wave" variant="circular" />
              </Avatar>
            )}
            {team &&
              team.map((user) => (
                <UserAvatar
                  key={user.uid}
                  user={user}
                  star={project.createdBy === user.uid}
                  removeCallback={removeAssignmentFromUser}
                  resendInviteCallback={resendEmailInvite}
                />
              ))}
          </AvatarGroup>
        </Box>
      </Box>
      {showAll && (
        <Box
          component={Button}
          variant="outlined"
          data-testid="project-team-minimize"
          onClick={() => {
            setShowAll(false);
            setMaxAvatars(maxAvatarsDefault);
          }}
          sx={{
            p: 0,
            minWidth: 0,
            display: 'flex',
            alignItems: 'center',
            width: 10,
            cursor: 'pointer',
          }}
        >
          <ShowMinimalIcon />
        </Box>
      )}
      {!disableInvites && <AddTeamMemberToggle callback={openAddTeamMemberDialog} />}
      <AddTeamMember open={open} onClose={closeAddTeamMemberDialog} />
    </Stack>
  );
};
