import { Fade, LinearProgress } from '@mui/material';

interface Options {
  delay: number;
  loading: boolean;
}

export const DelayedLinearProgress = ({ delay, loading }: Options) => {
  return (
    <Fade
      in={loading}
      style={{
        transitionDelay: loading ? `${delay}ms` : '0ms',
      }}
      unmountOnExit
    >
      <LinearProgress />
    </Fade>
  );
};
