import { getApp } from 'firebase/app';
import { deleteDoc, doc, getFirestore } from 'firebase/firestore';

import { KaeplaProject } from '../types/Application/KaeplaProject';
import { KaeplaSimulation } from '../types/Application/KaeplaSimulation';

interface Options {
  project: KaeplaProject;
  simulation: KaeplaSimulation;
}

export const deleteSimulation = async ({ project, simulation }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/simulations/${simulation.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  return deleteDoc(firestoreDocumentReference);
};
