import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger';

interface KOptions {
  email: string;
  hostname: string;
}

export const requestSignInLink = async ({ email, hostname }: KOptions) => {
  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const getSignInEmailLink = httpsCallable(functions, 'getSignInEmailLink');

  requestLog.log(`getSignInEmailLink.post().json(${JSON.stringify({ email, hostname })})`);
  const result = await getSignInEmailLink({ email, hostname });
  return result;
};
