import { getApp } from 'firebase/app';
import { collection, DocumentData, getFirestore, doc, setDoc } from 'firebase/firestore';

import { getKaeplaTargets } from '../api/getKaeplaTargets';
import { KaeplaDimension } from '../types/Application/KaeplaDimension';
import { KaeplaProject } from '../types/Application/KaeplaProject';
import { KaeplaSimulation } from '../types/Application/KaeplaSimulation';
import { KaeplaTargets } from '../types/Application/KaeplaTargets';
import { KaeplaTargetsFigure } from '../types/Application/KaeplaTargetsFigure';

interface Options {
  project: KaeplaProject;
  simulation?: KaeplaSimulation;
  createdBy: string;
  scopePath: string[];
  targetFigures: Partial<KaeplaTargetsFigure>[];
  targetsDimension?: KaeplaDimension;
}

export const createNewTargets = async ({
  project,
  simulation,
  scopePath,
  createdBy,
  targetFigures,
  targetsDimension,
}: Options) => {
  if (!project?.defaultPerspective?.valueDimension) return;
  const dimension = targetsDimension?.columnName || project.defaultPerspective.valueDimension;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/targets`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const documentReference = doc(firestoreCollectionReference);

  const targetFiguresExtended: Partial<KaeplaTargetsFigure>[] = [];
  if (dimension && targetFigures && dimension) {
    targetFigures.forEach((t) => {
      const figure: Partial<KaeplaTargetsFigure> = {
        ...t,
        targetsId: documentReference.id,
        scopePathStringified: JSON.stringify(scopePath),
        dimension,
        timeSlice: 'YEAR',
        aggregation: 'SUM',
        currency: targetsDimension?.currency,
        decimalPositions: targetsDimension?.decimalPositions,
      };
      targetFiguresExtended.push(figure);
    });
  }

  const _targets: KaeplaTargets = {
    projectId: project.id,
    dimension,
    id: documentReference.id,
    targetsMatrix: `${project.dataMatrix}Targets`,
    createdBy,
    scopePath,
    isBeingUpdated: true,
    scopePathStringified: JSON.stringify(scopePath),
  };
  if (simulation) {
    _targets.simulationId = simulation.id;
  }

  await setDoc(documentReference, _targets as DocumentData);

  void getKaeplaTargets({
    params: {
      projectId: project.id,
      targetsId: documentReference.id,
      targetFigures: targetFiguresExtended,
    },
  });

  return _targets;
};
