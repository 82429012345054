import ScopeTreeIcon from '@mui/icons-material/AccountTreeOutlined';
import CancelTreeIcon from '@mui/icons-material/CancelOutlined';
import { Box, LinearProgress, Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import MermaidReact from 'mermaid-react';
import { clone } from 'rambda';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ScopesOverview } from './features/ScopesOverview';
import { graphFromScopeModel } from './helpers/graphFromScopeModel';
import { useAuth } from '../../../AuthProvider';
import { getFromKaepla } from '../../../services/api/getFromKaepla';
import { createEvent } from '../../../services/firestore/createEvent';
import { matrixFilteredState } from '../../../services/recoil/nonpersistent/matrixFilteredState';
import { modelState } from '../../../services/recoil/nonpersistent/modelState';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { scopePathsState } from '../../../services/recoil/nonpersistent/scopePathsState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { KaeplaApiParameters } from '../../../services/types/Application/KaeplaApiParameters';
import { KaeplaDataOperation } from '../../../services/types/Application/KaeplaDataOperation';
import { KaeplaEventType } from '../../../services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from '../../../services/types/Application/KaeplaFunctionGroup';
import { KaeplaQueryType } from '../../../services/types/Application/KaeplaQueryType';
import { MatrixSummary } from '../../../services/types/MatrixSummary';
import { Layout } from '../../Layout/Layout';

export const Scopes = () => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [matrixFiltered, setMatrixFiltered] = useRecoilState(matrixFilteredState);
  const [model, setModel] = useRecoilState(modelState);
  const [scopePaths, setScopePaths] = useRecoilState(scopePathsState);
  const [scopePath, setScopePath] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [graph, setGraph] = useState<string | null>(null);
  const [showGraph, setShowGraph] = useState<boolean>(false);

  useEffect(() => {
    let newScopePaths = clone(scopePaths);
    if (!newScopePaths) {
      newScopePaths = {};
    }
    if (!newScopePaths[project.id]) {
      newScopePaths[project.id] = [];
    }
    setScopePath(newScopePaths[project.id] || []);
    const _graph = graphFromScopeModel({
      model,
      scopePath: newScopePaths[project.id] || [],
    });
    setGraph(_graph);

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.SCOPES_ENTER_SCOPES_OVERVIEW,
      functionGroup: KaeplaFunctionGroup.SCOPES,
      operation: KaeplaDataOperation.READ,
      scopePath: currentScopePath,
      project,
    });
  }, [project, model, scopePaths, kaeplaUser?.uid, currentScopePath]);

  useEffect(() => {
    const parameters: KaeplaApiParameters = {
      q: 'dimensionsDetails' as KaeplaQueryType,
      p: currentScopePath,
      projectId: project.id,
      s: 'Scopes',
    };
    getFromKaepla({
      callBack: (apiResponse) => {
        if (!apiResponse) return;
        // console.log("->", _data.response);
        const response = apiResponse.response as MatrixSummary;
        setMatrixFiltered((_matrixFiltered) => ({
          ..._matrixFiltered,
          details: response.details,
        }));
      },
      setLoading,
      params: parameters,
      uid: kaeplaUser?.uid,
    });
  }, [project.id, currentScopePath, kaeplaUser?.uid, setMatrixFiltered]);

  if (!matrixFiltered?.details) {
    return <LinearProgress />;
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={3}>
        {graph && (
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              m: 2,
              p: 1,
              zIndex: 3,
              cursor: 'pointer',
            }}
            onClick={() => setShowGraph(!showGraph)}
          >
            <Paper sx={{ p: 1, pb: 0 }}>{showGraph ? <CancelTreeIcon /> : <ScopeTreeIcon />}</Paper>
          </Box>
        )}
        {graph && showGraph && (
          <Grid item xs={12}>
            <Paper sx={{ p: 1, pb: 0 }}>
              <MermaidReact
                id="test"
                mmd={graph}
                // onClick={() => console.log("test Click")}
                // onRender={(svg) => console.log("render content", svg)}
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <ScopesOverview
            {...{
              matrix: matrixFiltered,
              model,
              setModel,
              scopePath,
              scopePaths,
              setScopePaths,
              loading,
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};
