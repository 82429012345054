import { createPaletteForLabelAndDataset, defaultChartColor } from './chartColors';
import { cleanColumnName } from '../../../../../helpers/cleanColumnName';

export interface KaeplaChartLabel {
  label: string;
  datasetName: string;
  color: string;
  isToggled: boolean;
}

export const createChartLabels = (datasets: string[], label: string) => {
  const palette = createPaletteForLabelAndDataset(label, datasets);

  const chartLabels: KaeplaChartLabel[] = datasets.map((dataset) => ({
    datasetName: dataset,
    label: cleanColumnName(dataset).trim(),
    color: palette.map.get(dataset) || defaultChartColor,
    isToggled: false,
  }));

  return chartLabels;
};
