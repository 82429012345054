import { getApp } from 'firebase/app';
import { collection, getDocs, getFirestore, limit, query, where } from 'firebase/firestore';

import { KaeplaProject } from '../types/Application/KaeplaProject';
import { KaeplaTargets } from '../types/Application/KaeplaTargets';

interface Options {
  project: KaeplaProject;
  scopePathStringified?: string;
}

export const getTargetsForProject = async ({ project, scopePathStringified }: Options) => {
  const data: KaeplaTargets[] = [];
  if (!project) return data;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/targets`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const whereClauses = [];
  if (scopePathStringified) {
    whereClauses.push(where('scopePathStringified', '==', scopePathStringified));
  }
  const firestoreQuery = query(firestoreCollection, ...whereClauses, limit(50));
  const snapshot = await getDocs(firestoreQuery);
  snapshot.forEach((document_) => {
    const targets = document_.data();
    targets.id = document_.id;
    data.push(targets as KaeplaTargets);
  });
  return data;
};
