import { KaeplaPerspective } from '../../../../services/types/Application/KaeplaPerspective';
import { cleanAggregation } from '../../../helpers/cleanAggregation';
import { cleanColumnName } from '../../../helpers/cleanColumnName';

export const chartTitle = (perspective: Partial<KaeplaPerspective>) => {
  let aggregationOverTimeDimension = '';
  if (perspective?.valueDimension && perspective?.timeDimension) {
    const valueDimension = cleanColumnName(perspective.valueDimension);
    const aggregation = cleanAggregation(perspective.aggregation || 'SUM');
    const time = cleanColumnName(perspective.timeDimension);
    aggregationOverTimeDimension = `${valueDimension} ${aggregation} over ${time}`;
  }
  return aggregationOverTimeDimension;
};
