import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { requestLog } from '../../Frontend/helpers/logger';
import { KaeplaGetUsersRequest } from '../types/Application/KaeplaGetUsersRequest';
import { KaeplaUser } from '../types/Application/KaeplaUser';

type DataReturn = (data: KaeplaUser[]) => void;

interface KOptions {
  callBack?: DataReturn;
  setLoading?: (loading: boolean) => void;
  params: KaeplaGetUsersRequest;
}

export const getUsersForUser = ({ callBack, setLoading, params }: KOptions) => {
  if (typeof setLoading === 'function') {
    setLoading(true);
  }

  const functions = getFunctions(getApp('kaepla'), 'europe-west3');
  const getKaeplaMatrix = httpsCallable(functions, 'getUsers');

  requestLog.log(`kaeplaUsers.post().json(${JSON.stringify(params)})`);
  void getKaeplaMatrix(params).then((result) => {
    // console.log("loading", params.q, loading);
    if (typeof setLoading === 'function') {
      setLoading(false);
    }
    if (callBack) {
      callBack(result.data as KaeplaUser[]);
    }
  });
};
