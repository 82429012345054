import { useRecoilValue } from 'recoil';

import { knownProjectFiltersState } from '../../../../../../../services/recoil/nonpersistent/knownProjectFiltersState';
import { KaeplaPerspectiveBlock } from '../../../../../../../services/types/Application/KaeplaPerspectiveBlock';
import { cleanAggregation } from '../../../../../../helpers/cleanAggregation';
import { cleanColumnName } from '../../../../../../helpers/cleanColumnName';

interface ABOptions {
  block: KaeplaPerspectiveBlock;
}
export const DonutChartTitle = ({ block }: ABOptions) => {
  const valueDimension = cleanColumnName(block.valueDimension);
  const aggregation = cleanAggregation(block.aggregation);
  const aggregationDimension = cleanColumnName(block.aggregationDimension);
  const filterId = block.filterId;
  const knownProjectFilters = useRecoilValue(knownProjectFiltersState);
  const filter = knownProjectFilters?.find((k) => k.id === filterId);

  return (
    <>
      {valueDimension} <u>{aggregation}</u> per <b>{aggregationDimension}</b>
      {filter && ` for ${filter.name}`}
    </>
  );
};
