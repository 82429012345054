import { useRecoilValue } from 'recoil';

import { ProjectAssignmentsListenerKaeplaAdmins } from './ProjectAssignmentsListenerKaeplaAdmins';
import { ProjectAssignmentsListenerUsers } from './ProjectAssignmentsListenerUsers';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState';
import { logger } from '../helpers/logger';

export const ProjectAssignmentsListener = () => {
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);

  logger.log('ProjectAssignmentsListener renders');

  if (kaeplaAssignment) {
    return <ProjectAssignmentsListenerKaeplaAdmins />;
  }

  return <ProjectAssignmentsListenerUsers />;
};
