import { KaeplaProjectAssignment } from '../../services/types/Application/KaeplaProjectAssignment';

export const getUserRootPaths = (projectAssignments: KaeplaProjectAssignment[]) => {
  const _userRootPaths: Record<string, string> = {};
  projectAssignments.forEach((assignment) => {
    if (!_userRootPaths[assignment.projectId]) {
      _userRootPaths[assignment.projectId] = assignment.scopePathStringified;
    }
    if (assignment.scopePathStringified.length < _userRootPaths[assignment.projectId].length) {
      _userRootPaths[assignment.projectId] = assignment.scopePathStringified;
    }
  });

  return _userRootPaths;
};
