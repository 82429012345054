import { Box } from '@mui/material';
import { clone } from 'rambda';
import { VictoryPie, VictoryTheme } from 'victory';

import { KaeplaDimensionDetails } from '../../../../services/types/Application/KaeplaDimensionDetails';
import { hasDimensionPie } from '../../Perspectives/helpers/hasDimensionPie';

interface Options {
  detail: KaeplaDimensionDetails;
  chartColors: string[];
  totalCount: number | undefined;
}

export const DimensionPie = ({ detail, chartColors, totalCount }: Options) => {
  if (!hasDimensionPie(detail, totalCount)) return null;

  const data = clone(detail.dimensionDetails).slice(
    0,
    detail.dimensionDetails.length > 25 ? 10 : 25,
  );

  const dataMap = data.map((d) => ({
    x: d.value,
    y: Math.abs(d.numberOfRecords),
  }));

  const colorScale = data.map((d, index) => ({
    name: d.value,
    color: chartColors[index],
  }));

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ maxWidth: 300, m: 0 }}>
        <VictoryPie
          // padAngle={0.1}
          padding={{ top: 10, bottom: 10 }}
          style={{
            labels: { display: 'none' },
            data: {
              fill: (d) => {
                return (
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  (colorScale.find((c) => c.name === d.datum.x)?.color as string) || '#eee'
                );
              },
            },
          }}
          theme={VictoryTheme.material}
          data={dataMap}
          innerRadius={50}
          animate={{ easing: 'exp' }}
        />
      </Box>
    </Box>
  );
};
