/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import Logout from '@mui/icons-material/Logout';
import UserIcon from '@mui/icons-material/PersonOutlined';
import {
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  Box,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { isBeta } from 'src/Frontend/helpers/isBeta';

import { BenchmarkToggle } from './BenchmarkToggle';
import { ColorModeToggle } from './ColorModeToggle';
import packageJson from '../../../../package.json';
import { useAuth } from '../../../AuthProvider';
import { UserAvatarEditor } from '../../Screens/User/features/UserAvatarEditor';

export const AccountMenu = () => {
  const navigate = useNavigate();
  const { kaeplaUser } = useAuth();

  // User Menu
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorElement);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const goToLogout = () => {
    navigate('/Logout');
  };

  const goToProfile = () => {
    navigate('/User');
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          data-testid="main-user-avatar"
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            imgProps={{ id: 'main-avatar-image' }}
            sx={{ width: { xs: 30, sm: 45 }, height: { xs: 30, sm: 45 } }}
            src={kaeplaUser?.base64Avatar || kaeplaUser?.avatar}
          >
            {kaeplaUser?.acronym}
          </Avatar>
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorElement}
        data-testid="account-menu"
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <UserAvatarEditor />
        <Divider sx={{ mt: 2, mb: 2 }} />
        <MenuItem
          data-testid="profile"
          onClick={() => {
            goToProfile();
          }}
        >
          <ListItemIcon>
            <UserIcon />
          </ListItemIcon>
          Profile
        </MenuItem>
        <ColorModeToggle />
        <BenchmarkToggle />
        <Divider />
        <MenuItem
          data-testid="logout"
          onClick={() => {
            // void logOut();
            goToLogout();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        <Divider />
        <MenuItem>
          {isBeta() && <Box sx={{ color: red[500], fontSize: 14, mr: 1 }}>β</Box>}
          <Typography variant="caption">{packageJson.version}</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};
