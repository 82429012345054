import DeleteIcon from '@mui/icons-material/Delete';
import PerspectivesIcon from '@mui/icons-material/QueryStatsOutlined';
import FavoriteIcon from '@mui/icons-material/StarRounded';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey, yellow } from '@mui/material/colors';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useAuth } from 'src/AuthProvider';
import { scopePathSimplified } from 'src/Frontend/helpers/scopePathSimplified';
import { useUserPreferences } from 'src/UserPreferencesProvider';
import { createEvent } from 'src/services/firestore/createEvent';
import { toggleSimulationFavorite } from 'src/services/firestore/toggleSimulationFavorite';
import { scopePathsState } from 'src/services/recoil/nonpersistent/scopePathsState';
import { simulationFavoritesState } from 'src/services/recoil/nonpersistent/simulationFavoritesState';
import { simulationState } from 'src/services/recoil/nonpersistent/simulationState';
import { projectAssignmentsState } from 'src/services/recoil/nonpersistent/userAssignmentState';
import { currentScopePathState } from 'src/services/recoil/persistent/currentScopePathState';
import { KaeplaDataOperation } from 'src/services/types/Application/KaeplaDataOperation';
import { KaeplaEventType } from 'src/services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from 'src/services/types/Application/KaeplaFunctionGroup';

import { runKaeplaSimulation } from '../../../../services/api/runKaeplaSimulation';
import { deleteSimulation } from '../../../../services/firestore/deleteSimulation';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState';
import { knownUsersState } from '../../../../services/recoil/persistent/knownUsersState';
import { KaeplaSimulation } from '../../../../services/types/Application/KaeplaSimulation';
import { BQJobInfo } from '../../../features/BQJobInfo';
import { UserAvatar } from '../../../features/UserAvatar';
import { HumanReadableTimestampType, convertTimestamp } from '../../../helpers/convertTimestamp';
import { checkPathIsInScope } from '../../Scopes/helpers/checkPathIsInScope';
import { perspectivesColor } from '../../defaults';

interface Options {
  simulation: KaeplaSimulation;
  own?: boolean;
}
export const SimulationCard = ({ simulation, own }: Options) => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  const { setPreferences } = useUserPreferences();
  const project = useRecoilValue(projectState);
  const knownUsers = useRecoilValue(knownUsersState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const simulationFavorites = useRecoilValue(simulationFavoritesState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);

  // get & set
  const [currentScopePath, setCurrentScopePath] = useRecoilState(currentScopePathState);
  const [scopePaths, setScopePaths] = useRecoilState(scopePathsState);
  // set
  const setSimulation = useSetRecoilState(simulationState);

  const owner = knownUsers.find((user) => user.uid === simulation.createdBy);
  const [hover, setHover] = useState(false);

  const isFavorite = () => {
    return simulationFavorites
      .filter((s) => s.isFavorite)
      .some((s) => s.simulationId === simulation.id);
  };

  const isInScope = () => {
    return checkPathIsInScope({
      project,
      projectAssignments,
      scopePath: simulation.scopePath,
    });
  };

  return (
    <Card
      elevation={hover ? 8 : 2}
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => {
        if (!kaeplaUser?.uid) return;
        setSimulation(simulation);
        setPreferences({ lastSimulationId: simulation.id });

        void toggleSimulationFavorite({
          uid: kaeplaUser.uid,
          simulation,
          scopePath: currentScopePath,
          isFavorite: true,
        });
        navigate(`/Simulation/${project.id}/${simulation.id}`);
      }}
      onFocus={() => {
        setHover(true);
      }}
      onBlur={() => {
        setHover(false);
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <CardHeader
        title={simulation.name}
        avatar={owner && <UserAvatar user={owner} />}
        subheader={
          simulation.simulatedAt
            ? `updated ${convertTimestamp(
                simulation.simulatedAt,
                HumanReadableTimestampType.timeago,
              )}`
            : 'did not run yet'
        }
      />
      <CardContent sx={{ minHeight: 150 }}>
        <PerspectivesIcon
          sx={{ color: perspectivesColor, fontSize: 14, mr: 0.5, verticalAlign: 'middle' }}
        />
        <Tooltip title={simulation.simulationMatrix}>
          <Link
            variant="caption"
            underline="hover"
            color="inherit"
            sx={{
              opacity: isInScope() ? 1 : 0.6,
            }}
            onClick={(event) => {
              event.stopPropagation();
              if (!kaeplaUser?.uid) return;
              if (!isInScope()) {
                return;
              }
              // TODO: ask user if it is okay to switch the scope if we're not already here
              setCurrentScopePath(simulation.scopePath);
              const newScopePaths = { ...scopePaths };
              newScopePaths[simulation.projectId] = simulation.scopePath;
              setScopePaths(newScopePaths);

              setSimulation(simulation);
              setPreferences({ lastSimulationId: simulation.id });

              void toggleSimulationFavorite({
                uid: kaeplaUser.uid,
                simulation,
                scopePath: currentScopePath,
                isFavorite: true,
              });

              void createEvent({
                uid: kaeplaUser?.uid,
                eventType: KaeplaEventType.ASSIGNMENT_SELECT_ASSIGNMENT,
                functionGroup: KaeplaFunctionGroup.ASSIGNMENTS,
                operation: KaeplaDataOperation.READ,
                project,
                scopePath: simulation.scopePath,
              });
              navigate('/Perspective');
            }}
          >
            {[project.name, ...scopePathSimplified(simulation.scopePath)].join(' ➔ ')}
          </Link>
        </Tooltip>
        {/* <SimulationInfo simulation={simulation} /> */}
        {simulation?.lastSimulationJob && !simulation.isBeingSimulated && (
          <BQJobInfo job={simulation.lastSimulationJob} />
        )}
      </CardContent>
      <CardActions sx={{ m: 0, pt: 0 }}>
        <IconButton
          aria-label="add to favorites"
          onClick={(event) => {
            if (!kaeplaUser?.uid) return;
            event.stopPropagation();
            void toggleSimulationFavorite({
              uid: kaeplaUser.uid,
              simulation,
              scopePath: currentScopePath,
              isFavorite: !isFavorite(),
            });
          }}
        >
          <FavoriteIcon sx={{ color: isFavorite() ? yellow[800] : grey[400] }} />
        </IconButton>
        <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        {(own || kaeplaAssignment) && (
          <Tooltip title={simulation.id}>
            <IconButton
              sx={{ alignSelf: 'flex-end' }}
              color="primary"
              onClick={(event) => {
                const _delete = async () => {
                  event.stopPropagation();
                  // TODO
                  // this should actually be done by either a firestore trigger
                  // or the other way round, delete the simulation from firestore
                  // as a job.on('complete') callback function
                  void runKaeplaSimulation({
                    params: {
                      projectId: project.id,
                      simulationId: simulation.id,
                      deleteSimulation: true,
                    },
                  });
                  await deleteSimulation({ project, simulation });
                  navigate(`/Simulations`);
                };
                void _delete();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
};
