import { atom } from 'recoil';

import { localForageEffect } from './localForageEffect';

export const currentScopePathState = atom<string[]>({
  key: 'currentScopePath',
  default: [],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  effects_UNSTABLE: [localForageEffect],
});
