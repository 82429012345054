import { path } from 'rambda';

import { getCleanPath } from './getCleanPath';
import { KaeplaModel } from '../../../services/types/Application/KaeplaModel';

export const getDimensionsAtPath = (model: KaeplaModel, scopePath: string[]) => {
  const cleanPath = getCleanPath(scopePath);
  const current = cleanPath.at(-1);

  cleanPath.pop(); // we want the parent
  let nodeItems = Object.keys(model.delegation).filter((n) => n !== current);
  if (cleanPath.length > 0) {
    const dotNotation = cleanPath.join('.');
    const node = path<Record<string, unknown>>(dotNotation, model.delegation) || {};
    // eslint-disable-next-line @typescript-eslint/ban-types
    nodeItems = Object.keys(node).filter((n) => n !== current);
  }

  return nodeItems;
};
