import { KaeplaSimulation } from '../../../../../../../services/types/Application/KaeplaSimulation';
import { MatrixAggregationEntry } from '../../../../../../../services/types/MatrixAggregationEntry';
import { cleanColumnName } from '../../../../../../helpers/cleanColumnName';
import { currentDataColorShift, simulationDataColorShift } from '../../helpers/chartColors';
import { KaeplaChartLabel } from '../../helpers/createChartLabels';

interface TOptions {
  datasetLabel: string;
  aggregationData: MatrixAggregationEntry[];
  datasets: string[];
  chartLabels: KaeplaChartLabel[];
  simulation?: Partial<KaeplaSimulation>;
  aggregationDataSimulated?: MatrixAggregationEntry[];
}

export interface KaeplaPieChartDatasets {
  labels: string[];
  datasets: KaeplaPieChartDataset[];
}

export interface KaeplaPieChartDataset {
  label: string;
  data: number[];
  backgroundColor?: string[];
  borderWidth?: number;
  borderStyle?: string[];
  borderColor?: string[];
  hidden?: boolean;
}

export const getChartDataForPieChart = ({
  datasetLabel,
  aggregationData,
  datasets,
  chartLabels,
  simulation,
  aggregationDataSimulated,
}: TOptions) => {
  const ticks = datasets.map((d) => cleanColumnName(d));

  const chartDatasets: KaeplaPieChartDataset[] = [];

  if (simulation && aggregationDataSimulated) {
    chartDatasets.push({
      label: datasetLabel,
      data: chartLabels
        .filter((c) => !c.isToggled)
        .map((c) => aggregationDataSimulated.find((d) => d.label === c.datasetName)?.value || 0),
      backgroundColor: chartLabels
        .filter((c) => !c.isToggled)
        .map((c) => simulationDataColorShift(c.color)),
      borderWidth: 1,
    });
  }

  chartDatasets.push({
    label: datasetLabel,
    data: chartLabels
      .filter((c) => !c.isToggled)
      .map((c) => aggregationData.find((d) => d.label === c.datasetName)?.value || 0),
    backgroundColor: chartLabels
      .filter((c) => !c.isToggled)
      .map((c) => currentDataColorShift(c.color)),
    borderWidth: 1,
  });

  return {
    labels: ticks,
    datasets: chartDatasets,
  };
};
