import { Box } from '@mui/material';

import { DonutChartBlock } from './DonutChart/DonutChartBlock';
import { StackedBarChartBlock } from './StackedBarChart/StackedBarChartBlock';
import { KaeplaPerspectiveBlock } from '../../../../../../services/types/Application/KaeplaPerspectiveBlock';

interface Options {
  block: KaeplaPerspectiveBlock;
  blockNumber: number;
  locked?: boolean;
}

export const PerspectiveBlock = ({ block, blockNumber, locked }: Options) => {
  switch (block.type) {
    case 'AggregationOverTime': {
      return <StackedBarChartBlock block={block} blockNumber={blockNumber} locked={locked} />;
    }
    case 'AggregationPercentages': {
      return <DonutChartBlock block={block} blockNumber={blockNumber} locked={locked} />;
    }
    case 'TreeMap': {
      return <Box>TreeMap {JSON.stringify(block)}</Box>;
    }
    // No default
  }
  return <Box>{JSON.stringify(block)}</Box>;
};
