import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../AuthProvider';
import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener';
import { getResellers } from '../../services/firestore/getResellers';
import { knownResellersState } from '../../services/recoil/nonpersistent/knownResellersState';
import { resellerAssignmentsState } from '../../services/recoil/nonpersistent/resellerAssignmentState';
import { brandingState } from '../../services/recoil/persistent/brandingState';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState';
import { KaeplaResellerAssignment } from '../../services/types/Application/KaeplaResellerAssignment';
import { logger } from '../helpers/logger';

export const ResellerAssignmentsListener = () => {
  const { kaeplaUser } = useAuth();
  const setKnownResellers = useSetRecoilState(knownResellersState);
  const setResellerAssignments = useSetRecoilState(resellerAssignmentsState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const branding = useRecoilValue(brandingState);

  useEffect(() => {
    if (!kaeplaUser?.uid) return; // user isn't authenticated yet
    if (!branding?.realmType || !branding?.appRealmId) return; // we aways need a branding
    const fireStorePath = `resellerAssignments`;
    const queryWhere: WhereClause[] = [];
    if (!kaeplaAssignment) {
      queryWhere.push(
        {
          fieldPath: 'uid',
          opStr: '==',
          value: kaeplaUser.uid,
        },
        {
          fieldPath: 'complete',
          opStr: '==',
          value: true,
        },
      );

      if (branding?.realmType === 'customer') {
        // TODO: how can we use KaeplaDomainType.customer?
        // customer domain lock through branding
        queryWhere.push({
          fieldPath: 'resellerId',
          opStr: '==',
          value: branding.providerId,
        });
      }

      if (branding?.realmType === 'reseller') {
        // customer domain lock through branding
        queryWhere.push({
          fieldPath: 'resellerId',
          opStr: '==',
          value: branding.appRealmId,
        });
      }
    }

    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (_resellerAssignments) => {
        const load = async () => {
          const resellerAssignments = _resellerAssignments as KaeplaResellerAssignment[];
          logger.log('ResellerAssignmentsListener fires:', resellerAssignments);
          setResellerAssignments(resellerAssignments);
          if (resellerAssignments.length === 0) {
            setKnownResellers([]);
            return;
          }

          const resellerIds = resellerAssignments.map((c) => c.resellerId);
          const uniqueResellerIds = resellerIds.filter((v, index, a) => a.indexOf(v) === index);
          const _knownResellers = await getResellers({ resellerIds: uniqueResellerIds });
          setKnownResellers(_knownResellers);
        };
        void load();
      },
    });
    return () => {
      unsubscribe();
    };
  }, [
    branding?.appRealmId,
    branding?.providerId,
    branding?.realmType,
    kaeplaAssignment,
    kaeplaUser?.uid,
    setKnownResellers,
    setResellerAssignments,
  ]);

  return null;
};
