import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import { Box, Toolbar, Typography } from '@mui/material';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';

import { ChartToolbarEditCopyDelete } from './ChartToolbarEditCopyDelete';
import { ChartToolbarFilter } from './ChartToolbarFilter';
import { ChartToolbarGridSpan } from './ChartToolbarGridSpan';
import { ChartToolbarZoom } from './ChartToolbarZoom';
import { useAuth } from '../../../../../../AuthProvider';
import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState';
import { filterSettingsState } from '../../../../../../services/recoil/persistent/filterSettingState';
import { KaeplaPerspectiveBlock } from '../../../../../../services/types/Application/KaeplaPerspectiveBlock';

interface Options {
  chartReference: MutableRefObject<undefined>;
  block: KaeplaPerspectiveBlock;
  setEditing: Dispatch<SetStateAction<boolean>>;
  editing: boolean;
  blockNumber: number;
  locked?: boolean;
  canZoomAndPan?: boolean;
  highlightFilterOverride?: boolean;
}

export const ChartHeaderToolbar = ({
  chartReference,
  block,
  setEditing,
  editing,
  blockNumber,
  locked,
  canZoomAndPan,
  highlightFilterOverride,
}: Options) => {
  const { kaeplaUser } = useAuth();
  const perspective = useRecoilValue(perspectiveState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const toolbarElements = [];

  if (highlightFilterOverride && block.filterId && filterSettings.isActive) {
    toolbarElements.push('chartFilterOverride');
  }

  if (!locked && !editing && perspective?.createdBy === kaeplaUser?.uid) {
    toolbarElements.push('chartToolbarFilter');
  }

  if (!locked && !editing && perspective?.createdBy === kaeplaUser?.uid) {
    toolbarElements.push('chartToolbarGridSpan');
  }

  if (!locked && !editing && perspective?.createdBy === kaeplaUser?.uid) {
    toolbarElements.push('chartToolbarEditCopyDelete');
  }

  if (canZoomAndPan) {
    toolbarElements.push('chartToolbarZoom');
  }

  if (toolbarElements.length === 0) {
    return null;
  }

  return (
    <Toolbar sx={{ minHeight: 16 }} disableGutters variant="dense">
      <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
      {toolbarElements.includes('chartFilterOverride') && locked && (
        <Box sx={{ mr: 1 }}>
          <FilterIcon sx={{ mb: -0.3 }} color="warning" fontSize="small" />
          <Typography variant="caption" sx={{ verticalAlign: 'text-bottom' }}>
            filter on page has no effect on this chart!
          </Typography>
        </Box>
      )}
      {toolbarElements.includes('chartToolbarFilter') && (
        <Box sx={{ mr: 1 }}>
          <ChartToolbarFilter block={block} blockNumber={blockNumber} />
        </Box>
      )}
      {toolbarElements.includes('chartToolbarGridSpan') && (
        <Box sx={{ mr: 1 }}>
          <ChartToolbarGridSpan block={block} blockNumber={blockNumber} />
        </Box>
      )}
      {toolbarElements.includes('chartToolbarEditCopyDelete') && (
        <Box sx={{ mr: 1 }}>
          <ChartToolbarEditCopyDelete
            block={block}
            setEditing={setEditing}
            blockNumber={blockNumber}
          />
        </Box>
      )}
      {toolbarElements.includes('chartToolbarZoom') && (
        <Box sx={{ mr: 1 }}>
          <ChartToolbarZoom chartReference={chartReference} />
        </Box>
      )}
    </Toolbar>
  );
};
