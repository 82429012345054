import { getApp } from 'firebase/app';
import { getFirestore, DocumentData, doc, updateDoc } from 'firebase/firestore';

import { getDimensionByColumnName } from './getDimensionByColumnName';
import { KaeplaDimension } from '../types/Application/KaeplaDimension';
import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  project: KaeplaProject;
  dimension: Partial<KaeplaDimension>;
}

export const updateDimensionCurrency = async ({ project, dimension }: Options) => {
  if (!project.id || !dimension.columnName) return;
  const dimensionFromServer = await getDimensionByColumnName({ project, dimension });
  if (!dimensionFromServer?.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/dimensions/${dimensionFromServer.id}`;
  const documentReference = doc(firestoreDatabase, fireStorePath);
  const update = { ...dimensionFromServer };
  update.currency = dimension.currency;
  update.decimalPositions = dimension.decimalPositions;
  await updateDoc(documentReference, update as DocumentData);
  return dimension;
};
