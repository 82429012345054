import AddIcon from '@mui/icons-material/Add';
import { Fab, FormControlLabel, Switch, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useUserPreferences } from 'src/UserPreferencesProvider';
import { createEvent } from 'src/services/firestore/createEvent';
import { toggleSimulationFavorite } from 'src/services/firestore/toggleSimulationFavorite';
import { KaeplaDataOperation } from 'src/services/types/Application/KaeplaDataOperation';
import { KaeplaEventType } from 'src/services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from 'src/services/types/Application/KaeplaFunctionGroup';

import { SimulationOverview } from './features/SimulationOverview';
import { useAuth } from '../../../AuthProvider';
import { createNewSimulation } from '../../../services/firestore/createNewSimulation';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { Layout } from '../../Layout/Layout';

export const Simulations = () => {
  const { kaeplaUser } = useAuth();
  const { setPreferences, getPreferences } = useUserPreferences();
  const navigate = useNavigate();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [showAllSimulationsForProject, setShowAllSimulationsForProject] = useState(false);

  const handleShowAllSimulationsForProject = () => {
    if (!kaeplaUser?.uid) return;
    setShowAllSimulationsForProject(!showAllSimulationsForProject);
    setPreferences({
      showAllSimulations: !showAllSimulationsForProject,
    });
  };

  const create = async () => {
    if (!kaeplaUser) return;
    const _simulation = await createNewSimulation({
      project,
      scopePath: currentScopePath,
      createdBy: kaeplaUser.uid,
    });

    void toggleSimulationFavorite({
      uid: kaeplaUser.uid,
      simulation: _simulation,
      scopePath: currentScopePath,
      isFavorite: true,
    });
    navigate(`/Simulation/${project.id}/${_simulation.id}`);
  };

  useEffect(() => {
    const _preferences = getPreferences();
    if (_preferences) {
      setShowAllSimulationsForProject(!!_preferences.showAllSimulations);
    }
  }, [getPreferences]);

  useEffect(() => {
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.SIMULATIONS_ENTER_SIMULATIONS_OVERVIEW,
      functionGroup: KaeplaFunctionGroup.SIMULATIONS,
      operation: KaeplaDataOperation.READ,
      scopePath: currentScopePath,
      project,
    });
  }, [currentScopePath, kaeplaUser?.uid, project]);

  if (!project) {
    navigate('/');
    return null;
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <>
        <Toolbar disableGutters variant="dense">
          <Typography variant="h5">{project.name} Simulations</Typography>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <FormControlLabel
            control={
              <Switch
                checked={showAllSimulationsForProject}
                size="small"
                onChange={handleShowAllSimulationsForProject}
              />
            }
            label="all"
          />
        </Toolbar>
        <Fab
          color="primary"
          aria-label="add simulation"
          data-testid="simulation-add"
          sx={{ position: 'fixed', bottom: 20, right: 20 }}
          onClick={() => {
            void create();
          }}
        >
          <AddIcon />
        </Fab>
        <SimulationOverview showAllSimulationsForProject={showAllSimulationsForProject} />
      </>
    </Layout>
  );
};
