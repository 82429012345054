import { Box } from '@mui/material';
import { Utils as QbUtils, Config, ImmutableTree } from '@react-awesome-query-builder/mui';
import { useRecoilValue } from 'recoil';

import { filterSettingsState } from '../../../../services/recoil/persistent/filterSettingState';

interface Options {
  tree: ImmutableTree;
  config: Config;
}

export const FilterSQL = ({ tree, config }: Options) => {
  const filterSettings = useRecoilValue(filterSettingsState);
  if (!filterSettings.showSql) return null;
  return (
    <Box component="pre" style={{ whiteSpace: 'pre-wrap', fontSize: '80%' }}>
      {QbUtils.sqlFormat(tree, config)}
    </Box>
  );
};
