import { Alert, Box, Card, Grid } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { DelayedLinearProgress } from 'src/Frontend/features/DelayedLinearProgress';
import { KaeplaQueryType } from 'src/services/types/Application/KaeplaQueryType';

import { StackedBarChart } from './StackedBarChart';
import { StackedBarChartHeader } from './StackedBarChartHeader';
import {
  getChartDataForStackedBarChart,
  KaeplaChartDatasets,
} from './getChartDataForStackedBarChart';
import { useAuth } from '../../../../../../../AuthProvider';
import { getFromKaepla } from '../../../../../../../services/api/getFromKaepla';
import { projectState } from '../../../../../../../services/recoil/nonpersistent/projectState';
import { simulationState } from '../../../../../../../services/recoil/nonpersistent/simulationState';
import { currentScopePathState } from '../../../../../../../services/recoil/persistent/currentScopePathState';
import { filterSettingsState } from '../../../../../../../services/recoil/persistent/filterSettingState';
import { filterTreeState } from '../../../../../../../services/recoil/persistent/filterTreeState';
import { timeSliceState } from '../../../../../../../services/recoil/persistent/timeSliceState';
import { KaeplaPerspectiveBlock } from '../../../../../../../services/types/Application/KaeplaPerspectiveBlock';
import { MatrixMultiTimeseries } from '../../../../../../../services/types/MatrixMultiTimeseries';
import { CardContentMinPadding } from '../../../../../../features/CardContentMinPadding';
import { chartParameters } from '../../helpers/chartParameters';
import { chartSimulationParameters } from '../../helpers/chartSimulationParameters';
import { KaeplaChartLabel, createChartLabels } from '../../helpers/createChartLabels';
import { ChartEditor } from '../ChartEditor';
import { ChartLabels } from '../ChartLabels';

interface AOptions {
  block: KaeplaPerspectiveBlock;
  blockNumber: number;
  locked?: boolean;
}

export const StackedBarChartBlock = ({ block, blockNumber, locked }: AOptions) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const timeSlice = useRecoilValue(timeSliceState);
  const simulation = useRecoilValue(simulationState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const filterTree = useRecoilValue(filterTreeState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [error, setError] = useState<string>();
  const [chartLabels, setChartLabels] = useState<KaeplaChartLabel[]>([]);
  const [chartData, setChartData] = useState<KaeplaChartDatasets>();
  const chartReference = useRef();

  const toggleLabel = (label: KaeplaChartLabel) => {
    const newChartLabels = [...chartLabels];
    const labelReference = newChartLabels.find((l) => l.datasetName === label.datasetName);
    if (labelReference && chartData?.datasets) {
      labelReference.isToggled = !labelReference.isToggled;
      const newDatasets = [...chartData.datasets];
      const datasetReference = newDatasets.filter((l) => l.label === label.datasetName);
      if (datasetReference) {
        datasetReference.forEach((d) => {
          d.hidden = !d.hidden;
        });
        setChartData({ ...chartData, datasets: newDatasets });
        setChartLabels(newChartLabels);
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    if (!block.aggregationDimension) {
      setEditing(true);
      setLoading(false);
      return;
    }

    getFromKaepla({
      errorCallBack: (_error) => {
        setChartLabels([]);
        setChartData({
          labels: [],
          datasets: [],
        });
        setError(`Chart data backend error: ${_error.message}`);
        setLoading(false);
      },
      callBack: (apiResponse) => {
        if (!apiResponse) {
          setLoading(false);
          return;
        }
        const result = apiResponse.response as MatrixMultiTimeseries;
        const _chartLabels = createChartLabels(result.labels, block.aggregationDimension);
        setChartLabels(_chartLabels);
        // if we want simulations, we get them here and set the chartData in the next callback
        // else, we just set the chartData and finish loading
        if (simulation?.id) {
          getFromKaepla({
            errorCallBack: (_error) => {
              setChartLabels([]);
              setChartData({
                labels: [],
                datasets: [],
              });
              setError(`Chart simulation data backend Error: ${_error.message}`);
              setLoading(false);
            },
            callBack: (_apiResponse) => {
              if (!_apiResponse) {
                setLoading(false);
                return;
              }
              const _result = _apiResponse.response as MatrixMultiTimeseries;
              const _chartData = getChartDataForStackedBarChart({
                timeSeries: result.timeseries,
                datasets: result.labels,
                chartLabels: _chartLabels,
                simulation,
                timeSeriesSimulated: _result.timeseries,
              });
              setChartData(_chartData);
              setLoading(false);
            },
            params: chartSimulationParameters({
              project,
              queryType: 'aggregationOverTime' as KaeplaQueryType,
              currentScopePath,
              filterTree,
              filterActive: filterSettings.isActive,
              block,
              simulation,
              timeSlice,
            }),
            uid: kaeplaUser?.uid,
          });
        } else {
          const _chartData = getChartDataForStackedBarChart({
            timeSeries: result.timeseries,
            datasets: result.labels,
            chartLabels: _chartLabels,
          });
          setChartData(_chartData);
          setLoading(false);
        }
      },
      params: chartParameters({
        project,
        queryType: 'aggregationOverTime' as KaeplaQueryType,
        currentScopePath,
        filterTree,
        filterActive: filterSettings.isActive,
        block,
        timeSlice,
      }),
      uid: kaeplaUser?.uid,
    });
  }, [
    block,
    currentScopePath,
    filterSettings?.isActive,
    filterTree,
    kaeplaUser?.uid,
    project,
    simulation,
    timeSlice,
  ]);

  if (!filterSettings.isInitialized) return null;

  return (
    <Card sx={{ minHeight: 320, height: '100%' }}>
      {loading ? (
        <DelayedLinearProgress loading={loading} delay={1000} />
      ) : (
        <Box sx={{ height: 4 }} />
      )}
      <Grid container>
        <Grid item xs={editing ? 8 : 12}>
          <CardContentMinPadding sx={{ pt: 1.1 }}>
            <StackedBarChartHeader
              chartReference={chartReference}
              block={block}
              setEditing={setEditing}
              editing={editing}
              blockNumber={blockNumber}
              locked={locked}
              canZoomAndPan
              highlightFilterOverride
            />
            {error && <Alert severity="error">{error}</Alert>}
            {!error && (
              <StackedBarChart
                chartReference={chartReference}
                chartData={chartData}
                loading={loading}
              />
            )}
            <ChartLabels
              block={block}
              chartLabels={chartLabels}
              simulation={simulation}
              toggleLabel={toggleLabel}
            />
          </CardContentMinPadding>
        </Grid>
        {editing && (
          <Grid item xs={4}>
            <ChartEditor
              chartReference={chartReference}
              block={block}
              blockNumber={blockNumber}
              setEditing={setEditing}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
