import { getApp } from 'firebase/app';
import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore';
import { Dispatch, SetStateAction } from 'react';

import { KaeplaEvent } from '../types/Application/KaeplaEvent';
import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  project: KaeplaProject;
  scopePathStringified: string;
  lastVisible: QueryDocumentSnapshot<DocumentData> | undefined;
  setLastVisible: Dispatch<SetStateAction<QueryDocumentSnapshot<DocumentData> | undefined>>;
  more: number;
}

export const getEvents = async ({
  project,
  scopePathStringified,
  lastVisible,
  setLastVisible,
  more,
}: Options) => {
  const data: KaeplaEvent[] = [];
  if (!project || !scopePathStringified) return data;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `events`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const firestoreQuery = query(
    firestoreCollection,
    where('projectId', '==', project.id),
    where('scopePathStringified', '==', scopePathStringified),
    orderBy('dateTime', 'desc'),
    startAfter(lastVisible),
    limit(more),
  );
  const snapshot = await getDocs(firestoreQuery);
  const _lastVisible = snapshot.docs.at(-1);
  setLastVisible(_lastVisible);
  snapshot.forEach((document_) => {
    const event = document_.data();
    event.id = document_.id;
    data.push(event as KaeplaEvent);
  });
  return data;
};
