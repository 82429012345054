import { Box } from '@mui/material';
import { RecaptchaVerifier } from 'firebase/auth';
import { Dispatch, SetStateAction, useEffect } from 'react';

import { kaeplaAuth } from '../../../../firebaseInit';
interface Options {
  setRecaptchaVerifyer: Dispatch<SetStateAction<RecaptchaVerifier | undefined>>;
}

export const ReCaptcha = ({ setRecaptchaVerifyer }: Options) => {
  useEffect(() => {
    const _recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-idx',
      { size: 'invisible' },
      kaeplaAuth,
    );

    setRecaptchaVerifyer(_recaptchaVerifier);
  }, [setRecaptchaVerifyer]);

  /*
  As of lately, Google allows reCaptchas with a size of "invisible" (reCaptcha options)
  to have a css property of visibility:hidden. Be aware that display: none; is still forbidden.
  That way we can get rid of the reCaptcha badge which, by default appears in the bottom right corner 
  each time the widget's function is called.
  See: https://stackoverflow.com/questions/44543157/how-to-hide-the-google-invisible-recaptcha-badge
  */
  return <Box sx={{ visibility: 'hidden' }} id="recaptcha-idx" />;
};
