import AddIcon from '@mui/icons-material/Add';
import { Toolbar, Typography, FormControlLabel, Switch, Fab } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useAuth } from 'src/AuthProvider';
import { Layout } from 'src/Frontend/Layout/Layout';
import { useUserPreferences } from 'src/UserPreferencesProvider';
import { createEvent } from 'src/services/firestore/createEvent';
import { createNewPerspective } from 'src/services/firestore/createNewPerspective';
import { perspectiveFavoritesState } from 'src/services/recoil/nonpersistent/perspectiveFavoritesState';
import { perspectiveState } from 'src/services/recoil/nonpersistent/perspectiveState';
import { perspectivesState } from 'src/services/recoil/nonpersistent/perspectivesState';
import { projectState } from 'src/services/recoil/nonpersistent/projectState';
import { currentScopePathState } from 'src/services/recoil/persistent/currentScopePathState';
import { KaeplaDataOperation } from 'src/services/types/Application/KaeplaDataOperation';
import { KaeplaEventType } from 'src/services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from 'src/services/types/Application/KaeplaFunctionGroup';

import { PerspectivesOverview } from './PerspectivesOverview';
import { updatePerspectiveFavorites } from './helpers/updatePerspectiveFavorites';

export const Perspectives = () => {
  const { kaeplaUser } = useAuth();
  const { setPreferences, getPreferences } = useUserPreferences();
  const navigate = useNavigate();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const setPerspective = useSetRecoilState(perspectiveState);
  const setPerspectives = useSetRecoilState(perspectivesState);
  const [perspectiveFavorites, setPerspectiveFavorites] = useRecoilState(perspectiveFavoritesState);
  const [showAllPerspectivesForProject, setShowAllPerspectivesForProject] = useState(false);

  const handleShowAllPerspectivesForProject = () => {
    if (!kaeplaUser?.uid) return;
    setShowAllPerspectivesForProject(!showAllPerspectivesForProject);
    setPreferences({
      showAllPerspectives: !showAllPerspectivesForProject,
    });
  };

  const create = async () => {
    if (!kaeplaUser) return;
    const _perspective = await createNewPerspective({
      project,
      createdBy: kaeplaUser.uid,
      scopePath: currentScopePath,
    });

    setPerspective(_perspective);
    setPreferences({ lastPerspectiveId: _perspective.id });

    updatePerspectiveFavorites({
      uid: kaeplaUser.uid,
      project,
      perspective: _perspective,
      currentScopePath,
      perspectiveFavorites,
      setPerspectiveFavorites,
      isFav: true,
    });

    setPerspectives((perspectives) => [...perspectives, _perspective]);

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PERSPECTIVES_CREATE_PERSPECTIVE,
      functionGroup: KaeplaFunctionGroup.PERSPECTIVES,
      operation: KaeplaDataOperation.READ,
      project,
      scopePath: _perspective.scopePath,
    });

    navigate(`/Perspective`);
  };

  useEffect(() => {
    const _preferences = getPreferences();
    if (_preferences) {
      setShowAllPerspectivesForProject(!!_preferences.showAllPerspectives);
    }
  }, [getPreferences]);

  useEffect(() => {
    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PERSPECTIVES_ENTER_SIMULATIONS_OVERVIEW,
      functionGroup: KaeplaFunctionGroup.PERSPECTIVES,
      operation: KaeplaDataOperation.READ,
      scopePath: currentScopePath,
      project,
    });
  }, [currentScopePath, kaeplaUser?.uid, project]);

  if (!project) {
    navigate('/');
    return null;
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <>
        <Toolbar disableGutters variant="dense">
          <Typography variant="h5">{project.name} Perspectives</Typography>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
          <FormControlLabel
            control={
              <Switch
                checked={showAllPerspectivesForProject}
                size="small"
                onChange={handleShowAllPerspectivesForProject}
              />
            }
            label="all"
          />
        </Toolbar>
        <Fab
          color="primary"
          aria-label="add simulation"
          data-testid="simulation-add"
          sx={{ position: 'fixed', bottom: 20, right: 20 }}
          onClick={() => {
            void create();
          }}
        >
          <AddIcon />
        </Fab>
        <PerspectivesOverview showAllPerspectivesForProject={showAllPerspectivesForProject} />
      </>
    </Layout>
  );
};
