import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { DocumentData, OrderByDirection, QuerySnapshot } from 'firebase/firestore';

import { requestLog } from '../../Frontend/helpers/logger';
import { watcherServiceKaepla } from '../../firebaseInit';

interface Options {
  fireStorePath: string;
  queryWhere?: WhereClause[];
  orderBy?:
    | {
        fieldPath: string;
        direction: OrderByDirection;
      }
    | undefined;
  limit?: number;
  callback: (documents: DocumentData[]) => void;
  snapshotCallback?: (snapshot: QuerySnapshot<DocumentData>) => void;
  watcherKeyCallback?: (key: string) => void;
  context?: string;
}

export const addFirestoreCollectionListener = ({
  fireStorePath,
  queryWhere,
  orderBy,
  limit,
  callback,
  snapshotCallback,
  watcherKeyCallback,
  context,
}: Options) => {
  const firestoreService = watcherServiceKaepla.get();
  return firestoreService.subscribeToCollection({
    path: fireStorePath,
    where: queryWhere,
    orderBy,
    limit,
    onSnapshot: (snapshot, key) => {
      requestLog.log(
        `firestore collectionListener subscribe to ${fireStorePath} key: ${key}`,
        `context: ${context || 'n/a'}`,
        queryWhere,
      );
      const documents: DocumentData[] = [];
      snapshot.docs.forEach((document) => {
        const data = document.data();
        data.id = document.id;
        documents.push(data);
      });
      if (snapshotCallback) {
        snapshotCallback(snapshot);
      }
      if (watcherKeyCallback) {
        watcherKeyCallback(key);
      }
      callback(documents);
    },
    onError: (error) => {
      requestLog.log(`firestore collectionListener error`, error);
      return false;
    },
  });
};
