import { getCleanPath } from './getCleanPath';
import { getCurrentLevel } from './getCurrentLevel';
import { KaeplaDimensionDetails } from '../../../services/types/Application/KaeplaDimensionDetails';
import { MatrixDimensions } from '../../../services/types/MatrixDimensions';

interface Options {
  scopePath: string[];
  detail: KaeplaDimensionDetails;
  dimensions: MatrixDimensions | undefined;
}

export const isDimensionDelegable = ({ scopePath, detail, dimensions }: Options) => {
  if (!dimensions) return false;

  // this is where we are now, we want it on top of all others
  const currentLevel = getCurrentLevel(scopePath);
  if (currentLevel === detail.columnName) {
    // console.log(detail.dimension, "not delegable -> self");
    // self
    return true;
  }

  if (detail.dimensionDetails.length < 2) {
    return false;
  }
  // has been delegated along this path already
  if (getCleanPath(scopePath).includes(detail.columnName)) {
    // console.log(detail.dimension, "not delegable -> delegated on path");
    return false;
  }

  const dimensionMeta = dimensions?.dimensions.find(
    (dimension) => dimension.columnName === detail.columnName,
  );
  // floats probably do not make any sense for scopes
  if (dimensionMeta && ['FLOAT64', 'DATE'].includes(dimensionMeta?.dataType)) {
    return false;
  }
  // integers probably do not make any sense for scopes if there are more than 20 rows
  if (
    detail.dimensionDetails.length > 20 &&
    dimensionMeta &&
    ['INT64'].includes(dimensionMeta?.dataType)
  ) {
    return false;
  }
  if (detail.dimensionDetails.length > 50) {
    return false;
  }
  return true;
};
