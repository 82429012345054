import { Box, Grid, Typography } from '@mui/material';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

import { StackedBarChartTitle } from './StackedBarChartTitle';
import { KaeplaPerspectiveBlock } from '../../../../../../../services/types/Application/KaeplaPerspectiveBlock';
import { getGridSpanNumberForHeader } from '../../helpers/getGridSpanNumberForHeader';
import { ChartHeaderToolbar } from '../ChartHeaderToolbar';

interface Options {
  chartReference: MutableRefObject<undefined>;
  block: KaeplaPerspectiveBlock;
  setEditing: Dispatch<SetStateAction<boolean>>;
  editing: boolean;
  blockNumber: number;
  locked?: boolean;
  canZoomAndPan?: boolean;
  highlightFilterOverride?: boolean;
}

export const StackedBarChartHeader = ({
  chartReference,
  block,
  setEditing,
  editing,
  blockNumber,
  locked,
  canZoomAndPan,
  highlightFilterOverride,
}: Options) => {
  return (
    <Grid container direction="row-reverse">
      <Grid
        item
        xs={getGridSpanNumberForHeader('xs', block.gridSpan)}
        sm={getGridSpanNumberForHeader('sm', block.gridSpan)}
        md={getGridSpanNumberForHeader('md', block.gridSpan)}
        lg={getGridSpanNumberForHeader('lg', block.gridSpan)}
        xl={getGridSpanNumberForHeader('xl', block.gridSpan)}
      >
        <ChartHeaderToolbar
          {...{
            chartReference,
            block,
            setEditing,
            editing,
            blockNumber,
            locked,
            canZoomAndPan,
            highlightFilterOverride,
          }}
        />
      </Grid>
      <Grid
        item
        xs={getGridSpanNumberForHeader('xs', block.gridSpan)}
        sm={getGridSpanNumberForHeader('sm', block.gridSpan)}
        md={getGridSpanNumberForHeader('md', block.gridSpan)}
        lg={getGridSpanNumberForHeader('lg', block.gridSpan)}
        xl={getGridSpanNumberForHeader('xl', block.gridSpan)}
      >
        <Typography sx={{ fontSize: 18, my: 1 }} color="text.secondary" gutterBottom>
          <Box component="span" sx={{ fontSize: '90%', fontWeight: '100', mr: 1 }}>
            <StackedBarChartTitle block={block} />
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};
