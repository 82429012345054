import {
  Card,
  CardHeader,
  CardContent,
  Button,
  CardActions,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { CurrencyDecimalPlaces } from './CurrencyDecimalPlaces';
import { CurrencySelect } from './CurrencySelect';
import { useAuth } from '../../../../../AuthProvider';
import { updateDimensionCurrency } from '../../../../../services/firestore/updateDimension';
import { projectState } from '../../../../../services/recoil/nonpersistent/projectState';
import { KaeplaDimension } from '../../../../../services/types/Application/KaeplaDimension';
import { cleanColumnName } from '../../../../helpers/cleanColumnName';

interface AOptions {
  dimension: KaeplaDimension;
  ideal: boolean;
  desiredDataType: string;
  currencyDefault: string;
  setCurrencyDefault: Dispatch<SetStateAction<string>>;
  decimalPositionsDefault: number;
  setDecimalPositionsDefault: Dispatch<SetStateAction<number>>;
}

export const CurrencyDimensionCard = ({
  dimension,
  ideal,
  desiredDataType,
  currencyDefault,
  setCurrencyDefault,
  decimalPositionsDefault,
  setDecimalPositionsDefault,
}: AOptions) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const [enabled, setEnabled] = useState(!!dimension.currency || !!dimension.decimalPositions);
  const [currency, setCurrency] = useState<string | undefined>(dimension.currency);
  const [decimalPositions, setDecimalPositions] = useState<number | undefined>(
    dimension.decimalPositions,
  );

  let fontSize = '100%';
  if (!ideal) {
    fontSize = '80%';
  }

  useEffect(() => {
    if (!enabled) return;
    if (!dimension.currency && !currency) return;
    if (!decimalPositions) return;
    const dimensionUpdate = { ...dimension };
    dimensionUpdate.currency = currency;
    dimensionUpdate.decimalPositions = decimalPositions;
    void updateDimensionCurrency({ project, dimension: dimensionUpdate });
  }, [currency, decimalPositions, dimension, enabled, project]);

  return (
    <Card
      sx={{
        fontSize,
        opacity: ideal ? 1 : 0.7,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <CardHeader
        sx={{ pb: 0 }}
        title={<Box sx={{ fontSize }}>{cleanColumnName(dimension.columnName)}</Box>}
        subheader={
          <>
            <Box
              component="span"
              sx={{
                fontSize,
                color: dimension.dataType === desiredDataType ? 'green' : 'red',
              }}
            >
              {dimension.dataType}
            </Box>
          </>
        }
      />
      <CardContent sx={{ pt: 0, pb: 0 }}>
        {enabled && (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body2">Currency, number of decimal positions</Typography>
            </Grid>
            <Grid item xs={6}>
              <CurrencySelect currency={currency} setCurrency={setCurrency} />
            </Grid>
            <Grid item xs={6}>
              <CurrencyDecimalPlaces
                decimalPositions={decimalPositions}
                setDecimalPositions={setDecimalPositions}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
      {ideal && (
        <CardActions sx={{ m: 1, pt: 0 }}>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              if (!kaeplaUser) return;
              setEnabled(!enabled);
              if (enabled) {
                // eslint-disable-next-line unicorn/no-useless-undefined
                setCurrency(undefined);
                // eslint-disable-next-line unicorn/no-useless-undefined
                setDecimalPositions(undefined);
              } else {
                if (dimension.currency && dimension.decimalPositions) {
                  setCurrency(dimension.currency);
                  setDecimalPositions(dimension.decimalPositions);
                } else {
                  setCurrency(currencyDefault);
                  setDecimalPositions(decimalPositionsDefault);
                }
              }
            }}
          >
            {enabled ? 'disable' : 'enable'}
          </Button>
          {enabled && (
            <Button
              size="small"
              variant="outlined"
              color="info"
              onClick={() => {
                if (!kaeplaUser) return;
                setCurrencyDefault(currency || 'EUR');
                setDecimalPositionsDefault(decimalPositions || 2);
              }}
            >
              set as default
            </Button>
          )}
        </CardActions>
      )}
    </Card>
  );
};
