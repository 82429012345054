import { Box } from '@mui/material';
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { useAuth } from '../../../../../AuthProvider';
import { getFromKaepla } from '../../../../../services/api/getFromKaepla';
import { matrixFilteredState } from '../../../../../services/recoil/nonpersistent/matrixFilteredState';
import { projectState } from '../../../../../services/recoil/nonpersistent/projectState';
import { simulationState } from '../../../../../services/recoil/nonpersistent/simulationState';
import { currentScopePathState } from '../../../../../services/recoil/persistent/currentScopePathState';
import { filterSettingsState } from '../../../../../services/recoil/persistent/filterSettingState';
import { filterTreeState } from '../../../../../services/recoil/persistent/filterTreeState';
import { KaeplaApiParameters } from '../../../../../services/types/Application/KaeplaApiParameters';
import { KaeplaQueryType } from '../../../../../services/types/Application/KaeplaQueryType';
import { MatrixData } from '../../../../../services/types/MatrixData';
import { MatrixJob } from '../../../../../services/types/MatrixJob';
import { BQJobInfo } from '../../../../features/BQJobInfo';
import { getColumns } from '../../helpers/getColumns';
import { getColumnsAndRows } from '../../helpers/getColumnsAndRows';

export const DataPreview = () => {
  const { kaeplaUser } = useAuth();
  const simulation = useRecoilValue(simulationState);
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const filterTree = useRecoilValue(filterTreeState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const defaultColumns =
    getColumns(project, matrixFiltered?.dimensions?.dimensions, simulation) || [];

  // local states
  const [job, setJob] = useState<MatrixJob>();
  const [data, setData] = useState<Record<string, unknown>[]>([]);
  const [columns, setColumns] = useState<MRT_ColumnDef<Record<string, unknown>>[]>(defaultColumns);
  const [loading, setLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  // table state
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    // if (matrixLoading || perspectiveLoading) return;

    // no multi-sorting for now
    let orderBy;
    let sortOrder;
    if (sorting && sorting[0]) {
      orderBy = sorting[0].id;
      sortOrder = sorting[0].desc ? 'DESC' : 'ASC';
    }

    const parameters: KaeplaApiParameters = {
      q: 'records' as KaeplaQueryType,
      p: [] as string[],
      projectId: project.id,
      simulationId: simulation?.id,
      orderBy,
      sortOrder,
      limit: pagination.pageSize,
      offset: pagination.pageIndex * pagination.pageSize,
      searchKey: globalFilter,
      s: 'DataPreview',
    };

    parameters.p = currentScopePath;

    if (filterSettings.isActive) {
      parameters.filterTree = filterTree;
    }

    getFromKaepla({
      callBack: (apiResponse) => {
        if (!apiResponse) {
          setIsError(true);
          return;
        }
        if (data.length === 0) {
          setLoading(true);
        } else {
          setIsRefetching(true);
        }
        const result = apiResponse.response as MatrixData;
        const _job = result.job;
        setJob(_job);

        const { columns: columnsTransformed, rows } = getColumnsAndRows(
          result,
          project,
          simulation,
        );
        setColumns(columnsTransformed);
        setData(rows);
        setRowCount(result.rowCount);
        setIsError(false);
        setIsRefetching(false);
      },
      setLoading,
      params: parameters,
      uid: kaeplaUser?.uid,
    });
  }, [
    project.id,
    simulation?.id,
    currentScopePath,
    filterTree,
    filterSettings.isActive,
    project,
    simulation,
    kaeplaUser?.uid,
    pagination.pageSize,
    pagination.pageIndex,
    data.length,
    globalFilter,
    sorting,
  ]);

  return (
    <MaterialReactTable
      renderTopToolbarCustomActions={() =>
        job && (
          <Box sx={{ p: 1 }}>
            <BQJobInfo job={job} />
          </Box>
        )
      }
      columns={columns}
      data={data}
      initialState={{ density: 'compact' }}
      manualFiltering
      manualPagination
      manualSorting
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: 'error',
              children: 'Error loading data',
            }
          : undefined
      }
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      state={{
        globalFilter,
        isLoading: loading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: isRefetching,
        sorting,
      }}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      rowCount={rowCount}
      enableColumnFilters={false}
    />
  );
};
