import { getApp } from 'firebase/app';
import { getFirestore, collection, query, getDocs, where } from 'firebase/firestore';

import { KaeplaDimension } from '../types/Application/KaeplaDimension';
import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  project: KaeplaProject;
  dimension: Partial<KaeplaDimension>;
}

export const getDimensionByColumnName = async ({ project, dimension }: Options) => {
  if (!project.id || !dimension.columnName) return;
  const data: KaeplaDimension[] = [];
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/dimensions/`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const firestoreQuery = query(
    firestoreCollection,
    where('columnName', '==', dimension.columnName),
  );
  const snapshot = await getDocs(firestoreQuery);
  snapshot.forEach((document) => {
    const dimensionFromServer = document.data();
    dimensionFromServer.id = document.id;
    data.push(dimensionFromServer as KaeplaDimension);
  });
  return data[0];
};
