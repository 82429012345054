import { Grid, Typography, Divider, Alert, AlertTitle } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { DimensionCard } from './components/DimensionCard';
import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState';
import { KaeplaDimensionDetails } from '../../../../services/types/Application/KaeplaDimensionDetails';
import { KaeplaPerspective } from '../../../../services/types/Application/KaeplaPerspective';

const isChoosableAsIdentifierDimension = (detail: KaeplaDimensionDetails) => {
  if (['DATE', 'DECIMAL', 'FLOAT64'].includes(detail.dataType)) return false;
  const nulls = detail.dimensionDetails.find((row) => row.value === null);
  let nullValues = 0;
  if (nulls) {
    nullValues = nulls.numberOfRecords;
  }
  if (nullValues > 0) return false;

  return true;
};

const choosableDimensions = (details?: KaeplaDimensionDetails[]) => {
  if (!details) return [];
  return details.filter((detail) => isChoosableAsIdentifierDimension(detail));
};

const notChoosableDimensions = (details?: KaeplaDimensionDetails[]) => {
  if (!details) return [];
  return details
    .filter((detail) => detail.dataType === 'FLOAT64')
    .filter((detail) => !isChoosableAsIdentifierDimension(detail));
};

interface Options {
  defaultPerspective: Partial<KaeplaPerspective>;
  setDefaultPerspective: (step: Partial<KaeplaPerspective>) => void;
}

export const StepIdentifierDimension = ({ setDefaultPerspective, defaultPerspective }: Options) => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);

  if (!matrixFiltered?.details || matrixFiltered?.details.length === 0) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} container justifyContent="center">
          <Alert severity="error">
            <AlertTitle>ERROR</AlertTitle>
            Matrix dimensions could not be read
          </Alert>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          These Dimensions are ideal for identifying unique records over all data
        </Typography>
      </Grid>
      {choosableDimensions(matrixFiltered?.details)?.map((detail) => (
        <Grid key={detail.columnName} item xs={12} sm={6} md={4} lg={3}>
          <DimensionCard
            detail={detail}
            ideal={true}
            totalCount={matrixFiltered.totalCount?.totalCount || 0}
            setDefaultPerspective={setDefaultPerspective}
            defaultPerspective={defaultPerspective}
            dimensionType="identifierDimension"
            desiredDataType="STRING"
          />
        </Grid>
      ))}
      {!choosableDimensions(matrixFiltered?.details) ||
        (choosableDimensions(matrixFiltered?.details).length === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="center">
              <Alert severity="warning">
                <AlertTitle>WARNING</AlertTitle>
                No ideal dimensions for identifying unique records over all data
              </Alert>
            </Grid>
          </Grid>
        ))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">
          These Dimensions are not ideal for identifying unique records over all data
        </Typography>
      </Grid>
      {notChoosableDimensions(matrixFiltered?.details)?.map((detail) => (
        <Grid key={detail.columnName} item xs={12} sm={6} md={4} lg={3}>
          <DimensionCard
            detail={detail}
            ideal={false}
            totalCount={matrixFiltered.totalCount?.totalCount || 0}
            dimensionType="identifierDimension"
            desiredDataType="STRING"
          />
        </Grid>
      ))}
    </Grid>
  );
};
