import { atom } from 'recoil';

import { KaeplaProjectAssignment } from '../../types/Application/KaeplaProjectAssignment';

const defaultProjectAssignmentState: KaeplaProjectAssignment[] | undefined = [];

export const projectAssignmentsState = atom({
  key: 'projectAssignments',
  default: defaultProjectAssignmentState,
});
