export enum KaeplaFunctionGroup {
  ASSIGNMENTS = 'Assignments',
  TARGETS = 'Targets',
  SCOPES = 'Scopes',
  PERSPECTIVES = 'Perspectives',
  PROJECTS = 'Projects',
  SIMULATIONS = 'Simulations',
  ALIGNMENTS = 'Alignments',
  RESELLER = 'Reseller',
  CUSTOMER = 'Customer',
  ADMIN = 'Admin',
}
