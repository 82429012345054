import { generateFromString } from 'generate-avatar';
import colors from 'nice-color-palettes';

export const getRandomAvatar = (name: string) => {
  const chartColors1 = [...colors[0], ...colors[1]];
  const chartColors2 = [...colors[10], ...colors[11]];
  const chartColors3 = [...colors[2], ...colors[3]];
  const chartColors4 = [...colors[4], ...colors[5]];
  const chartColors5 = [...colors[6], ...colors[7]];
  const chartColors6 = [...colors[8], ...colors[9]];
  const variants: string[] = [
    `data:image/svg+xml;utf8,${generateFromString(name)}`,
    `https://source.boringavatars.com/bauhaus/50/${name}?square=true&colors=${chartColors1.join(
      ',',
    )}`,
    `https://source.boringavatars.com/bauhaus/50/${name}?marble=true&colors=${chartColors2.join(
      ',',
    )}`,
    `https://source.boringavatars.com/bauhaus/50/${name}?square=true&colors=${chartColors3.join(
      ',',
    )}`,
    `https://source.boringavatars.com/marble/50/${name}?colors=${chartColors4.join(',')}`,
    `https://source.boringavatars.com/bauhaus/50/${name}?square=true&colors=${chartColors5.join(
      ',',
    )}`,
    `https://source.boringavatars.com/marble/50/${name}?colors=${chartColors6.join(',')}`,
  ];
  const random = Math.floor(Math.random() * variants.length);
  return variants[random];
};
