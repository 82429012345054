import { Grid, List, Paper } from '@mui/material';

import { ManageMFA } from './features/ManageMFA';
import { UserAvatarEditor } from './features/UserAvatarEditor';
import { useAuth } from '../../../AuthProvider';
import { Layout } from '../../Layout/Layout';

export const User = () => {
  const { user } = useAuth();

  if (!user) return null;

  return (
    <Layout>
      <Grid container sx={{ maxWidth: 500 }} spacing={2}>
        <Grid item xs={12}>
          <List data-testid="user-avatar-editor" component={Paper}>
            <UserAvatarEditor />
          </List>
        </Grid>
        <Grid item xs={12}>
          <ManageMFA />
        </Grid>
      </Grid>
    </Layout>
  );
};
