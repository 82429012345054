import { Input } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

interface Options {
  value: string;
  callback: (value: string) => void;
  changeCallback?: (value: string) => void;
  fullWidth?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

export const InlineEdit = ({
  value,
  callback,
  changeCallback,
  fullWidth,
  disabled,
  readOnly,
}: Options) => {
  const [focus, setFocus] = useState(false);
  const [text, setText] = useState<string>(value);
  const inputField = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <Input
      name="editable-text"
      value={text}
      sx={{ fontSize: '110%' }}
      onKeyUp={(event) => {
        if (event.key === 'Enter') {
          inputField.current?.blur();
        }
      }}
      onChange={(event) => {
        setText(event.target.value);
        if (changeCallback) {
          changeCallback(event.target.value);
        }
      }}
      onFocus={() => {
        setFocus(true);
      }}
      onBlur={() => {
        callback(text);
        setFocus(false);
      }}
      inputRef={inputField}
      disableUnderline={!focus || readOnly}
      fullWidth={fullWidth}
      disabled={!!disabled}
      readOnly={!!readOnly}
    />
  );
};
