import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useAuth } from 'src/AuthProvider';
import { addFirestoreCollectionListener } from 'src/services/firestore/addFirestoreCollectionListener';
import { applicationState } from 'src/services/recoil/nonpersistent/applicationState';
import { projectState } from 'src/services/recoil/nonpersistent/projectState';
import { projectTargetsState } from 'src/services/recoil/nonpersistent/projectTargetsState';
import { currentScopePathState } from 'src/services/recoil/persistent/currentScopePathState';
import { KaeplaTargets } from 'src/services/types/Application/KaeplaTargets';

import { logger, requestLog } from '../helpers/logger';

interface Options {
  showAllTargetsForProject?: boolean;
}

export const TargetsListener = ({ showAllTargetsForProject }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const setProjectTargets = useSetRecoilState(projectTargetsState);
  const setApp = useSetRecoilState(applicationState);

  // this is the firestore targetsListener
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    logger.log('\nuseEffect TargetsListener');

    const fireStorePath = `projects/${project.id}/targets`;
    const queryWhere: WhereClause[] = [];
    if (!showAllTargetsForProject) {
      queryWhere.push({
        fieldPath: 'scopePathStringified',
        opStr: '==',
        value: JSON.stringify(currentScopePath),
      });
    }
    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      queryWhere,
      callback: (dataFromServer) => {
        const targetsFromServer = dataFromServer as KaeplaTargets[];
        requestLog.log(`TargetsListener targetsFromServer`, targetsFromServer);
        setProjectTargets(targetsFromServer);
        setApp((app) => ({ ...app, targetsListenerActive: true }));
      },
    });

    return () => {
      requestLog.log(`unsubscribe targets Listener`);
      setApp((app) => ({ ...app, targetsListenerActive: false }));
      unsubscribe();
    };
  }, [
    currentScopePath,
    kaeplaUser?.uid,
    project?.id,
    setProjectTargets,
    setApp,
    showAllTargetsForProject,
  ]);

  return null;
};
