import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import numbro from 'numbro';
import { useRecoilValue } from 'recoil';
import { matrixLoadingState } from 'src/services/recoil/nonpersistent/matrixLoadingState';

import { matrixFilteredState } from '../../../../../../services/recoil/nonpersistent/matrixFilteredState';
import { KaeplaProject } from '../../../../../../services/types/Application/KaeplaProject';
import { BQJobInfo } from '../../../../../features/BQJobInfo';

interface Options {
  project: KaeplaProject;
}

export const ProjectRecordsCount = ({ project }: Options) => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const matrixLoading = useRecoilValue(matrixLoadingState);

  return (
    <Box>
      <Typography sx={{ fontSize: '100%' }}>Records</Typography>
      <Typography sx={{ fontSize: '90%' }} color="text.secondary">
        {matrixFiltered?.totalCount?.totalCount && !matrixLoading
          ? numbro(matrixFiltered.totalCount.totalCount).format({
              average: true,
              mantissa: 1,
            })
          : 'n/a'}{' '}
        {matrixFiltered?.totalCount?.job && <BQJobInfo job={matrixFiltered.totalCount?.job} />}
      </Typography>
    </Box>
  );
};
