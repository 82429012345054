import { getScopeGraph } from './getScopeGraph';
import { KaeplaModel } from '../../../../services/types/Application/KaeplaModel';

interface Options {
  model: KaeplaModel;
  scopePath: string[];
}
export const graphFromScopeModel = ({ model, scopePath }: Options) => {
  if (!model.delegation) return null;

  return getScopeGraph({ delegation: model.delegation, scopePath });
};
