import { Dispatch, RefObject, SetStateAction } from 'react';
import { useRecoilValue } from 'recoil';
import { KaeplaSimulationParameterNumeric } from 'src/services/types/Application/KaeplaSimulationParameterNumeric';
import { KaeplaSimulationParameterText } from 'src/services/types/Application/KaeplaSimulationParameterText';
import { isKaeplaSimulationParameterNumeric } from 'src/services/types/Application/isKaeplaSimulationParameterNumeric';
import { isKaeplaSimulationParameterText } from 'src/services/types/Application/isKaeplaSimulationParameterText';

import { SimulationParameterNumeric } from './SimulationParameterNumeric';
import { SimulationParameterText } from './SimulationParameterText';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { KaeplaSimulation } from '../../../../services/types/Application/KaeplaSimulation';
import { KaeplaSimulationRulesetWithParameters } from '../../../../services/types/Application/KaeplaSimulationRulesetWithParameters';

interface Options {
  parameter: KaeplaSimulationParameterNumeric | KaeplaSimulationParameterText;
  simulation: KaeplaSimulation;
  ruleset: KaeplaSimulationRulesetWithParameters;
  equalizerParentReference: RefObject<HTMLInputElement>;
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  setSimulationRulesets: Dispatch<
    SetStateAction<KaeplaSimulationRulesetWithParameters[] | undefined>
  >;
  setPreview: Dispatch<SetStateAction<boolean>>;
  own: boolean;
}

export const SimulationParameter = ({
  parameter,
  simulation,
  ruleset,
  equalizerParentReference,
  simulationRulesets,
  setSimulationRulesets,
  setPreview,
  own,
}: Options) => {
  const project = useRecoilValue(projectState);

  if (!project) return null;

  if (isKaeplaSimulationParameterNumeric(parameter)) {
    return (
      <SimulationParameterNumeric
        key={parameter.dimension}
        equalizerParentReference={equalizerParentReference}
        parameter={parameter}
        ruleset={ruleset}
        simulation={simulation}
        own={own}
        simulationRulesets={simulationRulesets}
        setSimulationRulesets={setSimulationRulesets}
        setPreview={setPreview}
      />
    );
  } else if (isKaeplaSimulationParameterText(parameter)) {
    return (
      <SimulationParameterText
        key={parameter.dimension}
        parameter={parameter}
        ruleset={ruleset}
        simulation={simulation}
        own={own}
        simulationRulesets={simulationRulesets}
        setSimulationRulesets={setSimulationRulesets}
        setPreview={setPreview}
      />
    );
  }

  return null;
};
