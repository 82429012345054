import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { matrixFilteredState } from '../../../../../../services/recoil/nonpersistent/matrixFilteredState';
import { KaeplaDimensionDetails } from '../../../../../../services/types/Application/KaeplaDimensionDetails';
import { cleanColumnName } from '../../../../../helpers/cleanColumnName';

interface Options {
  setDimension: Dispatch<SetStateAction<string>>;
  dimension: string;
  isEligibleForDimension: (detail: KaeplaDimensionDetails) => boolean;
}

export const DimensionSelect = ({ setDimension, dimension, isEligibleForDimension }: Options) => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const [dimensionValue, setDimensionValue] = useState(dimension);

  const handleChange = (event: SelectChangeEvent) => {
    setDimension(event.target.value);
    setDimensionValue(event.target.value);
  };

  const eligibleDimensions = (details?: KaeplaDimensionDetails[]) => {
    if (!details) return [];
    return details.filter((detail) => isEligibleForDimension(detail));
  };

  return (
    <FormControl sx={{ m: 0, minWidth: 220 }} size="small" margin="dense">
      <Select
        id="simulationSelect"
        value={dimensionValue || ''}
        onChange={handleChange}
        size="small"
      >
        <MenuItem value="" dense>
          <em>None</em>
        </MenuItem>
        {eligibleDimensions(matrixFiltered?.details).map((detail) => (
          <MenuItem key={detail.columnName} value={detail.columnName} dense>
            {cleanColumnName(detail.columnName)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
