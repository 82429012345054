import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, IconButton, Toolbar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Dispatch, SetStateAction, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { KaeplaSimulationParameterNumeric } from 'src/services/types/Application/KaeplaSimulationParameterNumeric';
import { KaeplaSimulationParameterText } from 'src/services/types/Application/KaeplaSimulationParameterText';

import { ParameterText } from './ParameterText';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { KaeplaSimulation } from '../../../../services/types/Application/KaeplaSimulation';
import { KaeplaSimulationRulesetWithParameters } from '../../../../services/types/Application/KaeplaSimulationRulesetWithParameters';
import { cleanColumnName } from '../../../helpers/cleanColumnName';

interface Options {
  parameter: KaeplaSimulationParameterNumeric | KaeplaSimulationParameterText;
  simulation: KaeplaSimulation;
  ruleset: KaeplaSimulationRulesetWithParameters;
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  setSimulationRulesets: Dispatch<
    SetStateAction<KaeplaSimulationRulesetWithParameters[] | undefined>
  >;
  setPreview: Dispatch<SetStateAction<boolean>>;
  own: boolean;
}

export const SimulationParameterText = ({
  parameter,
  simulation,
  ruleset,
  simulationRulesets,
  setSimulationRulesets,
  setPreview,
  own,
}: Options) => {
  const theme = useTheme();
  const project = useRecoilValue(projectState);
  const [addValue, setAddValue] = useState(false);

  if (!project) return null;

  return (
    <Box
      borderBottom={1}
      // borderRadius={1}
      borderColor={theme.palette.grey[300]}
      p={1}
      px={2}
      sx={{
        minHeight: 100,
        opacity: simulation?.isBeingSimulated ? 0.3 : 1,
      }}
    >
      <Grid container spacing={1} sx={{ height: '100%' }}>
        <Grid item xs={12}>
          <Toolbar disableGutters variant="dense">
            {cleanColumnName(parameter.dimension)}
            <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
            <IconButton
              aria-label="delete"
              color="primary"
              size="small"
              disabled={!own}
              onClick={() => {
                setAddValue(true);
              }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="delete"
              color="primary"
              size="small"
              disabled={!own}
              onClick={() => {
                const newRulesets = [...simulationRulesets].map((r) => {
                  if (r.id === ruleset.id) {
                    const newRuleset = { ...ruleset };
                    newRuleset.parameters = [...newRuleset.parameters].filter(
                      (p) => p.dimension !== parameter.dimension,
                    );
                    return newRuleset;
                  }
                  return r;
                });
                setSimulationRulesets(newRulesets);
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Toolbar>
        </Grid>
        <Grid item xs={12}>
          <ParameterText
            parameter={parameter}
            ruleset={ruleset}
            own={own}
            simulationRulesets={simulationRulesets}
            setSimulationRulesets={setSimulationRulesets}
            setPreview={setPreview}
            addValue={addValue}
            setAddValue={setAddValue}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
