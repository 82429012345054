import CloseIcon from '@mui/icons-material/Close';
import { Grid, TextField, Button, Stack, Alert, IconButton, Typography } from '@mui/material';
import Image from 'mui-image';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { BrandedRequestEmail } from './BrandedRequestEmail';
import { SecondFactor } from './SecondFactor';
import { useAuth } from '../../../../AuthProvider';
import { pageState } from '../../../../services/recoil/persistent/pageState';
import { KaeplaBranding } from '../../../../services/types/Application/KaeplaBranding';
import { documentTitle } from '../../../helpers/documentTitle';

interface Options {
  branding: KaeplaBranding;
}

export const BrandedLogin = ({ branding }: Options) => {
  const { signIn, sendEmailLink, signInWithLink, authError, clearAuthError, secondFactorRequired } =
    useAuth();
  const navigate = useNavigate();
  const [searchParameters] = useSearchParams();
  const action = searchParameters.get('action');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [linkLogin, setLinkLogin] = useState(true);
  const [loginLinkSent, setLoginLinkSent] = useState(false);
  const [requestInviteEmail, setRequestInviteEmail] = useState(false);
  const setPage = useSetRecoilState(pageState);

  useEffect(() => {
    if (action === 'signInWithLink') {
      void signInWithLink();
    } else if (action === 'signInWithInviteLink') {
      setRequestInviteEmail(true);
    }
  }, [action, signInWithLink]);

  const goToDashboard = () => {
    setPage('/');
    navigate('/Login');
  };

  if (requestInviteEmail) {
    return <BrandedRequestEmail branding={branding} />;
  }

  if (branding && secondFactorRequired) {
    return <SecondFactor />;
  }

  return (
    <form>
      <Grid container spacing={3} data-testid="login-BrandedLogin" alignItems="center">
        {branding?.appLogoUrl && (
          <Grid item xs={12} container justifyContent="center">
            <Image
              data-testid="login-BrandedLoginImage"
              width={200}
              alt={documentTitle(branding)}
              src={branding?.appLogoUrl}
            />
          </Grid>
        )}
        {branding?.appName && !branding.appLogoUrl && (
          <Grid item xs={12}>
            <Typography data-testid="login-BrandedLoginAppName" sx={{ color: '#000' }} variant="h2">
              {branding.appName}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            inputProps={{ 'data-testid': 'login-BrandedLoginEmail' }}
            label="Email"
            size="small"
            fullWidth
            sx={{ minWidth: 250 }}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            autoFocus
            disabled={loginLinkSent}
          />
        </Grid>
        {!linkLogin && !loginLinkSent && (
          <Grid item xs={12}>
            <TextField
              inputProps={{ 'data-testid': 'login-BrandedLoginPassword' }}
              label="Password"
              size="small"
              fullWidth
              type={'password'}
              sx={{ minWidth: 250, opacity: linkLogin ? 0.5 : 1 }}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  let _email = email;
                  if (!email.includes('@')) {
                    setEmail(`${email}@kaepla.atrigam.com`);
                    _email = `${email}@kaepla.atrigam.com`;
                  }
                  void signIn(_email, password);
                  goToDashboard();
                  event.stopPropagation();
                }
              }}
              disabled={linkLogin || loginLinkSent}
            />
          </Grid>
        )}
        {authError && (
          <Grid item xs={12}>
            <Alert
              variant="outlined"
              severity="error"
              data-testid="login-BrandedLoginErrorAlert"
              action={
                <IconButton
                  aria-label="close"
                  data-testid="login-BrandedLoginErrorAlertClose"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setEmail('');
                    setPassword('');
                    setLoginLinkSent(false);
                    clearAuthError();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {authError.code}
            </Alert>
          </Grid>
        )}
        {loginLinkSent && !authError && (
          <Grid item xs={12}>
            <Alert
              data-testid="login-BrandedLoginLinkSentAlert"
              variant="outlined"
              severity="info"
              action={
                <IconButton
                  data-testid="login-BrandedLoginLinkSentAlertClose"
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setLoginLinkSent(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              An email with a login link is on the way.
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack
            sx={{ width: '100%' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {!linkLogin && (
              <Button
                data-testid="login-BrandedLoginWithPasswordButton"
                variant="text"
                onClick={() => {
                  setLinkLogin(true);
                  setPassword('');
                }}
              >
                Login with Email Link
              </Button>
            )}
            {linkLogin && (
              <Button
                data-testid="login-BrandedLoginSwitchLoginTypeButton"
                variant="text"
                onClick={() => {
                  setLinkLogin(false);
                }}
                disabled={loginLinkSent}
              >
                Login
              </Button>
            )}
            {linkLogin && (
              <Button
                data-testid="login-BrandedLoginWithEmailLinkButton"
                variant="contained"
                onClick={() => {
                  let emailToInvite = email;
                  if (!emailToInvite.includes('@')) {
                    setEmail(`${email}@kaepla.atrigam.com`);
                    emailToInvite = `${email}@kaepla.atrigam.com`;
                  }
                  void sendEmailLink(emailToInvite);
                  setLoginLinkSent(true);
                }}
                disabled={loginLinkSent || email === ''}
              >
                Send Email Login Link
              </Button>
            )}
            {!linkLogin && (
              <Button
                data-testid="login-BrandedLoginWithEmailAndPasswordButton"
                variant="contained"
                onClick={() => {
                  let _email = email;
                  if (!email.includes('@')) {
                    setEmail(`${email}@kaepla.atrigam.com`);
                    _email = `${email}@kaepla.atrigam.com`;
                  }
                  void signIn(_email, password);
                  goToDashboard();
                }}
              >
                Login
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};
