import { withServiceWorkerUpdater, ServiceWorkerUpdaterProps } from '@3m1/service-worker-updater';
import { Alert, AlertTitle, Button, Snackbar } from '@mui/material';
import localForage from 'localforage';

const ServiceWorkerUpdater = (properties: ServiceWorkerUpdaterProps) => {
  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = properties;
  return newServiceWorkerDetected ? (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open>
      <Alert
        severity="info"
        action={
          <Button
            onClick={() => {
              void localForage.clear(); // clear out recoil-persist
              onLoadNewServiceWorkerAccept();
            }}
          >
            Update
          </Button>
        }
      >
        <AlertTitle>New version available</AlertTitle>
        It's recommended to update right away.
      </Alert>
    </Snackbar>
  ) : null;
};

export const SWUpdater = withServiceWorkerUpdater(ServiceWorkerUpdater);
