import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  Point,
  Filler,
} from 'chart.js';
import numbro from 'numbro';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useRecoilValue } from 'recoil';
import { perspectiveTargetFiguresState } from 'src/services/recoil/nonpersistent/perspectiveTargetFigures';

import { perspectiveState } from '../../../../../services/recoil/nonpersistent/perspectiveState';
import { timeSeriesState } from '../../../../../services/recoil/nonpersistent/timeSeriesState';
import {
  matrixDataColor,
  matrixDataColorLight,
  simulationDataColor,
  simulationDataColorLight,
  simulationDataColorShade,
  simulationDataLowerLight,
  simulationDataUpperLight,
  simulationPreviewDataColor,
  simulationPreviewDataColorDM,
  simulationPreviewDataColorLight,
  simulationPreviewDataColorLightDM,
  targetsDataColor,
  targetsDataColorLight,
} from '../../../defaults';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 2,
    },
    line: {
      borderWidth: 1,
    },
    filler: {
      propagate: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        callback: (value: string | number) => {
          return (
            '€' +
            numbro(value).format({
              average: true,
              mantissa: 1,
            })
          );
        },
      },
    },
  },
};

interface Options {
  setHasSimulation: Dispatch<SetStateAction<boolean>>;
  setHasTargets: Dispatch<SetStateAction<boolean>>;
  simulationPreview?: boolean;
}

export const DataTimelineChart = ({
  setHasSimulation,
  setHasTargets,
  simulationPreview,
}: Options) => {
  const theme = useTheme();
  const previewColor =
    theme.palette.mode === 'light' ? simulationPreviewDataColor : simulationPreviewDataColorDM;
  const previewColorLight =
    theme.palette.mode === 'light'
      ? simulationPreviewDataColorLight
      : simulationPreviewDataColorLightDM;
  const perspective = useRecoilValue(perspectiveState);
  const perspectiveTargetFigures = useRecoilValue(perspectiveTargetFiguresState);
  const timeSeries = useRecoilValue(timeSeriesState);
  const [data, setData] = useState<ChartData<'line', (number | Point | null)[], unknown>>();

  useEffect(() => {
    if (!perspective?.id) return;
    if (!timeSeries?.timeseries) return;
    const dataPoints = timeSeries.timeseries.map((d) => ({
      x: d.date as unknown as number,
      y: d.value,
    }));

    const targetDataPoints = perspectiveTargetFigures.map((d) => ({
      x: d.validAsOf as unknown as number,
      y: d.absoluteValue,
    }));

    if (targetDataPoints.length > 0) {
      setHasTargets(true);
    } else {
      setHasTargets(false);
    }

    const dataPointsSimulation = timeSeries?.timeseries
      .filter((d) => !!d.simulationValue)
      .map((d) => ({
        x: d.date as unknown as number,
        y: d.simulationValue as number,
      }));

    const dataPointsSimulationUpperThresh = [...dataPointsSimulation].map((d) => {
      const dataX = dataPoints.find((dd) => dd.x === d.x);
      const diff = Math.abs((dataX?.y || 0) - d.y);
      const thresh = d.y + diff * 0.5;
      const point = {
        x: d.x as unknown as number,
        y: thresh,
      };
      return point;
    });

    const dataPointSimulationLowerThresh = [...dataPointsSimulation].map((d) => {
      const dataX = dataPoints.find((dd) => dd.x === d.x);
      const diff = Math.abs((dataX?.y || 0) - d.y);
      const thresh = d.y - diff * 0.25;
      const point = {
        x: d.x as unknown as number,
        y: thresh,
      };
      return point;
    });

    if (dataPointsSimulation.length > 0) {
      setHasSimulation(true);
    } else {
      setHasSimulation(false);
    }
    // console.log("dataPointsSimulation-->", dataPointsSimulation);

    const label = perspective?.aggregationOverTimeName || 'n/a';
    const _data: ChartData<'line', (number | Point | null)[], unknown> = {
      datasets: [
        {
          label,
          data: dataPoints,
          borderColor: matrixDataColor,
          backgroundColor: matrixDataColorLight,
        },
        {
          label: `${label} Targets`,
          data: targetDataPoints,
          borderColor: targetsDataColor,
          backgroundColor: targetsDataColorLight,
        },
        {
          label: `${label} Simulation Upper Threshold`,
          data: dataPointsSimulationUpperThresh,
          borderColor: simulationPreview ? previewColor : simulationDataUpperLight,
          backgroundColor: simulationPreview ? previewColorLight : simulationDataColorShade,
          pointRadius: 0,
          fill: '+2',
        },
        {
          label: `${label} Simulation`,
          data: dataPointsSimulation,
          borderColor: simulationPreview ? previewColor : simulationDataColor,
          backgroundColor: simulationPreview ? previewColorLight : simulationDataColorLight,
        },
        {
          label: `${label} Simulation Lower Threshold`,
          data: dataPointSimulationLowerThresh,
          borderColor: simulationPreview ? previewColor : simulationDataLowerLight,
          backgroundColor: simulationPreview ? previewColorLight : simulationDataColorShade,
          pointRadius: 0,
        },
      ],
    };
    setData(_data);
  }, [
    timeSeries.timeseries,
    simulationPreview,
    perspective?.id,
    perspective?.aggregationOverTimeName,
    previewColor,
    previewColorLight,
    setHasSimulation,
    perspectiveTargetFigures,
    setHasTargets,
  ]);

  return <Box height={200}>{data && <Line options={options} data={data} />}</Box>;
};
