import { Alert, Divider, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useAuth } from 'src/AuthProvider';
import { perspectivesState } from 'src/services/recoil/nonpersistent/perspectivesState';
import { projectState } from 'src/services/recoil/nonpersistent/projectState';
import { projectAssignmentsState } from 'src/services/recoil/nonpersistent/userAssignmentState';
import { currentScopePathState } from 'src/services/recoil/persistent/currentScopePathState';
import { KaeplaPerspective } from 'src/services/types/Application/KaeplaPerspective';

import { PerspectiveCard } from './PerspectiveCard';
import { checkPathIsInScope } from '../Scopes/helpers/checkPathIsInScope';

interface Options {
  showAllPerspectivesForProject: boolean;
}

export const PerspectivesOverview = ({ showAllPerspectivesForProject }: Options) => {
  const { kaeplaUser } = useAuth();
  const perspectives = useRecoilValue(perspectivesState);
  const [ownPerspectives, setOwnPerspectives] = useState<KaeplaPerspective[]>([]);
  const [otherPerspectives, setOtherPerspectives] = useState<KaeplaPerspective[]>([]);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const project = useRecoilValue(projectState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);

  const isInScope = useCallback(
    (scopePath: string[]) => {
      if (project.id === 'default') return true;
      return checkPathIsInScope({
        project,
        projectAssignments,
        scopePath,
      });
    },
    [project, projectAssignments],
  );

  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    const perspectivesFiltered = [...perspectives].filter((s) => {
      if (showAllPerspectivesForProject) {
        return true;
      } else {
        if (s.scopePathStringified === JSON.stringify(currentScopePath)) {
          return true;
        }
      }
      return false;
    });
    const own = perspectivesFiltered.filter((s) => s.createdBy === kaeplaUser.uid);
    if (project.defaultPerspective) {
      const defaultPerspective = { ...project.defaultPerspective };
      defaultPerspective.id = 'default';
      setOwnPerspectives([defaultPerspective, ...own]);
    } else {
      setOwnPerspectives(own);
    }
    const other = perspectivesFiltered
      .filter((p) => isInScope(p.scopePath))
      .filter((s) => s.createdBy !== kaeplaUser.uid);
    setOtherPerspectives(other);
  }, [
    currentScopePath,
    kaeplaUser?.uid,
    showAllPerspectivesForProject,
    perspectives,
    isInScope,
    project.defaultPerspective,
  ]);

  return (
    <Grid container spacing={1}>
      {ownPerspectives.length > 0 ? (
        <Grid xs={12} item>
          <Typography variant="h6">Own Perspectives</Typography>
        </Grid>
      ) : (
        <Grid xs={12} item>
          <Alert variant="outlined" severity="info">
            Your have no perspectives for this project. Create one!
          </Alert>
        </Grid>
      )}
      {ownPerspectives?.map((_simulation) => {
        return (
          <Grid xs={12} sm={6} md={4} lg={3} key={_simulation.id} item>
            <PerspectiveCard perspective={_simulation} own />
          </Grid>
        );
      })}
      {otherPerspectives.length > 0 && (
        <Grid xs={12} item>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="h6">Other Perspectives</Typography>
        </Grid>
      )}
      {otherPerspectives?.map((_perspective) => {
        return (
          <Grid xs={12} sm={6} md={4} lg={3} key={_perspective.id} item>
            <PerspectiveCard perspective={_perspective} />
          </Grid>
        );
      })}
    </Grid>
  );
};
