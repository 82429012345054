import { getApp } from 'firebase/app';
import { collection, doc, DocumentData, getFirestore, setDoc, Timestamp } from 'firebase/firestore';

import { createOrUpdateUser } from './createOrUpdateUser';
import { getProjectAssignmentsForUserAndScopePath } from './getProjectAssignmentsForUserAndScopePath';
import { getUID } from './getUID';
import { getUserByEmail } from './getUserByEmail';
import { getNamesFromEmail } from '../../Frontend/helpers/getNamesFromEmail';
import { KaeplaProject } from '../types/Application/KaeplaProject';
import { KaeplaProjectAssignment } from '../types/Application/KaeplaProjectAssignment';
import { KaeplaUser } from '../types/Application/KaeplaUser';

interface Options {
  project: KaeplaProject;
  email: string;
  scopePath: string[];
  assignedBy: string;
}

export const createProjectAssignmentForEmail = async ({
  project,
  email,
  scopePath,
  assignedBy,
}: Options) => {
  let user = await getUserByEmail({ email });
  if (!user) {
    const namesFromEmail = getNamesFromEmail(email);
    const userToCreate: Partial<KaeplaUser> = {
      uid: getUID(),
      email,
      displayName: namesFromEmail.displayName,
      acronym: namesFromEmail.acronym,
    };
    user = await createOrUpdateUser({ user: userToCreate });
  }
  if (!user || !project) return;
  const existingAssignments = await getProjectAssignmentsForUserAndScopePath({
    uid: user.uid,
    projectId: project.id,
    scopePathStringified: JSON.stringify(scopePath),
  });

  if (existingAssignments.length > 0) {
    return;
  }
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projectAssignments`;
  const firestoreCollectionReference = collection(firestoreDatabase, fireStorePath);
  const firestoreDocumentReference = doc(firestoreCollectionReference);
  const assignment: KaeplaProjectAssignment = {
    id: firestoreDocumentReference.id,
    projectId: project.id,
    inviteEmail: email,
    inviteAccessDomain: window.location.hostname,
    customerId: project.customerId,
    resellerId: project.resellerId,
    uid: user.uid,
    scopePath,
    scopePathStringified: JSON.stringify(scopePath),
    assignedAt: Timestamp.now(),
    assignedBy,
    updatedAt: Timestamp.now(),
  };

  await setDoc(firestoreDocumentReference, assignment as DocumentData);
};
