import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../AuthProvider';
import { addFirestoreCollectionListener } from '../../services/firestore/addFirestoreCollectionListener';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState';
import { knownUsersState } from '../../services/recoil/persistent/knownUsersState';
import { KaeplaUser } from '../../services/types/Application/KaeplaUser';

export const UserListenerKaeplaAdmin = () => {
  const { kaeplaUser } = useAuth();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const setKnownUsers = useSetRecoilState(knownUsersState);

  useEffect(() => {
    if (!kaeplaUser?.uid || !kaeplaAssignment) return;
    const fireStorePath = `users`;
    const unsubscribe = addFirestoreCollectionListener({
      fireStorePath,
      callback: (_users) => {
        if (_users) {
          const users = _users as KaeplaUser[];
          setKnownUsers(users);
        }
      },
    });
    return () => {
      unsubscribe();
    };
  }, [kaeplaAssignment, kaeplaUser?.uid, setKnownUsers]);

  return null;
};
