import { Paper, Box, CssBaseline } from '@mui/material';
import Favicon from 'react-favicon';
import { useRecoilValue } from 'recoil';

import { BrandedLogout } from './features/BrandedLogout';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState';
import { brandingState } from '../../../services/recoil/persistent/brandingState';
import { SWUpdater } from '../../features/ServiceWorkerUpdater';

export const Logout = () => {
  const app = useRecoilValue(applicationState);
  const branding = useRecoilValue(brandingState);

  return (
    <Box
      sx={{
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#EEEEEE',
      }}
    >
      <CssBaseline enableColorScheme />
      {branding?.appIconBase64 && <Favicon url={branding?.appIconBase64} />}
      <SWUpdater />
      <Paper
        data-testid="login-BrandedLoginPaper"
        sx={{ padding: 5, minWidth: 350, maxWidth: 400 }}
      >
        {!app.connecting && branding?.appName && <BrandedLogout branding={branding} />}
      </Paper>
    </Box>
  );
};
