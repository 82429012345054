/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Grid } from '@mui/material';
import { clone } from 'rambda';
import { Dispatch, SetStateAction, SyntheticEvent, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { perspectiveState } from 'src/services/recoil/nonpersistent/perspectiveState';
import { KaeplaSimulationParameterNumeric } from 'src/services/types/Application/KaeplaSimulationParameterNumeric';

import { KaeplaSlider } from './KaeplaSlider';
import { AbsoluteSimulationValueDialog } from './_features/AbsoluteSimulationValueDialog';
import { TimeSliceValueAndLabel } from './_features/TimeSliceValueAndLabel';
import { calculatePercentage } from './_helpers/calculatePercentage';
import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState';
import { timeSeriesState } from '../../../../services/recoil/nonpersistent/timeSeriesState';
import { KaeplaSimulation } from '../../../../services/types/Application/KaeplaSimulation';
import { KaeplaSimulationParameter } from '../../../../services/types/Application/KaeplaSimulationParameter';
import { KaeplaSimulationPercentage } from '../../../../services/types/Application/KaeplaSimulationPercentage';
import { KaeplaSimulationRulesetWithParameters } from '../../../../services/types/Application/KaeplaSimulationRulesetWithParameters';
import { MatrixTimeSlice } from '../../../../services/types/MatrixTimeSlice';
import { simulationDataColor } from '../../defaults';

function valuetext(value: number) {
  return `${value}%`;
}

interface Options {
  year: number;
  parameter: KaeplaSimulationParameter;
  equalizerHeight: number;
  simulation: KaeplaSimulation;
  ruleset: KaeplaSimulationRulesetWithParameters;
  allowNegativePercentage: boolean;
  simulationRulesets: KaeplaSimulationRulesetWithParameters[];
  setSimulationRulesets: Dispatch<
    SetStateAction<KaeplaSimulationRulesetWithParameters[] | undefined>
  >;
  setPreview: Dispatch<SetStateAction<boolean>>;
  own: boolean;
}

export const ParameterYear = ({
  year,
  parameter,
  ruleset,
  equalizerHeight,
  simulation,
  allowNegativePercentage,
  simulationRulesets,
  setSimulationRulesets,
  setPreview,
  own,
}: Options) => {
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const parameterDimension = matrixFiltered?.dimensions?.dimensions.find(
    (d) => d.columnName === parameter.dimension,
  );
  const timeSeries = useRecoilValue(timeSeriesState);
  const perspective = useRecoilValue(perspectiveState);
  const [percentage, setPercentage] = useState<number>(0);
  const [parameterValue, setParameterValue] = useState<number>(0);
  const [simulationValue, setSimulationValue] = useState<number>(0);
  const [absoluteSimulationValue, setAbsoluteSimulationValue] = useState<number>(0);
  const [isAbsolute, setIsAbsolute] = useState(false);
  const [isPrecalculated, setIsPrecalculated] = useState(false);
  const [open, setOpen] = useState(false);

  const handlePopoverOpen = () => {
    if (!own) return;
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  const saveAbsoluteSimulationValue = () => {
    if (!own) return;
    if (!parameterValue) return;
    const newPercentage = Math.ceil(
      calculatePercentage(parameterValue, absoluteSimulationValue) * 100,
    );
    saveParameterChange(newPercentage, true);
    setOpen(false);
  };

  useEffect(() => {
    if (!parameterDimension?.columnName) {
      // TODO: show error
      return;
    }

    if (simulation.isBeingSimulated) {
      setIsPrecalculated(false);
      return;
    }

    const _valuesForTimeSlot = timeSeries.timeseries.find(
      (series) => series.date === `${year}-01-01`,
    );

    let lookupDimension = parameterDimension.columnName;
    let lookupSimulationDimension = `${parameterDimension.columnName}__Simulated`;
    if (parameterDimension.columnName === perspective.valueDimension) {
      lookupDimension = 'value';
      lookupSimulationDimension = 'simulationValue';
    }

    let _parameterValue = 0;
    if (_valuesForTimeSlot?.[lookupDimension]) {
      _parameterValue = _valuesForTimeSlot[lookupDimension] as number;
      setParameterValue(_parameterValue);
      if (!isPrecalculated) {
        setAbsoluteSimulationValue(_parameterValue);
      }
    }

    let _percentage = 0;
    let _simulationValue = _parameterValue;
    const _parameter = [...ruleset.parameters].find(
      (p) => p.dimension === parameter.dimension,
    ) as KaeplaSimulationParameterNumeric;
    if (_parameter && _parameter?.percentages) {
      const parameterOfYear = clone(_parameter.percentages).find((_p) => _p.key === `${year}`);
      if (parameterOfYear) {
        _percentage = parameterOfYear.percentage || 0;
        if (parameterOfYear.absoluteValue) {
          setAbsoluteSimulationValue(parameterOfYear.absoluteValue);
          setIsAbsolute(true);
          _simulationValue = _parameterValue + _parameterValue * (_percentage / 100);
        } else {
          const simulationParameterName = lookupSimulationDimension;
          if (_valuesForTimeSlot?.[simulationParameterName]) {
            _simulationValue = _valuesForTimeSlot?.[simulationParameterName] as number;
            setAbsoluteSimulationValue(_simulationValue || 0);
          }
          setIsAbsolute(false);
        }
      }
    }

    setSimulationValue(_simulationValue);
    setPercentage(_percentage || 0);
  }, [
    simulation,
    parameter,
    year,
    timeSeries,
    ruleset.parameters,
    isPrecalculated,
    parameterDimension?.columnName,
    perspective.valueDimension,
  ]);

  const changeParameter = (
    _event: Event | SyntheticEvent<Element, Event>,
    newValue: number | number[],
  ) => {
    setPercentage(newValue as number);
  };

  const saveParameterChange = (newValue: number | number[], valueIsAbsolute: boolean) => {
    // const newSimulation: KaeplaSimulation = { ...simulation };
    const newRulesets = [...simulationRulesets].map((r) => {
      if (r.id === ruleset.id) {
        const newRuleset = { ...ruleset };
        const _parameter = [...newRuleset.parameters].find(
          (p) => p.dimension === parameter.dimension,
        );

        if (!_parameter) return r;
        const newParameter = { ..._parameter } as KaeplaSimulationParameterNumeric;

        let newPercentages: KaeplaSimulationPercentage[] = [];

        if (newParameter?.percentages) {
          newPercentages = [...newParameter.percentages].filter(
            (_percentage) => _percentage.key !== `${year}`,
          );
        }

        const newPercentage: KaeplaSimulationPercentage = {
          key: `${year}`,
          timeSlice: 'YEAR' as MatrixTimeSlice,
          dateFrom: `${year}-01-01`,
          dateUntil: `${year}-12-31`,
          percentage: newValue as number,
          originalValue: parameterValue,
          currency: parameterDimension?.currency,
          decimalPositions: parameterDimension?.decimalPositions,
        };

        if (
          valueIsAbsolute &&
          absoluteSimulationValue &&
          absoluteSimulationValue !== simulationValue
        ) {
          newPercentage.absoluteValue = absoluteSimulationValue;
        } else {
          delete newPercentage.absoluteValue;
        }

        newPercentages.push(newPercentage);
        newParameter.percentages = newPercentages;

        const newParameters = newRuleset.parameters.filter(
          (p) => p.dimension !== parameter.dimension,
        );
        newParameters.push(newParameter);
        newRuleset.parameters = newParameters.sort((a, b) =>
          a.dimension.localeCompare(b.dimension),
        );
        return newRuleset;
      }
      return r;
    });

    // newSimulation.rulesets = newRulesets;
    setSimulationRulesets(newRulesets);
    setPreview(true);
  };

  return (
    <Grid container spacing={0} sx={{ border: '0px solid green' }}>
      <Grid item container justifyContent="flex-end" xs={12}>
        <Box
          sx={{
            height: `${equalizerHeight - 150}px`,
            pb: 2,
            border: '0px solid red',
          }}
        >
          <KaeplaSlider
            aria-label="Percentage"
            orientation="vertical"
            min={allowNegativePercentage ? -100 : 0}
            value={percentage}
            valueLabelFormat={valuetext}
            valueLabelDisplay="on"
            onChange={changeParameter}
            onChangeCommitted={(_event, value) => {
              saveParameterChange(value, false);
              const sliderValue = value as number;
              setIsAbsolute(false);

              // precalculate simulation value
              if (parameterValue) {
                const newSimulationValue = parameterValue + (parameterValue / 100) * sliderValue;
                setSimulationValue(newSimulationValue);
                setAbsoluteSimulationValue(newSimulationValue || 0);
                setIsPrecalculated(true);
              }
            }}
            disabled={!!simulation.isBeingSimulated || !own}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ border: '0px solid red', textAlign: 'center' }}>{year}</Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            textAlign: 'center',
          }}
        >
          <TimeSliceValueAndLabel valueDimension={parameterDimension} value={parameterValue} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            border: `0px solid ${simulationDataColor}`,
            borderBottomWidth: isAbsolute ? '1px' : '0px',
            textAlign: 'center',
          }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onClick={handlePopoverOpen}
        >
          <TimeSliceValueAndLabel
            valueDimension={parameterDimension}
            value={isAbsolute ? absoluteSimulationValue : simulationValue}
            simulation
            editable={own}
            isPrecalculated={isPrecalculated}
            isAbsolute={isAbsolute}
          />
        </Box>
      </Grid>
      {parameterDimension && own && (
        <AbsoluteSimulationValueDialog
          open={open}
          absoluteSimulationValue={absoluteSimulationValue}
          setAbsoluteSimulationValue={setAbsoluteSimulationValue}
          saveAbsoluteSimulationValue={saveAbsoluteSimulationValue}
          handlePopoverClose={handlePopoverClose}
          year={year}
          valueDimension={parameterDimension}
          parameterValue={parameterValue}
          simulationValue={simulationValue}
        />
      )}
    </Grid>
  );
};
