import { updateProject } from './updateProject';
import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  project: KaeplaProject;
  uid: string;
}

export const takeOverProject = async ({ project, uid }: Options) => {
  if (!uid) return;
  const update = { ...project, createdBy: uid };
  await updateProject({ project: update });
};
