import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Typography,
  Box,
  InputLabel,
} from '@mui/material';
import { clone } from 'rambda';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { useAuth } from '../../../../../../AuthProvider';
import { applicationState } from '../../../../../../services/recoil/nonpersistent/applicationState';
import { knownProjectFiltersState } from '../../../../../../services/recoil/nonpersistent/knownProjectFiltersState';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../../../../services/recoil/persistent/currentScopePathState';
import { filterSettingsState } from '../../../../../../services/recoil/persistent/filterSettingState';
import { filterTreeState } from '../../../../../../services/recoil/persistent/filterTreeState';
import { KaeplaFilter } from '../../../../../../services/types/Application/KaeplaFilter';
import { SelectAvatar } from '../../../../../features/SelectAvatar';

interface Options {
  disabled?: boolean;
}

export const SelectFilter = ({ disabled }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const knownProjectFilters = useRecoilValue(knownProjectFiltersState);
  const resetFilterTree = useResetRecoilState(filterTreeState);
  const resetFilterSettings = useResetRecoilState(filterSettingsState);
  const [filterTree, setFilterTree] = useRecoilState(filterTreeState);
  const [filterSettings, setFilterSettings] = useRecoilState(filterSettingsState);
  const [application, setApplication] = useRecoilState(applicationState);
  const [filters, setFilters] = useState<KaeplaFilter[]>();
  const [selectedFilter, setSelectedFilter] = useState<string>();

  useEffect(() => {
    if (!kaeplaUser) return;
    const loadFilters = () => {
      const filterFromState = knownProjectFilters?.find(
        (_filter) => _filter.id === filterSettings.filterPresetId,
      );

      if (filterFromState) {
        setSelectedFilter(filterFromState.id);
      } else {
        // eslint-disable-next-line unicorn/no-useless-undefined
        setSelectedFilter(undefined);
      }
      setFilters(knownProjectFilters);
    };
    void loadFilters();
  }, [
    kaeplaUser?.uid,
    project.id,
    currentScopePath,
    kaeplaUser,
    project,
    resetFilterTree,
    filterSettings.filterPresetId,
    filterSettings.updatedAt,
    resetFilterSettings,
    knownProjectFilters,
  ]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedFilter(event.target.value);
    const chosenFilter = clone(filters)?.find((_filter) => _filter.id === event.target.value);
    if (chosenFilter) {
      chosenFilter.id = event.target.value;
      if (filterTree.id !== chosenFilter.filterTree.id) {
        setFilterTree(chosenFilter.filterTree);
      }
      setFilterSettings({
        ...filterSettings,
        filterPresetId: chosenFilter.id,
        isActive: true,
        filterCreatedBy: chosenFilter.createdBy,
      });
    } else {
      resetFilterTree();
      resetFilterSettings();
      setFilterSettings({
        ...filterSettings,
        filterPresetId: undefined,
        filterCreatedBy: undefined,
        isActive: false,
      });
      const newApplication = clone(application);
      newApplication.showFilter = false;
      setApplication(newApplication);
    }
  };

  if (filters?.length === 0) {
    return (
      <Typography sx={{ fontSize: '100%', opacity: disabled ? 0.5 : 1 }} color="text.secondary">
        {disabled ? 'filters are disabled' : 'no filters yet'}
      </Typography>
    );
  }

  return (
    <FormControl
      fullWidth
      sx={{ mt: 0, p: 0, minWidth: 160 }}
      size="small"
      margin="dense"
      variant="standard"
    >
      <InputLabel size="small" id="filter-label">
        Filter
      </InputLabel>
      {filters && (
        <Select
          id="filterSelect"
          value={selectedFilter || ''}
          label="Select a Filter"
          onChange={handleChange}
          size="small"
          SelectDisplayProps={{ style: { marginTop: 0, padding: 0, paddingRight: 16 } }}
          disableUnderline
          autoFocus={false}
          disabled={disabled}
        >
          <MenuItem dense value="">
            <em>No Filter</em>
          </MenuItem>
          {filters?.map((_filter) => (
            <MenuItem dense key={_filter.id} value={_filter.id} sx={{ whiteSpace: 'nowrap' }}>
              <Box component="span" mr={1}>
                <SelectAvatar uid={_filter.createdBy} />
              </Box>
              {_filter.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
