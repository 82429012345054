import { Grid, Button, TextField, Alert } from '@mui/material';
import { MultiFactorInfo } from 'firebase/auth';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

import { completeNewFactorEnrollment } from './completeNewFactorEnrollment';
import { useAuth } from '../../../../AuthProvider';

interface Options {
  setAddFactor: Dispatch<SetStateAction<boolean>>;
  setEnrolledFactors: Dispatch<SetStateAction<MultiFactorInfo[]>>;
  setVerificationCodeRequired: Dispatch<SetStateAction<boolean>>;
  setEditable: Dispatch<SetStateAction<boolean>>;
  verificationId: string;
}

export const VerifyNewFactorWithCode = ({
  setAddFactor,
  setEditable,
  setEnrolledFactors,
  setVerificationCodeRequired,
  verificationId,
}: Options) => {
  const { user, kaeplaUser, setUser } = useAuth();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [verificationError, setVerificationError] = useState<string>('');
  if (!user || !kaeplaUser) return null;

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Verification Code"
            variant="standard"
            value={verificationCode}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setVerificationCode(event.target.value);
            }}
            inputProps={{
              'data-testid': 'mfa-verification-code',
              autoComplete: 'off',
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            data-testid="mfa-button-cancel"
            onClick={() => {
              setEditable(false);
            }}
          >
            cancel
          </Button>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <Button
            variant="contained"
            data-testid="mfa-button-verify"
            onClick={() => {
              void completeNewFactorEnrollment({
                kaeplaUser,
                setAddFactor,
                setEnrolledFactors,
                setUser,
                setVerificationCodeRequired,
                setVerificationError,
                user,
                verificationCode,
                verificationId,
                setEditable,
              });
            }}
          >
            verify
          </Button>
        </Grid>
        {verificationError !== '' && (
          <Grid item xs={12}>
            <Alert data-testid="mfa-error" severity="error">
              {verificationError}
            </Alert>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
