import { getColumns } from './getColumns';
import { KaeplaProject } from '../../../../services/types/Application/KaeplaProject';
import { KaeplaSimulation } from '../../../../services/types/Application/KaeplaSimulation';
import { MatrixData } from '../../../../services/types/MatrixData';

export const getColumnsAndRows = (
  records: MatrixData,
  project: KaeplaProject,
  simulation?: Partial<KaeplaSimulation>,
) => {
  let _data: Record<string, unknown>[] = [{ na: 'n/a' }];
  if (records?.rows) {
    _data = records.rows;
  }

  return {
    columns: getColumns(project, records.dimensions, simulation),
    rows: _data,
  };
};
