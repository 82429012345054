import { Grid } from '@mui/material';

import { PerspectiveBlock } from './features/PerspectiveBlock';
import { getGridSpanNumber } from './helpers/getGridSpanNumber';
import { KaeplaPerspectiveBlock } from '../../../../../services/types/Application/KaeplaPerspectiveBlock';

interface Options {
  blocks: KaeplaPerspectiveBlock[];
  locked?: boolean;
}

export const PerspectiveBlocks = ({ blocks, locked }: Options) => {
  return (
    <>
      {blocks.map((block, index) => (
        <Grid
          key={`${block.type}${index}`}
          item
          xs={getGridSpanNumber('xs', locked ? block.gridSpan : 'large')}
          sm={getGridSpanNumber('sm', locked ? block.gridSpan : 'large')}
          md={getGridSpanNumber('md', locked ? block.gridSpan : 'large')}
          lg={getGridSpanNumber('lg', locked ? block.gridSpan : 'large')}
          xl={getGridSpanNumber('xl', locked ? block.gridSpan : 'medium')}
        >
          <PerspectiveBlock block={block} blockNumber={index} locked={locked} />
        </Grid>
      ))}
    </>
  );
};
