import { FirebaseError } from 'firebase/app';
import { multiFactor, PhoneAuthProvider, RecaptchaVerifier, User } from 'firebase/auth';
import { Dispatch, SetStateAction } from 'react';

import { kaeplaAuth } from '../../../../firebaseInit';
import { mfaLog } from '../../../helpers/logger';

interface Options {
  user: User | null;
  recaptchaVerifier: RecaptchaVerifier | undefined;
  phoneNumber: string;
  setPasswordRequired: Dispatch<SetStateAction<boolean>>;
  setVerificationCodeRequired: Dispatch<SetStateAction<boolean>>;
  setVerificationId: Dispatch<SetStateAction<string>>;
}

export const getVerificationCode = async ({
  recaptchaVerifier,
  user,
  phoneNumber,
  setPasswordRequired,
  setVerificationCodeRequired,
  setVerificationId,
}: Options) => {
  if (!recaptchaVerifier || !user?.email) return null;

  mfaLog.log('getVerificationCode?');
  const mfaSession = await multiFactor(user).getSession();
  mfaLog.log('mfaSession', mfaSession);

  // Specify the phone number and pass the MFA session.
  const phoneInfoOptions = {
    phoneNumber,
    session: mfaSession,
  };

  mfaLog.log('phoneInfoOptions', phoneInfoOptions);

  const phoneAuthProvider = new PhoneAuthProvider(kaeplaAuth);
  // Send SMS verification code.
  const verificationIdFromAuthProvider = await phoneAuthProvider
    .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
    .catch((error: FirebaseError) => {
      mfaLog.log('ERROR', error);
      if (error.code === 'auth/requires-recent-login') {
        setPasswordRequired(true);
      }
    });
  if (!verificationIdFromAuthProvider) return;
  mfaLog.log('verificationIdFromAuthProvider', verificationIdFromAuthProvider);
  setVerificationId(verificationIdFromAuthProvider);
  setVerificationCodeRequired(true);
};
