import StarIcon from '@mui/icons-material/StarOutline';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  IconButton,
  Typography,
} from '@mui/material';
import pluralize from 'pluralize';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { useAuth } from '../../../../AuthProvider';
import { updateFilter } from '../../../../services/firestore/updateFilter';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { filterSettingsState } from '../../../../services/recoil/persistent/filterSettingState';
import { filterTreeState } from '../../../../services/recoil/persistent/filterTreeState';
import { knownUsersState } from '../../../../services/recoil/persistent/knownUsersState';
import { KaeplaFilter } from '../../../../services/types/Application/KaeplaFilter';
import { toDoLog } from '../../../helpers/logger';
import { InlineEdit } from '../../InlineEdit';
import { UserAvatar } from '../../UserAvatar';
import { getRuleCountFromFilterTree } from '../helpers/getRuleCountFromFilterTree';

interface Options {
  filter: KaeplaFilter;
  selected: boolean;
}

export const FilterPresetCard = ({ filter, selected }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const knownUsers = useRecoilValue(knownUsersState);
  const setFilterTree = useSetRecoilState(filterTreeState);
  const [filterSettings, setFilterSettings] = useRecoilState(filterSettingsState);
  const rulesCount = getRuleCountFromFilterTree(filter.filterTree);
  const user = knownUsers.find((k) => k.uid === filter.createdBy);
  return (
    <Card elevation={0} sx={{ border: '1px solid #ccc' }}>
      <CardContent sx={{ pb: 0 }}>
        <Typography component="div" noWrap sx={{ fontSize: '100%' }}>
          <InlineEdit
            fullWidth
            value={filter.name}
            callback={(newValue) => {
              const newFilterPreset = { ...filter };
              newFilterPreset.name = newValue;
              void updateFilter({ project, filter: newFilterPreset });
            }}
            readOnly={filter.createdBy !== kaeplaUser?.uid}
          />
        </Typography>
      </CardContent>
      <CardContent>
        <IconButton
          size="small"
          edge="start"
          sx={{ mr: 1, ml: -1 }}
          onClick={() => {
            toDoLog.log('set as favorite');
          }}
        >
          <StarIcon />
        </IconButton>
        {user && <UserAvatar sx={{ mr: 1 }} user={user} size={25} />}
        <Chip
          label={`${pluralize('rule', rulesCount, true)}`}
          variant="outlined"
          color="primary"
          size="small"
        />
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setFilterTree(filter.filterTree);
            setFilterSettings({
              ...filterSettings,
              filterPresetId: filter.id,
              isActive: true,
              filterCreatedBy: filter.createdBy,
            });
          }}
          disabled={selected}
        >
          choose
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setFilterTree(filter.filterTree);
            setFilterSettings({ ...filterSettings, filterPresetId: filter.id });
          }}
          disabled={filter.createdBy !== kaeplaUser?.uid}
        >
          delete
        </Button>
      </CardActions>
    </Card>
  );
};
