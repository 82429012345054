import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Grid, IconButton } from '@mui/material';
import { useState } from 'react';

import { CustomerDomain } from './CustomerDomain';
import { KaeplaCustomer } from '../../../../services/types/Application/KaeplaCustomer';

interface Options {
  customer: KaeplaCustomer;
}

export const CustomerDomains = ({ customer }: Options) => {
  const [open, setOpen] = useState(false);
  const allDomains = [
    ...(customer?.customerDomains || []),
    ...(customer?.resellerDomains || []),
  ].filter((v, index, a) => a.indexOf(v) === index);

  return (
    <Grid data-testid="customer-domains" container spacing={0}>
      <Grid item xs={10}>
        <CustomerDomain customer={customer} printAll={open} />
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end">
        {allDomains.length > 1 && (
          <Box sx={{ mr: -1 }}>
            <IconButton
              size="small"
              edge="end"
              data-testid="customer-domains-toggle"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
