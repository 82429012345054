import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { lastCustomerIDState } from 'src/services/recoil/persistent/lastCustomerState';

import { useAuth } from '../../AuthProvider';
import { applicationState } from '../../services/recoil/nonpersistent/applicationState';
import { knownCustomersState } from '../../services/recoil/nonpersistent/knownCustomersState';
import { knownProjectsState } from '../../services/recoil/nonpersistent/knownProjectsState';
import { projectState } from '../../services/recoil/nonpersistent/projectState';
import { projectsOverviewState } from '../../services/recoil/nonpersistent/projectsOverviewState';
import { selectedCustomerState } from '../../services/recoil/nonpersistent/selectedCustomer';
import { currentScopePathState } from '../../services/recoil/persistent/currentScopePathState';
import { lastProjectIdState } from '../../services/recoil/persistent/lastProjectState';
import { logger } from '../helpers/logger';

export const LastProjectAndCustomer = () => {
  const { kaeplaUser } = useAuth();
  const lastProject = useRecoilValue(lastProjectIdState);
  const lastCustomer = useRecoilValue(lastCustomerIDState);
  const knownProjects = useRecoilValue(knownProjectsState);
  const knownCustomers = useRecoilValue(knownCustomersState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const setProject = useSetRecoilState(projectState);
  const setSelectedCustomer = useSetRecoilState(selectedCustomerState);
  const setProjectsOverview = useSetRecoilState(projectsOverviewState);
  const [app, setApp] = useRecoilState(applicationState);

  // For non-admins, only when app is not initialized
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!app.projectsLoaded || !app.customersLoaded) return;
    if (app.initialized) return;

    let selectedCustomer: string | undefined;
    let projectsForOverview = knownProjects;

    logger.log('\nuseEffect LastAppSate');
    logger.log('LastAppSate currentScopePath', currentScopePath);

    logger.log(
      'LastAppSate knownProjects',
      knownProjects.map((k) => k.id),
    );

    logger.log(
      'LastAppSate knownCustomers',
      knownCustomers.map((k) => k.id),
    );

    if (knownProjects && knownProjects.length > 0) {
      // if we have known projects, we check if we still have the last we visited and set it
      const project = knownProjects.find((k) => k.id === lastProject);
      logger.log('LastAppSate setProject from lastProject', lastProject, project?.id);
      if (lastProject && project?.id) {
        selectedCustomer = project.customerId;
        setProject(project);
      } else {
        logger.log('LastAppSate setProject from knownProjects[0]', knownProjects[0]?.id);
        selectedCustomer = knownProjects[0].customerId;
        setProject(knownProjects[0]);
      }

      // this is only for users who have more than 1 customer assignments
      if (knownCustomers.length > 0) {
        const customer = knownCustomers.find((k) => k.id === selectedCustomer);
        logger.log(
          'LastAppSate setSelectedCustomer from lastProject',
          selectedCustomer,
          customer?.id,
        );
        if (selectedCustomer && customer?.id) {
          projectsForOverview = knownProjects.filter((k) => k.customerId === customer.id);
          selectedCustomer = customer.id;
        }
      }
    }

    // if we couldn't get the last customer from the last project
    // maybe we can use the local state
    if (!selectedCustomer && lastCustomer && knownCustomers && knownCustomers.length > 0) {
      const customer = knownCustomers.find((k) => k.id === lastCustomer);
      if (customer) {
        logger.log(
          'LastAppSate setSelectedCustomer from lastVisitedCustomerId',
          lastCustomer,
          customer?.id,
        );
        selectedCustomer = customer.id;
        projectsForOverview = knownProjects.filter((k) => k.customerId === customer.id);
      }
    }

    // this is for users who have a customer assignment but no project yet
    if (knownCustomers.length > 1 && !selectedCustomer) {
      selectedCustomer = knownCustomers[0].id;
      projectsForOverview = knownProjects.filter((k) => k.customerId === knownCustomers[0].id);
    }

    setSelectedCustomer(knownCustomers.find((k) => k.id === selectedCustomer));
    setProjectsOverview(projectsForOverview);
    setApp({ ...app, initialized: true });
  }, [
    app,
    kaeplaUser?.uid,
    knownProjects,
    knownCustomers,
    lastProject,
    setApp,
    setProject,
    setSelectedCustomer,
    setProjectsOverview,
    currentScopePath,
    lastCustomer,
  ]);

  return null;
};
