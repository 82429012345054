export enum KaeplaEventType {
  GENERAL_EVENT = 'Event',

  ASSIGNMENT_ENTER_ASSIGNMENTS_OVERVIEW = 'Enter Assignments Overview',
  ASSIGNMENT_SELECT_ASSIGNMENT = 'Select an Assignment',

  CUSTOMERS_ENTER_CUSTOMERS_OVERVIEW = 'Enter Customers Overview',

  TARGETS_ENTER_TARGETS_OVERVIEW = 'Enter Targets Overview',
  TARGETS_CREATE_TARGETS = 'Create Targets',
  TARGETS_CREATE_TARGETS_FROM_SIMULATION = 'Create Targets from Simulation',
  TARGETS_DELETE_TARGETS = 'Delete Targets',
  TARGETS_SELECT_TARGETS = 'Select Targets',

  PERSPECTIVES_VIEW_PERSPECTIVE = 'View Perspective',
  PERSPECTIVES_ENTER_SIMULATIONS_OVERVIEW = 'Enter Perspectives Overview',
  PERSPECTIVES_CREATE_PERSPECTIVE = 'Create a Perspective',
  PERSPECTIVES_SELECT_PERSPECTIVE = 'Select a Perspective',
  PERSPECTIVES_DELETE_PERSPECTIVE = 'Delete a Perspective',

  PROJECT_SELECT_PROJECT = 'Select project',
  PROJECT_TEAM_LEAVE_TEAM = 'Leave project team',
  PROJECT_TEAM_REMOVE_USER_FROM_TEAM = 'Remove user from project team',
  PROJECT_TEAM_JOIN_TEAM = 'Join project team',
  PROJECT_TEAM_INVITE_USER_TO_TEAM = 'Invite user to project team',
  PROJECT_ENTER_PROJECT_OVERVIEW = 'Enter Projects Overview',

  CUSTOMER_SELECT_PROJECT = 'Select customer',
  CUSTOMER_TEAM_LEAVE_TEAM = 'Leave customer team',
  CUSTOMER_TEAM_REMOVE_USER_FROM_TEAM = 'Remove user from customer team',
  CUSTOMER_TEAM_JOIN_TEAM = 'Join customer team',
  CUSTOMER_TEAM_INVITE_USER_TO_TEAM = 'Invite user to customer team',

  RESELLER_SELECT_PROJECT = 'Select reseller',
  RESELLER_TEAM_LEAVE_TEAM = 'Leave reseller team',
  RESELLER_TEAM_REMOVE_USER_FROM_TEAM = 'Remove user from reseller team',
  RESELLER_TEAM_JOIN_TEAM = 'Join reseller team',
  RESELLER_TEAM_INVITE_USER_TO_TEAM = 'Invite user to reseller team',
  RESELLERS_ENTER_RESELLERS_OVERVIEW = 'Enter Resellers Overview',

  SCOPES_ENTER_SCOPES_OVERVIEW = 'Enter Scopes Overview',
  SCOPES_REQUEST_PATH_INFO = 'Request scope path information',

  SIMULATIONS_ENTER_SIMULATIONS_OVERVIEW = 'Enter Simulations Overview',
}
