import ClearDimensionIcon from '@mui/icons-material/LayersClear';
import ScopesIcon from '@mui/icons-material/LayersOutlined';
import { Box, FormControl, Input, MenuItem, Select, Tooltip } from '@mui/material';

import { cleanColumnName } from '../../helpers/cleanColumnName';
import { scopePathElementShortenLevel } from '../defaults';
import { truncateMiddle } from '../helpers/truncateMiddle';

type CallBack = (index: number, value: string) => void;
interface PathElementOptions {
  placeholder: string;
  level: string;
  index: number;
  items: string[];
  activeItems?: string[];
  truncate: number;
  otherDimensions?: string[];
  disabled?: boolean;
  parentIsEnabled?: boolean;
  scopePathLength: number;
  callBack?: CallBack;
}
export const PathElement = ({
  placeholder,
  level,
  index,
  items,
  activeItems,
  truncate,
  otherDimensions,
  disabled,
  parentIsEnabled,
  scopePathLength,
  callBack,
}: PathElementOptions) => {
  let selectedValue = level;
  if (level.includes(':')) {
    selectedValue = level.split(':')[1];
  }

  let placeholderToggle = <em>{`Select ${placeholder}`}</em>;
  if (selectedValue !== '') {
    placeholderToggle = (
      <>
        <ClearDimensionIcon fontSize="small" sx={{ mr: 1, opacity: 0.3 }} />
        Clear&nbsp;<b>{placeholder}</b>
      </>
    );
  }

  return (
    <FormControl
      sx={{ minWidth: 50 }}
      key={level}
      variant="standard"
      margin="dense"
      size="small"
      disabled={disabled}
    >
      <Select
        size="small"
        disabled={disabled}
        sx={{
          pointerEvents: disabled ? 'none' : 'default',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        value={selectedValue}
        displayEmpty
        onChange={(event) => {
          if (callBack !== undefined) {
            callBack(index, event.target.value);
          }
        }}
        SelectDisplayProps={{ style: { paddingRight: 0 } }}
        input={<Input disableUnderline margin="dense" />}
        IconComponent={() => null}
        renderValue={(selected) => {
          if (!selected) {
            return (
              <MenuItem component="div" dense sx={{ p: 0 }}>
                {placeholderToggle}
              </MenuItem>
            );
          }
          return (
            <MenuItem component="div" dense sx={{ p: 0 }}>
              <Tooltip title={placeholder}>
                <Box
                  component="span"
                  sx={{
                    opacity: 0.5,
                    display: scopePathLength >= scopePathElementShortenLevel ? 'none' : 'inherit',
                    fontSize: 'inherit',
                  }}
                >
                  {truncateMiddle(placeholder, truncate)}:&nbsp;
                </Box>
              </Tooltip>
              {truncateMiddle(selected, truncate)}
            </MenuItem>
          );
        }}
      >
        {otherDimensions &&
          otherDimensions.map((item) => (
            <MenuItem value={`changeDimension!!${item}`} key={item} dense>
              <ScopesIcon fontSize="small" sx={{ mr: 1, opacity: 0.3, fontSize: 'inherit' }} />
              Change to&nbsp;<b>{cleanColumnName(item)}</b>
            </MenuItem>
          ))}
        <MenuItem value="" dense>
          {placeholderToggle}
        </MenuItem>
        {/* until items are loaded */}
        {items.length === 0 && (
          <MenuItem value={selectedValue} key={selectedValue} dense>
            <ScopesIcon fontSize="small" sx={{ mr: 1, opacity: 0.5, fontSize: 'inherit' }} />
            {cleanColumnName(selectedValue)}
          </MenuItem>
        )}
        {/* now items are loaded */}
        {items.map((item) => (
          <MenuItem
            value={item}
            key={item}
            dense
            disabled={!activeItems?.includes(item) && !parentIsEnabled}
          >
            <ScopesIcon fontSize="small" sx={{ mr: 1, opacity: 0.5, fontSize: 'inherit' }} />
            {cleanColumnName(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
