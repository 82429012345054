import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  id: string;
}

export const getProject = async ({ id }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  const document = await getDoc(firestoreDocumentReference);
  return document.data() as KaeplaProject;
};
