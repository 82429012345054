import { atom } from 'recoil';

import { KaeplaModel } from '../../types/Application/KaeplaModel';

const defaultModelState: KaeplaModel = {
  delegation: {} as Record<string, unknown>,
};

export const modelState = atom<KaeplaModel>({
  key: 'model',
  default: defaultModelState,
});
