import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { EventGroup } from './EventGroup';
import { EventUser } from './EventUser';
import { groupEvents } from './groupEvents';
import { knownUsersState } from '../../../services/recoil/persistent/knownUsersState';
import { KaeplaEvent } from '../../../services/types/Application/KaeplaEvent';

interface Options {
  events: KaeplaEvent[];
  fetchEvents: () => Promise<void>;
  hasMore: boolean;
}

export interface KaeplaEventGroup {
  uid: string;
  events: KaeplaEvent[];
  lastEvent: KaeplaEvent;
}

export const EventGroups = ({ events, fetchEvents, hasMore }: Options) => {
  const knownUsers = useRecoilValue(knownUsersState);
  const [eventGroups, setEventGroups] = useState<Record<string, KaeplaEventGroup>>({});

  useEffect(() => {
    const _eventGroups = groupEvents(events);
    setEventGroups(_eventGroups);
    // auto-load more events, until we reach the scroll-bottom
    if (hasMore && Object.keys(_eventGroups).length < 12) {
      void fetchEvents();
    }
  }, [events, fetchEvents, hasMore]);

  return (
    <Box>
      {Object.entries(eventGroups).map(([key, group]) => (
        <Box key={key}>
          <TimelineItem>
            <TimelineOppositeContent color="textSecondary">
              <EventUser uid={group.uid} knownUsers={knownUsers} />
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <EventGroup group={group} />
          </TimelineItem>
        </Box>
      ))}
    </Box>
  );
};
