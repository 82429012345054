import { Box, Grid } from '@mui/material';

import { ChartLabel } from './ChartLabel';
import { KaeplaPerspectiveBlock } from '../../../../../../services/types/Application/KaeplaPerspectiveBlock';
import { KaeplaSimulation } from '../../../../../../services/types/Application/KaeplaSimulation';
import { matrixDataColor, simulationDataColor } from '../../../../defaults';
import { currentDataColorShift, simulationDataColorShift } from '../helpers/chartColors';
import { KaeplaChartLabel } from '../helpers/createChartLabels';

interface Options {
  block: KaeplaPerspectiveBlock;
  chartLabels: KaeplaChartLabel[];
  simulation?: Partial<KaeplaSimulation>;
  toggleLabel: (label: KaeplaChartLabel) => void;
}

export const ChartLabels = ({ block, chartLabels, simulation, toggleLabel }: Options) => {
  let labelSpan = 3;
  switch (block.gridSpan) {
    case 'large': {
      labelSpan = 2;

      break;
    }
    case 'medium': {
      labelSpan = 6;

      break;
    }
    case 'small': {
      labelSpan = 4;

      break;
    }
    // No default
  }
  return (
    <>
      <Box fontSize={12} pt={1}>
        {simulation && (
          <ChartLabel
            label="Original Data"
            color="transparent"
            borderColor={currentDataColorShift(matrixDataColor)}
            type="square"
            active
          />
        )}
        <Grid container>
          {chartLabels.map((l) => (
            <Grid key={l.datasetName} item xs={labelSpan}>
              <ChartLabel
                label={l.label}
                color={currentDataColorShift(l.color)}
                borderColor={matrixDataColor}
                onClick={() => toggleLabel(l)}
                active={!l.isToggled}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      {simulation && (
        <Box fontSize={12} pt={2}>
          <ChartLabel
            label={`Simulation (${simulation.name || 'n/a'})`}
            color="transparent"
            borderColor={simulationDataColorShift(simulationDataColor)}
            type="square"
            active
          />
          <Grid container>
            {chartLabels.map((l) => (
              <Grid key={l.datasetName} item xs={labelSpan}>
                <ChartLabel
                  label={l.label}
                  color={simulationDataColorShift(l.color)}
                  borderColor={simulationDataColorShift(simulationDataColor)}
                  onClick={() => toggleLabel(l)}
                  active={!l.isToggled}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};
