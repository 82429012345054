import { getApp } from 'firebase/app';
import { collection, getDocs, getFirestore, limit, query } from 'firebase/firestore';

import { KaeplaPerspective } from '../types/Application/KaeplaPerspective';
import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  project: KaeplaProject;
}

export const getPerspectivesForProject = async ({ project }: Options) => {
  const data: KaeplaPerspective[] = [];
  if (!project) return data;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `projects/${project.id}/perspectives`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const firestoreQuery = query(firestoreCollection, limit(50));
  const snapshot = await getDocs(firestoreQuery);
  snapshot.forEach((document_) => {
    const perspective = document_.data();
    perspective.id = document_.id;
    data.push(perspective as KaeplaPerspective);
  });
  return data;
};
