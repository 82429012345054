import { FirebaseError } from 'firebase/app';
import {
  Auth,
  UserCredential,
  User,
  signInWithEmailAndPassword,
  signOut,
  signInWithEmailLink,
  isSignInWithEmailLink,
  getMultiFactorResolver,
  MultiFactorResolver,
  AuthError,
  MultiFactorError,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from 'firebase/auth';
import localforage from 'localforage';
import {
  ReactNode,
  useEffect,
  useState,
  useContext,
  createContext,
  Dispatch,
  SetStateAction,
} from 'react';

import { getNamesFromEmail } from './Frontend/helpers/getNamesFromEmail';
import { mfaLog } from './Frontend/helpers/logger';
import { stringToColor } from './Frontend/helpers/stringToColor';
import { kaeplaAuth, watcherServiceKaepla } from './firebaseInit';
import { requestSignInLink } from './services/api/requestSignInLink';
import { createOrUpdateUser } from './services/firestore/createOrUpdateUser';
import { getUser } from './services/firestore/getUser';
import { KaeplaUser } from './services/types/Application/KaeplaUser';

export interface AuthProviderProperties {
  children?: ReactNode;
}

export interface AuthContextModel {
  auth: Auth;
  authError: FirebaseError | null;
  authState: boolean | null;
  clearAuthError: () => void;
  emailForSecondFactor: string;
  kaeplaUser: KaeplaUser | null;
  logOut: () => Promise<void>;
  multiFactorResolver: MultiFactorResolver | undefined;
  reAuthenticateWithPassword: (
    password: string,
    callback?: (isReauthenticated: boolean) => void,
  ) => Promise<void>;
  secondFactorRequired: boolean;
  setSecondFactorRequired: Dispatch<SetStateAction<boolean>>;
  sendEmailLink: (email: string) => Promise<void>;
  setKaeplaUser: Dispatch<SetStateAction<KaeplaUser | null>>;
  setUser: Dispatch<SetStateAction<User | null>>;
  signIn: (email: string, password: string) => Promise<UserCredential | void>;
  signInWithLink: () => Promise<UserCredential | void>;
  user: User | null;
}

export const AuthContext = createContext<AuthContextModel>({} as AuthContextModel);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

const getKaeplaUserFromFbUser = (userFromFB: KaeplaUser, firebaseUser: User) => {
  let firstName = '';
  let lastName = '';
  let displayName =
    userFromFB?.displayName || firebaseUser?.displayName || firebaseUser?.email || 'n/a';
  let acronym = '';
  if (firebaseUser?.email) {
    const namesFromEmail = getNamesFromEmail(firebaseUser.email);
    firstName = namesFromEmail.firstName;
    lastName = namesFromEmail.lastName;
    displayName = namesFromEmail.displayName;
    acronym = namesFromEmail.acronym;
  }

  if (!userFromFB?.displayName) {
    displayName = `${firstName} ${lastName}`;
    acronym = `${displayName.split(' ')[0][0]}${displayName.split(' ')[1][0]}`;
  }
  if (displayName) {
    const nameParts = displayName.split(' ');
    if (nameParts.length === 2) {
      acronym = `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1].charAt(0).toUpperCase()}`;
    }
    // console.log('2 displayName', displayName);
    // console.log('2 acronym', acronym);
    // console.log('2 color', stringToColor(`Ä${displayName}`));
  }
  const _kaeplaUser: KaeplaUser = {
    ...userFromFB,
    uid: firebaseUser.uid,
    displayName,
    acronym,
    color: stringToColor(`Ä${displayName}`),
    email: firebaseUser.email || 'n/a',
  };

  return _kaeplaUser;
};

export const AuthProvider = ({ children }: AuthProviderProperties): JSX.Element => {
  const [user, setUser] = useState<User | null>(null);
  const [kaeplaUser, setKaeplaUser] = useState<KaeplaUser | null>(null);
  const [authState, setAuthState] = useState<boolean | null>(null);
  const [authError, setAuthError] = useState<FirebaseError | null>(null);
  const [secondFactorRequired, setSecondFactorRequired] = useState<boolean>(false);
  const [emailForSecondFactor, setEmailForSecondFactor] = useState<string>('');
  const [multiFactorResolver, setMultiFactorResolver] = useState<MultiFactorResolver>();

  const clearAuthError = () => {
    setAuthError(null);
  };

  const signIn = async (email: string, password: string): Promise<UserCredential | void> => {
    setAuthError(null);
    return signInWithEmailAndPassword(kaeplaAuth, email, password).catch((error: AuthError) => {
      if (error.code === 'auth/multi-factor-auth-required') {
        setSecondFactorRequired(true);
        setEmailForSecondFactor(email);
        const resolver = getMultiFactorResolver(kaeplaAuth, error as MultiFactorError);
        setMultiFactorResolver(resolver);
        return;
      }
      setAuthError(error);
    });
  };

  const reAuthenticateWithPassword = async (
    password: string,
    callback?: (isAuthenticated: boolean) => void,
  ) => {
    if (!user?.email) return;
    const credential = EmailAuthProvider.credential(user.email, password);
    mfaLog.log('re-authenticate with password');
    await reauthenticateWithCredential(user, credential)
      .then(() => {
        mfaLog.log('no need for a second second factor');
        if (callback) callback(true);
      })
      .catch((error: AuthError) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          mfaLog.log('we need second factor', error.code);
          setSecondFactorRequired(true);
          const resolver = getMultiFactorResolver(kaeplaAuth, error as MultiFactorError);
          setMultiFactorResolver(resolver);
          return;
        }
        mfaLog.log('we have a problem', error.code);
        if (callback) callback(false);
      });

    mfaLog.log('reauthenticateWithCredential');
    await user.reload();
  };

  const signInWithLink = async () => {
    setAuthError(null);
    const email: string | null = await localforage.getItem('emailForSignIn');
    if (isSignInWithEmailLink(kaeplaAuth, window.location.href) && email) {
      await localforage.removeItem('emailForSignIn');
      return signInWithEmailLink(kaeplaAuth, email, window.location.href).catch(
        (error: FirebaseError) => {
          setAuthError(error);
        },
      );
    }
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again.
    return;
  };

  const sendEmailLink = async (email: string) => {
    setAuthError(null);
    return requestSignInLink({ email, hostname: window.location.hostname })
      .then(() => {
        void localforage.setItem('emailForSignIn', email);
      })
      .catch((error: FirebaseError) => {
        setAuthError(error);
      });
  };

  const logOut = () => {
    setAuthState(false);
    void localforage.clear();
    return signOut(kaeplaAuth);
  };

  // This is if we log in via emailLink directly on kaepla
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const unsubscribe = kaeplaAuth.onAuthStateChanged((firebaseUser) => {
      const loadUser = async () => {
        if (firebaseUser?.email && firebaseUser?.uid) {
          const userFromFB = await getUser({ uid: firebaseUser.uid });
          const _kaeplaUser = getKaeplaUserFromFbUser(userFromFB, firebaseUser);
          void createOrUpdateUser({ user: _kaeplaUser });
          setKaeplaUser(_kaeplaUser);
          setAuthState(true);
        } else {
          watcherServiceKaepla.get().unsubscribeAll();
          await localforage.clear();
          setAuthState(false);
        }
        setUser(firebaseUser);
      };
      void loadUser();
    });
    return unsubscribe;
  }, []);

  const values: AuthContextModel = {
    auth: kaeplaAuth,
    authError,
    authState,
    clearAuthError,
    emailForSecondFactor,
    kaeplaUser,
    logOut,
    multiFactorResolver,
    reAuthenticateWithPassword,
    secondFactorRequired,
    setSecondFactorRequired,
    sendEmailLink,
    setKaeplaUser,
    setUser,
    signIn,
    signInWithLink,
    user,
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
