import { useRecoilValue } from 'recoil';

import { CustomerAssignmentsListenerKaeplaAdmins } from './CustomerAssignmentsListenerKaeplaAdmins';
import { CustomerAssignmentsListenerUsers } from './CustomerAssignmentsListenerUsers';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState';
import { logger } from '../helpers/logger';

export const CustomerAssignmentsListener = () => {
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);

  logger.log('CustomerAssignmentsListener renders');

  if (kaeplaAssignment) {
    return <CustomerAssignmentsListenerKaeplaAdmins />;
  }

  return <CustomerAssignmentsListenerUsers />;
};
