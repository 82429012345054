import { WhereClause } from '@atrigam/atrigam-service-firebase-watcher';
import { getApp } from 'firebase/app';
import {
  QueryFieldFilterConstraint,
  collection,
  getDocs,
  getFirestore,
  limit,
  query,
  where,
} from 'firebase/firestore';

import { KaeplaPerspectiveFavorite } from '../types/Application/KaeplaPerspectiveFavorite';
import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  uid: string;
  project: KaeplaProject;
}

export const getPerspectiveFavorites = async ({ project, uid }: Options) => {
  const data: KaeplaPerspectiveFavorite[] = [];
  if (!project) return data;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `perspectiveFavorites`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const queryWhere: WhereClause[] = [
    {
      fieldPath: 'uid',
      opStr: '==',
      value: uid,
    },
    {
      fieldPath: 'projectId',
      opStr: '==',
      value: project.id,
    },
  ];

  const whereClauses: QueryFieldFilterConstraint[] = [];
  queryWhere.forEach((w) => {
    whereClauses.push(where(w.fieldPath, w.opStr, w.value));
  });

  const firestoreQuery = query(firestoreCollection, ...whereClauses, limit(50));
  const snapshot = await getDocs(firestoreQuery);
  snapshot.forEach((document_) => {
    const perspective = document_.data();
    perspective.id = document_.id;
    data.push(perspective as KaeplaPerspectiveFavorite);
  });
  return data;
};
