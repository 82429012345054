import { Box, Button } from '@mui/material';
import { useEffect, Dispatch, SetStateAction } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useUserPreferences } from 'src/UserPreferencesProvider';
import { perspectiveState } from 'src/services/recoil/nonpersistent/perspectiveState';

import { useAuth } from '../../../../../AuthProvider';
import { getFromKaepla } from '../../../../../services/api/getFromKaepla';
import { projectState } from '../../../../../services/recoil/nonpersistent/projectState';
import { simulationState } from '../../../../../services/recoil/nonpersistent/simulationState';
import { timeSeriesState } from '../../../../../services/recoil/nonpersistent/timeSeriesState';
import { currentScopePathState } from '../../../../../services/recoil/persistent/currentScopePathState';
import { filterSettingsState } from '../../../../../services/recoil/persistent/filterSettingState';
import { filterTreeState } from '../../../../../services/recoil/persistent/filterTreeState';
import { timeSliceState } from '../../../../../services/recoil/persistent/timeSliceState';
import { KaeplaApiParameters } from '../../../../../services/types/Application/KaeplaApiParameters';
import { KaeplaQueryType } from '../../../../../services/types/Application/KaeplaQueryType';
import { MatrixTimeSlice } from '../../../../../services/types/MatrixTimeSlice';
import { MatrixTimeseries } from '../../../../../services/types/MatrixTimeseries';

const slices = [
  { a: 'y', s: 'YEAR' },
  { a: 'q', s: 'QUARTER' },
  { a: 'm', s: 'MONTH' },
  { a: 'w', s: 'WEEK' },
];

interface Options {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const TimeSliceSwitcher = ({ setLoading }: Options) => {
  const { kaeplaUser } = useAuth();
  const { setPreferences } = useUserPreferences();
  const project = useRecoilValue(projectState);
  const simulation = useRecoilValue(simulationState);
  const perspective = useRecoilValue(perspectiveState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const filterTree = useRecoilValue(filterTreeState);
  const filterSettings = useRecoilValue(filterSettingsState);
  const [timeSlice, setTimeSlice] = useRecoilState(timeSliceState);
  const setTimeSeries = useSetRecoilState(timeSeriesState);

  useEffect(() => {
    // console.log('->', filterSettings.isActive, simulation?.id);
    if (simulation?.isBeingSimulated) return;

    const parameters: KaeplaApiParameters = {
      q: 'timeseries' as KaeplaQueryType,
      p: [] as string[],
      projectId: project.id,
      simulationId: simulation?.id,
      perspectiveId: perspective?.id,
      timeSlice,
      s: 'TimeSliceSwitcher',
    };

    parameters.p = currentScopePath;

    if (filterSettings.isActive) {
      parameters.filterTree = filterTree;
    }

    getFromKaepla({
      callBack: (apiResponse) => {
        if (!apiResponse) return;
        const result = apiResponse.response as MatrixTimeseries;
        if (result) {
          setTimeSeries(result);
        }
      },
      setLoading,
      params: parameters,
      uid: kaeplaUser?.uid,
    });
  }, [
    currentScopePath,
    filterSettings.isActive,
    filterTree,
    kaeplaUser?.uid,
    perspective,
    project.id,
    setLoading,
    setTimeSeries,
    simulation?.id,
    simulation?.isBeingSimulated,
    timeSlice,
  ]);

  return (
    <>
      {slices.map((slice) => {
        return (
          <Box
            // component="span"
            component={Button}
            variant={slice.s === timeSlice ? 'outlined' : 'text'}
            size="small"
            onClick={() => {
              setTimeSlice(slice.s as MatrixTimeSlice);
              setPreferences({ lastScopeTimeSlice: slice.s });
              return;
            }}
            sx={{
              p: 0,
              minWidth: '2rem',
              mr: 1,
              cursor: 'pointer',
              // textDecoration: slice.s === timeSlice ? "underline" : "none",
              fontWeight: slice.s === timeSlice ? '400' : '100',
            }}
            key={slice.a}
          >
            {slice.a}
          </Box>
        );
      })}
    </>
  );
};
