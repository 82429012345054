import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import numbro from 'numbro';
import pluralize from 'pluralize';
import { useEffect, useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { lastCustomerIDState } from 'src/services/recoil/persistent/lastCustomerState';

import { CardMenu } from './CardMenu';
import { useAuth } from '../../../../AuthProvider';
import { createEvent } from '../../../../services/firestore/createEvent';
import { updateProject } from '../../../../services/firestore/updateProject';
import { applicationState } from '../../../../services/recoil/nonpersistent/applicationState';
import { knownCustomersState } from '../../../../services/recoil/nonpersistent/knownCustomersState';
import { knownResellersState } from '../../../../services/recoil/nonpersistent/knownResellersState';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { scopePathsState } from '../../../../services/recoil/nonpersistent/scopePathsState';
import { selectedCustomerState } from '../../../../services/recoil/nonpersistent/selectedCustomer';
import { projectAssignmentsState } from '../../../../services/recoil/nonpersistent/userAssignmentState';
import { userRootPathsState } from '../../../../services/recoil/nonpersistent/userRootPathsState';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState';
import { lastProjectIdState } from '../../../../services/recoil/persistent/lastProjectState';
import { timeSliceState } from '../../../../services/recoil/persistent/timeSliceState';
import { KaeplaCustomer } from '../../../../services/types/Application/KaeplaCustomer';
import { KaeplaDataOperation } from '../../../../services/types/Application/KaeplaDataOperation';
import { KaeplaEventType } from '../../../../services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from '../../../../services/types/Application/KaeplaFunctionGroup';
import { KaeplaProject } from '../../../../services/types/Application/KaeplaProject';
import { MatrixTimeSlice } from '../../../../services/types/MatrixTimeSlice';
import { ResellerAvatar } from '../../../features/ResellerAvatar';
import { parseErrorLog } from '../../../helpers/logger';
import { simplifyScopePath } from '../../../helpers/simplifyScopePath';
import { timeAgo } from '../../../helpers/timeAgo';
import { ProjectTeam } from '../../Perspectives/features/ProjectSummary/features/ProjectTeam';

interface Options {
  project: KaeplaProject;
  selected: boolean;
}
const StyledCardHeader = styled(({ ...other }) => <CardHeader {...other} />)`
  & .MuiCardHeader-content {
    flex: 1 1 auto;
    width: 90%;
  }
`;

export const ProjectCard = ({ project, selected }: Options) => {
  const theme = useTheme();
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  // get
  const app = useRecoilValue(applicationState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const knownResellers = useRecoilValue(knownResellersState);
  const knownCustomers = useRecoilValue(knownCustomersState);
  const userRootPaths = useRecoilValue(userRootPathsState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);
  // set
  const setProject = useSetRecoilState(projectState);
  const setLastProject = useSetRecoilState(lastProjectIdState);
  const setTimeSlice = useSetRecoilState(timeSliceState);
  const setScopePaths = useSetRecoilState(scopePathsState);
  const setSelectedCustomer = useSetRecoilState(selectedCustomerState);
  const setLastCustomer = useSetRecoilState(lastCustomerIDState);
  const setCurrentScopePath = useSetRecoilState(currentScopePathState);
  // local
  const [hover, setHover] = useState(false);
  const [defaultPath, setDefaultPath] = useState<string[]>();
  const [assignmentsCount, setAssignmentsCount] = useState(0);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);
  };

  const getSubHeader = () => {
    if (project.matrixInPreparation) {
      return 'data is being prepared now';
    }
    if (project.matrixUnavailable) {
      return 'no data available';
    }
    if (project.initializedAt) {
      return timeAgo(project.initializedAt) || 'never';
    }
    return 'not set up yet';
  };

  const subHeaderText = getSubHeader();

  const customerFromProject = (p: KaeplaProject) => {
    return knownCustomers.find((c) => c.id === p.customerId);
  };

  const customer = customerFromProject(project);

  const resellerFromCustomer = (c: KaeplaCustomer | undefined) => {
    return knownResellers.filter((k) => !!k).find((r) => r.id === c?.resellerId);
  };

  const reseller = resellerFromCustomer(customer);

  // TODO: this was ou first attempt to redirect the user in a meaningful way
  // now we send them to the Assignments overview to choose the scope to go by themselves
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const goToProject = () => {
    if (!defaultPath) return;
    setCurrentScopePath(defaultPath);
    const newScopePaths: Record<string, string[] | undefined> = {};
    newScopePaths[project.id] = defaultPath;
    setScopePaths(newScopePaths);
    setProject(project);
    const _customer = knownCustomers.find((c) => c.id === project.customerId);
    setSelectedCustomer(_customer);
    if (_customer) {
      setLastCustomer(_customer.id);
    }
    setLastProject(project.id);
    setTimeSlice(project.defaultPerspective?.timeSlice || ('QUARTER' as MatrixTimeSlice));

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PROJECT_SELECT_PROJECT,
      functionGroup: KaeplaFunctionGroup.PROJECTS,
      operation: KaeplaDataOperation.READ,
      project,
      scopePath: defaultPath,
    });
    navigate(`/Project/${project.id}`);
  };

  const goToAssignments = () => {
    setProject(project);
    const rootPathStringified = userRootPaths[project.id];
    if (rootPathStringified) {
      const path = JSON.parse(rootPathStringified) as string[];
      setCurrentScopePath(path);
    }

    const _customer = knownCustomers.find((c) => c.id === project.customerId);
    setSelectedCustomer(_customer);
    if (_customer) {
      setLastCustomer(_customer.id);
    }
    setLastProject(project.id);
    setTimeSlice(project.defaultPerspective?.timeSlice || ('QUARTER' as MatrixTimeSlice));
    if (project.matrixUnavailable || project.matrixInPreparation) {
      navigate(`/Perspective`);
      return;
    }
    navigate(`/Assignments`);
  };

  const generateDummyData = async () => {
    if (!defaultPath) return;
    const newProject = {
      ...project,
      matrixUnavailable: true,
      matrixInPreparation: false,
    } as KaeplaProject;
    await updateProject({ project: newProject });

    const newScopePaths: Record<string, string[] | undefined> = {};
    newScopePaths[newProject.id] = defaultPath;
    setScopePaths(newScopePaths);
    setProject(newProject);
    const _customer = knownCustomers.find((c) => c.id === project.customerId);
    setSelectedCustomer(_customer);
    if (_customer) {
      setLastCustomer(_customer.id);
    }
    setLastProject(newProject.id);
    setTimeSlice(newProject.defaultPerspective?.timeSlice || ('QUARTER' as MatrixTimeSlice));
    navigate(`/Perspective`);
  };

  const handleGenerateDummyData = () => {
    void generateDummyData();
  };

  useEffect(() => {
    if (!app.projectsLoaded) return;
    if (userRootPaths[project.id]) {
      let parsed: string[] = [];
      try {
        parsed = JSON.parse(userRootPaths[project.id]) as string[];
      } catch (error) {
        parseErrorLog.log('parse error', error);
      }
      setDefaultPath(parsed);
    }
    // kaeplaAdmins
    if (kaeplaAssignment && !userRootPaths[project.id]) {
      setDefaultPath([]);
    }
    setAssignmentsCount(projectAssignments.filter((p) => p.projectId === project.id).length);
  }, [app.projectsLoaded, project.id, userRootPaths, projectAssignments, kaeplaAssignment]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card
        data-testid="project-card"
        elevation={selected || hover ? 8 : 2}
        onFocus={() => {
          setHover(true);
        }}
        onBlur={() => {
          setHover(false);
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        sx={{
          cursor: 'pointer',
          // opacity: selected || hover ? 1 : 0.8,
          position: 'relative',
        }}
        onClick={() => {
          goToAssignments();
        }}
      >
        <StyledCardHeader
          avatar={
            customer && (
              <Avatar
                data-testid="project-avatar"
                sx={{
                  bgcolor: 'transparent',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor:
                    theme.palette.mode === 'light'
                      ? theme.palette.grey[300]
                      : theme.palette.grey[800],
                }}
                variant="square"
              >
                <img width="16" alt={customer.name} src={customer.iconBase64} />
              </Avatar>
            )
          }
          title={
            <Tooltip
              slotProps={{
                popper: {
                  sx: { opacity: kaeplaAssignment?.devTeamMember ? 1 : 3 },
                },
              }}
              title={project.id}
            >
              <Box data-testid="project-name">{project.name}</Box>
            </Tooltip>
          }
          subheader={<Box data-testid="project-subheader">{subHeaderText}</Box>}
          titleTypographyProps={{
            noWrap: true,
          }}
          action={
            (kaeplaAssignment || project.createdBy === kaeplaUser?.uid) && (
              <IconButton
                edge="end"
                size="small"
                data-testid="project-menu-toggle"
                onClick={(event) => {
                  event.stopPropagation();
                  handleMenuClick(event);
                }}
              >
                <MoreVertIcon data-testid="project-menu-icon" />
              </IconButton>
            )
          }
        />
        <CardContent
          sx={{
            minHeight: 100,
            py: 0,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {kaeplaAssignment && reseller && (
                <IconButton
                  data-testid="project-reseller-button"
                  size="small"
                  aria-label="settings"
                  edge="start"
                >
                  <ResellerAvatar
                    title={reseller.name}
                    icon={reseller.iconBase64}
                    small
                    id={reseller.id}
                  />
                </IconButton>
              )}
              <Chip
                data-testid="project-records"
                sx={{ mr: 1, mt: 1 }}
                label={`${numbro(project.totalRecordsCount).format({
                  average: true,
                  mantissa: 1,
                })} records`}
                size="small"
                variant="outlined"
              />
              <Chip
                data-testid="project-dimensions"
                sx={{ mr: 1, mt: 1 }}
                label={`${project.totalDimensionsCount || 'NaN'} dimensions`}
                size="small"
                variant="outlined"
              />
              <Chip
                data-testid="project-assignmentsCount"
                sx={{ mr: 1, mt: 1 }}
                label={`${pluralize('assignment', assignmentsCount, true)}`}
                size="small"
                variant="outlined"
              />
            </Grid>
            {defaultPath && (
              <Grid item xs={12}>
                <Typography variant="caption">
                  {defaultPath.length > 0
                    ? simplifyScopePath(defaultPath).join(' ➔ ')
                    : 'All Scopes'}
                </Typography>
                <Divider />
              </Grid>
            )}
            {defaultPath && (
              <Grid item sm={12}>
                <ProjectTeam project={project} disableInvites defaultPath={defaultPath} />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {(kaeplaAssignment || project.createdBy === kaeplaUser?.uid) && (
        <CardMenu
          anchorEl={anchorElement}
          open={!!anchorElement}
          onClose={handleMenuClose}
          handleGenerateDummyData={handleGenerateDummyData}
          project={project}
        />
      )}
    </Grid>
  );
};
