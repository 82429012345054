import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { createEvent } from 'src/services/firestore/createEvent';
import { getTargetsForSimulation } from 'src/services/firestore/getTargetsForSimulation';
import { perspectiveState } from 'src/services/recoil/nonpersistent/perspectiveState';
import { KaeplaDataOperation } from 'src/services/types/Application/KaeplaDataOperation';
import { KaeplaEventEffect } from 'src/services/types/Application/KaeplaEventEffect';
import { KaeplaEventType } from 'src/services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from 'src/services/types/Application/KaeplaFunctionGroup';
import { KaeplaTargets } from 'src/services/types/Application/KaeplaTargets';
import { MatrixSimulationParameterNumeric } from 'src/services/types/MatrixSimulationParameterNumeric';
import { MatrixSimulationParameterText } from 'src/services/types/MatrixSimulationParameterText';
import { v4 as uuidv4 } from 'uuid';

import { AddSimulationParameterToRuleset } from './AddSimulationParameterToRuleset';
import { SimulationEditorHeader } from './SimulationEditorHeader';
import { SimulationEditorHeaderOwner } from './SimulationEditorHeaderOwner';
import { SimulationRulesets } from './SimulationRulesets';
import { SimulationTargets } from './SimulationTargets';
import { convertSimulationParametersToRulesets } from './_helpers/convertSimulationParametersToRulesets';
import { useAuth } from '../../../../AuthProvider';
import { getFromKaepla } from '../../../../services/api/getFromKaepla';
import { addSimulationListener } from '../../../../services/firestore/addSimulationListener';
import { createNewTargets } from '../../../../services/firestore/createNewTargets';
import { getProject } from '../../../../services/firestore/getProject';
import { getSimulation } from '../../../../services/firestore/getSimulation';
import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState';
import { modelState } from '../../../../services/recoil/nonpersistent/modelState';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { simulationState } from '../../../../services/recoil/nonpersistent/simulationState';
import { timeSeriesState } from '../../../../services/recoil/nonpersistent/timeSeriesState';
import { currentScopePathState } from '../../../../services/recoil/persistent/currentScopePathState';
import { knownUsersState } from '../../../../services/recoil/persistent/knownUsersState';
import { KaeplaApiParameters } from '../../../../services/types/Application/KaeplaApiParameters';
import { KaeplaProject } from '../../../../services/types/Application/KaeplaProject';
import { KaeplaQueryType } from '../../../../services/types/Application/KaeplaQueryType';
import { KaeplaSimulation } from '../../../../services/types/Application/KaeplaSimulation';
import { KaeplaSimulationParameter } from '../../../../services/types/Application/KaeplaSimulationParameter';
import { KaeplaSimulationRulesetWithParameters } from '../../../../services/types/Application/KaeplaSimulationRulesetWithParameters';
import { KaeplaTargetsFigure } from '../../../../services/types/Application/KaeplaTargetsFigure';
import { KaeplaUser } from '../../../../services/types/Application/KaeplaUser';
import { MatrixApiResponse } from '../../../../services/types/MatrixApiResponse';
import { MatrixSimulationParameters } from '../../../../services/types/MatrixSimulationParameters';
import { Layout } from '../../../Layout/Layout';
import { convertTimestamp } from '../../../helpers/convertTimestamp';
import { logger, requestLog } from '../../../helpers/logger';
import { DataTimeline } from '../../Perspectives/features/DataTimeline/DataTimeline';
import { ProjectSummary } from '../../Perspectives/features/ProjectSummary/ProjectSummary';

export interface SimulationParameterOptions {
  simulationId: string;
  parameter: KaeplaSimulationParameter;
}

export const SimulationEditor = () => {
  const { id, projectId } = useParams();
  const navigate = useNavigate();
  const { kaeplaUser } = useAuth();
  // get
  const knownUsers = useRecoilValue(knownUsersState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const perspective = useRecoilValue(perspectiveState);
  const timeseries = useRecoilValue(timeSeriesState);
  // set
  const setSimulationStored = useSetRecoilState(simulationState);
  const setModel = useSetRecoilState(modelState);
  // get & set
  const [matrixFiltered, setMatrixFiltered] = useRecoilState(matrixFilteredState);
  const [project, setProject] = useRecoilState(projectState);
  const [simulation, setSimulation] = useState<KaeplaSimulation>();
  // local
  const [simulationRulesets, setSimulationRulesets] =
    useState<KaeplaSimulationRulesetWithParameters[]>();
  const [ruleset, setRuleset] = useState<KaeplaSimulationRulesetWithParameters>();
  const [createTargets, setCreateTargets] = useState(false);
  const [preview, setPreview] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const [simulatedAt, setSimulatedAt] = useState<string>('n/a');
  const [addingParameter, setAddingParameter] = useState<boolean>(false);
  const [own, setOwn] = useState<boolean>(false);
  const [owner, setOwner] = useState<KaeplaUser>();
  const [targetsForSimulation, setTargetsForSimulation] = useState<KaeplaTargets[]>([]);

  const setUpSimulation = useCallback(
    (_project: KaeplaProject, _simulation: KaeplaSimulation) => {
      setProject(_project);
      if (_project.model) {
        setModel(_project.model);
      } else {
        setModel({ delegation: {} });
      }
      setSimulation(_simulation);
      setOwn(_simulation?.createdBy === kaeplaUser?.uid);
      const _owner = knownUsers.find((user) => user.uid === _simulation?.createdBy);
      setOwner(_owner);

      if (!_simulation.rulesets) {
        const _ruleset: KaeplaSimulationRulesetWithParameters = {
          simulationId: _simulation.id,
          position: 0,
          id: uuidv4(),
          parameters: [],
        };
        setRuleset(_ruleset);
        setPreview(false);
        return; // no need to load parameters from server
      }

      getTargetsForSimulation({ simulation: _simulation }).then(
        (targetsFromServer) => {
          setTargetsForSimulation(targetsFromServer);
        },
        () => {
          logger.log('getTargetsForSimulation promise rejected');
        },
      );

      const _parameters: KaeplaApiParameters = {
        q: 'simulationParameters' as KaeplaQueryType,
        p: currentScopePath,
        projectId: _project.id,
        simulationId: id,
        s: 'SimulationEditor',
      };

      getFromKaepla({
        callBack: (apiResponse) => {
          if (!apiResponse) return;
          const result = apiResponse.response as MatrixSimulationParameters;
          const simulationParametersFromServer = result.rows as unknown as (
            | MatrixSimulationParameterNumeric
            | MatrixSimulationParameterText
          )[];

          const _rulesets = convertSimulationParametersToRulesets({
            rulesets: _simulation.rulesets,
            parameters: simulationParametersFromServer,
          });

          setSimulationRulesets(_rulesets);
          setPreview(false);
        },
        params: _parameters,
        uid: kaeplaUser?.uid,
      });
    },
    [currentScopePath, id, kaeplaUser?.uid, knownUsers, setModel, setProject],
  );

  const createTargetsFromSimulation = async () => {
    if (!simulation || !kaeplaUser) return;
    const targetFigures: Partial<KaeplaTargetsFigure>[] = [];
    const dimension = perspective.valueDimension || project.defaultPerspective?.valueDimension;

    const targetsDimension = matrixFiltered?.dimensions?.dimensions.find(
      (d) => d.columnName === dimension,
    );

    timeseries.timeseries.forEach((t) => {
      if (t.value > 0) {
        const figure: Partial<KaeplaTargetsFigure> = {
          validAsOf: t.date,
          originalValue: t.value,
          absoluteValue: t.simulationValue,
        };
        targetFigures.push(figure);
      }
    });

    const newTargets = await createNewTargets({
      project,
      scopePath: simulation.scopePath,
      createdBy: kaeplaUser.uid,
      targetsDimension,
      simulation,
      targetFigures,
    });

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.TARGETS_CREATE_TARGETS_FROM_SIMULATION,
      effect: KaeplaEventEffect.PROJECT_TARGETS_CHANGE,
      functionGroup: KaeplaFunctionGroup.TARGETS,
      operation: KaeplaDataOperation.CREATE,
      project,
      scopePath: currentScopePath,
      simulationId: simulation.id,
    });

    if (newTargets) {
      navigate(`/Targets/${project.id}/${newTargets.id}`);
    }
  };

  // this is for the "reset" function
  useEffect(() => {
    const loadSimulation = async () => {
      if (!kaeplaUser?.uid) return;
      if (!projectId || !id) return;
      const projectFromServer = await getProject({
        id: projectId,
      });
      const simulationFromServer = await getSimulation({
        projectId: projectFromServer.id,
        simulationId: id,
      });

      setPreview(false);
      setUpSimulation(projectFromServer, simulationFromServer);
    };
    if (reset) {
      setReset(false);
      void loadSimulation();
    }
  }, [id, kaeplaUser?.uid, projectId, reset, setUpSimulation]);

  // this loads the project and the matrix initially
  // we get projectId and Id (simulation) from URL-params
  // useParams() react-router's useParams()
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!projectId || !id) return;
    const load = async () => {
      const projectFromServer = await getProject({
        id: projectId,
      });
      if (!projectFromServer) {
        return;
      }
      setProject(projectFromServer);

      const parameters: KaeplaApiParameters = {
        q: 'summary' as KaeplaQueryType,
        p: currentScopePath,
        projectId: projectFromServer.id,
        simulationId: id,
        s: 'SimulationEditor',
      };

      getFromKaepla({
        callBack: (apiResponse) => {
          if (!apiResponse) return;
          const result = apiResponse.response as MatrixApiResponse;
          setMatrixFiltered((current) => {
            return { ...current, ...result };
          });
        },
        params: parameters,
        uid: kaeplaUser?.uid,
      });
    };
    void load();
  }, [currentScopePath, id, kaeplaUser?.uid, projectId, setMatrixFiltered, setProject]);

  // this is the simulationListener
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id || !id) return;

    const unsubscribe = addSimulationListener({
      projectId: project.id,
      simulationId: id,
      callback: (dataFromServer) => {
        const simulationFromServer = dataFromServer as KaeplaSimulation;
        setSimulationStored(simulationFromServer);
        if (simulationFromServer?.simulatedAt) {
          const humanReadableTimeStamp = convertTimestamp(simulationFromServer.simulatedAt);
          setSimulatedAt(humanReadableTimeStamp);
        }
        setUpSimulation(project, simulationFromServer);
      },
    });
    return () => {
      requestLog.log(`unsubscribe simulation Listener`);
      unsubscribe();
    };
  }, [kaeplaUser?.uid, project, setSimulationStored, setUpSimulation, id]);

  if (!simulation) {
    return (
      <Layout>
        <Typography variant="body2">loading simulation...</Typography>
      </Layout>
    );
  }

  return (
    <Layout hasScopeNavigation={!project.matrixUnavailable} showCustomerSelector>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5} lg={4}>
          <ProjectSummary disableSimulationSelect disableFilters />
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <DataTimeline
            simulationPreview={preview}
            simulationRunning={!!simulation?.isBeingSimulated}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <SimulationEditorHeader own={own} owner={owner} simulation={simulation} />
            <SimulationEditorHeaderOwner
              id={id}
              own={own}
              simulation={simulation}
              simulationRulesets={simulationRulesets}
              simulatedAt={simulatedAt}
              setReset={setReset}
              setCreateTargets={setCreateTargets}
              createTargets={createTargets}
              targetsForSimulation={targetsForSimulation}
              setRuleset={setRuleset}
              preview={preview}
              setPreview={setPreview}
              addingParameter={addingParameter}
              setAddingParameter={setAddingParameter}
            />
            {createTargets && own && (
              <SimulationTargets
                project={project}
                perspective={perspective}
                createTargetsFromSimulation={createTargetsFromSimulation}
                setCreateTargets={setCreateTargets}
                targetsForSimulation={targetsForSimulation}
              />
            )}
            {ruleset && addingParameter && own && (
              <Grid item xs={12}>
                <AddSimulationParameterToRuleset
                  simulation={simulation}
                  setSimulation={setSimulation}
                  simulationRulesets={simulationRulesets}
                  setSimulationRulesets={setSimulationRulesets}
                  ruleset={ruleset}
                  setAddingParameter={setAddingParameter}
                />
              </Grid>
            )}
            {!addingParameter && !simulation?.rulesets && (
              <Grid item xs={12}>
                <Alert
                  severity="info"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAddingParameter(true);
                      }}
                    >
                      Add
                    </Button>
                  }
                >
                  Add a simulation parameter!
                </Alert>
              </Grid>
            )}
            {!addingParameter && (
              <Grid item xs={12}>
                <SimulationRulesets
                  own={own}
                  simulation={simulation}
                  setSimulation={setSimulation}
                  simulationRulesets={simulationRulesets}
                  setSimulationRulesets={setSimulationRulesets}
                  setPreview={setPreview}
                  setRuleset={setRuleset}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Box sx={{ m: 8 }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};
