import { useRecoilValue } from 'recoil';

import { knownProjectFiltersState } from '../../../../../../../services/recoil/nonpersistent/knownProjectFiltersState';
import { KaeplaPerspectiveBlock } from '../../../../../../../services/types/Application/KaeplaPerspectiveBlock';
import { cleanAggregation } from '../../../../../../helpers/cleanAggregation';
import { cleanColumnName } from '../../../../../../helpers/cleanColumnName';

interface ABOptions {
  block: KaeplaPerspectiveBlock;
}
export const StackedBarChartTitle = ({ block }: ABOptions) => {
  const valueDimension = cleanColumnName(block.valueDimension);
  const aggregation = cleanAggregation(block.aggregation);
  const aggregationDimension = cleanColumnName(block.aggregationDimension);
  const time = cleanColumnName(block.timeDimension);
  const filterId = block.filterId;
  const knownProjectFilters = useRecoilValue(knownProjectFiltersState);

  let title = (
    <>
      {valueDimension} <u>{aggregation}</u> per <b>{aggregationDimension}</b> over {time}
    </>
  );

  if (knownProjectFilters && filterId) {
    const filter = knownProjectFilters.find((k) => k.id === filterId);
    if (filter?.name) {
      title = (
        <>
          {valueDimension} <u>{aggregation}</u> per <b>{aggregationDimension}</b> over {time} for{' '}
          {filter.name}
        </>
      );
    }
  }

  return title;
};
