import { Box } from '@mui/material';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import numbro from 'numbro';
import { useEffect, useMemo, useState } from 'react';
import { camelToSentenceCase } from 'src/Frontend/helpers/camelToSentenceCase';

import { KaeplaDimensionDetails } from '../../../../services/types/Application/KaeplaDimensionDetails';
import { cleanColumnName } from '../../../helpers/cleanColumnName';

interface ColoredCellOptions {
  rowNumber: number;
  colorArray: string[];
  cellValue: string | number | unknown;
  replaceUnderscores?: boolean;
}

const CellWithRowColor = ({
  rowNumber,
  colorArray,
  cellValue,
  replaceUnderscores = true,
}: ColoredCellOptions) => {
  let value = `${cellValue as string}`;
  if (replaceUnderscores) {
    value = cleanColumnName(`${cellValue as string}`);
  }
  return (
    <Box>
      <Box
        component="span"
        sx={{
          width: 10,
          height: 10,
          mr: 1,
          backgroundColor: colorArray[rowNumber],
          display: 'inline-block',
        }}
      />
      {value}
    </Box>
  );
};

interface Options {
  detail: KaeplaDimensionDetails;
  chartColors: string[];
}

export const DimensionSummaryTable = ({ detail, chartColors }: Options) => {
  const [data, setData] = useState<Record<string, unknown>[]>([]);
  const [columns, setColumns] = useState<string[]>([]);

  useEffect(() => {
    const _data = [...detail.dimensionDetails]
      .map((_row, index) => {
        const row: Record<string, unknown> = { ..._row };
        if (row.numberOfRecords) {
          row.numberOfRecords = numbro(row.numberOfRecords).format({
            average: true,
            mantissa: 1,
          });
        }
        row.value = (
          <CellWithRowColor rowNumber={index} colorArray={chartColors} cellValue={row.value} />
        );
        // row.color = chartColors[index];
        return row;
      })
      .slice(0, detail.dimensionDetails.length > 25 ? 10 : 25);
    setData(_data); // Setting the data that we want to display
    if (_data[0]) {
      setColumns(Object.keys(_data[0]));
    }
  }, [detail, chartColors]);

  const colsMap = columns.map((d) => ({
    accessorKey: d,
    id: d,
    header: camelToSentenceCase(cleanColumnName(d)),
  }));

  const headers = useMemo<MRT_ColumnDef<Record<string, unknown>>[]>(() => colsMap, [colsMap]);

  return (
    <MaterialReactTable
      columns={headers}
      data={data}
      initialState={{ density: 'compact' }}
      enableColumnActions={false}
      enableSelectAll={false}
      enableRowSelection={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}
      enableBottomToolbar={false}
      enableTopToolbar={false}
      muiTableBodyRowProps={{ hover: false }}
    />
  );
};
