import { Box, Chip, LinearProgress, Paper, Toolbar, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getPerspectiveFavorites } from 'src/services/firestore/getPerspectiveFavorites';
import { getPerspectivesForProject } from 'src/services/firestore/getPerspectivesForProject';
import { perspectiveFavoritesState } from 'src/services/recoil/nonpersistent/perspectiveFavoritesState';
import { perspectivesState } from 'src/services/recoil/nonpersistent/perspectivesState';
import { simulationsState } from 'src/services/recoil/nonpersistent/simulationsState';
import { KaeplaPerspective } from 'src/services/types/Application/KaeplaPerspective';

import { useAuth } from '../../../AuthProvider';
import { createEvent } from '../../../services/firestore/createEvent';
import { getTargetsForProject } from '../../../services/firestore/getTargetsForProject';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { scopePathsState } from '../../../services/recoil/nonpersistent/scopePathsState';
import { projectAssignmentsState } from '../../../services/recoil/nonpersistent/userAssignmentState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { KaeplaDataOperation } from '../../../services/types/Application/KaeplaDataOperation';
import { KaeplaEventType } from '../../../services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from '../../../services/types/Application/KaeplaFunctionGroup';
import { KaeplaProjectAssignment } from '../../../services/types/Application/KaeplaProjectAssignment';
import { KaeplaTargets } from '../../../services/types/Application/KaeplaTargets';
import { Layout } from '../../Layout/Layout';
import { mainColor } from '../../Theme/colors';
import { DelayedLinearProgress } from '../../features/DelayedLinearProgress';
import { HumanReadableTimestampType, convertTimestamp } from '../../helpers/convertTimestamp';
import { simplifyScopePath } from '../../helpers/simplifyScopePath';

export const Assignments = () => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  // get
  const project = useRecoilValue(projectState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);
  // get & set
  const [currentScopePath, setCurrentScopePath] = useRecoilState(currentScopePathState);
  const [simulations, setSimulations] = useRecoilState(simulationsState);
  // set
  const setScopePaths = useSetRecoilState(scopePathsState);
  const setPerspectives = useSetRecoilState(perspectivesState);
  const setPerspectiveFavorites = useSetRecoilState(perspectiveFavoritesState);
  // local
  const [targets, setTargets] = useState<KaeplaTargets[]>();
  const [loading, setLoading] = useState(true);
  const [selecting, setSelecting] = useState(false);

  const isSelected = (assignment: KaeplaProjectAssignment) => {
    return JSON.stringify(currentScopePath) === assignment.scopePathStringified;
  };

  const simulationCount = (assignment: KaeplaProjectAssignment) => {
    if (!simulations) return 0;
    return simulations.filter((s) => s.scopePathStringified === assignment.scopePathStringified)
      .length;
  };

  const targetsCount = (assignment: KaeplaProjectAssignment) => {
    if (!targets) return 0;
    return targets.filter((s) => s.scopePathStringified === assignment.scopePathStringified).length;
  };

  // this is the "component mounts" effect
  useEffect(() => {
    if (!kaeplaUser?.uid) return;
    if (!project?.id) return;
    if (selecting) return;

    void createEvent({
      uid: kaeplaUser.uid,
      eventType: KaeplaEventType.ASSIGNMENT_ENTER_ASSIGNMENTS_OVERVIEW,
      functionGroup: KaeplaFunctionGroup.ASSIGNMENTS,
      operation: KaeplaDataOperation.READ,
      project,
    });

    const load = async () => {
      let perspectivesFromServer = await getPerspectivesForProject({
        project,
      });
      const defaultPerspective = { ...project.defaultPerspective } as KaeplaPerspective;
      if (defaultPerspective) {
        defaultPerspective.id = 'default';
        perspectivesFromServer = [defaultPerspective, ...perspectivesFromServer];
      }
      setPerspectives(perspectivesFromServer);
      const perspectiveFavorites = await getPerspectiveFavorites({ project, uid: kaeplaUser.uid });
      setPerspectiveFavorites(perspectiveFavorites);
      const targetsFromServer = await getTargetsForProject({
        project,
      });
      setTargets(targetsFromServer);
      setLoading(false);
    };
    void load();
  }, [
    kaeplaUser?.uid,
    project?.id,
    kaeplaUser,
    project,
    currentScopePath,
    setPerspectives,
    setSimulations,
    selecting,
    setPerspectiveFavorites,
  ]);

  if (!project) {
    navigate('/');
  }

  if (project.matrixUnavailable) {
    navigate('/Perspective');
  }

  if (!targets) {
    return (
      <Layout>
        <DelayedLinearProgress loading={loading} delay={1000} />
      </Layout>
    );
  }

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <>
        {loading && <LinearProgress />}
        <Toolbar disableGutters variant="dense">
          <Typography data-testid="assignments-title" variant="h5">
            {project.name} Assignments
          </Typography>
          <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        </Toolbar>
        {projectAssignments
          .filter((a) => a.projectId === project.id)
          .map((assignment) => (
            <Tooltip key={assignment.id} title={assignment.id}>
              <Box
                data-testid="assignments-card"
                component={Paper}
                sx={{
                  p: 1,
                  mb: 1,
                  cursor: 'pointer',
                  borderLeft: isSelected(assignment) ? `3px solid ${mainColor}` : 'none',
                }}
                elevation={isSelected(assignment) ? 3 : 1}
                onClick={() => {
                  if (loading) return;
                  setSelecting(true);
                  setCurrentScopePath(assignment.scopePath);
                  setScopePaths((previousScopePaths) => {
                    const newScopePaths = { ...previousScopePaths };
                    newScopePaths[assignment.projectId] = assignment.scopePath;
                    return newScopePaths;
                  });

                  void createEvent({
                    uid: kaeplaUser?.uid,
                    eventType: KaeplaEventType.ASSIGNMENT_SELECT_ASSIGNMENT,
                    functionGroup: KaeplaFunctionGroup.ASSIGNMENTS,
                    operation: KaeplaDataOperation.READ,
                    project,
                    scopePath: assignment.scopePath,
                  });
                  navigate('/Perspective');
                }}
              >
                {assignment.scopePath.length > 0 && (
                  <Typography data-testid="assignments-scopePath" variant="body1">
                    {project.name} ➔ {simplifyScopePath(assignment.scopePath, 1).join(' ➔ ')}
                  </Typography>
                )}
                {assignment.scopePath.length === 0 && (
                  <Typography data-testid="assignments-scopePath" variant="body1">
                    {project.name}, all scopes
                  </Typography>
                )}
                <Box sx={{ mt: 1 }}>
                  <Chip
                    data-testid="assignments-simulationsCount"
                    sx={{ mr: 1, mb: 1 }}
                    size="small"
                    label={`${simulationCount(assignment)} simulations`}
                  />
                  <Chip
                    data-testid="assignments-targetsCount"
                    sx={{ mr: 1, mb: 1 }}
                    size="small"
                    label={`${targetsCount(assignment)} targets`}
                  />
                </Box>
                <Typography data-testid="assignments-lastChecked" variant="caption">
                  last checked:{' '}
                  {assignment.lastVisitedAt
                    ? convertTimestamp(assignment.lastVisitedAt, HumanReadableTimestampType.timeago)
                    : 'never'}
                </Typography>
              </Box>
            </Tooltip>
          ))}
      </>
    </Layout>
  );
};
