import { getApp } from 'firebase/app';
import { getFirestore, collection, query, getDocs, where } from 'firebase/firestore';

import { KaeplaCustomerAssignment } from '../types/Application/KaeplaCustomerAssignment';

interface Options {
  uid: string;
  customerId: string;
}

export const getCustomerAssignmentsForUser = async ({ uid, customerId }: Options) => {
  const data: KaeplaCustomerAssignment[] = [];
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `customerAssignments`;
  const firestoreCollection = collection(firestoreDatabase, fireStorePath);
  const firestoreQuery = query(
    firestoreCollection,
    where('uid', '==', uid),
    where('customerId', '==', customerId),
  );
  const snapshot = await getDocs(firestoreQuery);
  snapshot.forEach((document_) => {
    data.push(document_.data() as KaeplaCustomerAssignment);
  });
  return data;
};
