import AddIcon from '@mui/icons-material/AddOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOffIcon from '@mui/icons-material/EditOffOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { PerspectiveMainParameters } from './PerspectiveMainParameters';
import { DataTimeline } from './features/DataTimeline/DataTimeline';
import { PerspectiveBlocks } from './features/PerspectiveBlocks/PerspectiveBlocks';
import { ProjectSummary } from './features/ProjectSummary/ProjectSummary';
import { useAuth } from '../../../AuthProvider';
import { addPerspectiveBlock } from '../../../services/firestore/addPerspectiveBlock';
import { deletePerspective } from '../../../services/firestore/deletePerspective';
import { updatePerspective } from '../../../services/firestore/updatePerspective';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState';
import { perspectiveState } from '../../../services/recoil/nonpersistent/perspectiveState';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { KaeplaPerspective } from '../../../services/types/Application/KaeplaPerspective';
import { KaeplaPerspectiveBlock } from '../../../services/types/Application/KaeplaPerspectiveBlock';
import { KaeplaPerspectiveBlockType } from '../../../services/types/Application/KaeplaPerspectiveBlockType';
import { Layout } from '../../Layout/Layout';
import { Filters } from '../../features/Filters/Filters';
import { InlineEdit } from '../../features/InlineEdit';

interface POptions {
  perspective: Partial<KaeplaPerspective>;
}

export const CustomPerspective = ({ perspective }: POptions) => {
  const { kaeplaUser } = useAuth();
  // get
  const application = useRecoilValue(applicationState);
  const project = useRecoilValue(projectState);
  // set
  const setPerspective = useSetRecoilState(perspectiveState);

  const deleteOwnPerspective = () => {
    void deletePerspective({ project, perspective });
    const defaultPerspective = {
      ...project?.defaultPerspective,
    } as KaeplaPerspective;
    defaultPerspective.id = 'default';
    setPerspective(defaultPerspective);
  };

  const addBlock = async () => {
    if (!project.defaultPerspective) return;
    const block: Partial<KaeplaPerspectiveBlock> = {
      type: 'AggregationOverTime' as KaeplaPerspectiveBlockType,
      aggregation: 'SUM',
      valueDimension: project.defaultPerspective?.valueDimension,
      timeDimension: project.defaultPerspective?.timeDimension,
    };
    const newPerspective = await addPerspectiveBlock({ project, perspective, block });
    setPerspective(newPerspective);
  };

  return (
    <Layout hasScopeNavigation showCustomerSelector>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5} lg={4}>
          <ProjectSummary />
        </Grid>
        <Grid item xs={12} md={7} lg={8}>
          <DataTimeline />
        </Grid>
        <Grid sx={{ display: application.showFilter ? 'block' : 'none' }} item xs={12}>
          <Filters />
        </Grid>
        <Grid item xs={9}>
          <Typography component="div" variant="h5">
            <InlineEdit
              value={perspective.name || 'n/a'}
              callback={(newValue) => {
                const newPerspective = { ...perspective };
                newPerspective.name = newValue;
                void updatePerspective({ project, perspective: newPerspective });
                setPerspective(newPerspective);
              }}
              readOnly={perspective?.createdBy !== kaeplaUser?.uid}
            />
          </Typography>
          <Typography component="div" variant="body2">
            <InlineEdit
              value={perspective.description || ''}
              callback={(newValue) => {
                const newPerspective = { ...perspective };
                newPerspective.description = newValue;
                void updatePerspective({ project, perspective: newPerspective });
                setPerspective(newPerspective);
              }}
              fullWidth
              readOnly={perspective?.createdBy !== kaeplaUser?.uid}
            />
          </Typography>
        </Grid>
        {perspective?.createdBy === kaeplaUser?.uid && (
          <Grid item container xs={3} justifyContent="flex-end">
            <Box>
              <ButtonGroup disableElevation variant="outlined" size="small">
                <Button
                  onClick={() => {
                    // TODO: Delete Dialog
                    void deleteOwnPerspective();
                  }}
                  disabled={perspective?.createdBy !== kaeplaUser?.uid}
                  endIcon={<DeleteIcon sx={{ m: 0.25, ml: -1 }} />}
                />
                <Button
                  onClick={() => {
                    const newPerspective = { ...perspective };
                    newPerspective.locked = !perspective.locked;
                    void updatePerspective({ project, perspective: newPerspective });
                    setPerspective(newPerspective);
                  }}
                  disabled={perspective?.createdBy !== kaeplaUser?.uid}
                  endIcon={
                    perspective.locked ? (
                      <EditIcon sx={{ m: 0.25, ml: -1 }} />
                    ) : (
                      <EditOffIcon sx={{ m: 0.25, ml: -1 }} />
                    )
                  }
                />
              </ButtonGroup>
            </Box>
            <Box sx={{ ml: 2 }}>
              <Button
                size="small"
                onClick={() => {
                  void addBlock();
                }}
                disabled={perspective.locked || perspective?.createdBy !== kaeplaUser?.uid}
                startIcon={<AddIcon />}
              >
                Add Chart
              </Button>
            </Box>
          </Grid>
        )}
        <PerspectiveMainParameters perspective={perspective} setPerspective={setPerspective} />
        {perspective.blocks && (
          <PerspectiveBlocks blocks={perspective.blocks} locked={perspective?.locked || false} />
        )}
      </Grid>
    </Layout>
  );
};
