import { Alert, Box, Button, Grid, TextField, Typography } from '@mui/material';
import {
  MultiFactorInfo,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  PhoneMultiFactorInfo,
  RecaptchaVerifier,
} from 'firebase/auth';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

import { useAuth } from '../../../../AuthProvider';
import { kaeplaAuth } from '../../../../firebaseInit';
import { mfaLog } from '../../../helpers/logger';

interface Options {
  enrolledFactors: MultiFactorInfo[];
  recaptchaVerifier: RecaptchaVerifier | undefined;
  setIsReAuthenticated: Dispatch<SetStateAction<boolean>>;
  setEditable: Dispatch<SetStateAction<boolean>>;
}

export const SecondFactor = ({
  enrolledFactors,
  recaptchaVerifier,
  setIsReAuthenticated,
  setEditable,
}: Options) => {
  const { user, setSecondFactorRequired, multiFactorResolver } = useAuth();
  let phoneFactor: Partial<PhoneMultiFactorInfo> = { phoneNumber: '' };
  if (enrolledFactors.length > 0) {
    phoneFactor = enrolledFactors[0] as PhoneMultiFactorInfo;
  }
  const [phoneNumber, setPhoneNumber] = useState<string>(
    user?.phoneNumber || phoneFactor.phoneNumber || '',
  );
  const [verificationId, setVerificationId] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');

  const getVerificationCode = async () => {
    mfaLog.log('recaptchaVerifier', recaptchaVerifier);
    mfaLog.log('multiFactorResolver', multiFactorResolver);
    if (!recaptchaVerifier || !multiFactorResolver) return null;

    // Specify the phone number and pass the MFA session.
    const phoneInfoOptions = {
      multiFactorHint: multiFactorResolver.hints[0],
      session: multiFactorResolver.session,
    };
    mfaLog.log('phoneInfoOptions', phoneInfoOptions);
    const phoneAuthProvider = new PhoneAuthProvider(kaeplaAuth);
    // Send SMS verification code.
    const verificationIdFromAuthProvider = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier,
    );
    mfaLog.log('verificationIdFromAuthProvider', verificationIdFromAuthProvider);
    setPhoneNumber('');
    setVerificationId(verificationIdFromAuthProvider);
  };

  const verifyWithCode = () => {
    mfaLog.log('verificationId', verificationId);
    mfaLog.log('recaptchaVerifier', recaptchaVerifier);
    mfaLog.log('multiFactorResolver', multiFactorResolver);
    if (!user || verificationId === '' || verificationCode === '' || !multiFactorResolver) return;

    const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);

    void multiFactorResolver
      .resolveSignIn(multiFactorAssertion)
      .then(function (userCredential) {
        mfaLog.log('re-authentication success!');
        setIsReAuthenticated(true);
        setVerificationCode('');
        setVerificationId('');
        setPhoneNumber('');
      })
      .catch(function (error: string) {
        setIsReAuthenticated(false);
        setVerificationCode('');
        setVerificationId('');
        setPhoneNumber('');
        alert(`Error authenticating with phone number. ${error}`);
      });
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Box id="recaptcha-id" />
      {!verificationId && (
        <Grid item xs={12}>
          <Alert data-testid="mfa-info-will-send-code" severity="info" sx={{ mb: 1 }}>
            <Typography variant="caption">
              Press "Get Code" and we'll send a text message with a verification code to your phone.
            </Typography>
          </Alert>
          <TextField
            label="Phone Number"
            variant="standard"
            value={phoneNumber}
            inputProps={{ 'data-testid': 'mfa-phone-number', autoComplete: 'off' }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setPhoneNumber(event.target.value);
            }}
          />
        </Grid>
      )}
      {verificationId && (
        <Grid item xs={12}>
          <Alert data-testid="mfa-info-code-is-sent" severity="info" sx={{ mb: 1 }}>
            <Typography variant="caption">
              We sent a text message with a verification code to your phone. Please enter that code
              below and click "verify".
            </Typography>
          </Alert>
          <TextField
            label="Verification Code"
            variant="standard"
            value={verificationCode}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setVerificationCode(event.target.value);
            }}
            inputProps={{
              'data-testid': 'mfa-verification-code',
              autoComplete: 'off',
            }}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <Button
          data-testid="mfa-button-cancel"
          onClick={() => {
            setEditable(false);
            setSecondFactorRequired(false);
          }}
        >
          cancel
        </Button>
      </Grid>
      {!verificationId && (
        <Grid item xs={6} container justifyContent="flex-end">
          <Button
            data-testid="mfa-button-get-code"
            variant="contained"
            onClick={() => {
              void getVerificationCode();
            }}
          >
            Get Code
          </Button>
        </Grid>
      )}
      {verificationId && (
        <Grid item xs={6} container justifyContent="flex-end">
          <Button
            data-testid="mfa-button-verify"
            variant="contained"
            onClick={() => {
              void verifyWithCode();
            }}
          >
            verify
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
