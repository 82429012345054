import { getApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

import { KaeplaReseller } from '../types/Application/KaeplaReseller';

interface Options {
  id: string;
}

export const getReseller = async ({ id }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `resellers/${id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  const document = await getDoc(firestoreDocumentReference);
  return document.data() as KaeplaReseller;
};
