import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';

export enum HumanReadableTimestampType {
  date = 'Date',
  timeago = 'TimeAgo',
  dateAndTimeago = 'DateAndTimeAgo',
}

interface JsonTimestamp {
  _seconds: number;
  _nanoseconds: number;
}

export const convertTimestamp = (
  _fireStoreTimestamp: Timestamp | JsonTimestamp,
  type?: HumanReadableTimestampType,
) => {
  let fireStoreTimestamp = _fireStoreTimestamp;
  if (
    (_fireStoreTimestamp as JsonTimestamp)._seconds &&
    (_fireStoreTimestamp as JsonTimestamp)._nanoseconds
  ) {
    fireStoreTimestamp = new Timestamp(
      (_fireStoreTimestamp as JsonTimestamp)._seconds,
      (_fireStoreTimestamp as JsonTimestamp)._nanoseconds,
    );
  } else if ((_fireStoreTimestamp as Timestamp).toMillis) {
    fireStoreTimestamp = _fireStoreTimestamp as Timestamp;
  } else {
    return '??';
  }

  const humanReadableTimeStamp = DateTime.fromMillis(fireStoreTimestamp.toMillis()).toFormat(
    "dd LLL yyyy 'at' HH:mm",
  );
  if (type === HumanReadableTimestampType.date) {
    return `${humanReadableTimeStamp}`;
  }
  const relative = DateTime.fromMillis(fireStoreTimestamp.toMillis()).toRelative() || 'now';
  if (type === HumanReadableTimestampType.timeago) {
    return `${relative}`;
  }
  return `${humanReadableTimeStamp} - ${relative}`;
};
