import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useAuth } from '../../../../../../AuthProvider';
import { getFiltersForProject } from '../../../../../../services/firestore/getFiltersForProject';
import { updatePerspectiveBlock } from '../../../../../../services/firestore/updatePerspectiveBlock';
import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState';
import { projectState } from '../../../../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../../../../services/recoil/persistent/currentScopePathState';
import { KaeplaFilter } from '../../../../../../services/types/Application/KaeplaFilter';
import { KaeplaPerspectiveBlock } from '../../../../../../services/types/Application/KaeplaPerspectiveBlock';
import { SelectAvatar } from '../../../../../features/SelectAvatar';

interface Options {
  block: KaeplaPerspectiveBlock;
  blockNumber: number;
}

export const ChartToolbarFilter = ({ block, blockNumber }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [perspective, setPerspective] = useRecoilState(perspectiveState);
  const [filters, setFilters] = useState<KaeplaFilter[]>();
  const [selectedFilter, setSelectedFilter] = useState<string>();

  const updateBlockFilter = async (filterId: string) => {
    const newBlock: KaeplaPerspectiveBlock = { ...block, filterId };
    const newPerspective = await updatePerspectiveBlock({
      project,
      perspective,
      block: newBlock,
      blockNumber,
    });
    setPerspective(newPerspective);
  };

  const handleFilterChange = (event: SelectChangeEvent<string>) => {
    void updateBlockFilter(event.target.value);
  };

  useEffect(() => {
    if (!kaeplaUser) return;
    const loadFilters = async () => {
      const filtersFromServer = await getFiltersForProject({
        project,
        scopePathStringified: JSON.stringify(currentScopePath),
      });
      setFilters(filtersFromServer);
      if (block.filterId !== undefined) {
        setSelectedFilter(block.filterId);
      }
    };
    void loadFilters();
  }, [block, currentScopePath, kaeplaUser, project]);

  return (
    <Select
      id="filterSelect"
      value={selectedFilter || ''}
      label="Select a Filter"
      onChange={handleFilterChange}
      size="small"
      SelectDisplayProps={{
        style: { marginTop: 0, padding: 3, paddingTop: 2, paddingBottom: 3, paddingRight: 40 },
      }}
      autoFocus={false}
      sx={{ minWidth: 200 }}
    >
      <MenuItem dense value="">
        <em>No Filter</em>
      </MenuItem>
      {filters?.map((_filter) => (
        <MenuItem dense key={_filter.id} value={_filter.id} sx={{ whiteSpace: 'nowrap' }}>
          <Box component="span" mr={1}>
            <SelectAvatar uid={_filter.createdBy} />
          </Box>
          {_filter.name}
        </MenuItem>
      ))}
    </Select>
  );
};
