import { Box } from '@mui/material';

import { Layout } from '../Layout/Layout';

export const Logs = () => {
  return (
    <Layout>
      <Box sx={{ height: 100 }}>logs</Box>
    </Layout>
  );
};
