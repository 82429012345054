import DeleteIcon from '@mui/icons-material/DeleteOutline';
import RenameIcon from '@mui/icons-material/EditOutlined';
import ResetIcon from '@mui/icons-material/RestartAltOutlined';
import SetupIcon from '@mui/icons-material/SettingsOutlined';
import { Menu } from '@mui/material';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { Timestamp } from 'firebase/firestore';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ProjectDeleteDialog } from './ProjectDeleteDialog';
import { ProjectRenameDialog } from './ProjectRenameDialog';
import { useAuth } from '../../../../AuthProvider';
import { fireBaseDeleteField } from '../../../../services/firestore/_firestoreShorthands';
import { takeOverProject } from '../../../../services/firestore/takeOverProject';
import { updateProject } from '../../../../services/firestore/updateProject';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { kaeplaAssignmentState } from '../../../../services/recoil/persistent/kaeplaAssignmentState';
import { KaeplaPerspective } from '../../../../services/types/Application/KaeplaPerspective';
import { KaeplaProject } from '../../../../services/types/Application/KaeplaProject';

interface Options {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  handleGenerateDummyData: () => void;
  project: KaeplaProject;
}
export const CardMenu = ({
  anchorEl,
  open,
  onClose,
  handleGenerateDummyData,
  project,
}: Options) => {
  const navigate = useNavigate();
  const setProject = useSetRecoilState(projectState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const { kaeplaUser } = useAuth();

  const [openProjectRename, setOpenProjectRename] = useState(false);
  const [openProjectDelete, setOpenProjectDelete] = useState(false);

  const handleCloseRenameDialog = () => {
    setOpenProjectRename(false);
  };

  const handleCloseDeleteDialog = () => {
    setOpenProjectDelete(false);
  };

  const resetAndSetup = () => {
    const newProject = { ...project, id: project.id } as KaeplaProject;
    delete newProject.defaultPerspective;
    delete newProject.initializedAt;
    setProject(newProject);
    const projectUpdate = { ...newProject, id: project.id } as KaeplaProject;
    projectUpdate.initializedAt = fireBaseDeleteField() as Timestamp;
    projectUpdate.defaultPerspective = fireBaseDeleteField() as unknown as KaeplaPerspective;
    void updateProject({ project: projectUpdate });
    navigate(`/Project/${project.id}`);
  };

  if (!open) return null;

  return (
    <>
      <Menu anchorEl={anchorEl} open onClose={onClose}>
        {project.createdBy === kaeplaUser?.uid && (
          <MenuItem
            data-testid="project-rename"
            onClick={(event) => {
              event.stopPropagation();
              setOpenProjectRename(true);
            }}
          >
            <ListItemIcon>
              <RenameIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Rename</ListItemText>
          </MenuItem>
        )}

        {project.createdBy === kaeplaUser?.uid && (
          <MenuItem
            data-testid="project-generate-dummy-data"
            onClick={(event) => {
              event.stopPropagation();
              handleGenerateDummyData();
            }}
          >
            <ListItemIcon>
              <SetupIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Generate Dummy Data</ListItemText>
          </MenuItem>
        )}

        {project.createdBy === kaeplaUser?.uid && (
          <MenuItem
            data-testid="project-setup"
            onClick={(event) => {
              event.stopPropagation();
              resetAndSetup();
            }}
            disabled={project.matrixUnavailable}
          >
            <ListItemIcon>
              <SetupIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Setup</ListItemText>
          </MenuItem>
        )}

        {project.createdBy === kaeplaUser?.uid && (
          <MenuItem
            data-testid="project-reset"
            onClick={(event) => {
              event.stopPropagation();
              resetAndSetup();
            }}
            disabled={!project.initializedAt}
          >
            <ListItemIcon>
              <ResetIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Reset</ListItemText>
          </MenuItem>
        )}

        {kaeplaUser && kaeplaAssignment?.devTeamMember && project.createdBy !== kaeplaUser?.uid && (
          <MenuItem
            data-testid="project-takeover"
            onClick={(event) => {
              event.stopPropagation();
              void takeOverProject({ project, uid: kaeplaUser.uid });
            }}
          >
            <ListItemText>Take Over</ListItemText>
          </MenuItem>
        )}

        {(kaeplaAssignment || project.createdBy === kaeplaUser?.uid) && <Divider />}

        {(kaeplaAssignment || project.createdBy === kaeplaUser?.uid) && (
          <MenuItem
            // onClick={(event) => {
            //   event.stopPropagation();
            //   void deleteProject({ project });
            // }}
            data-testid="project-delete"
            onClick={(event) => {
              event.stopPropagation();
              setOpenProjectDelete(true);
            }}
          >
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <ProjectRenameDialog
        open={openProjectRename}
        handleClose={handleCloseRenameDialog}
        handleMenuClose={onClose}
        project={project}
      />
      <ProjectDeleteDialog
        open={openProjectDelete}
        handleClose={handleCloseDeleteDialog}
        handleMenuClose={onClose}
        project={project}
      />
    </>
  );
};
