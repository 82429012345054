import { Button, Grid, TextField, Typography } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { ChangeEvent, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useDebouncedCallback } from 'use-debounce';

import { fireBaseTimestamp } from '../../../../services/firestore/_firestoreShorthands';
import { updateProject } from '../../../../services/firestore/updateProject';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { KaeplaPerspective } from '../../../../services/types/Application/KaeplaPerspective';
import { KaeplaProject } from '../../../../services/types/Application/KaeplaProject';
import { MatrixTimeSlice } from '../../../../services/types/MatrixTimeSlice';
import { DataTimeline } from '../features/DataTimeline/DataTimeline';
import { chartTitle } from '../helpers/aggregationOverTime';

interface Options {
  defaultPerspective: Partial<KaeplaPerspective>;
  setDefaultPerspective: (step: Partial<KaeplaPerspective>) => void;
}

export const StepDone = ({ defaultPerspective, setDefaultPerspective }: Options) => {
  const [project, setProject] = useRecoilState(projectState);

  useEffect(() => {
    setProject((_project) => {
      const newProject = { id: _project.id } as KaeplaProject;
      newProject.defaultPerspective = { ...defaultPerspective } as KaeplaPerspective;
      newProject.defaultPerspective.timeSlice = 'QUARTER' as MatrixTimeSlice;
      newProject.defaultPerspective.scopePath = [];
      newProject.defaultPerspective.scopePathStringified = '[]';
      void updateProject({ project: newProject });
      return { ..._project, ...newProject };
    });
  }, [defaultPerspective, setProject]);

  const changeAggregationOverTimeName = useDebouncedCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newDefaulterspective: Partial<KaeplaPerspective> = {
        ...defaultPerspective,
        aggregationOverTimeName: event.target.value,
      };
      setDefaultPerspective(newDefaulterspective);
    },
    500,
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container justifyContent="center">
        <Typography sx={{ fontSize: '110%' }}>
          How do you want to call "
          {project?.defaultPerspective && chartTitle(project.defaultPerspective)}" (e.g. "Turnover")
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <TextField
          required
          id="outlined-required"
          label="Required"
          defaultValue=""
          onChange={changeAggregationOverTimeName}
        />
      </Grid>
      <Grid item xs={12}>
        <DataTimeline />
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Button
          variant="contained"
          size="large"
          sx={{ mt: 4 }}
          onClick={() => {
            const newProject = { id: project.id } as KaeplaProject;
            newProject.initializedAt = fireBaseTimestamp() as Timestamp;
            newProject.defaultPerspective = defaultPerspective as KaeplaPerspective;
            setProject({ ...project, ...newProject });
            void updateProject({ project: newProject });
          }}
        >
          Done
        </Button>
      </Grid>
    </Grid>
  );
};
