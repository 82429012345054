import { Alert, Box, Collapse, Paper } from '@mui/material';
import {
  Utils as QbUtils,
  Query,
  Builder,
  Config,
  ImmutableTree,
  BuilderProps,
  JsonTree,
  GroupProperties,
} from '@react-awesome-query-builder/mui';
// eslint-disable-next-line import/no-unassigned-import
import '@react-awesome-query-builder/mui/css/compact_styles.css';
// eslint-disable-next-line import/no-unassigned-import
import './helpers/filters.css';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { matrixLoadingState } from 'src/services/recoil/nonpersistent/matrixLoadingState';
import { useDebouncedCallback } from 'use-debounce';

import { FilterActions } from './features/FilterActions';
import { FilterSQL } from './features/FilterSQL';
import { FilterToolbar } from './features/FilterToolbar';
import { buttonsOnHover, filterConfigInit, KaeplaConfig } from './helpers/filterConfig';
import { getFieldSettings } from './helpers/getFieldSettings';
import { getRuleCountFromFilterTree } from './helpers/getRuleCountFromFilterTree';
import { useAuth } from '../../../AuthProvider';
import { knownProjectFiltersState } from '../../../services/recoil/nonpersistent/knownProjectFiltersState';
import { matrixFilteredState } from '../../../services/recoil/nonpersistent/matrixFilteredState';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { filterSettingsState } from '../../../services/recoil/persistent/filterSettingState';
import { filterTreeState } from '../../../services/recoil/persistent/filterTreeState';

export const Filters = () => {
  const { kaeplaUser } = useAuth();
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const matrixLoading = useRecoilValue(matrixLoadingState);
  const project = useRecoilValue(projectState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const knownProjectFilters = useRecoilValue(knownProjectFiltersState);
  const [filterSettings, setFilterSettings] = useRecoilState(filterSettingsState);
  const [filterTree, setFilterTree] = useRecoilState<JsonTree>(filterTreeState);
  const [filters, setFilters] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(filterTree), filterConfigInit),
    config: filterConfigInit,
  });
  const [config, setConfig] = useState<Config>(filterConfigInit);
  const [choosePreset, setChoosePreset] = useState(false);

  const toggleFilterActive = useCallback(() => {
    // if we do have children in the current tree, the filter is active
    if (
      !filterTree?.children1 ||
      (filterTree?.children1 && (filterTree.children1 as unknown as []).length === 0)
    ) {
      setFilterSettings((_filterSettings) => ({ ..._filterSettings, isActive: false }));
    }
  }, [filterTree.children1, setFilterSettings]);

  const applyFilter = () => {
    const jsonTree = QbUtils.getTree(filters.tree);
    setFilterTree(jsonTree);
    setFilterSettings({ ...filterSettings, isApplied: true });
  };

  useEffect(() => {
    if (matrixLoading) return;
    if (!matrixFiltered?.dimensions || !kaeplaUser?.uid) return;
    setFilterSettings((_filterSettings) => ({
      ..._filterSettings,
      isInitialized: false,
    }));
    const load = () => {
      let UserSettings: Config = {
        ...KaeplaConfig,
      };

      const filterFromKnownFilters = knownProjectFilters?.find(
        (k) => k.id === filterSettings?.filterPresetId,
      );

      if (filterFromKnownFilters) {
        UserSettings = {
          ...KaeplaConfig,
          settings: {
            ...KaeplaConfig.settings,
            immutableGroupsMode: kaeplaUser.uid !== filterFromKnownFilters.createdBy,
            immutableFieldsMode: kaeplaUser.uid !== filterFromKnownFilters.createdBy,
            immutableOpsMode: kaeplaUser.uid !== filterFromKnownFilters.createdBy,
            immutableValuesMode: kaeplaUser.uid !== filterFromKnownFilters.createdBy,
          },
        };
      }

      const queryBuilderConfig: Config = {
        ...UserSettings,
        fields: getFieldSettings({
          project,
          currentScopePath,
          uid: kaeplaUser.uid,
          matrixFiltered,
        }),
      };

      setConfig(queryBuilderConfig);

      toggleFilterActive();

      const immutableTree = QbUtils.checkTree(QbUtils.loadTree(filterTree), queryBuilderConfig);
      setFilters({
        tree: immutableTree,
        config: queryBuilderConfig,
      });

      const ruleCount = getRuleCountFromFilterTree(filterTree);
      setFilterSettings((_filterSettings) => ({
        ..._filterSettings,
        isInitialized: true,
        isApplied: false,
        ruleCount,
        filterCreatedBy: filterFromKnownFilters?.createdBy || kaeplaUser?.uid,
      }));
    };

    void load();
  }, [
    matrixFiltered,
    filterTree.id,
    filterSettings?.filterPresetId,
    project.id,
    kaeplaUser?.uid,
    knownProjectFilters,
    project,
    currentScopePath,
    toggleFilterActive,
    filterTree,
    setFilterSettings,
    matrixLoading,
  ]);

  const onChange = useDebouncedCallback((immutableTree: ImmutableTree, _config: Config) => {
    // we want the outer group always to have AND as a conjunction
    let checkedTree = immutableTree;
    let tree = QbUtils.getTree(immutableTree);
    const treeProperties = { ...tree }.properties as GroupProperties;
    if (treeProperties && treeProperties.conjunction === 'OR') {
      // console.log('!! - changing outer conjunction to AND');
      treeProperties.conjunction = 'AND';
      checkedTree = QbUtils.checkTree(QbUtils.loadTree(tree), _config);
      tree = QbUtils.getTree(checkedTree);
    }

    toggleFilterActive();

    setFilters((previousState) => ({ ...previousState, tree: checkedTree, config: _config }));
    const isApplied = JSON.stringify(tree) === JSON.stringify(filterTree);
    const ruleCount = getRuleCountFromFilterTree(tree);
    setFilterSettings({ ...filterSettings, isApplied, ruleCount });
  }, 1000);

  // without class qb-lite buttons are always rendered
  const renderBuilder = useCallback(
    (properties: BuilderProps) => (
      <Box className="query-builder-container">
        <Box className={`query-builder ${buttonsOnHover ? 'qb-lite' : ''}`}>
          <Builder {...properties} />
        </Box>
      </Box>
    ),
    [],
  );

  return (
    <Paper sx={{ padding: 1 }}>
      <FilterToolbar
        tree={filters.tree}
        config={filters.config}
        choosePreset={choosePreset}
        setChoosePreset={setChoosePreset}
      />
      <FilterSQL tree={filters.tree} config={filters.config} />
      <Collapse in={filterSettings.isExpanded && !choosePreset} timeout="auto" unmountOnExit>
        {filterSettings.ruleCount === 0 && filterSettings.filterCreatedBy === kaeplaUser?.uid && (
          <Alert severity="info">Create a new filter by adding a filter rule group!</Alert>
        )}
        {filterSettings.ruleCount > 0 && filterSettings.filterCreatedBy !== kaeplaUser?.uid && (
          <Alert severity="info">
            You can't edit other user's filters, but you can copy (duplicate) them.
          </Alert>
        )}
        <Query {...config} value={filters.tree} onChange={onChange} renderBuilder={renderBuilder} />
        <FilterActions applyFilter={applyFilter} />
      </Collapse>
    </Paper>
  );
};
