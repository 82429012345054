import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useRecoilState } from 'recoil';

import { deleteReseller } from '../../../../services/firestore/deleteReseller';
import { knownResellersState } from '../../../../services/recoil/nonpersistent/knownResellersState';
import { KaeplaReseller } from '../../../../services/types/Application/KaeplaReseller';
import { ResellerAvatar } from '../../../features/ResellerAvatar';

interface DDOptions {
  openDelete: boolean;
  handleCloseDelete: () => void;
  reseller: KaeplaReseller;
}
export const ResellerDeleteDialog = ({ openDelete, handleCloseDelete, reseller }: DDOptions) => {
  const [knownResellers, setKnownResellers] = useRecoilState(knownResellersState);
  return (
    <Dialog open={openDelete} onClose={handleCloseDelete}>
      <DialogTitle>
        Delete reseller?
        <IconButton
          size="small"
          sx={{ position: 'absolute', top: 0, right: 0 }}
          onClick={handleCloseDelete}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <CardHeader
          avatar={
            <ResellerAvatar title={reseller.name} icon={reseller.iconBase64} id={reseller.id} />
          }
          title={
            <Tooltip title={reseller.id}>
              <Box>{reseller.name}</Box>
            </Tooltip>
          }
          subheader={reseller.appName}
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="error"
          onClick={() => {
            handleCloseDelete();
            setKnownResellers(
              knownResellers.filter((k) => !!k).filter((k) => k.id !== reseller.id),
            );
            void deleteReseller({ resellerId: reseller.id });
          }}
        >
          delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
