import { Alert, Divider, Grid, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { projectTargetsState } from 'src/services/recoil/nonpersistent/projectTargetsState';

import { TargetsCard } from './TargetsCard';
import { useAuth } from '../../../AuthProvider';
import { createEvent } from '../../../services/firestore/createEvent';
import { projectState } from '../../../services/recoil/nonpersistent/projectState';
import { currentScopePathState } from '../../../services/recoil/persistent/currentScopePathState';
import { KaeplaDataOperation } from '../../../services/types/Application/KaeplaDataOperation';
import { KaeplaEventType } from '../../../services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from '../../../services/types/Application/KaeplaFunctionGroup';
import { KaeplaTargets } from '../../../services/types/Application/KaeplaTargets';

interface Options {
  showAllTargetsForProject: boolean;
}

export const TargetsOverview = ({ showAllTargetsForProject }: Options) => {
  const { kaeplaUser } = useAuth();
  const project = useRecoilValue(projectState);
  const projectTargets = useRecoilValue(projectTargetsState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const [ownTargets, setOwnTargets] = useState<KaeplaTargets[]>([]);
  const [otherTargets, setOtherTargets] = useState<KaeplaTargets[]>([]);

  const loadTargets = useCallback(() => {
    if (!kaeplaUser) return;
    const targetsFiltered = [...projectTargets].filter((s) => {
      if (showAllTargetsForProject) {
        return true;
      } else {
        if (s.scopePathStringified === JSON.stringify(currentScopePath)) {
          return true;
        }
      }
      return false;
    });
    const own = targetsFiltered.filter((s) => s.createdBy === kaeplaUser.uid);
    setOwnTargets(own);
    const other = targetsFiltered.filter((s) => s.createdBy !== kaeplaUser.uid);
    setOtherTargets(other);
  }, [currentScopePath, kaeplaUser, showAllTargetsForProject, projectTargets]);

  // this is the targetsListener
  useEffect(() => {
    if (!kaeplaUser || !project.id) return;

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.TARGETS_ENTER_TARGETS_OVERVIEW,
      functionGroup: KaeplaFunctionGroup.TARGETS,
      operation: KaeplaDataOperation.READ,
      scopePath: currentScopePath,
      project,
    });

    void loadTargets();
  }, [currentScopePath, kaeplaUser, loadTargets, project]);

  return (
    <Grid container spacing={1}>
      {ownTargets.length > 0 ? (
        <Grid xs={12} item>
          <Typography variant="h6">Own Targets</Typography>
        </Grid>
      ) : (
        <Grid xs={12} item>
          <Alert variant="outlined" severity="info">
            Your have no targets for this project scope. Create one!
          </Alert>
        </Grid>
      )}
      {ownTargets?.map((_targets) => {
        return (
          <Grid xs={12} sm={6} md={4} lg={3} key={_targets.id} item>
            <TargetsCard targets={_targets} own />
          </Grid>
        );
      })}
      {otherTargets.length > 0 && (
        <Grid xs={12} item>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Typography variant="h6">Other Targets</Typography>
        </Grid>
      )}
      {otherTargets?.map((_targets) => {
        return (
          <Grid xs={12} sm={6} md={4} lg={3} key={_targets.id} item>
            <TargetsCard targets={_targets} />
          </Grid>
        );
      })}
    </Grid>
  );
};
