import { Box, Stack } from '@mui/material';
import { useRecoilValue } from 'recoil';

import { knownResellersState } from '../../../services/recoil/nonpersistent/knownResellersState';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState';
import { ResellerAvatar } from '../../features/ResellerAvatar';

interface Options {
  resellerId: string;
}

export const KaeplaAdminResellerSwitch = ({ resellerId }: Options) => {
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const knownResellers = useRecoilValue(knownResellersState);

  return (
    <Stack direction="row" spacing={{ xs: 0.5, sm: 1, md: 2 }}>
      {kaeplaAssignment &&
        knownResellers.map((r) => (
          <Box
            key={r.id}
            sx={{
              cursor: r.id === resellerId ? 'inherit' : 'pointer',
              opacity: r.id === resellerId ? 1 : 0.5,
            }}
          >
            <ResellerAvatar title={r.name} icon={r.iconBase64} id={r.id} medium />
          </Box>
        ))}
    </Stack>
  );
};
