import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { Box, Button, Checkbox, Paper, Toolbar, Typography } from '@mui/material';
import { KaeplaTargets } from 'src/services/types/Application/KaeplaTargets';
import { KaeplaTargetsFigure } from 'src/services/types/Application/KaeplaTargetsFigure';

import { targetsColumnWidth } from './defaults';
import {
  matrixDataColor,
  targetsDataColor,
  targetsDataColorLowerLight,
  targetsDataColorUpperLight,
} from '../defaults';

interface Options {
  targets: Partial<KaeplaTargets>;
  batchActionsEnabled: boolean;
  setBatchActionsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTargetFigures: KaeplaTargetsFigure[];
  editable: boolean;
  batchDeleteTargetFigures: () => void;
  setSelectedTargetFigures: React.Dispatch<React.SetStateAction<KaeplaTargetsFigure[]>>;
}

export const TargetFigureHeaderLine = ({
  targets,
  editable,
  batchActionsEnabled,
  setBatchActionsEnabled,
  selectedTargetFigures,
  batchDeleteTargetFigures,
  setSelectedTargetFigures,
}: Options) => {
  return (
    <Box
      component={Paper}
      square
      elevation={0}
      borderBottom={1}
      borderColor="lightgray"
      sx={{ px: 2 }}
    >
      <Toolbar disableGutters variant="dense">
        <Typography variant="body1" sx={{ width: targetsColumnWidth }}>
          Year
        </Typography>
        <Typography variant="body1" sx={{ width: targetsColumnWidth, color: matrixDataColor }}>
          original value
        </Typography>
        <Typography variant="body1" sx={{ width: targetsColumnWidth, color: targetsDataColor }}>
          target
        </Typography>
        <Typography variant="body1" sx={{ width: targetsColumnWidth }}>
          <Box component="span" sx={{ color: targetsDataColorUpperLight }}>
            best
          </Box>
          <Box component="span" sx={{ mx: 1 }}>
            /
          </Box>
          <Box component="span" sx={{ color: targetsDataColorLowerLight }}>
            worst
          </Box>
        </Typography>
        <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        {batchActionsEnabled && selectedTargetFigures.length > 0 && (
          <Button
            variant="contained"
            aria-label="delete selected"
            size="small"
            disabled={!editable}
            onClick={() => {
              batchDeleteTargetFigures();
            }}
            startIcon={<DeleteIcon fontSize="small" />}
          >
            delete selected
          </Button>
        )}
        <Checkbox
          size="small"
          edge="end"
          value={batchActionsEnabled}
          disabled={!editable}
          onChange={() => {
            setSelectedTargetFigures([]);
            setBatchActionsEnabled(!batchActionsEnabled);
          }}
        />
      </Toolbar>
    </Box>
  );
};
