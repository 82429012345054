import { getApp } from 'firebase/app';
import { DocumentData, getFirestore, doc, updateDoc } from 'firebase/firestore';

import { KaeplaPerspective } from '../types/Application/KaeplaPerspective';
import { KaeplaPerspectiveBlock } from '../types/Application/KaeplaPerspectiveBlock';
import { KaeplaProject } from '../types/Application/KaeplaProject';

interface Options {
  project: KaeplaProject;
  perspective?: Partial<KaeplaPerspective>;
  block: KaeplaPerspectiveBlock;
}

export const clonePerspectiveBlock = async ({ project, perspective, block }: Options) => {
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  if (!perspective?.id) return perspective as KaeplaPerspective;
  const fireStorePath = `projects/${project.id}/perspectives/${perspective.id}`;
  const documentReference = doc(firestoreDatabase, fireStorePath);
  if (!perspective?.blocks) return perspective;
  const blocks = [...perspective.blocks];
  blocks.unshift(block);
  const _perspective: Partial<KaeplaPerspective> = {
    ...perspective,
    blocks,
  };
  await updateDoc(documentReference, _perspective as DocumentData);
  return _perspective as KaeplaPerspective;
};
