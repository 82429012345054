import ReloadUsersIcon from '@mui/icons-material/CachedOutlined';
import { IconButton, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { clone } from 'rambda';
import { useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useAuth } from '../../../AuthProvider';
import { getUsersForUser } from '../../../services/api/getUsersForUser';
import { applicationState } from '../../../services/recoil/nonpersistent/applicationState';
import { kaeplaAssignmentState } from '../../../services/recoil/persistent/kaeplaAssignmentState';
import { knownUsersState } from '../../../services/recoil/persistent/knownUsersState';
import { KaeplaUser } from '../../../services/types/Application/KaeplaUser';
import { Layout } from '../../Layout/Layout';
import { UserAvatar } from '../../features/UserAvatar';
import { convertTimestamp, HumanReadableTimestampType } from '../../helpers/convertTimestamp';

export const Users = () => {
  const { kaeplaUser } = useAuth();
  const [app, setApp] = useRecoilState(applicationState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const [knownUsers, setKnownUsers] = useRecoilState(knownUsersState);
  const [loading, setLoading] = useState(false);
  const tableContainerReference = useRef<HTMLDivElement>(null); // we can get access to the underlying TableContainer element and react to its scroll events

  const loadUsers = (users: KaeplaUser[]) => {
    setKnownUsers(users);
  };

  const getUsers = () => {
    if (!kaeplaUser) return;
    const newApplication = clone(app);
    const date = DateTime.now();
    const humanReadable = date.toLocaleString(DateTime.DATETIME_MED);
    newApplication.usersLastFetched = humanReadable;
    setApp(newApplication);
    getUsersForUser({ params: { uid: kaeplaUser.uid }, callBack: loadUsers, setLoading });
  };

  if (!kaeplaUser?.uid) return null;

  const columns: MRT_ColumnDef<KaeplaUser>[] = [
    {
      accessorFn: (row) => `${row.displayName} ${row.email}`, // accessorFn used to join multiple data into a single cell
      id: 'user',
      header: 'User',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Cell: ({ row }) => {
        const user = row.original as unknown as KaeplaUser;
        return (
          <ListItem sx={{ p: 0 }} dense disableGutters>
            <ListItemAvatar>
              <UserAvatar user={user} size={50} />
            </ListItemAvatar>
            <ListItemText
              primary={`${user.displayName} (${user.acronym})`}
              secondary={user.email}
              primaryTypographyProps={{ sx: { fontSize: '110%' } }}
              secondaryTypographyProps={{ sx: { fontSize: '110%' } }}
            />
          </ListItem>
        );
      },
    },
    {
      accessorKey: 'updatedAt',
      id: 'lastUpdate',
      header: 'Last Update',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Cell: ({ cell, row }) => {
        const user = row.original as unknown as KaeplaUser;
        const updatedAt = user.updatedAt;
        return (
          <ListItem sx={{ p: 0 }} dense disableGutters>
            <ListItemText
              primary={
                updatedAt ? convertTimestamp(updatedAt, HumanReadableTimestampType.date) : '??'
              }
              secondary={
                updatedAt && convertTimestamp(updatedAt, HumanReadableTimestampType.timeago)
              }
            />
          </ListItem>
        );
      },
    },
  ];

  return (
    <Layout>
      <MaterialReactTable
        columns={columns}
        data={knownUsers}
        initialState={{
          density: 'compact',
          showGlobalFilter: true,
        }}
        state={{ isLoading: loading }}
        positionGlobalFilter="left"
        filterFns={{
          userSearchFilter: (row, id, filterValue: string) => {
            const rowFound: string = row.getValue(id);
            return rowFound.toLowerCase().includes(filterValue.toLowerCase());
          },
        }}
        globalFilterFn="userSearchFilter"
        layoutMode="grid"
        enablePagination={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableStickyHeader
        enableStickyFooter
        muiTableContainerProps={{
          ref: tableContainerReference, // get access to the table container element
          sx: { maxHeight: '400px' }, // give the table a max height
        }}
        muiTableHeadCellProps={{
          sx: {
            display: 'none',
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            p: '2px 16px',
          },
        }}
        renderToolbarInternalActions={() => {
          if (!kaeplaUser) return;
          return (
            <>
              {app.usersLastFetched && (
                <Typography variant="caption">
                  {knownUsers.length} users, last reload {app.usersLastFetched}
                </Typography>
              )}
              {!kaeplaAssignment && (
                <IconButton color="primary" onClick={getUsers}>
                  <ReloadUsersIcon />
                </IconButton>
              )}
            </>
          );
        }}
        renderBottomToolbarCustomActions={() => <Typography>{knownUsers.length} users</Typography>}
      />
    </Layout>
  );
};
