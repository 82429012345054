import { DragEndEvent } from '@dnd-kit/core';
import { move } from 'rambda';
import { Dispatch, SetStateAction } from 'react';

import { updateSimulationWithScope } from '../../../../../services/firestore/updateSimulationWithScope';
import { KaeplaProject } from '../../../../../services/types/Application/KaeplaProject';
import { KaeplaSimulation } from '../../../../../services/types/Application/KaeplaSimulation';
import { KaeplaSimulationRuleset } from '../../../../../services/types/Application/KaeplaSimulationRuleset';

interface Options {
  event: DragEndEvent;
  simulation: KaeplaSimulation;
  setSimulation: Dispatch<SetStateAction<KaeplaSimulation | undefined>>;
  project: KaeplaProject;
  scopePath: string[];
}

export const handleRulesetDragEnd = ({
  project,
  scopePath,
  event,
  simulation,
  setSimulation,
}: Options) => {
  const rulesets = [...simulation.rulesets];
  const rulesetIdMap = rulesets.map((r) => r.id);
  const active = rulesetIdMap.indexOf(event.active.id as string);
  const over = rulesetIdMap.indexOf(event?.over?.id as string);
  const newRulesetIdMap = move(active, over, rulesetIdMap);
  const newRulesets: KaeplaSimulationRuleset[] = [];
  newRulesetIdMap.forEach((id, index) => {
    const set = rulesets.find((r) => r.id === id);
    if (set) {
      const position = index + 1;
      newRulesets.push({ ...set, position });
    }
  });
  const newSimulation = { ...simulation };
  newSimulation.rulesets = newRulesets;
  setSimulation(newSimulation);
  void updateSimulationWithScope({ project, scopePath, simulation: newSimulation });
};
