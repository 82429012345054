import { SetterOrUpdater } from 'recoil';

import { DimensionPathOptions } from './addDimensionToPath';
import { MatrixSummary } from '../../../services/types/MatrixSummary';

interface HandlePathChangeOptions {
  matrix: MatrixSummary;
  scopePath: string[];
  projectId: string;
  scopePaths: Record<string, string[] | undefined>;
  index: number;
  newValue: string;
  addDimensionToPath: (data: DimensionPathOptions) => string[];
  setScopePaths: SetterOrUpdater<Record<string, string[] | undefined>>;
  setCurrentScopePath: SetterOrUpdater<string[]>;
}

export const handlePathChange = ({
  matrix,
  scopePath,
  projectId,
  scopePaths,
  index,
  newValue,
  addDimensionToPath,
  setScopePaths,
  setCurrentScopePath,
}: HandlePathChangeOptions) => {
  let newDimension = newValue;
  let newScopePath = [...scopePath];
  if (newValue && `${newValue}`.includes('changeDimension!!')) {
    newDimension = newValue.replace('changeDimension!!', '');
    newScopePath.splice(index, newScopePath.length - index);
  }
  newScopePath = addDimensionToPath({
    matrix,
    index,
    newDimension,
    scopePath: newScopePath,
  });
  const newScopePaths = { ...scopePaths };
  newScopePaths[projectId] = newScopePath;
  setScopePaths(newScopePaths);
  setCurrentScopePath(newScopePaths[projectId] || []);
};
