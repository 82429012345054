import { Alert, Box, Grid, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { DummyDataCard } from './DummyDataCard';
import { useAuth } from '../../../../AuthProvider';
import { getFromKaepla } from '../../../../services/api/getFromKaepla';
import { addProjectListener } from '../../../../services/firestore/addProjectListener';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { KaeplaApiParameters } from '../../../../services/types/Application/KaeplaApiParameters';
import { KaeplaProject } from '../../../../services/types/Application/KaeplaProject';
import { KaeplaQueryType } from '../../../../services/types/Application/KaeplaQueryType';

export const CreateDummyMatrix = () => {
  const { kaeplaUser } = useAuth();
  const [project, setProject] = useRecoilState(projectState);
  const [loading, setLoading] = useState(false);

  // this is the projectListener
  useEffect(() => {
    const unsubscribe = addProjectListener({
      projectId: project.id,
      callback: (dataFromServer) => {
        const projectFromServer = dataFromServer as KaeplaProject;
        setProject(projectFromServer);
        if (projectFromServer.matrixUnavailable === false) {
          setLoading(false);
        }
      },
    });
    return () => {
      unsubscribe();
    };
  }, [project.id, setProject]);

  const generateData = (factor: number) => {
    const parameters: KaeplaApiParameters = {
      q: 'generateData' as KaeplaQueryType,
      projectId: project.id,
      limit: factor,
      s: 'DummyDataGenerator',
    };
    setLoading(true);
    getFromKaepla({
      params: parameters,
      uid: kaeplaUser?.uid,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" data-testid="create-dummy-data-header">
          Create Dummy Data
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="info" data-testid="create-dummy-data-info">
          <Typography variant="subtitle1">
            Currently your data can't be imported, but you can generate a set of dummy data.
          </Typography>
          Depending on the size of the dataset this may take from a few seconds to some minutes.
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" data-testid="create-dummy-data-callout">
          Select ~ number of records
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {loading || project.matrixInPreparation ? <LinearProgress /> : <Box sx={{ height: 4 }} />}
      </Grid>
      {project.matrixInPreparation && (
        <Grid item xs={12} data-testid="dummy-is-being-prepared">
          Data is being prepared now
        </Grid>
      )}
      {[
        { factor: 1, p: '~30k records', s: '~20sec.', d: false },
        { factor: 4, p: '~100k records', s: '~25sec.', d: false },
        { factor: 12, p: '~300k records', s: '~30sec.', d: false },
        { factor: 37, p: '~1m records', s: '~40sec.', d: false },
        { factor: 120, p: '~3m records', s: '~2min.', d: false },
        { factor: 200, p: '~5m records', s: '~3min.', d: true },
        { factor: 320, p: '~10m records', s: '~5min.', d: true },
      ].map((d) => (
        <Grid key={d.p} item xs={12} sm={6} md={4} lg={3} xl={2}>
          <DummyDataCard
            factor={d.factor}
            loading={loading}
            generateData={generateData}
            primary={d.p}
            secondary={d.s}
            disabled={d.d}
          />
        </Grid>
      ))}
    </Grid>
  );
};
