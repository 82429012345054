import { ChartOptions, TooltipItem } from 'chart.js';
import numbro from 'numbro';

import { cleanColumnName } from '../../../../../../helpers/cleanColumnName';

const formatLabel = (context: TooltipItem<'doughnut'>) => {
  const label = context.dataset.label || 'n/a';
  const value = context.parsed;
  const valuehr =
    '€' +
    numbro(value).format({
      average: true,
      mantissa: 1,
    });
  return `${cleanColumnName(label)}: ${valuehr}`;
};

export const donutChartConfig: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: formatLabel,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
      border: {
        display: false,
      },
    },
  },
};
