import {
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  Typography,
  Box,
  InputLabel,
} from '@mui/material';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useUserPreferences } from 'src/UserPreferencesProvider';
import { perspectiveFavoritesState } from 'src/services/recoil/nonpersistent/perspectiveFavoritesState';
import { perspectivesState } from 'src/services/recoil/nonpersistent/perspectivesState';
import { KaeplaPerspective } from 'src/services/types/Application/KaeplaPerspective';

import { perspectiveState } from '../../../../../../services/recoil/nonpersistent/perspectiveState';
import { SelectAvatar } from '../../../../../features/SelectAvatar';

export const SelectPerspective = () => {
  const { setPreferences } = useUserPreferences();
  const perspectives = useRecoilValue(perspectivesState);
  const perspectiveFavorites = useRecoilValue(perspectiveFavoritesState);
  const resetPerspective = useResetRecoilState(perspectiveState);
  const [perspective, setPerspective] = useRecoilState(perspectiveState);

  const isFavorite = (s: KaeplaPerspective) => {
    return (
      perspectiveFavorites.filter((sf) => sf.isFavorite).some((sf) => sf.perspectiveId === s.id) ||
      s.id === 'default'
    );
  };

  const handleChange = (event: SelectChangeEvent) => {
    const chosenPerspective = perspectives.find(
      (_perspective) => _perspective.id === event.target.value,
    );
    if (chosenPerspective) {
      setPerspective(chosenPerspective);
      setPreferences({ lastPerspectiveId: chosenPerspective.id });
    } else {
      resetPerspective();
    }
  };

  if (!perspectives || perspectives?.length === 0) {
    return (
      <Typography sx={{ fontSize: '100%' }} color="text.secondary">
        no perspectives yet
      </Typography>
    );
  }

  return (
    <FormControl
      fullWidth
      sx={{ mt: 0, p: 0, minWidth: 160 }}
      size="small"
      margin="dense"
      variant="standard"
    >
      <InputLabel size="small" id="perspective-label">
        Perspective
      </InputLabel>
      {perspectives && (
        <Select
          id="perspectiveSelect"
          value={perspective?.id || 'default'}
          label="Select a Perspective"
          onChange={handleChange}
          size="small"
          SelectDisplayProps={{ style: { marginTop: 0, padding: 0, paddingRight: 16 } }}
          disableUnderline
          autoFocus={false}
        >
          {perspectives
            ?.filter((s) => isFavorite(s))
            .map((_perspective) => (
              <MenuItem
                dense
                key={_perspective.id}
                value={_perspective.id}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <Box component="span" mr={1}>
                  <SelectAvatar uid={_perspective.createdBy} />
                </Box>
                {_perspective.name}
              </MenuItem>
            ))}
        </Select>
      )}
    </FormControl>
  );
};
