import {
  Box,
  Button,
  FormControl,
  Grid,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';
import colors from 'nice-color-palettes';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useAuth } from 'src/AuthProvider';
import { projectState } from 'src/services/recoil/nonpersistent/projectState';
import { currentScopePathState } from 'src/services/recoil/persistent/currentScopePathState';

import { AddSimulationParameterTabPanel } from './AddSimulationParameterTabPanel';
import { addNumericParameterToSimulationRulesets } from './_helpers/addNumericParameterToSimulationRulesets';
import { addTextParameterToSimulationRulesets } from './_helpers/addTextParameterToSimulationRulesets';
import { saveRulesets } from './_helpers/saveRulesets';
import { updateSimulationWithScope } from '../../../../services/firestore/updateSimulationWithScope';
import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState';
import { KaeplaDimensionDetails } from '../../../../services/types/Application/KaeplaDimensionDetails';
import { KaeplaSimulation } from '../../../../services/types/Application/KaeplaSimulation';
import { KaeplaSimulationRulesetWithParameters } from '../../../../services/types/Application/KaeplaSimulationRulesetWithParameters';
import { cleanColumnName } from '../../../helpers/cleanColumnName';
import { DimensionSummaryTable } from '../../Scopes/features/DimensionSummaryTable';

interface Options {
  simulation?: KaeplaSimulation;
  setSimulation: Dispatch<SetStateAction<KaeplaSimulation | undefined>>;
  simulationRulesets?: KaeplaSimulationRulesetWithParameters[];
  setSimulationRulesets: Dispatch<
    SetStateAction<KaeplaSimulationRulesetWithParameters[] | undefined>
  >;
  setAddingParameter: Dispatch<SetStateAction<boolean>>;
  ruleset: KaeplaSimulationRulesetWithParameters;
}

const isEligibleForAggregationDimension = (detail: KaeplaDimensionDetails) => {
  if (detail.dataType !== 'FLOAT64') return false;
  if (detail.isCalculated) return false;
  return true;
};

const eligibleNumberOverTimeDimensions = (details?: KaeplaDimensionDetails[]) => {
  if (!details) return [];
  return details.filter((detail) => isEligibleForAggregationDimension(detail));
};

const isEligibleForTextValueChangeDimension = (detail: KaeplaDimensionDetails) => {
  if (detail.dataType !== 'STRING') return false;
  if (detail.isCalculated) return false;
  return true;
};

const eligibleTextChangeDimensions = (details?: KaeplaDimensionDetails[]) => {
  if (!details) return [];
  return details.filter((detail) => isEligibleForTextValueChangeDimension(detail));
};

export const AddSimulationParameterToRuleset = ({
  ruleset,
  setAddingParameter,
  simulation,
  setSimulation,
  simulationRulesets,
  setSimulationRulesets,
}: Options) => {
  const { kaeplaUser } = useAuth();
  const matrixFiltered = useRecoilValue(matrixFilteredState);
  const currentScopePath = useRecoilValue(currentScopePathState);
  const chartColors = [...colors[0], ...colors[1]];

  const project = useRecoilValue(projectState);
  const [selectedNumericParameter, setSelectedNumericParameter] = useState('');
  const [selectedTextParameter, setSelectedTextParameter] = useState('');
  const [selectedTextParameterDetails, setSelectedTextParameterDetails] =
    useState<KaeplaDimensionDetails>();
  const [savingParameter, setSavingParameter] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const years = useMemo(
    () => matrixFiltered?.simulationYears?.years || [],
    [matrixFiltered?.simulationYears?.years],
  );

  const handleNumericParameterChange = (event: SelectChangeEvent) => {
    setSelectedTextParameter('');
    setSelectedNumericParameter(event.target.value);
  };

  const handleTextParameterChange = (event: SelectChangeEvent) => {
    setSelectedNumericParameter('');
    setSelectedTextParameter(event.target.value);
    if (matrixFiltered?.details) {
      const details = matrixFiltered?.details.find((d) => d.columnName === event.target.value);
      setSelectedTextParameterDetails(details);
    }
  };

  const addTextParameterButDoNotSaveYet = () => {
    if (!kaeplaUser?.uid || !simulation) return;
    setSavingParameter(true);
    const newSimulation = { ...simulation };
    const newRulesets = [...newSimulation.rulesets];
    newRulesets.push(ruleset);
    newSimulation.rulesets = newRulesets;

    const extendedRulesets = addTextParameterToSimulationRulesets({
      ruleset,
      parameter: selectedTextParameter,
      simulationRulesets: simulationRulesets || [],
    });

    setSimulationRulesets(extendedRulesets);

    void saveRulesets({
      project,
      simulation,
      simulationRulesets: extendedRulesets,
      scopePath: currentScopePath,
      uid: kaeplaUser.uid,
      callBack: (success: boolean) => {
        // TODO: proper error handling
        if (success) {
          // update firestore
          newSimulation.rulesets = extendedRulesets;
          setSimulation(newSimulation);
          void updateSimulationWithScope({
            project,
            scopePath: currentScopePath,
            simulation: newSimulation,
          });
        }
        setAddingParameter(false);
        setSavingParameter(false);
      },
    });
  };

  const addNumericParameterAndSave = () => {
    if (!kaeplaUser?.uid || !simulation) return;
    setSavingParameter(true);
    const newSimulation = { ...simulation };
    const newRulesets = [...newSimulation.rulesets];
    newRulesets.push(ruleset);
    newSimulation.rulesets = newRulesets;

    const extendedRulesets = addNumericParameterToSimulationRulesets({
      ruleset,
      parameter: selectedNumericParameter,
      parameterYears: years,
      simulationRulesets: simulationRulesets || [],
    });

    setSimulationRulesets(extendedRulesets);

    // update bQ
    void saveRulesets({
      project,
      simulation,
      simulationRulesets: extendedRulesets,
      scopePath: currentScopePath,
      uid: kaeplaUser.uid,
      callBack: (success: boolean) => {
        // TODO: proper error handling
        if (success) {
          // update firestore
          newSimulation.rulesets = extendedRulesets;
          setSimulation(newSimulation);
          void updateSimulationWithScope({
            project,
            scopePath: currentScopePath,
            simulation: newSimulation,
          });
        }
        setAddingParameter(false);
        setSavingParameter(false);
      },
    });
  };

  if (!simulationRulesets || !simulation) return null;

  return (
    <Box component={Paper} sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleTabsChange}>
          <Tab label="Numeric/Money Values" />
          <Tab label="Text Values" />
        </Tabs>
      </Box>
      <AddSimulationParameterTabPanel value={tabValue} index={0}>
        <Grid sx={{ maxWidth: 600 }} container spacing={2}>
          <Grid item xs={12}>
            Simulate the change of numeric values such as money or volumes over time. You can set
            approximate percentages and precise figures.
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
              <FormControl sx={{ m: 0, minWidth: 220 }} size="small" margin="dense">
                {savingParameter && <LinearProgress />}
                {/* Maybe use DimensionSelect from PerspectiveBlocks component here? */}
                <Select
                  id="simulationSelect"
                  value={selectedNumericParameter || ''}
                  onChange={handleNumericParameterChange}
                  size="small"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {eligibleNumberOverTimeDimensions(matrixFiltered?.details)
                    .filter(
                      (detail) =>
                        !ruleset.parameters.some((p) => p.dimension === detail.columnName),
                    )
                    .map((detail) => (
                      <MenuItem key={detail.columnName} value={detail.columnName}>
                        {cleanColumnName(detail.columnName)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                disabled={selectedNumericParameter === ''}
                onClick={() => {
                  addNumericParameterAndSave();
                }}
              >
                add
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setAddingParameter(false);
                }}
              >
                cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </AddSimulationParameterTabPanel>
      <AddSimulationParameterTabPanel value={tabValue} index={1}>
        <Grid sx={{ maxWidth: 600 }} container spacing={2}>
          <Grid item xs={12}>
            Simulate the change of non-numeric values such as locations or categories. We'll get all
            possible values for you.
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
              <FormControl sx={{ m: 0, minWidth: 220 }} size="small" margin="dense">
                {savingParameter && <LinearProgress />}
                <Select
                  id="simulationSelect"
                  value={selectedTextParameter || ''}
                  onChange={handleTextParameterChange}
                  size="small"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {eligibleTextChangeDimensions(matrixFiltered?.details)
                    .filter(
                      (detail) =>
                        !ruleset.parameters.some((p) => p.dimension === detail.columnName),
                    )
                    .sort((a, b) => a.columnName.localeCompare(b.columnName))
                    .map((detail) => (
                      <MenuItem key={detail.columnName} value={detail.columnName}>
                        {cleanColumnName(detail.columnName)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                disabled={selectedTextParameter === ''}
                onClick={() => {
                  addTextParameterButDoNotSaveYet();
                }}
              >
                add
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setAddingParameter(false);
                }}
              >
                cancel
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {selectedTextParameterDetails && (
              <DimensionSummaryTable
                detail={selectedTextParameterDetails}
                chartColors={chartColors}
              />
            )}
          </Grid>
        </Grid>
      </AddSimulationParameterTabPanel>
    </Box>
  );
};
