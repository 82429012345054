import { Box } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';
import { clone, move } from 'rambda';

import { KaeplaDimension } from '../../../../services/types/Application/KaeplaDimension';
import { KaeplaProject } from '../../../../services/types/Application/KaeplaProject';
import { KaeplaSimulation } from '../../../../services/types/Application/KaeplaSimulation';
import { cleanColumnName } from '../../../helpers/cleanColumnName';
import { formatValueWithDimension } from '../../../helpers/formatValueWithDimension';
import {
  matrixDataColor,
  matrixDataColorLight,
  simulationDataColor,
  simulationDataColorLight,
} from '../../defaults';

export const getColumns = (
  project: KaeplaProject,
  dimensions?: KaeplaDimension[],
  simulation?: Partial<KaeplaSimulation>,
) => {
  const columnsUnordered = dimensions || [];
  let columnsOrdered = clone(columnsUnordered);
  let index = 1;
  if (project?.defaultPerspective?.timeDimension) {
    const indexOfTimeDimension = columnsOrdered
      .map((d) => d.columnName)
      .indexOf(project.defaultPerspective.timeDimension);
    if (indexOfTimeDimension > 0) {
      columnsOrdered = move(indexOfTimeDimension, index, columnsOrdered);
      index++;
    }
  }
  if (project?.defaultPerspective?.valueDimension) {
    const indexOfAggregationDimension = columnsOrdered
      .map((d) => d.columnName)
      .indexOf(project.defaultPerspective.valueDimension);
    if (indexOfAggregationDimension > 0) {
      columnsOrdered = move(indexOfAggregationDimension, index, columnsOrdered);
      index++;
    }
    // Also display the simulation according to the perspective main aggregation
    if (simulation && project?.defaultPerspective.valueDimension) {
      const simulationDimensionIdentified =
        dimensions?.find(
          (dimension) => dimension.columnName === project?.defaultPerspective?.valueDimension,
        ) || ({} as unknown as KaeplaDimension);
      const simulationDimension = { ...simulationDimensionIdentified };
      simulationDimension.columnName = `${project.defaultPerspective.valueDimension}__Simulated`;
      columnsOrdered.push(simulationDimension);
      const indexOfAggregationDimensionS = columnsOrdered
        .map((d) => d.columnName)
        .indexOf(simulationDimension.columnName);
      if (indexOfAggregationDimensionS > 0) {
        columnsOrdered = move(indexOfAggregationDimensionS, index, columnsOrdered);
        index++;
      }
    }
  }

  const colsMap = columnsOrdered.map((d) => {
    const column: MRT_ColumnDef = {
      accessorKey: d.columnName,
      id: d.columnName,
      header: cleanColumnName(d.columnName, '\u00A0'), // utf8 no break space
    };
    if (
      project?.defaultPerspective?.valueDimension &&
      d.columnName === project?.defaultPerspective?.valueDimension
    ) {
      // e.g. Item_Sum
      column.Cell = ({ cell }) => (
        <Box
          sx={() => ({
            backgroundColor: matrixDataColorLight,
            borderColor: matrixDataColor,
            borderWidth: '1px',
            borderRadius: '0.25rem',
            borderStyle: 'solid',
            p: 0.5,
            mt: -1,
            mb: -1,
          })}
        >
          {formatValueWithDimension(d, cell.getValue<string>() || '0')}
        </Box>
      );
    } else if (
      project?.defaultPerspective?.valueDimension &&
      d.columnName === `${project.defaultPerspective.valueDimension}__Simulated`
    ) {
      // e.g. Item_Sum__Simulated
      column.Cell = ({ cell, row }) => {
        const originalRow = row.original as Record<string, number>;
        let originalValue = '0';
        if (project?.defaultPerspective?.valueDimension) {
          originalValue = `${originalRow[project.defaultPerspective.valueDimension]}`;
        }
        const thisValue = cell.getValue<string>() || '0';

        return (
          <Box
            sx={() => ({
              backgroundColor:
                `${originalValue}` === `${thisValue}` ? 'transparent' : simulationDataColorLight,
              borderColor: simulationDataColor,
              borderWidth: '1px',
              borderRadius: '0.25rem',
              borderStyle: 'dashed',
              p: 0.5,
              mt: -1,
              mb: -1,
            })}
          >
            {formatValueWithDimension(d, cell.getValue<string>() || '0')}
          </Box>
        );
      };
    } else if (d.currency) {
      // currency dimensions
      column.Cell = ({ cell }) => formatValueWithDimension(d, cell.getValue<string>() || '0');
    }
    return column;
  });

  return colsMap;
};
