import { ChartOptions, TooltipItem } from 'chart.js';
import numbro from 'numbro';

import { cleanColumnName } from '../../../../../../helpers/cleanColumnName';
import { matrixDataColorLight } from '../../../../../defaults';

const formatLabel = (context: TooltipItem<'bar'>) => {
  const label = context.dataset.label || 'n/a';
  const value = context.parsed.y;
  const valuehr =
    '€' +
    numbro(value).format({
      average: true,
      mantissa: 1,
    });
  return `${cleanColumnName(label)}: ${valuehr}`;
};

const formatTicks = (value: string | number) => {
  return (
    '€' +
    numbro(value).format({
      average: true,
      mantissa: 1,
    })
  );
};

export const stackedBarChartConfig: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: formatLabel,
      },
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        drag: {
          enabled: true,
          backgroundColor: matrixDataColorLight,
        },
        mode: 'x',
      },
      pan: {
        enabled: true,
        modifierKey: 'shift',
      },
    },
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        callback: formatTicks,
      },
    },
  },
};
