import { getApp } from 'firebase/app';
import { doc, DocumentData, getFirestore, updateDoc } from 'firebase/firestore';

import { KaeplaCustomer } from '../types/Application/KaeplaCustomer';

interface Options {
  customer: Partial<KaeplaCustomer>;
}

export const updateCustomer = async ({ customer }: Options) => {
  if (!customer.id) return;
  const firestoreDatabase = getFirestore(getApp('kaepla'));
  const fireStorePath = `customers/${customer.id}`;
  const firestoreDocumentReference = doc(firestoreDatabase, fireStorePath);
  await updateDoc(firestoreDocumentReference, customer as DocumentData);
};
