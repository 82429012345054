import { Dispatch, SetStateAction } from 'react';

import { DonutChartEditor } from './DonutChart/DonutChartEditor';
import { StackedBarChartChartEditor } from './StackedBarChart/StackedBarChartChartEditor';
import { KaeplaPerspectiveBlock } from '../../../../../../services/types/Application/KaeplaPerspectiveBlock';

interface EOptions {
  block: KaeplaPerspectiveBlock;
  setAggregation: Dispatch<SetStateAction<string>>;
  setValueDimension: Dispatch<SetStateAction<string>>;
  setAggregationDimension: Dispatch<SetStateAction<string>>;
  setTimeDimension: Dispatch<SetStateAction<string>>;
}
export const ChartEditorFromType = ({
  block,
  setAggregation,
  setValueDimension,
  setAggregationDimension,
  setTimeDimension,
}: EOptions) => {
  switch (block.type) {
    case 'AggregationOverTime': {
      return (
        <StackedBarChartChartEditor
          block={block}
          setAggregation={setAggregation}
          setValueDimension={setValueDimension}
          setAggregationDimension={setAggregationDimension}
          setTimeDimension={setTimeDimension}
        />
      );
    }
    case 'AggregationPercentages': {
      return (
        <DonutChartEditor
          block={block}
          setAggregation={setAggregation}
          setValueDimension={setValueDimension}
          setAggregationDimension={setAggregationDimension}
        />
      );
    }
    case 'TreeMap': {
      return null;
    }
    // No default
  }
  return null;
};
