import DeleteIcon from '@mui/icons-material/Delete';
import FavoriteIcon from '@mui/icons-material/StarRounded';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey, yellow } from '@mui/material/colors';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useAuth } from 'src/AuthProvider';
import { UserAvatar } from 'src/Frontend/features/UserAvatar';
import {
  convertTimestamp,
  HumanReadableTimestampType,
} from 'src/Frontend/helpers/convertTimestamp';
import { scopePathSimplified } from 'src/Frontend/helpers/scopePathSimplified';
import { useUserPreferences } from 'src/UserPreferencesProvider';
import { createEvent } from 'src/services/firestore/createEvent';
import { deletePerspective } from 'src/services/firestore/deletePerspective';
import { perspectiveFavoritesState } from 'src/services/recoil/nonpersistent/perspectiveFavoritesState';
import { perspectiveState } from 'src/services/recoil/nonpersistent/perspectiveState';
import { perspectivesState } from 'src/services/recoil/nonpersistent/perspectivesState';
import { projectState } from 'src/services/recoil/nonpersistent/projectState';
import { scopePathsState } from 'src/services/recoil/nonpersistent/scopePathsState';
import { projectAssignmentsState } from 'src/services/recoil/nonpersistent/userAssignmentState';
import { currentScopePathState } from 'src/services/recoil/persistent/currentScopePathState';
import { kaeplaAssignmentState } from 'src/services/recoil/persistent/kaeplaAssignmentState';
import { knownUsersState } from 'src/services/recoil/persistent/knownUsersState';
import { KaeplaDataOperation } from 'src/services/types/Application/KaeplaDataOperation';
import { KaeplaEventType } from 'src/services/types/Application/KaeplaEventType';
import { KaeplaFunctionGroup } from 'src/services/types/Application/KaeplaFunctionGroup';
import { KaeplaPerspective } from 'src/services/types/Application/KaeplaPerspective';

import { updatePerspectiveFavorites } from './helpers/updatePerspectiveFavorites';
import { checkPathIsInScope } from '../Scopes/helpers/checkPathIsInScope';

interface Options {
  perspective: KaeplaPerspective;
  own?: boolean;
}

export const PerspectiveCard = ({ perspective, own }: Options) => {
  const { kaeplaUser } = useAuth();
  const navigate = useNavigate();
  const { setPreferences } = useUserPreferences();
  // get
  const project = useRecoilValue(projectState);
  const knownUsers = useRecoilValue(knownUsersState);
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const projectAssignments = useRecoilValue(projectAssignmentsState);
  // get & set
  const [currentScopePath, setCurrentScopePath] = useRecoilState(currentScopePathState);
  const [scopePaths, setScopePaths] = useRecoilState(scopePathsState);
  const [perspectiveFavorites, setPerspectiveFavorites] = useRecoilState(perspectiveFavoritesState);
  // set
  const setPerspective = useSetRecoilState(perspectiveState);
  const setPerspectives = useSetRecoilState(perspectivesState);

  const owner = knownUsers.find((user) => user.uid === perspective.createdBy);
  const [hover, setHover] = useState(false);

  const isFavorite = () => {
    return perspectiveFavorites
      .filter((s) => s.isFavorite)
      .some((s) => s.perspectiveId === perspective.id);
  };

  const isInScope = () => {
    if (project.id === 'default') return true;
    return checkPathIsInScope({
      project,
      projectAssignments,
      scopePath: perspective.scopePath,
    });
  };

  const goToPerspective = () => {
    if (!kaeplaUser?.uid) return;
    if (!perspective.scopePath) return;
    if (!isInScope()) {
      return;
    }
    // TODO: ask user if it is okay to switch the scope if we're not already here
    setCurrentScopePath(perspective.scopePath);
    const newScopePaths = { ...scopePaths };
    newScopePaths[project.id] = perspective.scopePath;
    setScopePaths(newScopePaths);

    setPerspective(perspective);
    setPreferences({ lastPerspectiveId: perspective.id });
    updatePerspectiveFavorites({
      uid: kaeplaUser.uid,
      project,
      perspective,
      currentScopePath,
      perspectiveFavorites,
      setPerspectiveFavorites,
      isFav: true,
    });

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PERSPECTIVES_SELECT_PERSPECTIVE,
      functionGroup: KaeplaFunctionGroup.PERSPECTIVES,
      operation: KaeplaDataOperation.READ,
      project,
      scopePath: perspective.scopePath,
    });
    navigate('/Perspective');
  };

  const removePerspective = async () => {
    if (!kaeplaUser?.uid) return;
    if (!perspective.scopePath) return;
    if (!isInScope()) {
      return;
    }

    const key = `${kaeplaUser.uid}-${perspective.id}`;
    setPerspectiveFavorites((favorites) => favorites.filter((f) => f.id !== key));

    await deletePerspective({ project, perspective });
    setPerspectives((perspectives) => perspectives.filter((p) => p.id !== perspective.id));

    void createEvent({
      uid: kaeplaUser?.uid,
      eventType: KaeplaEventType.PERSPECTIVES_DELETE_PERSPECTIVE,
      functionGroup: KaeplaFunctionGroup.PERSPECTIVES,
      operation: KaeplaDataOperation.DELETE,
      project,
      scopePath: perspective.scopePath,
    });

    navigate(`/Perspectives`);
  };

  return (
    <Card
      elevation={hover ? 8 : 2}
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => {
        goToPerspective();
      }}
      onFocus={() => {
        setHover(true);
      }}
      onBlur={() => {
        setHover(false);
      }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <CardHeader
        title={perspective.name}
        avatar={owner && <UserAvatar user={owner} />}
        subheader={
          perspective.createdAt
            ? `updated ${convertTimestamp(
                perspective.createdAt,
                HumanReadableTimestampType.timeago,
              )}`
            : 'did not run yet'
        }
      />
      <CardContent sx={{ minHeight: 150 }}>
        <Tooltip title={perspective.id}>
          <Link
            variant="caption"
            underline="hover"
            color="inherit"
            sx={{
              opacity: isInScope() ? 1 : 0.6,
            }}
            onClick={(event) => {
              event.stopPropagation();
              goToPerspective();
            }}
          >
            {[project.name, ...scopePathSimplified(perspective.scopePath)].join(' ➔ ')}
          </Link>
        </Tooltip>
      </CardContent>
      <CardActions sx={{ m: 0, pt: 0 }}>
        <IconButton
          aria-label="add to favorites"
          onClick={(event) => {
            if (!kaeplaUser?.uid) return;
            event.stopPropagation();

            updatePerspectiveFavorites({
              uid: kaeplaUser.uid,
              project,
              perspective,
              currentScopePath,
              perspectiveFavorites,
              setPerspectiveFavorites,
              isFav: !isFavorite(),
            });
          }}
        >
          <FavoriteIcon sx={{ color: isFavorite() ? yellow[800] : grey[400] }} />
        </IconButton>
        <Typography color="inherit" noWrap sx={{ flexGrow: 1 }} />
        {(own || kaeplaAssignment) && (
          <Tooltip title={perspective.id}>
            <IconButton
              sx={{ alignSelf: 'flex-end' }}
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                void removePerspective();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </CardActions>
    </Card>
  );
};
