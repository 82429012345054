import { DocumentData } from 'firebase/firestore';

import { addFirestoreDocumentListener } from './addFirestoreDocumentListener';

interface Options {
  projectId: string;
  callback: (document: DocumentData | undefined) => void;
}

export const addProjectListener = ({ projectId, callback }: Options) => {
  const fireStorePath = `projects/${projectId}`;
  return addFirestoreDocumentListener({ fireStorePath, callback });
};
