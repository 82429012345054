import { Box, Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Step0Setup } from './Step0Setup';
import { StepIdentifierDimension } from './Step1IdentifierDimension';
import { StepValueDimension } from './Step2ValueDimension';
import { StepTimeDimension } from './Step3TimeDimension';
import { StepCurrency } from './Step4Currency';
import { StepDone } from './Step5Done';
import { StepButtons } from './Stepper/StepButtons';
import { Steps } from './Stepper/Steps';
import { useAuth } from '../../../../AuthProvider';
import { getFromKaepla } from '../../../../services/api/getFromKaepla';
import { matrixFilteredState } from '../../../../services/recoil/nonpersistent/matrixFilteredState';
import { projectState } from '../../../../services/recoil/nonpersistent/projectState';
import { KaeplaApiParameters } from '../../../../services/types/Application/KaeplaApiParameters';
import { KaeplaPerspective } from '../../../../services/types/Application/KaeplaPerspective';
import { KaeplaQueryType } from '../../../../services/types/Application/KaeplaQueryType';
import { MatrixSummary } from '../../../../services/types/MatrixSummary';

export const PerspectiveWizard = () => {
  const { kaeplaUser } = useAuth();
  const [project, setProject] = useRecoilState(projectState);
  const setMatrixFiltered = useSetRecoilState(matrixFilteredState);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [defaultPerspective, setDefaultPerspective] = useState<Partial<KaeplaPerspective>>({});

  useEffect(() => {
    setLoading(true);
    const parameters: KaeplaApiParameters = {
      q: 'summary' as KaeplaQueryType,
      p: [] as string[],
      projectId: project.id,
      s: 'Wizard',
    };
    getFromKaepla({
      callBack: (apiResponse) => {
        // console.log("->", apiResponse);
        if (!apiResponse) {
          setProject((_project) => ({ ..._project, matrixUnavailable: true }));
          setLoading(false);
          return;
        }
        setProject((_project) => ({ ..._project, matrixUnavailable: false }));
        const response = apiResponse.response as MatrixSummary;
        setMatrixFiltered((_matrixFiltered) => ({ ..._matrixFiltered, ...response }));
      },
      setLoading,
      params: parameters,
      uid: kaeplaUser?.uid,
    });
  }, [kaeplaUser?.uid, project.id, setMatrixFiltered, setProject]);

  return (
    <Paper sx={{ p: 2, bgcolor: 'transparent' }} variant="outlined" square>
      <Grid container>
        <Grid item xs={12} sm={6} md={8}>
          <Steps activeStep={step} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StepButtons
            activeStep={step}
            setStep={setStep}
            defaultPerspective={defaultPerspective}
            setDefaultPerspective={setDefaultPerspective}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ m: 3 }} />
        </Grid>
        <Grid item xs={12}>
          {!step && <Step0Setup setStep={setStep} loading={loading} />}
          {step === 1 && (
            <StepIdentifierDimension
              setDefaultPerspective={setDefaultPerspective}
              defaultPerspective={defaultPerspective}
            />
          )}
          {step === 2 && (
            <StepValueDimension
              setDefaultPerspective={setDefaultPerspective}
              defaultPerspective={defaultPerspective}
            />
          )}
          {step === 3 && (
            <StepTimeDimension
              setDefaultPerspective={setDefaultPerspective}
              defaultPerspective={defaultPerspective}
            />
          )}
          {step === 4 && <StepCurrency />}
          {step === 5 && (
            <StepDone
              setDefaultPerspective={setDefaultPerspective}
              defaultPerspective={defaultPerspective}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};
