import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/StarRounded';
import {
  Alert,
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material';
import { yellow } from '@mui/material/colors';
import copy from 'copy-to-clipboard';
import Image from 'mui-image';
import { SyntheticEvent, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { InlineEdit } from './InlineEdit';
import { useAuth } from '../../AuthProvider';
import { createOrUpdateUser } from '../../services/firestore/createOrUpdateUser';
import { kaeplaAssignmentState } from '../../services/recoil/persistent/kaeplaAssignmentState';
import { KaeplaUser } from '../../services/types/Application/KaeplaUser';
import { avatarAppearDuration } from '../Screens/defaults';

interface Options {
  user: KaeplaUser;
  self?: boolean;
  star?: boolean;
  lastInviteSent?: string;
  removeCallback?: (user: KaeplaUser) => void;
  resendInviteCallback?: (user: KaeplaUser) => void;
  size?: number;
  sx?: SxProps<Theme>;
}

export const UserAvatar = ({
  user,
  self,
  star,
  removeCallback,
  lastInviteSent,
  resendInviteCallback,
  size,
  sx,
}: Options) => {
  const { kaeplaUser } = useAuth();
  const kaeplaAssignment = useRecoilValue(kaeplaAssignmentState);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event: SyntheticEvent) => {
    setOpen(true);
    event.stopPropagation();
  };

  const handleClose = (event: SyntheticEvent) => {
    setOpen(false);
    event.stopPropagation();
  };

  let acronym = '';
  if (user.acronym) {
    acronym = user.acronym;
  } else if (user.displayName) {
    acronym = user.displayName;
  }
  let content: JSX.Element = <Box>{acronym.slice(0, 2)}</Box>;
  if (user.base64Avatar) {
    content = (
      <Image alt={user.displayName} src={user.base64Avatar} duration={avatarAppearDuration} />
    );
  }
  return (
    <>
      <Tooltip placement="top" key={user.uid} title={`${user.uid} - ${user.email}`}>
        <Badge
          color="default"
          overlap="circular"
          badgeContent={<StarIcon sx={{ fontSize: 20, color: yellow['A700'] }} />}
          invisible={!star}
        >
          <Avatar
            data-testid="user-avatar"
            data-acronym={user.acronym}
            data-email={user.email}
            sx={{ ...sx, width: size || 45, height: size || 45, cursor: 'pointer' }}
            onClick={handleClickOpen}
          >
            {content}
          </Avatar>
        </Badge>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <IconButton
            size="small"
            data-testid="user-dialog-close"
            sx={{ position: 'absolute', top: 0, right: 0 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                data-testid="user-dialog-user-avatar"
                sx={{ width: 100, height: 100 }}
                onClick={(event) => {
                  copy(user.uid);
                  event.stopPropagation();
                }}
              >
                {content}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              sx={{ pl: 2 }}
              primary={
                kaeplaAssignment ? (
                  <InlineEdit
                    fullWidth
                    value={user.displayName || ''}
                    callback={(newValue) => {
                      const update = { uid: user.uid, displayName: newValue };
                      void createOrUpdateUser({ user: update });
                    }}
                  />
                ) : (
                  user.displayName
                )
              }
              secondary={
                <Box component="span">
                  <Box component="span" data-testid="user-dialog-email">
                    {user.email}
                  </Box>
                  <br />
                  <Box component="span" data-testid="user-dialog-lastInviteSent">
                    invited {lastInviteSent}
                  </Box>
                </Box>
              }
              primaryTypographyProps={{ sx: { fontSize: '150%' } }}
              secondaryTypographyProps={{ sx: { fontSize: '120%' } }}
            />
          </ListItem>

          {removeCallback && star && (
            <Alert data-testid="user-dialog-remove-self-warning" severity="warning">
              You can remove yourself from this team, however to get back into the team you need to
              be invited again.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          {resendInviteCallback && kaeplaUser?.uid !== user.uid && (
            <Button
              data-testid="user-dialog-resend-invite"
              size="small"
              variant="contained"
              color="primary"
              onClick={(event) => {
                resendInviteCallback(user);
                setTimeout(() => {
                  // let the user see the effect
                  // TODO: make all this async/await
                  setOpen(false);
                }, 1500);
                event.stopPropagation();
              }}
            >
              Resend Invite Email
            </Button>
          )}
          {removeCallback && (
            <Button
              data-testid={self ? 'user-dialog-leave' : 'user-dialog-remove'}
              size="small"
              variant="contained"
              color="error"
              onClick={(event) => {
                removeCallback(user);
                setOpen(false);
                event.stopPropagation();
              }}
            >
              {self ? 'Leave Team' : 'Remove from Team'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
