import { AsyncFetchListValuesResult, SimpleField } from '@react-awesome-query-builder/mui';

import { typeMap } from './typeMap';
import { searchDimensionValues } from '../../../../services/api/searchDimensionValues';
import { KaeplaApiParameters } from '../../../../services/types/Application/KaeplaApiParameters';
import { KaeplaDimension } from '../../../../services/types/Application/KaeplaDimension';
import { KaeplaProject } from '../../../../services/types/Application/KaeplaProject';
import { KaeplaQueryType } from '../../../../services/types/Application/KaeplaQueryType';
import { cleanColumnName } from '../../../helpers/cleanColumnName';

interface Options {
  dimension: KaeplaDimension;
  project: KaeplaProject;
  currentScopePath: string[];
  uid: string;
}

export const getFieldSetting = ({ dimension, project, currentScopePath, uid }: Options) => {
  const fieldName = dimension.columnName;

  const getValuesForDimension = async (search: string | null, offset: number) => {
    const result: AsyncFetchListValuesResult = {
      values: [],
      hasMore: true,
    };
    if (!search) {
      return result;
    }

    const parameters: KaeplaApiParameters = {
      q: 'dimensionValueSearch' as KaeplaQueryType,
      projectId: project.id,
      dimension: fieldName,
      searchKey: search,
      limit: 5,
      offset,
    };

    parameters.p = currentScopePath;

    const searchResult = await searchDimensionValues({ ...parameters, uid });
    const resultData = searchResult.data;
    const response = (resultData as Record<string, Record<string, unknown>>).response;
    result.values = response.values as string[];
    result.hasMore = response.hasMore as boolean;
    return result;
  };

  switch (dimension.dataType) {
    case 'STRING': {
      return {
        label: cleanColumnName(fieldName),
        type: typeMap[dimension.dataType],
        fieldSettings: {
          asyncFetch: getValuesForDimension,
          useAsyncSearch: true,
          useLoadMore: true,
          forceAsyncSearch: false,
          allowCustomValues: false,
        },
        valueSources: ['value'],
      } as SimpleField;
    }
    case 'Date': {
      return {
        label: cleanColumnName(fieldName),
        type: typeMap[dimension.dataType],
        valueSources: ['value'],
      } as SimpleField;
    }
    case 'FLOAT64': {
      return {
        label: cleanColumnName(fieldName),
        type: typeMap[dimension.dataType],
        valueSources: ['value'],
      } as SimpleField;
    }
    default: {
      return {
        label: cleanColumnName(fieldName),
        type: typeMap[dimension.dataType],
        valueSources: ['value'],
      } as SimpleField;
    }
  }
};
